import React,{useState,useEffect} from 'react';
import { useParams} from "react-router-dom";
import { Controller, useForm} from "react-hook-form";

import MUIDataTable from "mui-datatables";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import '../../constant.css';
import '../transaction/transactions.css';
import { Select, MenuItem } from "@material-ui/core";
import moment from 'moment';
import './merchandiselist.css';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import { CSVLink } from "react-csv";
import {Button,} from "@material-ui/core";
import ViewMembershipConfig from './merchandise/viewmembershipconfig';

interface Props {
    onParentRender: () => void,
  }

interface ParamTypes {
    orgId: string;
}



interface IFormAddMembership {
    membershipname: string,
    membershipkit: string,
}


let defaultValues: any = {
    // selectedMerImg: '',
    membershipname: 'All',
    membershipkit: 'All',
}
export const GET_MEMBERSHIP_BYSIZE = gql`
query getMembershipInventory($membership_refrence_id: String!, $club_id: String!){
    getMembershipInventory(membership_refrence_id:$membership_refrence_id, club_id: $club_id){
        id
    	size
    	status
	    membership_refrence_id
    	created_at
    	updated_at
    	membershipName
    }
}`;

export const GET_MEMBERSHIP_ALL = gql`
query getMembershipAll($club_id: String!){
    getMembershipAll(club_id: $club_id){
        name
        img_url
      	status
      	club_id
      	refrence_id
        id
        buyer_type_id
        faqsLink
    }
}   
`
export default function MembershipConfig({onParentRender}:Props) {

    let { orgId } = useParams<ParamTypes>();
    const [membershipRefId, setMembershipRefId] = React.useState("");
    const [membershipKit, setMembershipKit] = React.useState("All");

    const { refetch, loading, data } = useQuery<any>(GET_MEMBERSHIP_BYSIZE, { variables: {membership_refrence_id:membershipRefId,club_id:orgId}});
    const { refetch:membershipAllRefetch, loading:membershipAllLoading, data:membershipAllData } = useQuery<any>(GET_MEMBERSHIP_ALL, { variables: {club_id:orgId}});
    const [membersData, setMembersData] = React.useState([]);
    const [arrayToCsv, setArrayToCsv] = useState<any>([]);
    
    const [open, setOpen] = React.useState(false);
    const [errorMsg, setErrorMsg] = useState<any>("");
    const [openToast, setOpenToast] = React.useState<any>(false);
    const [memberId , setMemberId] = React.useState<any>(0);
    const [viewType , setViewType] = React.useState<any>("Create");
    const [singleData, setSingleData] = React.useState([]);

    const {  control, } = useForm<IFormAddMembership>({ defaultValues, shouldUnregister: false });
    
    // to open error messages.
    const Alert = (props: AlertProps)  => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    console.log('Mererasda', membershipAllData);

    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };
    const handleClose = () => {
        setOpen(false);
        setMemberId(0);
        setViewType('Create');
        refetch();  // reload 
    };

    const handleEditClickOpen = (id:string) => {
        //console.log(id);
        setOpen(true);
        setMemberId(id);
        setViewType('Edit');
        // console.log(id);
    };

    const getMembershipsConfigList = (objData:any) => {
        let newMembershipData:any = []
        for (let i=0; i<objData.length; i++) {
            newMembershipData.push ({
                id: objData[i].id,
                membership_refrence_id: objData[i].membership_refrence_id,
                size: objData[i].size,
                status: objData[i].status,
                membershipName: objData[i].membershipName,
                updated_at: objData[i].updated_at,
                // membersUpdatedDate: objData[i].membersUpdatedDate,
                membersEditDet: JSON.stringify([{
                    id: objData[i].id,
                    membershipData: objData[i],
                }])
            })
        }
        setMembersData(newMembershipData)
    }

    useEffect(() => {
        console.log('DATA', data)
        if (!loading) getMembershipsConfigList(data.getMembershipInventory)
        // onParentRender();
    },[data]);
    
    const arrayToCsvFunc = (data: any) => {
        const titleKeys = Object.keys(data[0])
        let newMemberData = data.map((obj: any) => {
            return {
                membershipName : obj.membershipName,
                size: obj.size,
                status: obj.status,
                membership_refrence_id: obj.membership_refrence_id,
                membershipDate: moment(parseInt(obj.membersUpdatedDate)).format('MM/DD/YYYY hh:mm:ss')
            }
        });
        // let newData = data.map((obj: any) => {
        //     let newObj = { ...obj };
        //     delete newObj.id; // Remove the "id" property
        //     return newObj; // Return modified object
        // });
        const refinedData: any = [
            ['Member Name', 'Membership Name',  'Membership Kit',  'Patron ID',  'Patron Name', 'Members Kit Fulfillment Status', 'Fulfillment Update Date', ],
        ]
        newMemberData.forEach((item: any) => {
            refinedData.push(Object.values(item))
        })
        let csvContent = ''
        refinedData.forEach((row: any) => {
            csvContent += row.join(',') + '\n'
        })
        return csvContent;
    }
    useEffect(() => {
        if(membersData.length > 1){
            setArrayToCsv(arrayToCsvFunc(membersData));
        }
        // onParentRender();
    },[membersData]);

    // to pass table columns
    const columns:any = [
        {
            name:"membershipName",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "membershipName")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Memberships</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"size",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "size")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Size</a>
                        </th>
                    )
                }
            }
        },
        {
            name: 'status',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "status")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(8)} >
                            <a className="sort-by">Fulfillment Status</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    //console.log(value);
                    return (
                        <div><label className={value === 'true' ? 'status-active' : 'status-inactive'}>{value === 'true' ? "Available" : "Sold Out"}</label></div>
                    )
                }
            }
        },
        
        {
            name: 'updated_at',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { 
                    let orderName = (sortOrder.name == "updated_at")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Membership Config Updated_at</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    // console.log(value);
                    return (
                        // <div><label className="">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>
                        <div><label className="">{moment(parseInt(value)).format('MM/DD/YYYY hh:mm:ss')}</label></div>
                    )
                }
            }
        },
        {
            name: 'membersEditDet',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "membersEditDet")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(9)} >
                            {/* <a className="sort-by">Edit</a> */}
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let fieldValues = JSON.parse(value);
                    let userListingId = fieldValues[0].id;
                    // let fulfillmentStatus = fieldValues[0].fulfillmentStatus;
                    
                    return (
                        // <div><label className="">{value ? "true":"Intrue"}</label></div>
                        <label className=""><span onClick={() => {handleEditClickOpen(userListingId); setSingleData(tableMeta.tableData[tableMeta.rowIndex])}} data-id={userListingId} data-view="Edit" className="table-edit-icon"></span></label>
                    )
                }
            }
        },
    ];

    // // to se various options for table columns and rows.
    const options:any = {
        filter: false,
        filterType: "dropdown",
        responsive:"standard",
        print:false,
        download : false,
        pagination : true,
        viewColumns : false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader : true,
        tableBodyHeight:'450px',
        search:true,
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        isRowExpandable: (dataIndex:any, expandedRows:any) => {
            // if (dataIndex === 1 || dataIndex === 2) return false;

            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            if (expandedRows.data.length > 1 && expandedRows.data.filter((d:any) => d.dataIndex === dataIndex).length === 0) return false;
            return true;
        },
        rowsExpanded: [], //[0, 1],
        onRowExpansionChange: (curExpanded:any, allExpanded:any, rowsExpanded:any) => console.log(curExpanded, allExpanded, rowsExpanded)
    }

    const handleChange = (e: React.ChangeEvent<{ value: string | unknown }>) => {
        const targetType = (e.target as HTMLInputElement).value;
        const targetName = (e.target as HTMLInputElement).name;

        if (targetName === 'membershipname') {
            setMembershipRefId(targetType)
        }

        if (targetName === 'membershipkit') {
            setMembershipKit(targetType)
        }

        refetch();
    }    
    return (
        <div className="list-page manage-member-wrapper">
            <Grid container xs={12} sm={12}>
                <Grid  item sm={12} xs={12}>
                    <Paper className="table-wrapper">
                        <Grid container className="table-head-section" sm={12}>
                            <Grid item sm={4}>
                                <label className="table-heading">MEMBERSHIP CONFIG LIST</label>
                            </Grid>
                            <Grid  item sm={8} className="select-with-label align-right flx-align-center flx-end">
                            
                            {/* Category */}
                            <div className="input-group" style={{marginRight: 10}}>
                                <label style={{marginRight: 10}}>Membership Name</label>
                                <Controller
                                    name="membershipname"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ value }) => (
                                        <Select label="Membership Name" displayEmpty name="membershipname" value={membershipRefId} className={`custom-select form-select`} onChange={(e) => handleChange(e)}>
                                            <MenuItem value=''>All</MenuItem>
                                            {membershipAllData?.getMembershipAll.map((res: any) => {
                                                return(<MenuItem value={res.id}>{res.name}</MenuItem>)
                                            })}
                                        </Select>
                                    )}
                                />
                            </div>
                            <Button className="btn btn-secondary" style={{ minWidth: "82px", }}>
                                <CSVLink filename={"members_list_export.csv"} data={arrayToCsv} className='import-btn-tile' style={{ color: "#8A8D9D", textDecoration: "none" }}>
                                    Export to CSV
                                </CSVLink>
                            </Button>
                            </Grid>
                            <Grid container className='cust-tble-hdr' xs={12} sm={12}>
                                <Grid item sm={12} xs={12}>
                                    <div className="table-container muidata-table cust-tbl-block">
                                        {loading ? (
                                            <Loader size="90px"/>
                                        ):(
                                            <MUIDataTable
                                                title={""}
                                                data={membersData}
                                                columns={columns}
                                                options={options}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>    
                </Grid>
            </Grid>
            
            {/* to open Add Merchandise Dialog */}
            {/* {open && <AddMerchandise onOpen={open} editId={fulfillmentId} viewTypeTxt={viewType} handleCloseClick={handleClose} />} */}
            <ViewMembershipConfig onOpen={open} editId={memberId} singleData={singleData} viewTypeTxt={viewType} handleCloseClick={handleClose} />

            {/* To display error message */}
            <Snackbar open={openToast} autoHideDuration={5000}  onClose={handleClosed}>
                <Alert onClose={handleClosed} severity="success">{errorMsg}</Alert>
            </Snackbar>
        </div>
    )
}
