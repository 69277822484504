import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import moment from 'moment'
import "moment-timezone"
import Snackbar from '@material-ui/core/Snackbar';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem, Switch } from "@material-ui/core";
import '../../../constant.css';
import './addmerchandise.css';
import { gql, useMutation, useQuery } from '@apollo/client';
import S3 from 'aws-s3';
//import { s3config } from "../../../s3config";
import { config, zoneList } from '../../../config';
import Loader from '../../../components/Loader/loader';
import SponsorEmty from '../../../assets/sponsor-empty@2x.svg';

import SponsorLogo from '../../../assets/sponsor-logo.png';

import MerchandiseLogo from '../../../assets/ic-default-logo.svg';
import PlusIcon from '../../../assets/ic-plus.svg';

import { stringify } from 'querystring';
import { AnyCnameRecord } from 'dns';

interface Props {
    onOpen: boolean,
    handleCloseClick: () => void,
    viewTypeTxt?: any,
    singleMerchData?: any,
    editId?: any
}

interface ParamTypes {
    orgId: string;
}

interface IFormAddMerchandise {
    category: string,
    membershipkit: string,
    memberName: string,
    sthExperience: string,
    pointsCost: string,
    description: string,
    emailVoucher: string,
    merFulfillment: string,
    merStatus: string
}

let defaultValues: any = {
    // selectedMerImg: '',
    category: '',
    memberName: '',
    membershipkit:'',
    sthExperience: '',
    pointsCost: '',
    description: '',
    emailVoucher: 'YES',
    merFulfillment: 'YES',
    merStatus: 'ACTIVE'
}

const UPDATE_FULFILLMENT = gql`
    mutation updateMembersKitFulfillmentStatus($id: String!, $membershipKit: String!, $member_name: String!, $memberskit_fulfillment: Boolean!){
        updateMembersKitFulfillmentStatus(id: $id, membershipKit: $membershipKit, member_name: $member_name, memberskit_fulfillment: $memberskit_fulfillment)
    }
`;

export default function ViewFulfillmentListItem({ handleCloseClick, onOpen, viewTypeTxt, editId, singleMerchData }: Props) {
    const editMerchandiseId = editId > 0 ? editId : 0;
    const open = onOpen;
    console.log('')
    const [category, setCategory] = useState<any>('Merch');
    const [membershipkit, setMembershipKit] = useState<any>('');
    const [membersName, setMembersName] = useState<any>('');

    const [cancelDisabled, setCancelDisabled] = useState<any>(true);

    const [statusType, setStatusType] = useState<any>('');

    const [loader, setLoader] = useState<any>(false);               // Loader icon

    let { orgId } = useParams<ParamTypes>();

    // Events display by month.

    const [confirmed, setConfirmed] = React.useState(false);
    const inputRef: any = useRef();

    const [errorMsg, setErrorMsg] = useState<any>("");
    const [openToast, setOpenToast] = React.useState<any>(false);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openCancelConfirm, setOpenCancelConfirm] = React.useState(false);

    const [confirmMessage, setConfirmMessage] = useState<any>("Are you sure with the Point Requested value?");


    const { register, handleSubmit, errors, control, reset, formState, setValue } = useForm<IFormAddMerchandise>({ defaultValues, shouldUnregister: false });
    console.log("SINGLE DATA >>>", singleMerchData)
    // to open error messages 
    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // to clear all hooks values here before cancel the dialogue box
    const handleClearAndClose = () => {
        setConfirmed(false);
        setOpenConfirm(false);
        setOpenCancelConfirm(false)
        setOpenToast(false);

        setValue("sthExperience", '');
        setValue("category", '');
        setValue("memberName", '');
        setValue("pointsCost", '');
        setValue("description", '');
        setValue("numberOfListing", 0);
        setValue("emailVoucher", 'YES');
        setValue("merFulfillment", 'YES');
        setValue("merStatus", 'ACTIVE');
        setMembershipKit('')
        setMembersName('')
        setCategory('')
        setStatusType('')
        setLoader(false)
        setCancelDisabled(true)

        handleCloseClick()
    }

    useEffect(() => {
        
        setCategory(singleMerchData.memberskitFulfillment);
        setMembershipKit(singleMerchData.membershipkit);
        setMembersName(singleMerchData.memberName);
        setValue('category', singleMerchData.memberskitFulfillment)
        setValue('membershipkit', singleMerchData.membershipkit)
        setValue('memberName', singleMerchData.memberName)
    }, [singleMerchData, onOpen]);

    // to close snack bar error message toaster.
    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const [updateFulfillmentStatus] = useMutation(UPDATE_FULFILLMENT, {
        // after updating the post, we go to the home page
        onCompleted: () => {
            handleCloseClick();
            setConfirmed(false)
            setOpenConfirm(false)
        }
    });

    // cancel click on confirm prompt.
    const clickCanceled = () => {
        setConfirmed(false);
        setOpenConfirm(false);
    }

    const clickConfirmed: SubmitHandler<IFormAddMerchandise> = (fieldData) => {
        // updateMerchandiseStatus({ variables: { merchandise_id: editMerchandiseId, merchandise_status: fieldData.merStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' } });
        updateFulfillmentStatus({ variables: { id: editId, memberskit_fulfillment: fieldData.category === 'true' ? true : false, membershipKit: fieldData.membershipkit, member_name: fieldData.memberName } });
       
        // setConfirmed(true);
        // setOpenConfirm(false);
    }

    const onCancel = () => {
        if (!cancelDisabled) {
            setConfirmMessage("Are you sure you want to cancel? By cancel this operation, you may lose all changes you made.");
            setOpenConfirm(true);
            setOpenCancelConfirm(true)
        } else {
            handleClearAndClose()
        }
    }

    const onHandleCancel = (objType: any) => {
        if (objType === 'YES') {
            handleClearAndClose()
        } if (objType === 'NO') {
            setOpenConfirm(false)
            setOpenCancelConfirm(false)
        }
    }

    const onSubmit: SubmitHandler<IFormAddMerchandise> = fieldData => {

        setConfirmMessage("Are you sure with the selected fulfillment status & Membership Kit?");

        if (!confirmed) setOpenConfirm(true);
    }

    // to change dropdown values here.
    const handleChange = (e: React.ChangeEvent<{ value: string | unknown }>) => {
        const targetType = (e.target as HTMLInputElement).value;
        const targetName = (e.target as HTMLInputElement).name;

        if (targetName === 'category') {
            setCategory(targetType)
            setValue('category', targetType)
        }

        if (targetName === 'membershipkit') {
            setMembershipKit(targetType)
            setValue('membershipkit', targetType)
        }

        if (targetName === 'memberName') {
            setMembersName(targetType)
            setValue('memberName', targetType)
        }

        setValue(targetName, targetType);
        setCancelDisabled(false)
    }

    return (
        <div>
            <Dialog open={onOpen} onClose={handleCloseClick} aria-labelledby="form-dialog-title" className="common-dialog-container merchandise-container confirmation-popup" disableBackdropClick={true} disableEscapeKeyDown={true}>
                <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Edit Fulfillment</DialogTitle>
                    <DialogContent>
                        {/* Full page */}
                        <Grid className="form-grid" container sm={12} spacing={6}>
                            {/* Left side block */}
                            <Grid className='pl-zero' item sm={4} xs={12}>
                                <div className="input-group">
                                    <label className="input-label">Members Name*</label>
                                    <Controller as={TextField} rules={{ required: true }} type="text" name="memberName" inputRef={inputRef} placeholder="Enter members name" className={`cust-color form-fields  ${errors.memberName && 'error'}`} control={control} defaultValue={defaultValues.memberName} onChange={(e: any) => handleChange(e)} />
                                    {errors.memberName && errors.memberName.type == "required" && (<label className="error-message">Field is required</label>)}
                                    {/* {errors.memberName && errors.memberName.type == "pattern" && (<label className="error-message">Please enter maximum of 2 decimal values(Ex:125.64)</label>)} */}
                                </div>
                                {/* Category */}
                                <div className="input-group">
                                    <label className="input-label">Fulfillment</label>
                                    <Controller
                                        name="category"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ value }) => (
                                            <Select displayEmpty name="category" value={value} className={`custom-select form-select  ${errors.category && 'error'}`} onChange={(e) => handleChange(e)}>
                                                <MenuItem value={'true'} >Delivered</MenuItem>
                                                <MenuItem value={'false'} >Not Delivered</MenuItem>
                                            </Select>
                                        )}
                                    />
                                    {errors.category && errors.category.type == "required" && (<label className="error-message">Field is required</label>)}
                                </div>
                                <div className="input-group">
                                    <label className="input-label">Membership Kit Size</label>
                                    <Controller
                                        name="membershipkit"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ value }) => (
                                            <Select displayEmpty name="membershipkit" value={value} className={`custom-select form-select  ${errors.category && 'error'}`} onChange={(e) => handleChange(e)}>
                                                <MenuItem value={'S'} >S</MenuItem>
                                                <MenuItem value={'M'} >M</MenuItem>
                                                <MenuItem value={'L'} >L</MenuItem>
                                                <MenuItem value={'XL'} >XL</MenuItem>
                                                <MenuItem value={'XXL'} >XXL</MenuItem>
                                            </Select>
                                        )}
                                    />
                                    {errors.category && errors.category.type == "required" && (<label className="error-message">Field is required</label>)}
                                </div>
                            </Grid>

                            {/* Right side block */}
                            <Grid item className="borderL padr0" sm={8} xs={12} >
                                <Grid container spacing={6}>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Patron ID</label><br/>
                                        <label className="input-label"  style={{fontWeight: "bold"}}>{singleMerchData.patronId}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Patron Name</label><br/>
                                        <label className="input-label"  style={{fontWeight: "bold"}}>{singleMerchData.patronName}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Members Name</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.memberName}</label>
                                        {/* <label className="input-label" style={{fontWeight: "bold"}}>{moment(parseInt(singleMerchData.transactionDate)).format('MM/DD/YYYY')}</label> */}
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Membership</label><br/>
                                        <label className="input-label"  style={{fontWeight: "bold"}}>{singleMerchData.membershipName}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Membership Kit</label><br/>
                                        <label className="input-label"  style={{fontWeight: "bold"}}>{singleMerchData.membershipkit}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Membership Kit Fulfillment Status</label><br/>
                                        <label className="input-label"  style={{fontWeight: "bold"}}>Delivered</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Membership Kit Fulfillment Date</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{moment(parseInt(singleMerchData.membersUpdatedDate)).format('MM/DD/YYYY')}</label>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Confirmation of Submit Merchandise Details */}
                        {openConfirm &&
                            <div className="confim-model-container cust-modal">
                                <Paper className="confirmation-wrapper padd-20">
                                    <Grid container sm={12}>
                                        <div className="confirmation-sec">
                                            <p>{confirmMessage}</p>
                                            <DialogActions>
                                                <Button className="btn btn-secondary" onClick={clickCanceled} color="primary">CANCEL</Button>
                                                <Button color="primary" onClick={handleSubmit(clickConfirmed)} className="btn btn-primary button">CONFIRM</Button>
                                            </DialogActions>
                                        </div>
                                    </Grid>
                                </Paper>
                            </div>
                        }

                        {openCancelConfirm &&
                            <div className="confim-model-container cust-modal">
                                <Paper className="confirmation-wrapper padd-20">
                                    <Grid container sm={12}>
                                        <div className="confirmation-sec">
                                            <p>{confirmMessage}</p>
                                            <DialogActions>
                                                <Button className="btn btn-secondary" onClick={() => onHandleCancel('NO')} color="primary">NO</Button>
                                                <Button color="primary" onClick={() => onHandleCancel('YES')} className="btn btn-primary button">YES</Button>
                                            </DialogActions>
                                        </div>
                                    </Grid>
                                </Paper>
                            </div>
                        }
                    </DialogContent>

                    {/* Submit Merchandise Details */}
                    <DialogActions>
                        <Button onClick={onCancel} className="btn btn-secondary" color="primary">CANCEL</Button>
                        <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>UPDATE</Button>
                    </DialogActions>
                </form>

                <Snackbar open={openToast} autoHideDuration={5000} onClose={handleClosed}>
                    <Alert onClose={handleClosed} severity="error">{errorMsg}</Alert>
                </Snackbar>
            </Dialog>
        </div>
    )
}




