import React, {useEffect, useState} from 'react'
import { useHistory } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Snackbar from '@material-ui/core/Snackbar';
import Resizer from 'react-image-file-resizer';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Loader from '../../../components/Loader/loader';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import '../../../constant.css';
import '../manageclubs.css';
import OrgImage from '../../../assets/Bitmap@2x.png';
import EmptyOrg from '../../../assets/org-empty@2x.svg';

import { gql, useMutation,useQuery } from '@apollo/client';
// import { CREATE_ORG } from '../../../graphql';
import S3 from 'aws-s3';
//import { s3config } from "../../../s3config";
import { config } from '../../../config';

import { stringify } from 'querystring';
 

interface Props {
    onOpen : boolean,
    handleCloseClick: () => void,
    editData?: string,
    editId?: string

}
interface IFormAddClub {
    orgLogo: string;
    name: string;
    orgCode: string;
    rate: number;
    rosterApi : string;
    scheduleApi : string;
    videoApi : string;
    eventsApi : string;
    newsApi : string;
    facebook : string;
    instagram : string;
    twitter : string;
}

let defaultValues:any = {
    orgLogo: "",
    name: "",
    orgCode: "",
    rate: 1,
    rosterApi : "https://www.mlb.com/",
    scheduleApi : "https://www.mlb.com/",
    videoApi : "https://www.mlb.com/",
    eventsApi : "https://www.mlb.com/",
    newsApi : "https://www.mlb.com/",
    facebook : "",
    instagram : "",
    twitter : "",
}

const UPDATE_ORG = gql`
mutation updateOrgs($id : String!,$data: UpdateClubInput! ){
    updateOrg(id: $id, data: $data ){
      name
     orgCode
    }
   }`;

   const ACTIVE_DEACTIVE_ORG = gql`
mutation deActiveOrg($id : String!,$data: UpdateClubInput! ){
    deActiveOrg(id: $id, data: $data ){
      name
     orgCode
    }
   }`;


const CREATE_ORG = gql`
  mutation createOrgs($data: NewClubInput! ) {
    createOrg(data: $data) {
      name,
      orgCode,
      rate
    }
  }
`;
const GET_ORG_BY_ID = gql`
  query getClub($clubId: String!) {
    getClub(clubId: $clubId) {
      id
      name
      orgCode
      logoUrl
      rate
      rosterApi
      scheduleApi
      videoApi
      eventsApi
      newsApi
      facebook
      instagram
      twitter
      isActive

    }
  }
`;
const CHECK_ORG_CODE = gql`
query checkOrgCode($org_code:String!){
    checkOrgExist(org_code:$org_code)
}`;

const UPLOAD_MERCH_IMAGE = gql`
mutation uploadMerchImage($imageData: String!, $fileNamePrefix: String!){
    uploadMerchImage(imageData: $imageData, fileNamePrefix: $fileNamePrefix)
}
`;

export default function CreateClub({handleCloseClick,onOpen,editData,editId}:Props) {
    const open = onOpen;
    const orgId = editId?editId:"";
    //const methods = useForm<IFormAddClub>({});
    //const { handleSubmit, control, reset,errors, formState,setValue } = methods;
    const [orgInfo ,setOrgInfo] = useState<any>({});
    const [logoUrl, setlogoUrl] = useState<any>();
    const [openConfirm,setOpenConfirm] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);
    const [errorMsg,setErrorMsg] = useState<any>("");
    const [isActiveStatus, setIsActiveStatus] = useState<any>(true); 
    const [openToast,setOpenToast] = React.useState<any>(false);
    const [orgCodeValid,setOrgCodeValid] = React.useState<any>(true);
    const Alert = (props: AlertProps)  => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
    const { loading, data,refetch } = useQuery<any>(GET_ORG_BY_ID, { variables: { clubId: orgId } });
    const { loading:orgCodeLoad, data:orgCodeRes,fetchMore ,error} = useQuery<any>(CHECK_ORG_CODE, { variables: { org_code: orgId } });

    const { register, handleSubmit, errors,control, reset, formState, setValue} = useForm<IFormAddClub>({defaultValues,shouldUnregister:false});

    const fieldSetValueFunction = (defaultValue:any) =>{
            setlogoUrl(defaultValues.logoUrl);
            setValue("name",defaultValues.name);
            setValue("orgCode",defaultValues.orgCode);
            // setValue("orgLogo",defaultValues.logoUrl);
            setValue("rate",defaultValues.rate);
            setValue("rosterApi",defaultValues.rosterApi);
            setValue("scheduleApi",defaultValues.scheduleApi);
            setValue("videoApi",defaultValues.videoApi);
            setValue("eventsApi",defaultValues.eventsApi);
            setValue("newsApi",defaultValues.newsApi);
            setValue("facebook",defaultValues.facebook);
            setValue("instagram",defaultValues.instagram);
            setValue("twitter",defaultValues.twitter);
    }
    
    useEffect(() => {
        refetch();
        if(!loading && editId){
            defaultValues = {...data.getClub}; 
            setIsActiveStatus(!data.getClub.isActive);
            fieldSetValueFunction(defaultValues);
        }else{
            defaultValues = {
                orgLogo: "",
                name: "",
                orgCode: "",
                rate: 1,
                rosterApi : "https://www.mlb.com/",
                scheduleApi : "https://www.mlb.com/",
                videoApi : "https://www.mlb.com/",
                eventsApi : "https://www.mlb.com/",
                newsApi : "https://www.mlb.com/",
                facebook : "https://www.facebook.com/",
                instagram : "",
                twitter : "https://twitter.com/",
            }
            fieldSetValueFunction(defaultValues);
        }
    },[data,open,setValue])
    

    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenToast(false);
      };

    const [createOrgs] = useMutation(CREATE_ORG,{
        // after updating the post, we go to the home page
        onCompleted: () => handleCloseClick(),
        onError: (data) => { 
            setErrorMsg(data.message); 
            setOpenToast(true);
         }

      });
      const [deActiveOrg] = useMutation(ACTIVE_DEACTIVE_ORG,{
        // after updating the post, we go to the home page
        onCompleted: () => handleCloseClick(),
        onError: (data) => { 
            setErrorMsg(data.message); 
            setOpenToast(true);
         }
      });
    
    const [uploadMerchImage] = useMutation(UPLOAD_MERCH_IMAGE, {
        // after updating the post, we go to the home page
        // onCompleted: () => handleCloseClick(),
        onCompleted: (res) => {
            console.log('RESULT', res)
        }
    });

    const [updateOrgs] = useMutation(UPDATE_ORG,{
        // after updating the post, we go to the home page
        onCompleted: () => handleCloseClick(),
        onError: (data) => { 
            setErrorMsg(data.message); 
            setOpenToast(true);
         }
      });
    const onSubmit: SubmitHandler<any> = data =>
    {
        if(logoUrl){
        if(orgCodeValid){
            let orgRate:number = parseFloat(data.rate);

        if (orgId) {
            updateOrgs({variables: 
                    {
                        id : orgId,
                        data: 
                        {
                            name: data.name,
                            rate: parseFloat(orgRate.toFixed(2)),
                            orgCode: data.orgCode,
                            logoUrl: logoUrl,
                            rosterApi:data.rosterApi,
                            scheduleApi:data.scheduleApi,
                            videoApi : data.videoApi,
                            eventsApi : data.eventsApi,
                            newsApi : data.newsApi,
                            facebook : data.facebook,
                            instagram :data.instagram,
                            twitter  : data.twitter    
                        }
                    }
                });
        } else {
           
            createOrgs({variables: 
                {
                    data: 
                    {
                        name: data.name,
                        rate: parseFloat(orgRate.toFixed(2)),
                        orgCode: data.orgCode,
                        logoUrl: logoUrl,
                        rosterApi:data.rosterApi,
                        scheduleApi:data.scheduleApi,
                        videoApi : data.videoApi,
                        eventsApi : data.eventsApi,
                        newsApi : data.newsApi,
                        facebook : data.facebook,
                        instagram :data.instagram,
                        twitter  : data.twitter   
                    }
                }
            });
        
        }
            }else{
                setErrorMsg("Please enter a valid org code for existing MLB teams");
                setOpenToast(true);
            }
        }else{
            setErrorMsg("Please upload the organisation image.");
            setOpenToast(true);
        }
        //handleCloseClick();
    }

    

    const [loader,setLoader] = useState<any>(false);

    const uploadImage = async (e:React.ChangeEvent<any>) =>{
        const targetFiles = e.target.files;

        if(targetFiles[0].type == "image/png" || targetFiles[0].type == "image/jpeg" ){
            setLoader(true);
            if(targetFiles.length>0){
                    const file = targetFiles[0];
                    const reader = new FileReader();

                    reader.onload = (e) => {
                        // Update state with the base64 encoded image string
                        console.log('READER', reader.result);
                        uploadMerchImage({
                          variables:
                          {
                              "imageData": reader.result,
                              "fileNamePrefix": 'newFileName',
                          }
                      }).then((res:any)=>{
                          console.log('adminresult', res?.data?.uploadMerchImage);
                          setlogoUrl(res?.data?.uploadMerchImage);
                            setLoader(false);
                      }).catch((err: any) => console.error(err));
                      };
                      if (file) {
                        // Read the image file as a data URL
                        reader.readAsDataURL(file);
                      }
            }
        }else{
            setErrorMsg("Image format should be in png,jpg,svg");
            setOpenToast(true);
        }
    }
    const clickCanceled = () => {
        setConfirmed(false);
        setOpenConfirm(false);
    }
    
    const clickConfirmed: SubmitHandler<any> = (fieldData) => {
        if(orgId){
            deActiveOrg({variables: 
                {
                    id : orgId,
                    data: 
                    {
                        isActive:isActiveStatus
                    }
                }
            });
        }
        setConfirmed(true);
        setOpenConfirm(false);
    }
    const handleChangeField = (e: React.ChangeEvent<any>) => {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName = (e.target as HTMLInputElement).name;
        console.log(targetValue);
        setValue(targetName ,targetValue);
        targetValue.toLowerCase()
        setValue("rosterApi","https://www.mlb.com/"+targetValue.toLowerCase()+"/roster");
        setValue("scheduleApi","https://www.mlb.com/"+targetValue.toLowerCase()+"/schedule");
        setValue("videoApi","https://www.mlb.com/"+targetValue.toLowerCase()+"/video");
        setValue("eventsApi","https://www.mlb.com/"+targetValue.toLowerCase());
        setValue("newsApi","https://www.mlb.com/"+targetValue.toLowerCase()+"/news");
        setValue("facebook","https://www.facebook.com/"+targetValue.toLowerCase());
        setValue("instagram",targetValue.toLowerCase());
        setValue("twitter","https://twitter.com/"+targetValue.toLowerCase());
    }

    const handleInActiveClick = (orgId:any) => {
        setOpenConfirm(true);
    }

    const checkOrgCodeExist = (e: React.ChangeEvent<any>) =>{
        const targetValue = (e.target as HTMLInputElement).value;
        const targetElement = (e.target as HTMLInputElement);
        const targetName = (e.target as HTMLInputElement).name;
        fetchMore({ variables :{org_code : targetValue.toUpperCase()}}).then(fetchResult => {
            console.log(fetchResult.data);
            setOrgCodeValid(fetchResult.data);
            //console.log(fetchResult.error);
        }).catch( error => {
            setOrgCodeValid(false);
            setErrorMsg("Please enter a valid org code for existing MLB teams");
            setOpenToast(true);
        })
    }

    const removeUploadedImage = () =>{
        setlogoUrl(null);
    }

    return (
        <Dialog open={onOpen} onClose={handleCloseClick} aria-labelledby="form-dialog-title" className="common-dialog-container org-container">
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{editId ? "Edit" : "Add" } organization</DialogTitle>
                <DialogContent>
                     <Grid className="form-grid" container sm={12} spacing={6}>
                            <Grid item sm={4} xs={12}>
                                
                                <div className="input-group input-file-group edit">
                                    {loader && <Loader size="30px"/>}
                                    <img className="profile-img-editview" src={logoUrl || EmptyOrg} alt="org-logo"/>
                                    <input ref={register} type="file" name="orgLogo" onChange={(e) => uploadImage(e)} className="form-fields form-field-file" />                            
                                    { (editId && logoUrl != null) ? (
                                        <span className="image-close-icon" onClick={removeUploadedImage}>x</span>
                                    ) : (<span></span>)
                                    }
                                    <div className="file-mask-section edit-view">
                                        <span className="upload-img"></span>
                                        <label>upload Logo*</label>
                                        
                                    </div>
                                    {errors.orgLogo && <label className="error-message">Field is required</label>}
                                    <span className="image-note">For best experience, upload only circular image with dimension 145 x 145 pixel.</span> 
                                </div>
                                <div className="input-group case-sensitive">
                                    <label className="input-label">Organization Name*</label>
                                    <Controller as={TextField}  rules={{ required: true,pattern:/[A-Za-z]/ }} type="text" name="name" placeholder="Organization Name" className={`form-fields  ${errors.name && 'error'}`} control={control}  />                            
                                    {errors.name && <label className="error-message">Field is required</label>}
                                </div>
                                <div className="input-group case-sensitive">
                                    <label className="input-label">Organization Code*</label>
                                    <Controller  disabled={ (editId)?true:false} name="orgCode"   control={control} rules={{ required: true, validate : (value) =>value.length<=15 && value.length>=3 }}   render = { ({value}) => ( <TextField type="text" name="orgCode" value={value} placeholder="Organization Code" className={`form-fields  ${errors.orgCode && 'error'}`}   onChange={(e) => handleChangeField(e)}   />)}  />                            
                                    {errors.orgCode && errors.orgCode.type == "required" && (<label className="error-message">Field is requried</label>)}
                                    {errors.orgCode && errors.orgCode.type == "validate" && (<label className="error-message">Code should be Min 3 and Max 15 Characters</label>)}                                </div>  
                                {/* <div className="input-group">
                                    <label className="input-label">Rate per Point(USD)*</label>
                                    <Controller as={TextField}  type="number" rules={{ required: true, pattern:/[0-9]/ ,validate: (value) =>value > 0,  maxLength : 5 }} name="rate" placeholder="Organization Rate" className={`form-fields  ${errors.rate && 'error'}`}  control={control} />                            
                                    {errors.rate && errors.rate.type == "required" && (<label className="error-message">Field is required</label>)}
                                    {errors.rate && errors.rate.type == "pattern" && (<label className="error-message">Field should be in number</label>)}
                                    {errors.rate && errors.rate.type == "maxLength" && (<label className="error-message">Rate should not be more than 5 digits</label>)} 
                                    {errors.rate && errors.rate.type == "validate" && (<label className="error-message">Rate at should not be 0</label>)} 

                                </div> */}
                            </Grid>
                            <Grid item className="borderL padr0" sm={8} xs={12}>
                                <h5 className="section-heading form-heading mrg0">Organization Info</h5>
                                <div className="input-group">
                                    <label className="input-label" >Player Roster</label>
                                    <Controller as={TextField} disabled type="text" name="rosterApi" placeholder="API Information" className="form-fields" control={control} defaultValue="" />                            
                                </div>
                                <div className="input-group">
                                    <label className="input-label" >Game Schedule</label>
                                    <Controller as={TextField} disabled type="text" name="scheduleApi" placeholder="API Information" className="form-fields" control={control} defaultValue="" />                            
                                </div>
                                <div className="input-group">
                                    <label className="input-label" >Video Streaming</label>
                                    <Controller as={TextField} disabled  type="text" name="videoApi" placeholder="API Information" className="form-fields" control={control} defaultValue="" />                            
                                </div>
                                <div className="input-group">
                                    <label className="input-label" >Events</label>
                                    <Controller as={TextField} disabled type="text" name="eventsApi" placeholder="API Information" className="form-fields" control={control} defaultValue="" />                            
                                </div>
                                <div className="input-group">
                                    <label className="input-label" >News</label>
                                    <Controller as={TextField} disabled type="text" name="newsApi" placeholder="API Information" className="form-fields" control={control} defaultValue="" />                            
                                </div>
                                <div className="input-group social-url-container">
                                    <label className="input-label" >Socialnetwork Handles</label>
                                    <Controller as={TextField} disabled type="text" name="facebook" placeholder="URL Information" className="form-fields social-field fb" control={control} defaultValue="" />                            
                                    <Controller as={TextField} disabled type="text" name="instagram" placeholder="URL Information" className="form-fields social-field insta display-none" control={control} defaultValue="" />                            
                                    <Controller as={TextField} disabled type="text" name="twitter" placeholder="URL Information" className="form-fields social-field twitter" control={control} defaultValue="" />                            
                                </div>
                            </Grid>
                            
                        </Grid>
                        
                    </DialogContent>
                    <DialogActions>
                        {editId &&
                        <Button onClick={() => handleInActiveClick(data.getClub.id)} className="btn btn-secondary btn-delete" color="primary">
                            { !isActiveStatus ? "DEACTIVATE":"ACTIVATE"}
                        </Button>
                        }
                        <Button onClick={handleCloseClick} className="btn btn-secondary" color="primary">
                            CANCEL
                        </Button>
                        <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>
                            {editId? "UPDATE ORG":"ADD ORG"}   
                        </Button>
                    </DialogActions>
                    
                </form>
                { openConfirm &&
                        <div className="confim-model-container with-scroll">
                            <Paper  className="confirmation-wrapper padd-20">
                                <Grid container sm={12}>
                                    <div className="confirmation-sec">
                                        <p>Are you sure you want to {!isActiveStatus? "deactivate":"activate" } this Organization. By choosing Confirm you will loose all the information for {data.getClub.name}</p>
                                        <DialogActions>
                                            <Button  className="btn btn-secondary" onClick={clickCanceled} color="primary">
                                                CANCEL
                                            </Button>
                                            <Button color="primary" onClick={handleSubmit(clickConfirmed)} className="btn btn-primary button">
                                                CONFIRM
                                            </Button>
                                        </DialogActions>
                                    </div>    
                                </Grid>
                            </Paper>    
                        </div>
                        }
                <Snackbar open={openToast} autoHideDuration={5000}  onClose={handleClosed}>
                    <Alert onClose={handleClosed} severity="error">
                        {errorMsg}
                    </Alert>
                </Snackbar>
        </Dialog>
        
    )
}
