import * as React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextField from "@material-ui/core/TextField";
import Radio from "@mui/material/Radio";

interface Props {
  fieldEnabled?: any;
  handleQuizList?: any;
  updateQuiz: (obj: any) => void;
  setError: (obj: any) => void;
  setOpenToast: (obj: any) => void;
}

export default function PromoQuiz({
  fieldEnabled,
  handleQuizList,
  updateQuiz,
  setError,
  setOpenToast,
}: Props) {
  const [quizList, setQuizList] = React.useState<any>(handleQuizList);

  const [value, setValue] = React.useState(1);

  // to handle tab change here.
  const handleQuizTabChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  // to add new question here.
  const handleAddQuestion = () => {
    let tabIndex = quizList.length + 1;
    if (tabIndex === 4) {
      setError("You can add maximum 2 question");
      setOpenToast(true);
      return false;
    }
    let newQuizList = [...quizList];

    newQuizList.push({
      questionId: Math.floor(100000 + Math.random() * 900000),
      questionTitle: "",
      keyList: [
        { id: Math.floor(100000 + Math.random() * 900000), keyName: "" },
      ],
      answer: null,
    });

    setQuizList(newQuizList);
    updateQuiz(newQuizList);

    setValue(tabIndex.toString());
  };

  // to select answer radio option here.
  const handleRadioChange = (
    e: React.ChangeEvent<any>,
    objItem: any,
    objSelId: any
  ) => {
    let newQuizList = [...quizList];

    if (quizList && quizList.length > 0) {
      quizList.forEach((item: any, index: any) => {
        if (item.questionId === objItem.questionId) {
          newQuizList[index].answer = objSelId;
          setQuizList(newQuizList);
          updateQuiz(newQuizList);
        }
      });
    }
  };

  // to handle question change here.
  const handleQuestionChange = (e: React.ChangeEvent<any>, objItem: any) => {
    const targetValue = (e.target as HTMLInputElement).value;

    let newQuizList = [...quizList];

    if (quizList && quizList.length > 0) {
      quizList.forEach((item: any, index: any) => {
        if (item.questionId === objItem.questionId) {
          newQuizList[index].questionTitle =
            targetValue.length < 249 ? targetValue : targetValue.slice(0, 250);
          setQuizList(newQuizList);
          updateQuiz(newQuizList);
        }
      });
    }
  };

  // to handle question key change here.
  const handleQuestionKeyChange = (e: React.ChangeEvent<any>, objItem: any) => {
    const targetValue = (e.target as HTMLInputElement).value;

    let newQuizList = [...quizList];

    if (quizList && quizList.length > 0) {
      quizList.forEach((item: any, index: any) => {
        if (item.keyList && item.keyList.length > 0) {
          item.keyList.forEach((key: any, idx: any) => {
            if (item.keyList[idx].id === objItem.id) {
              newQuizList[index].keyList[idx].keyName =
                targetValue.length < 249
                  ? targetValue
                  : targetValue.slice(0, 250);
              setQuizList(newQuizList);
              updateQuiz(newQuizList);
            }
          });
        }
      });
    }
  };

  // to handle enter key from question key press.
  const handleQuestionKeyEnter = (e: any, objItem: any, objKey: any) => {
    if (e.key === "Enter") {
      let newQuizList = [...quizList];

      if (quizList && quizList.length > 0) {
        quizList.forEach((item: any, index: any) => {
          if (
            item.questionId === objItem.questionId &&
            objKey.keyName.length > 0
          ) {
            newQuizList[index].keyList.push({
              id: Math.floor(100000 + Math.random() * 900000),
              keyName: "",
            });
            setQuizList(newQuizList);
            updateQuiz(newQuizList);
          }
        });
      }
      e.preventDefault();
    }
  };

  // to handle add answer here.
  const handleAddAnswer = (e: any, objItem: any) => {
    let newQuizList = [...quizList];

    if (quizList && quizList.length > 0) {
      quizList.forEach((item: any, index: any) => {
        if (item.questionId === objItem.questionId) {
          if (newQuizList[index].keyList.length === 4) {
            setError("You can add maximum 4 option");
            setOpenToast(true);
            return false;
          }
          newQuizList[index].keyList.push({
            id: Math.floor(100000 + Math.random() * 900000),
            keyName: "",
          });
          setQuizList(newQuizList);
          updateQuiz(newQuizList);
        }
      });
    }
  };

  // to close the quiz key option.
  const handleQuizKeyClose = (
    e: React.ChangeEvent<any>,
    objItem: any,
    objKey: any
  ) => {
    let newQuizList = [...quizList];

    if (quizList && quizList.length > 0) {
      quizList.forEach((item: any, index: any) => {
        if (item.questionId === objItem.questionId) {
          let filteredArray = item.keyList.filter(function (key: any) {
            return objKey.id !== key.id;
          });
          newQuizList[index].keyList = filteredArray;
          setQuizList(newQuizList);
          updateQuiz(newQuizList);
        }
      });
    }
  };

  // to handle question close here.
  const handleQuizQuestionClose = (
    e: React.ChangeEvent<any>,
    objItem: any,
    objIdx: any
  ) => {
    if (!fieldEnabled) {
      if (quizList && quizList.length > 0) {
        let newQuizList = quizList.filter(function (key: any) {
          return objItem.questionId !== key.questionId;
        });

        setQuizList(newQuizList);
        updateQuiz(newQuizList);
        setValue(objIdx.toString());
      }
    }
  };

  return (
    <div className="quiz-container">
      <TabContext value={value.toString()}>
        <TabList
          onChange={handleQuizTabChange}
          aria-label="lab API tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          {quizList &&
            quizList.map((item: any, index: any) => {
              return (
                <Tab
                  component="div"
                  value={(index + 1).toString()}
                  label={
                    <span className="btn-tab-close">
                      {"Question " + (index + 1)}
                      <span
                        className="input-label btn-quiz-ques-close"
                        onClick={
                          !fieldEnabled && index > 0
                            ? (e: any) =>
                                handleQuizQuestionClose(e, item, index)
                            : () => {}
                        }
                      >
                        {!fieldEnabled && index > 0 ? "X" : ""}
                      </span>
                    </span>
                  }
                />
              );
            })}
        </TabList>
        {!fieldEnabled && (
          <div
            className="add-question"
            title="Add Question"
            onClick={handleAddQuestion}
          >
            +
          </div>
        )}

        {quizList &&
          quizList.map((item: any, index: any) => {
            return (
              <TabPanel value={(index + 1).toString()}>
                <div className="input-group m-zero">
                  <label
                    className="input-label"
                    style={{ display: "none" }}
                  ></label>
                  <TextField
                    id="standard-textarea"
                    autoFocus
                    multiline
                    rows={2}
                    placeholder="Add Question"
                    variant="standard"
                    value={item.questionTitle}
                    onChange={(e) => handleQuestionChange(e, item)}
                    disabled={fieldEnabled}
                  />
                </div>

                <div className="disp-flex-center mt-20">
                  <h5 className="section-heading form-heading mt-10 mb-10 quiz-ans">
                    Answer <span>(Add min 2 answers)</span>
                  </h5>
                  {!fieldEnabled && (
                    <div
                      className="add-answer"
                      title="Add Answer"
                      onClick={(e: any) => handleAddAnswer(e, item)}
                    >
                      +
                    </div>
                  )}
                </div>
                {item.keyList.map((key: any, idx: any) => {
                  return (
                    <div className="disp-flex">
                      <Radio
                        checked={key.id === item.answer}
                        name="radio-buttons"
                        value={item.id}
                        onChange={(e) => handleRadioChange(e, item, key.id)}
                        disabled={fieldEnabled}
                      />
                      <TextField
                        autoFocus
                        className="quiz-key"
                        id="standard-textarea"
                        multiline
                        placeholder="Add Answer"
                        variant="standard"
                        value={key.keyName}
                        onChange={(e) => handleQuestionKeyChange(e, key)}
                        onKeyPress={(e) => handleQuestionKeyEnter(e, item, key)}
                        disabled={fieldEnabled}
                      />
                      {!fieldEnabled && idx > 0 && (
                        <label
                          className="input-label btn-quiz-close"
                          onClick={(e) => handleQuizKeyClose(e, item, key)}
                        >
                          X
                        </label>
                      )}
                    </div>
                  );
                })}
              </TabPanel>
            );
          })}
      </TabContext>
    </div>
  );
}
