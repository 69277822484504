import React, { useEffect } from "react";
import { useState } from "react";
import "./ExternalApi.css";

import Box from "@mui/material/Box";
import { Button, Grid, List } from "@mui/material";
import { Paper } from "@material-ui/core";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Arrow from "../../assets/arrow-right.svg";
import GenerateApiKey from "./GenerateApiKey";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Backdrop, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

interface ParamTypes {
  orgId: string;
}

export const GET_EXTERNAL_API = gql`
  query getExternalApiKey($orgId: String!) {
    getExternalApiKey(orgId: $orgId) {
      orgId
      id
      apiKey
      apiKeyName
      isActive
    }
  }
`;
export const CONFIRM_CELL = gql`
  mutation updateExternalApiActiveDeactive($apiKey: String!) {
    updateExternalApiActiveDeactive(apiKey: $apiKey)
  }
`;

export default function ExternalApi() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isMsgState, setIsMsgState] = React.useState(false);
  let { orgId } = useParams<ParamTypes>();
  const [openDiaLog, setOpenDai] = React.useState(false);
  const [apiKey, setApiKey] = useState<any>("");
  const {
    loading: getApiLoading,
    data: getExternalApiData,
    refetch: getExternalApiRefetch,
  } = useQuery<any>(GET_EXTERNAL_API, {
    variables: { orgId: orgId },
    fetchPolicy: "network-only",
  });
  const [updateExternalApiActiveDeactive] = useMutation(CONFIRM_CELL, {
    onCompleted: () => {
      setIsLoading(false);
      setApiKey("");
      reloadListData();
      handleCloseDai();
    },
    onError: (data) => {},
  });

  useEffect(() => {
    if (getExternalApiData) {
      console.log("EXTRNL", getExternalApiData.getExternalApiKey);
    }
  }, [getExternalApiData]);

  const reloadListData = () => {
    getExternalApiRefetch();
  };

  const handleClickOpen = () => {
    setOpenDai(true);
  };

  const handleCloseDai = () => {
    setOpenDai(false);
  };
  const handleDeleteSponsor = () => {
    setIsLoading(true);
    updateExternalApiActiveDeactive({
      variables: {
        apiKey: apiKey,
      },
    });
    console.log("ApiKey", apiKey);
  };
  return (
    <React.Fragment>
      {getApiLoading ? (
        <Backdrop className="loader-signup" open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className="external-api" style={{ width: "150%" }}>
          <Box
            className="points-detail-section"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 0,
                width: "150%",
              },
            }}
          >
            <Grid item xs={12} md={12} sm={12}>
              <Paper>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "-10px",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                  }}
                >
                  <div style={{ marginTop: "-20px" }}>
                    <h2 style={{ marginBottom: "0px" }}>API Keys</h2>
                    <span>Open API Document</span>
                    <img
                      src={Arrow}
                      style={{
                        width: "25px",
                        marginBottom: "-7px",
                        marginLeft: "5px",
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <GenerateApiKey reloadList={reloadListData} />
                  </div>
                </Box>

                <div>
                  <TableContainer
                    component={Paper}
                    sx={{ mt: 5, border: 2, borderRadius: 3 }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ backgroundColor: "#f6f6f6" }}>
                        <TableRow sx={{ borderBottom: 2 }}>
                          <TableCell align="center">API Key Name</TableCell>
                          <TableCell align="center">API Key</TableCell>
                          <TableCell align="center"> </TableCell>

                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getExternalApiData &&
                        getExternalApiData.getExternalApiKey &&
                        getExternalApiData.getExternalApiKey.length ? (
                          getExternalApiData.getExternalApiKey.map(
                            (row: any) => (
                              <TableRow key={row.apiKeyName}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  {row.apiKeyName}
                                </TableCell>
                                <TableCell align="center">
                                  **************************************************************
                                </TableCell>
                                <TableCell align="center">{""}</TableCell>

                                <TableCell
                                  align="center"
                                  sx={{ color: "black" }}
                                >
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      handleClickOpen();
                                      setApiKey(row.id);
                                      setIsMsgState(row.isActive);
                                    }}
                                  >
                                    {row.isActive ? "Active" : "Deactive"}
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        ) : (
                          <TableRow>
                            <TableCell
                              component="th"
                              colSpan={4}
                              scope="row"
                              align="center"
                            >
                              No data found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Paper>
            </Grid>
          </Box>
        </div>
      )}
      <Dialog
        open={openDiaLog}
        onClose={handleCloseDai}
        aria-labelledby="form-dialog-title"
      >
        <form className="form-container">
          <p>
            Are you sure want to {isMsgState ? "Deactive" : "Active"} api Key ?
          </p>
          <DialogActions>
            <Button
              onClick={handleDeleteSponsor}
              color="primary"
              className="btn btn-primary button"
            >
              {isLoading ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                "CONFIRM"
              )}
            </Button>
            <Button
              onClick={handleCloseDai}
              className="btn btn-secondary"
              color="primary"
            >
              CANCEL
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
