import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import OrgImage from "../../assets/Bitmap@2x.png";
import EmptyOrg from "../../assets/org-empty@2x.svg";

import ClubDetailsTabs from "./clubdetailtabs";
import CreateClub from "./../manageclubs/createclub/createclub";
import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import "./clubdetails.css";

interface OrgInventory {
  id: string;
  name: string;
  orgCode: string;
}

const GET_ORG_BY_ID = gql`
  query getClub($clubId: String!) {
    getClub(clubId: $clubId) {
      id
      name
      orgCode
      rate
      logoUrl
      availablePoints
      totalPoints
      fanCount
      createdAt
    }
  }
`;

export const GET_POINTHOLDERS_QUERY = gql`
  query getPointHolderLists($clubId: String!) {
    getPointsHolders(clubId: $clubId) {
      givenName
      email
      walletId
      profilePicture
      walletBalance
      totalPoints
      walletId
      createdAt
    }
  }
`;
interface ParamTypes {
  orgId: string;
}

export default function ClubDetails() {
  const [open, setOpen] = React.useState(false);
  const [orgIds, setOrgId] = React.useState("");
  const [updatedDate, setUpdatedDate] = React.useState("");
  const [fanCount, setFancount] = React.useState();

  let { orgId } = useParams<ParamTypes>();
  const { loading, data, refetch } = useQuery<any>(GET_ORG_BY_ID, {
    variables: { clubId: orgId },
  });
  const {
    refetch: pointholdersRefetch,
    loading: pointholdersLoading,
    data: pointholdersData,
  } = useQuery<any>(GET_POINTHOLDERS_QUERY, { variables: { clubId: orgId } });
  console.log(data);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const valuesUpdate = () => {
    console.log("PARENT");
    refetch();
    pointholdersRefetch();
  };
  useEffect(() => {
    console.log("useEffect");
    if (!loading) {
      //console.log(data.getClub.createdAt);
      setUpdatedDate(data.getClub.createdAt);
    }
    //console.log(pointholdersData);
    if (!pointholdersLoading) {
      //console.log(pointholdersData.getPointsHolders);
      let selectedSponsor = pointholdersData.getPointsHolders.filter(
        (i: any) => i.totalPoints > 0
      );
      console.log(selectedSponsor);
      setFancount(selectedSponsor.length);
    }
    refetch();
  }, [open, data, pointholdersData]);
  const handleEditClickOpen = () => {
    //console.log(id);
    setOpen(true);
    setOrgId(orgId);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const history = useHistory();
  const backClick = () => history.push("/manageclubs");

  return (
    <div className="detail-page org-detail-page">
      <div className="page-head org-details-page-container p-relative padl-40">
        <Grid className="flx-align-center" container sm={12}>
          <Grid container sm={9}>
            <span className="back-icon" onClick={backClick}></span>
            {loading ? (
              <p>Loading ...</p>
            ) : (
              <Grid className="flx-align-start" container sm={12} spacing={3}>
                {" "}
                {/* border-right */}
                <Grid className="pad0" item sm={1}>
                  <img
                    src={data.getClub.logoUrl || EmptyOrg}
                    className="org-detail-img"
                    alt="organization image"
                  />
                </Grid>
                <Grid className="mrg" item sm={3}>
                  {" "}
                  {/* border-right */}
                  <h5 className="page-title page-main-title mrg0">
                    {data.getClub.name}
                  </h5>
                  <h5 className="page-title mrg0">({data.getClub.orgCode})</h5>
                  <Chip
                    className="status green"
                    variant="outlined"
                    label="Active"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            className="org-edit-section align-right flx-align-center"
            item
            sm={3}
          >
            <label className="update-date-txt">
              Last updated:{moment(updatedDate).format("MM/DD/YYYY")}
            </label>
            <button
              onClick={handleEditClickOpen}
              className="btn btn-secondary edit-btn"
            >
              <span className="btn-icon"></span>{" "}
              <label className="btn-caption">Edit Org</label>
            </button>
          </Grid>
        </Grid>
      </div>
      <div className="page-body">
        <Grid container sm={12} spacing={3}>
          <Grid item sm={12}>
            <ClubDetailsTabs onParentRender={valuesUpdate} />
          </Grid>
        </Grid>
      </div>
      <CreateClub
        onOpen={open}
        editId={orgIds}
        editData={"Edit"}
        handleCloseClick={handleClose}
      />
    </div>
  );
}
