import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import moment from 'moment'
import "moment-timezone"
import Snackbar from '@material-ui/core/Snackbar';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem, Switch } from "@material-ui/core";
import '../../../constant.css';
import './addmerchandise.css';
import { gql, useMutation, useQuery } from '@apollo/client';
import S3 from 'aws-s3';
//import { s3config } from "../../../s3config";
import { config, zoneList } from '../../../config';
import Loader from '../../../components/Loader/loader';
import SponsorEmty from '../../../assets/sponsor-empty@2x.svg';

import SponsorLogo from '../../../assets/sponsor-logo.png';

import MerchandiseLogo from '../../../assets/ic-default-logo.svg';
import PlusIcon from '../../../assets/ic-plus.svg';

import { stringify } from 'querystring';
import { AnyCnameRecord } from 'dns';

interface Props {
    onOpen: boolean,
    handleCloseClick: () => void,
    viewTypeTxt?: any,
    editId?: any,
    onSendData:(data: any) => any
}

interface ParamTypes {
    orgId: string;
}

interface IFormAddMerchandise {
    category: string,
    merchandiseName: string,
    sthExperience: string,
    pointsCost: string,
    description: string,
    emailVoucher: string,
    merFulfillment: string,
    merStatus: string
}

let defaultValues: any = {
    // selectedMerImg: '',
    category: 'Merch',
    merchandiseName: '',
    sthExperience: '',
    pointsCost: '',
    description: '',
    emailVoucher: 'YES',
    merFulfillment: 'YES',
    merStatus: 'ACTIVE'
}

// to get all STH Experience
const GET_STH_EXPERIENCE = gql`
    query eventsAllNames($orgId: String!){
        eventsAllNames(orgId: $orgId){
            eld_name
        }
    }
`;

// to get all STH Experience events list
const GET_STH_EXPERIENCE_EVENTS_LIST = gql`
    query eventsByNameDate($event_name: String!, $date: String!, $current_month: Boolean!, $orgId: String!) {
        eventsByNameDate(event_name: $event_name, date: $date, current_month: $current_month, orgId:$orgId){
            eld_id
            eld_name
            eld_date
            eld_starttime
            eld_endtime
            eld_location
            eld_city
            eld_country
            eld_event_id
            eld_availablecapacity
            eld_timezone_abbreviation
            eld_eventcost
        }
    }
`;

const CREATE_MERCHANDISE = gql`
    mutation addMerchandise($data: NewMerchandiseInput!) {
        addMerchandise(data: $data) {
            merchandise_id
            merchandise_name
        }
    }
`;

const UPLOAD_MERCH_IMAGE = gql`
mutation uploadMerchImage($imageData: String!, $fileNamePrefix: String!){
    uploadMerchImage(imageData: $imageData, fileNamePrefix: $fileNamePrefix)
}
`;

const MERCHANDISE_BY_ID = gql`
    query merchandiseById($merchandise_id: Int!,$merchandise_orgId: String!){
        merchandiseById(merchandise_id:$merchandise_id,merchandise_orgId:$merchandise_orgId){
        merchandise_id
        merchandise_imageurl
        merchandise_name
        merchandise_category
        merchandise_pointcost
        merchandise_numberoflisting
        merchandise_listings {
            ml_id
            ml_eldid
            ml_date
            ml_starttime
            ml_endtime
            ml_selectioncapacity
            ml_availablecapacity
            ml_location
            ml_city
            ml_country
            ml_event_id
            ml_timezone_abbreviation
            ml_eventcost
            ml_type_subtype
            ml_max_qty_per_user
        }
        merchandise_description
        merchandise_isemailvoucher
        merchandise_fulfillment
        merchandise_status
        }
    }
`;

const UPDATE_MERCHANDISE = gql`
    mutation updateMerchandise($merchandise_id: Int!,$data: UpdateMerchandiseInput! ){
        updateMerchandise(merchandise_id: $merchandise_id, data: $data ){
            merchandise_id
            merchandise_name
        }
    }
`;

const DEACTIVATE_MERCHANDISE_BY_ID = gql`
    mutation updateMerchandiseStatus($merchandise_id: Int!, $merchandise_status: String!){
        updateMerchandiseStatus(merchandise_id: $merchandise_id, merchandise_status: $merchandise_status)
    }
`;

export default function AddMerchandise({ handleCloseClick, onOpen, viewTypeTxt, editId, onSendData }: Props) {
    const editMerchandiseId = editId > 0 ? editId : 0;
    const open = onOpen;

    const [category, setCategory] = useState<any>('Merch');

    const [cancelDisabled, setCancelDisabled] = useState<any>(true);

    const [statusType, setStatusType] = useState<any>('');
    const [isADClicked, setIsADClicked] = useState<any>(false);     // AD -> ActivateDeactivate

    const [loader, setLoader] = useState<any>(false);               // Loader icon

    let { orgId } = useParams<ParamTypes>();

    // Events display by month.
    const [displayMonth, setDisplayMonth] = React.useState<any>(moment().format('MMM YYYY'));                   // 'Mar 2021'
    const [getEventsByYearMonth, setGetEventsByYearMonth] = React.useState<any>(moment().format('YYYY-MM'));      // '2021-03'
    const [isCurrentMonth, setIsCurrentMonth] = React.useState<any>(true);
    const [pastdate, setPastDate] = useState<any>(true);

    // Merchandise Image useState values. 
    const [defaultLogoUrl, setDefaultLogoUrl] = useState<any>(MerchandiseLogo);         // Default logo
    const [merchandiseImgUrl, setMerchandiseImgUrl] = React.useState<any>('');          // Selected Merchandise Image
    const [plusIconUrl, setPlusIconUrl] = React.useState<any>(PlusIcon);                // Plus icon 
    const [merchandiseImgList, setMerchandiseImgList] = React.useState<any>([
        { idx: 0, imageUrl: '', isActive: false },
        { idx: 1, imageUrl: '', isActive: false },
        { idx: 2, imageUrl: '', isActive: false }
    ]);

    // STHExperience useState.
    const [sthExperienceList, setSthExperienceList] = React.useState<any>([]);

    // to call API to get all STHExperience list.
    const { loading: sthExpLoading, data: sthExpData, refetch: sthExpRefetch } = useQuery<any>(GET_STH_EXPERIENCE, { variables: { orgId: orgId } });

    // to reassign all events list to useState for internal business logic.
    const [eventsList, setEventsList] = React.useState<any>([]);
    const [selectedEventsList, setSelectedEventsList] = React.useState<any>([]);
    const [selectedMerchList, setSelectedMerchList] = React.useState<any>([
        { idx: Math.floor(100000 + Math.random() * 900000), type: '', subType: '', qty: '', maxQtyPerCust: '', isEventType: 'NEW' }
    ]);

    const [selSTHExperience, setSelSTHExperience] = React.useState<any>('');
    const { loading: eventsListLoading, data: eventsListData, refetch: eventsRefetch } = useQuery<any>(GET_STH_EXPERIENCE_EVENTS_LIST, { variables: { event_name: selSTHExperience, date: getEventsByYearMonth, current_month: isCurrentMonth, orgId:orgId } });

    const [confirmed, setConfirmed] = React.useState(false);
    const inputRef: any = useRef();

    const [errorMsg, setErrorMsg] = useState<any>("");
    const [openToast, setOpenToast] = React.useState<any>(false);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openCancelConfirm, setOpenCancelConfirm] = React.useState(false);

    const [confirmMessage, setConfirmMessage] = useState<any>("Are you sure with the Point Requested value?");

    const { loading, data, refetch } = useQuery<any>(MERCHANDISE_BY_ID, { variables: { merchandise_id: editMerchandiseId, merchandise_orgId: orgId } });

    const { register, handleSubmit, errors, control, reset, formState, setValue } = useForm<IFormAddMerchandise>({ defaultValues, shouldUnregister: false });

    // to open error messages 
    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // to clear all hooks values here before cancel the dialogue box
    const handleClearAndClose = () => {
        setConfirmed(false);
        setOpenConfirm(false);
        setOpenCancelConfirm(false)
        setOpenToast(false);

        setValue("sthExperience", '');
        setValue("category", 'Merch');
        setValue("merchandiseName", '');
        setValue("pointsCost", '');
        setValue("description", '');
        setValue("numberOfListing", 0);
        setValue("emailVoucher", 'YES');
        setValue("merFulfillment", 'YES');
        setValue("merStatus", 'ACTIVE');

        setMerchandiseImgList([
            { idx: 0, imageUrl: '', isActive: false },
            { idx: 1, imageUrl: '', isActive: false },
            { idx: 2, imageUrl: '', isActive: false }
        ]);

        setSelectedMerchList([{ idx: Math.floor(100000 + Math.random() * 900000), type: '', subType: '', qty: '', maxQtyPerCust: '', isEventType: 'NEW' }])

        setCategory('Merch')
        setDisplayMonth(moment().format('MMM YYYY'))
        setGetEventsByYearMonth(moment().format('YYYY-MM'));
        setIsCurrentMonth(true)
        setStatusType('')
        setIsADClicked(false)
        setLoader(false)
        setPastDate(true)
        setSthExperienceList([])
        setMerchandiseImgUrl('')
        setEventsList([])
        setSelectedEventsList([])
        setSelSTHExperience('')
        setCancelDisabled(true)

        handleCloseClick()
    }

    // to pre-fill all form data in edit.
    const fieldSetValueFunction = (defaultValue: any) => {

        if (viewTypeTxt === 'Create') {

            if (selSTHExperience === '') {
                setValue("sthExperience", '');
                // setValue("category", 'Merch');
                // setValue("pointsCost", '');
                setValue("description", '');
                setValue("numberOfListing", 0);
                setValue("emailVoucher", 'YES');
                // setValue("merFulfillment", 'YES');
                setValue("merStatus", 'ACTIVE');

                setSelectedEventsList([])
            }

            if (merchandiseImgUrl === '') {
                setMerchandiseImgList([
                    { idx: 0, imageUrl: '', isActive: false },
                    { idx: 1, imageUrl: '', isActive: false },
                    { idx: 2, imageUrl: '', isActive: false }
                ]);
            }
        } else {
            // to clear all active image flag before set new active image falg as true.
            let tempArr = defaultValues.merchandise_imageurl && defaultValues.merchandise_imageurl.length > 0 ? JSON.parse(defaultValues.merchandise_imageurl) : []
            for (let i = 0; i < tempArr.length; i++) {
                if (tempArr[i].isActive) {
                    setMerchandiseImgUrl(tempArr[i].imageUrl)
                }
            }

            setMerchandiseImgList(defaultValues.merchandise_imageurl && defaultValues.merchandise_imageurl.length > 0 ? JSON.parse(defaultValues.merchandise_imageurl) : [])

            // if (defaultValues.merchandise_category === 'Merch' || defaultValues.merchandise_category === 'Autographs') {
            if (defaultValues.merchandise_category !== 'Experiences') {
                setValue("merchandiseName", defaultValues.merchandise_name);
                setValue("sthExperience", '');
                setValue("pointsCost", defaultValues.merchandise_pointcost ? defaultValues.merchandise_pointcost.toString() : '');
                // setCategory('Merch')
            } else {
                setValue("merchandiseName", '');
                setValue("sthExperience", defaultValues.merchandise_name);
                // setCategory('')
            }

            setValue("category", defaultValues.merchandise_category);
            setValue("description", defaultValues.merchandise_description);
            setValue("numberOfListing", defaultValues.merchandise_numberoflisting);
            setValue("emailVoucher", defaultValues.merchandise_isemailvoucher ? 'YES' : 'NO');
            setValue("merFulfillment", defaultValues.merchandise_fulfillment ? 'YES' : 'NO');
            setValue("merStatus", defaultValues.merchandise_status);

            // If Merchandise category is selected as Merch or Autographs.
            // if (defaultValues.merchandise_category === 'Merch' || defaultValues.merchandise_category === 'Autographs') {
            if (defaultValues.merchandise_category !== 'Experiences') {
                if (defaultValues.merchandise_listings.length >= selectedMerchList.length) {
                    let tempSelectedMerchList = []
                    if (defaultValues.merchandise_listings && defaultValues.merchandise_listings.length > 0) {
                        for (let i = 0; i < defaultValues.merchandise_listings.length; i++) {

                            let tempTypeSubType = defaultValues.merchandise_listings[i].ml_type_subtype.split('||')

                            tempSelectedMerchList.push({
                                // to get and maintain the merch list data in update form.
                                idx: defaultValues.merchandise_listings[i].ml_eldid,
                                type: tempTypeSubType[0],
                                subType: tempTypeSubType[1],
                                qty: defaultValues.merchandise_listings[i].ml_availablecapacity,
                                maxQtyPerCust: defaultValues.merchandise_listings[i].ml_max_qty_per_user,

                                // to get db fileds here to pass in update merchandise for merch category
                                ml_eldid: defaultValues.merchandise_listings[i].ml_eldid,
                                ml_date: defaultValues.merchandise_listings[i].ml_date,
                                ml_starttime: defaultValues.merchandise_listings[i].ml_starttime,
                                ml_endtime: defaultValues.merchandise_listings[i].ml_endtime,
                                ml_selectioncapacity: defaultValues.merchandise_listings[i].ml_selectioncapacity,
                                ml_availablecapacity: defaultValues.merchandise_listings[i].ml_availablecapacity,
                                ml_location: defaultValues.merchandise_listings[i].ml_location,
                                ml_city: defaultValues.merchandise_listings[i].ml_city,
                                ml_country: defaultValues.merchandise_listings[i].ml_country,
                                ml_id: parseInt(defaultValues.merchandise_listings[i].ml_id),
                                isEventSelected: true,
                                isEventType: 'OLD',
                                ml_event_id: defaultValues.merchandise_listings[i].ml_event_id,
                                ml_timezone_abbreviation: defaultValues.merchandise_listings[i].ml_timezone_abbreviation,
                                ml_eventcost: defaultValues.merchandise_listings[i].ml_eventcost,
                                ml_type_subtype: defaultValues.merchandise_listings[i].ml_type_subtype,
                                ml_max_qty_per_user: defaultValues.merchandise_listings[i].ml_max_qty_per_user
                            })
                        }
                    }
                    setSelectedMerchList(tempSelectedMerchList)
                }
            } else {
                // to show selected events and their capacity in edit form.
                // if (selectedEventsList.length === 0 && defaultValues.merchandise_listings.length > selectedEventsList.length ) {
                if (defaultValues.merchandise_listings.length > selectedEventsList.length) {
                    let tempSelectedEventsList = []
                    if (defaultValues.merchandise_listings && defaultValues.merchandise_listings.length > 0) {
                        for (let i = 0; i < defaultValues.merchandise_listings.length; i++) {
                            tempSelectedEventsList.push({
                                eld_id: defaultValues.merchandise_listings[i].ml_eldid,
                                eld_date: defaultValues.merchandise_listings[i].ml_date,
                                eld_starttime: defaultValues.merchandise_listings[i].ml_starttime,
                                eld_endtime: defaultValues.merchandise_listings[i].ml_endtime,
                                eld_selectioncapacity: defaultValues.merchandise_listings[i].ml_selectioncapacity,
                                eld_availablecapacity: defaultValues.merchandise_listings[i].ml_availablecapacity,
                                eld_location: defaultValues.merchandise_listings[i].ml_location,
                                eld_city: defaultValues.merchandise_listings[i].ml_city,
                                eld_country: defaultValues.merchandise_listings[i].ml_country,
                                ml_id: parseInt(defaultValues.merchandise_listings[i].ml_id),
                                isEventSelected: true,
                                isEventType: 'OLD',
                                eld_event_id: defaultValues.merchandise_listings[i].ml_event_id,
                                eld_timezone_abbreviation: defaultValues.merchandise_listings[i].ml_timezone_abbreviation,
                                eld_eventcost: defaultValues.merchandise_listings[i].ml_eventcost
                            })
                        }
                    }
                    setSelectedEventsList(tempSelectedEventsList)
                }
            }
        }
    }

    // to reassign all events list to useState.
    const getAllEventsList = (objData: any) => {
        if (objData && objData.eventsByNameDate.length > 0) {
            let newEventsList = []
            // to clear all active image flag before set new active image flag as true.
            for (let i = 0; i < objData.eventsByNameDate.length; i++) {
                let isEventMatched = false
                let tempCapacity = objData.eventsByNameDate[i].eld_availablecapacity
                for (let j = 0; j < selectedEventsList.length; j++) {
                    if (objData.eventsByNameDate[i].eld_id === selectedEventsList[j].eld_id) {
                        isEventMatched = true
                        tempCapacity = selectedEventsList[j].eld_availablecapacity

                    }
                }
                newEventsList.push({
                    eld_id: objData.eventsByNameDate[i].eld_id, // "444",
                    eld_name: objData.eventsByNameDate[i].eld_name, // "STH Tailgate",
                    eld_date: objData.eventsByNameDate[i].eld_date, // "2021-02-12",
                    eld_starttime: objData.eventsByNameDate[i].eld_starttime, // "10:00:00+00",
                    eld_endtime: objData.eventsByNameDate[i].eld_endtime, // "12:00:00+00",
                    eld_location: objData.eventsByNameDate[i].eld_location, // "Heinz Field",
                    eld_city: objData.eventsByNameDate[i].eld_city, // "Pittsburg",
                    eld_country: objData.eventsByNameDate[i].eld_country, // "US",
                    eld_selectioncapacity: tempCapacity,
                    eld_availablecapacity: tempCapacity,
                    isEventSelected: isEventMatched ? true : false,
                    isEventType: isEventMatched ? 'OLD' : 'NEW',
                    eld_event_id: objData.eventsByNameDate[i].eld_event_id,
                    eld_timezone_abbreviation: objData.eventsByNameDate[i].eld_timezone_abbreviation,
                    eld_eventcost: objData.eventsByNameDate[i].eld_eventcost
                })
            }
            setEventsList(newEventsList)
        } else {
            setEventsList([])
        }
    }

    useEffect(() => {
        // console.log('RK viewType', viewTypeTxt)
        // console.log('RK editId', editId)

        // console.log('RK category', category)

        // if (category !== 'Merch' && category !== 'Autographs') {
        if (category === 'Experiences') {
            // to pass all STHExperience list to useState to populate dropdown list.
            if (!sthExpLoading) setSthExperienceList(sthExpData.eventsAllNames)

            // console.log(selSTHExperience+"selSTHExperience")
            // console.log(eventsListLoading+"eventsListLoading")

            if (selSTHExperience && !eventsListLoading) {
                // console.log('events list data 1', eventsListData)
                getAllEventsList(eventsListData)
            }
        }

        // to get merchandise details using merchandise id for update merchandise.
        if (!loading && editId && (viewTypeTxt === 'Edit' || viewTypeTxt === 'Copy')) {
            // After updating the merchandise form, old state still maintained. So, forcefully refetch the data once to overwrite.
            refetch();
            // console.log('merchandise details', data)

            // eventsRefetch()

            if (data) {
                defaultValues = { ...data.merchandiseById };
                setCategory(defaultValues.merchandise_category);
                setStatusType(defaultValues.merchandise_status);

                // if (defaultValues.merchandise_category !== 'Merch' && defaultValues.merchandise_category !== 'Autographs') {
                if (defaultValues.merchandise_category === 'Experiences') {
                    setSelSTHExperience(defaultValues.merchandise_name);

                    // We have to do "eventsRefetch()" after getting STHExperience.
                    if (!eventsListData) eventsRefetch()

                    if (eventsListData && eventsListData.eventsByNameDate.length > 0) {
                        if (selectedEventsList.length === 0) {
                            let newEventsList = []
                            // newEventsList = [...eventsList];

                            // to clear all active image flag before set new active image flag as true.
                            for (let i = 0; i < eventsListData.eventsByNameDate.length; i++) {
                                let isEventMatched = false
                                let tempCapacity = 0
                                if (defaultValues.merchandise_listings && defaultValues.merchandise_listings.length > 0) {
                                    for (let j = 0; j < defaultValues.merchandise_listings.length; j++) {
                                        if (defaultValues.merchandise_listings[j].ml_eldid === eventsListData.eventsByNameDate[i].eld_id) {
                                            isEventMatched = true
                                            tempCapacity = defaultValues.merchandise_listings[j].ml_selectioncapacity
                                        }
                                    }
                                }
                                newEventsList.push({
                                    eld_id: eventsListData.eventsByNameDate[i].eld_id, // "444",
                                    eld_name: eventsListData.eventsByNameDate[i].eld_name, // "STH Tailgate",
                                    eld_date: eventsListData.eventsByNameDate[i].eld_date, // "2021-02-12",
                                    eld_starttime: eventsListData.eventsByNameDate[i].eld_starttime, // "10:00:00+00",
                                    eld_endtime: eventsListData.eventsByNameDate[i].eld_endtime, // "12:00:00+00",
                                    eld_location: eventsListData.eventsByNameDate[i].eld_location, // "Heinz Field",
                                    eld_city: eventsListData.eventsByNameDate[i].eld_city, // "Pittsburg",
                                    eld_country: eventsListData.eventsByNameDate[i].eld_country, // "US",
                                    eld_availablecapacity: eventsListData.eventsByNameDate[i].eld_availablecapacity,
                                    eld_selectioncapacity: tempCapacity,
                                    isEventSelected: isEventMatched ? true : false,
                                    isEventType: isEventMatched ? 'OLD' : 'NEW',
                                    eld_event_id: eventsListData.eventsByNameDate[i].eld_event_id,
                                    eld_timezone_abbreviation: eventsListData.eventsByNameDate[i].eld_timezone_abbreviation,
                                    eld_eventcost: eventsListData.eventsByNameDate[i].eld_eventcost
                                })
                            }
                            setEventsList(newEventsList)
                        }
                        // console.log('RK events ok 2', newEventsList)
                    }
                }

                fieldSetValueFunction(defaultValues);
            }
            // setConfirmed(true);
        }

        // to clear all state and hook values
        if (viewTypeTxt === 'Create') {
            sthExpRefetch()
            setOpenConfirm(false);
            setOpenCancelConfirm(false);
            setConfirmed(false);
            // setCategory('Merch')
            defaultValues = {}
            fieldSetValueFunction(defaultValues);
        }
    }, [category, selSTHExperience, sthExpData, eventsListData, data, open, setValue, statusType]);

    const [updateMerchandiseStatus] = useMutation(DEACTIVATE_MERCHANDISE_BY_ID, {
        // after updating the post, we go to the home page
        onCompleted: () => handleCloseClick(),
        onError: (data) => {
            setErrorMsg(data.message);
            setOpenToast(true);
        }
    });

    const handleDeleteMerchandise = () => {
        // setConfirmMessage("Are you sure you want to delete this Sponsor.By choosing Delete you will loose all the information for "+data.getSponsorWithId.sponsorName);
        if (data.merchandiseById.merchandise_status === 'ACTIVE') {
            setConfirmMessage("Are you sure you want to deactivate " + data.merchandiseById.merchandise_name + " merchandise?");
        } else if (data.merchandiseById.merchandise_status === 'INACTIVE') {
            setConfirmMessage("Are you sure you want to activate " + data.merchandiseById.merchandise_name + " merchandise?");
        }
        setIsADClicked(true)
        setOpenConfirm(true);
    }

    // to close snack bar error message toaster.
    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    // to add merchandise details 
    const [addMerchandise, { error, loading: resLoading }] = useMutation(CREATE_MERCHANDISE, {
        // after updating the post, we go to the home page
        // onCompleted: () => handleCloseClick(),
        onCompleted: () => handleClearAndClose()
    });

    // const [updateSponsors] = useMutation(UPDATE_SPONSOR , {
    //     // after updating the post, we go to the home page
    //     onCompleted: () => handleCloseClick(),
    // });

    const [updateMerchandise] = useMutation(UPDATE_MERCHANDISE, {
        // after updating the post, we go to the home page
        // onCompleted: () => handleCloseClick(),
        onCompleted: () => handleClearAndClose()
    });

    const [uploadMerchImage] = useMutation(UPLOAD_MERCH_IMAGE, {
        // after updating the post, we go to the home page
        // onCompleted: () => handleCloseClick(),
        onCompleted: (res) => {
            console.log('RESULT', res)
        }
    });

    // cancel click on confirm prompt.
    const clickCanceled = () => {
        setConfirmed(false);
        setOpenConfirm(false);
        inputRef.current.focus();
    }

    const clickConfirmed: SubmitHandler<IFormAddMerchandise> = (fieldData) => {
        if (editId && viewTypeTxt !== 'Copy') {
            if (isADClicked) {
                updateMerchandiseStatus({ variables: { merchandise_id: editMerchandiseId, merchandise_status: fieldData.merStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' } });
                onSendData(fieldData.merStatus === 'ACTIVE' ? 'YES' : 'NO')
                setTimeout(() => { handleClearAndClose() }, 1000)
            } else {
                // console.log("EDIT")

                // if (fieldData.category === 'Merch' || fieldData.category === 'Autographs') {
                if (fieldData.category !== 'Experiences') {
                    // to reassign as per add merchandise api key pair.
                    let tempMerchDataList = []
                    for (let i = 0; i < selectedMerchList.length; i++) {

                        if (selectedMerchList[i].isEventType === 'OLD') {
                            let tempDateTime: any = selectedMerchList[i].ml_date + ' ' + selectedMerchList[i].ml_starttime;
                            tempDateTime = tempDateTime ? new Date(tempDateTime).getTime() : 0

                            tempMerchDataList.push({
                                ml_id: selectedMerchList[i].ml_id ? selectedMerchList[i].ml_id : 0,
                                ml_eldid: selectedMerchList[i].ml_eldid ? selectedMerchList[i].ml_eldid : Math.floor(100000 + Math.random() * 900000),
                                ml_date: selectedMerchList[i].ml_date ? selectedMerchList[i].ml_date : '2099-10-10',
                                ml_starttime: selectedMerchList[i].ml_starttime ? selectedMerchList[i].ml_starttime : '12:00:00-07:00',
                                ml_endtime: selectedMerchList[i].ml_endtime ? selectedMerchList[i].ml_endtime : '13:00:00-07:00',
                                ml_datetime: tempDateTime.toString(),
                                ml_selectioncapacity: parseInt(selectedMerchList[i].qty),
                                ml_availablecapacity: parseInt(selectedMerchList[i].qty),
                                ml_location: selectedMerchList[i].ml_location,
                                ml_city: selectedMerchList[i].ml_city,
                                ml_country: selectedMerchList[i].ml_country,
                                ml_event_id: selectedMerchList[i].ml_event_id ? selectedMerchList[i].ml_event_id : Math.floor(100000 + Math.random() * 900000),
                                ml_timezone_abbreviation: selectedMerchList[i].ml_timezone_abbreviation,
                                ml_eventcost: parseInt(fieldData.pointsCost),
                                ml_type_subtype: selectedMerchList[i].type + '||' + selectedMerchList[i].subType,
                                ml_max_qty_per_user: parseInt(selectedMerchList[i].maxQtyPerCust)
                            })
                        } else {
                            let tDateTime: any = '2099-10-10 12:00:00-07:00';
                            tDateTime = tDateTime ? new Date(tDateTime).getTime() : 0

                            tempMerchDataList.push({
                                ml_id: 0,
                                ml_eldid: Math.floor(100000 + Math.random() * 900000),
                                ml_date: '2099-10-10',
                                ml_starttime: '12:00:00-07:00',
                                ml_endtime: '13:00:00-07:00',
                                ml_datetime: tDateTime.toString(),
                                ml_selectioncapacity: parseInt(selectedMerchList[i].qty),
                                ml_availablecapacity: parseInt(selectedMerchList[i].qty),
                                ml_location: '',
                                ml_city: '',
                                ml_country: '',
                                ml_event_id: Math.floor(100000 + Math.random() * 900000),
                                ml_timezone_abbreviation: 'EDT',
                                ml_eventcost: parseInt(fieldData.pointsCost),
                                ml_type_subtype: selectedMerchList[i].type + '||' + selectedMerchList[i].subType,
                                ml_max_qty_per_user: parseInt(selectedMerchList[i].maxQtyPerCust)
                            })
                        }
                    }

                    updateMerchandise({
                        variables:
                        {
                            merchandise_id: editMerchandiseId,
                            data:
                            {
                                merchandise_orgId: orgId,
                                merchandise_imageurl: JSON.stringify(merchandiseImgList),
                                merchandise_category: fieldData.category,
                                merchandise_name: fieldData.merchandiseName,
                                merchandise_pointcost: parseInt(fieldData.pointsCost),
                                merchandise_description: fieldData.description,
                                merchandise_numberoflisting: selectedMerchList.length > 0 ? selectedMerchList.length : 0,
                                merchandise_listings: tempMerchDataList,
                                merchandise_isemailvoucher: fieldData.emailVoucher === 'YES' ? true : false,
                                merchandise_fulfillment: fieldData.merFulfillment === 'YES' ? true : false,
                                merchandise_status: 'ACTIVE'
                            }
                        }
                    });
                } else {
                    // to reassign as per add merchandise api key pair.
                    let tempSelectedEventsList = []
                    for (let i = 0; i < selectedEventsList.length; i++) {

                        let tempDateTime: any = selectedEventsList[i].eld_date + ' ' + selectedEventsList[i].eld_starttime;
                        tempDateTime = tempDateTime ? new Date(tempDateTime).getTime() : 0

                        tempSelectedEventsList.push({
                            ml_id: selectedEventsList[i].ml_id ? selectedEventsList[i].ml_id : 0,
                            ml_eldid: selectedEventsList[i].eld_id,
                            ml_date: selectedEventsList[i].eld_date,
                            ml_starttime: selectedEventsList[i].eld_starttime,
                            ml_endtime: selectedEventsList[i].eld_endtime,
                            ml_datetime: tempDateTime.toString(),
                            ml_selectioncapacity: selectedEventsList[i].eld_selectioncapacity,
                            ml_availablecapacity: selectedEventsList[i].eld_availablecapacity,
                            ml_location: selectedEventsList[i].eld_location,
                            ml_city: selectedEventsList[i].eld_city,
                            ml_country: selectedEventsList[i].eld_country,
                            ml_event_id: selectedEventsList[i].eld_event_id,
                            ml_timezone_abbreviation: selectedEventsList[i].eld_timezone_abbreviation,
                            ml_eventcost: selectedEventsList[i].eld_eventcost,
                            ml_type_subtype: '',
                            ml_max_qty_per_user: 0
                        })
                    }

                    updateMerchandise({
                        variables:
                        {
                            merchandise_id: editMerchandiseId,
                            data:
                            {
                                merchandise_orgId: orgId,
                                merchandise_imageurl: JSON.stringify(merchandiseImgList),
                                merchandise_name: fieldData.sthExperience,
                                merchandise_category: fieldData.category,
                                // merchandise_pointcost: parseFloat(fieldData.pointsCost),
                                merchandise_pointcost: 1,
                                merchandise_description: fieldData.description,
                                merchandise_numberoflisting: selectedEventsList.length > 0 ? selectedEventsList.length : 0,
                                merchandise_listings: tempSelectedEventsList,
                                merchandise_isemailvoucher: fieldData.emailVoucher === 'YES' ? true : false,
                                merchandise_fulfillment: fieldData.merFulfillment === 'YES' ? true : false,
                                merchandise_status: 'ACTIVE'
                            }
                        }
                    });
                }

                // setErrorMsg("Merchandise updated successfully.");
                // setOpenToast(true);
                localStorage.setItem('isMerchandiseUpdated', 'YES')
                setTimeout(() => { handleClearAndClose() }, 1000)
            }
        } else {
            // console.log("ADD")

            // if (fieldData.category === 'Merch' || fieldData.category === 'Autographs') {
            if (fieldData.category !== 'Experiences') {
                // to reassign as per add merchandise api key pair.
                let tempMerchDataList = []
                for (let i = 0; i < selectedMerchList.length; i++) {

                    let tempDateTime: any = '2099-10-10' + ' ' + '12:00:00-07:00';
                    tempDateTime = tempDateTime ? new Date(tempDateTime).getTime() : 0

                    tempMerchDataList.push({
                        ml_eldid: Math.floor(100000 + Math.random() * 900000),
                        ml_date: '2099-10-10',
                        ml_starttime: '12:00:00-07:00',
                        ml_endtime: '13:00:00-07:00',
                        ml_datetime: tempDateTime.toString(),
                        ml_selectioncapacity: parseInt(selectedMerchList[i].qty),
                        ml_availablecapacity: parseInt(selectedMerchList[i].qty),
                        ml_location: '',
                        ml_city: '',
                        ml_country: '',
                        ml_event_id: Math.floor(100000 + Math.random() * 900000),
                        ml_timezone_abbreviation: 'EDT',
                        ml_eventcost: parseInt(fieldData.pointsCost),
                        ml_type_subtype: selectedMerchList[i].type + '||' + selectedMerchList[i].subType,
                        ml_max_qty_per_user: parseInt(selectedMerchList[i].maxQtyPerCust)
                    })
                }

                addMerchandise({
                    variables:
                    {
                        data:
                        {
                            merchandise_orgId: orgId,
                            merchandise_imageurl: JSON.stringify(merchandiseImgList),
                            merchandise_name: fieldData.merchandiseName,
                            merchandise_category: fieldData.category,
                            merchandise_pointcost: parseInt(fieldData.pointsCost),
                            merchandise_description: fieldData.description,
                            merchandise_numberoflisting: selectedMerchList.length > 0 ? selectedMerchList.length : 0,
                            merchandise_listings: tempMerchDataList,
                            merchandise_isemailvoucher: fieldData.emailVoucher === 'YES' ? true : false,
                            merchandise_fulfillment: fieldData.merFulfillment === 'YES' ? true : false,
                            merchandise_status: 'ACTIVE'
                        }
                    }
                });

                // console.log('RK test data', tempMerchDataList)
            } else {
                // to reassign as per add merchandise api key pair.
                let tempSelectedEventsList = []
                for (let i = 0; i < selectedEventsList.length; i++) {

                    let tempDateTime: any = selectedEventsList[i].eld_date + ' ' + selectedEventsList[i].eld_starttime;
                    tempDateTime = tempDateTime ? new Date(tempDateTime).getTime() : 0

                    tempSelectedEventsList.push({
                        ml_eldid: selectedEventsList[i].eld_id,
                        ml_date: selectedEventsList[i].eld_date,
                        ml_starttime: selectedEventsList[i].eld_starttime,
                        ml_endtime: selectedEventsList[i].eld_endtime,
                        ml_datetime: tempDateTime.toString(),
                        ml_selectioncapacity: selectedEventsList[i].eld_selectioncapacity,
                        ml_availablecapacity: selectedEventsList[i].eld_availablecapacity,
                        ml_location: selectedEventsList[i].eld_location,
                        ml_city: selectedEventsList[i].eld_city,
                        ml_country: selectedEventsList[i].eld_country,
                        ml_event_id: selectedEventsList[i].eld_event_id,
                        ml_timezone_abbreviation: selectedEventsList[i].eld_timezone_abbreviation,
                        ml_eventcost: selectedEventsList[i].eld_eventcost,
                        ml_type_subtype: '',
                        ml_max_qty_per_user: 0
                    })
                }

                addMerchandise({
                    variables:
                    {
                        data:
                        {
                            merchandise_orgId: orgId,
                            merchandise_imageurl: JSON.stringify(merchandiseImgList),
                            merchandise_name: fieldData.sthExperience,
                            merchandise_category: fieldData.category,
                            // merchandise_pointcost: parseFloat(fieldData.pointsCost),
                            merchandise_pointcost: 1,
                            merchandise_description: fieldData.description,
                            merchandise_numberoflisting: selectedEventsList.length > 0 ? selectedEventsList.length : 0,
                            merchandise_listings: tempSelectedEventsList,
                            merchandise_isemailvoucher: fieldData.emailVoucher === 'YES' ? true : false,
                            merchandise_fulfillment: fieldData.merFulfillment === 'YES' ? true : false,
                            merchandise_status: 'ACTIVE'
                        }
                    }
                });
            }

            // setErrorMsg("Merchandise created successfully.");
            // setOpenToast(true); 
            localStorage.setItem('isMerchandiseCreated', 'YES')
            setTimeout(() => { handleClearAndClose() }, 1000)
        }
        setConfirmed(true);
        setOpenConfirm(false);
    }

    const onCancel = () => {
        if (!cancelDisabled) {
            setConfirmMessage("Are you sure you want to cancel? By cancel this operation, you may lose all changes you made.");
            setOpenConfirm(true);
            setOpenCancelConfirm(true)
        } else {
            handleClearAndClose()
        }
    }

    const onHandleCancel = (objType: any) => {
        if (objType === 'YES') {
            handleClearAndClose()
        } if (objType === 'NO') {
            setOpenConfirm(false)
            setOpenCancelConfirm(false)
        }
    }

    const onSubmit: SubmitHandler<IFormAddMerchandise> = fieldData => {
        // setConfirmMessage("Are you sure with the Point Requested value?");

        // to show error message when merchandise images are not selected
        if (merchandiseImgUrl.length === 0) {
            setErrorMsg("Please upload a minimum of 1 merchandise image.");
            setOpenToast(true);
            setConfirmed(false);
            return;
        }

        // if (fieldData.category !== 'Merch' && fieldData.category !== 'Autographs') {
        if (fieldData.category === 'Experiences') {
            if (selectedEventsList.length === 0) {
                setErrorMsg("Please choose merchandise listings.");
                setOpenToast(true);
                setConfirmed(false);
                return;
            }
            // } else if (fieldData.category === 'Merch' || fieldData.category === 'Autographs') {
        } else if (fieldData.category !== 'Experiences') {
            let tempAllowed = true
            if (selectedMerchList.length > 1) {
                for (let inc = 0; inc < selectedMerchList.length; inc++) {
                    if (selectedMerchList[inc].type.trim() === '' || selectedMerchList[inc].qty === '' || selectedMerchList[inc].maxQtyPerCust === '' || selectedMerchList[inc].qty === '0' || selectedMerchList[inc].maxQtyPerCust === 0) tempAllowed = false
                }
            } else {
                for (let inc = 0; inc < selectedMerchList.length; inc++) {
                    if (selectedMerchList[inc].qty === '' || selectedMerchList[inc].maxQtyPerCust === '' || selectedMerchList[inc].qty === '0' || selectedMerchList[inc].maxQtyPerCust === 0) tempAllowed = false
                }
            }

            if (!tempAllowed) {
                setErrorMsg("Please enter merchandise listings.");
                setOpenToast(true);
                setConfirmed(false);
                return;
            }
        }

        if (fieldData.pointsCost.length > 0) {
            var regNumber = new RegExp('^[0-9]+$');
            if (!regNumber.test(fieldData.pointsCost)) {
                setErrorMsg("Please enter only numeric values in points cost filed.");
                setOpenToast(true);
                setConfirmed(false);
                return;
            }
        }

        if (fieldData.description.length === 0) {
            setErrorMsg("Please enter the description.");
            setOpenToast(true);
            setConfirmed(false);
            return;
        }

        setConfirmMessage("Are you sure with the selected merchandise listings?");

        if (!confirmed) setOpenConfirm(true);
    }

    // to check all 3 images has been uploaded or not.
    const merchandiseImageUploadChecking = () => {
        let isMaxCountReached = true
        let newMerchandiseImgList = [...merchandiseImgList];

        for (let i = 0; i <= merchandiseImgList.length - 1; i++) {
            if (newMerchandiseImgList[i].imageUrl === '') isMaxCountReached = false
        }
        return isMaxCountReached
    }

    // to upload selected images to S3.
    const uploadImage = (e: React.ChangeEvent<any>) => {
        const targetFiles: any = e.target.files;

        // console.log(targetFiles);

        if (targetFiles[0].type == "image/png" || targetFiles[0].type == "image/jpeg") {
            if (!merchandiseImageUploadChecking()) {
                setLoader(true);
                if (targetFiles.length > 0) {
                    // upload image graphql api start 
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const file = targetFiles[0];
                    const reader = new FileReader();
                
                    reader.onload = (e) => {
                      // Update state with the base64 encoded image string
                      console.log('READER', reader.result);
                      uploadMerchImage({
                        variables:
                        {
                            "imageData": reader.result,
                            "fileNamePrefix": 'merchandise'
                        }
                    }).then((res:any)=>{
                        console.log('result', res?.data?.uploadMerchImage);
                        setMerchandiseImgUrl(res?.data?.uploadMerchImage)

                            // copying the old merchandiseImgList array
                            let newMerchandiseImgList = [...merchandiseImgList];

                            // to clear all active image flag before set new active image falg as true.
                            for (let i = 0; i <= merchandiseImgList.length - 1; i++) {
                                newMerchandiseImgList[i].isActive = false;
                            }

                            let isImageAddedToArray = false
                            // replace newly added image with whatever you want to change it to
                            for (let i = 0; i <= merchandiseImgList.length - 1; i++) {
                                if (!isImageAddedToArray && newMerchandiseImgList[i].imageUrl === '') {
                                    newMerchandiseImgList[i].imageUrl = res?.data?.uploadMerchImage;
                                    newMerchandiseImgList[i].isActive = true;
                                    isImageAddedToArray = true
                                }
                            }

                            setMerchandiseImgList(newMerchandiseImgList);
                            setLoader(false);
                            setCancelDisabled(false)
                    }).catch((err: any) => console.error(err));
                    };
                
                    if (file) {
                      // Read the image file as a data URL
                      reader.readAsDataURL(file);
                    }
                    // upload image graphql api end
                }
            } else {
                setErrorMsg("You can upload a maximum of 3 images.");
                setOpenToast(true);
            }
        } else {
            setErrorMsg("Please upload image format as png or jpg.");
            setOpenToast(true);
        }
    }

    // to capture event capacity.
    const handleEventCapacity = (e: React.ChangeEvent<any>, selEventId: any) => {
        // console.log('rk chec')
        const targetValue = (e.target as HTMLInputElement).value;
        let newSelectedEventsList = [...selectedEventsList];

        if (selectedEventsList && selectedEventsList.length > 0) {
            for (let i = 0; i <= selectedEventsList.length - 1; i++) {
                if (selectedEventsList[i].eld_id === selEventId) {
                    newSelectedEventsList[i].eld_selectioncapacity = targetValue.length < 4 ? targetValue : targetValue.slice(0, 5);
                    setSelectedEventsList(newSelectedEventsList)
                }
            }
        }
    }

    // to capture type, sub-type, qty and max qty per customer.
    const handleMerchListData = (e: React.ChangeEvent<any>, idx: any, col: any) => {
        // console.log('rk chec')
        const targetValue = (e.target as HTMLInputElement).value;
        let newSelectedMerchList = [...selectedMerchList]; // selectedMerchList

        if (selectedMerchList && selectedMerchList.length > 0) {
            for (let i = 0; i <= selectedMerchList.length - 1; i++) {
                if (selectedMerchList[i].idx === idx) {
                    if (col == '1') newSelectedMerchList[i].type = targetValue
                    if (col == '2') newSelectedMerchList[i].subType = targetValue
                    if (col == '3') {
                        // newSelectedMerchList[i].qty = targetValue.length < 4 ? targetValue : targetValue.slice(0, 5);
                        let tempValue: any = targetValue.length < 4 ? targetValue : targetValue.slice(0, 5);
                        if (tempValue === '') newSelectedMerchList[i].qty = tempValue;
                        else if (!isNaN(tempValue) && parseInt(tempValue) >= 0 && !tempValue.includes('.') && !tempValue.includes('-')) newSelectedMerchList[i].qty = tempValue;
                    }
                    if (col == '4') {
                        // newSelectedMerchList[i].maxQtyPerCust = targetValue.length < 4 ? targetValue : targetValue.slice(0, 5);
                        let tempValue: any = targetValue.length < 4 ? targetValue : targetValue.slice(0, 5);
                        if (tempValue === '') newSelectedMerchList[i].maxQtyPerCust = tempValue;
                        else if (!isNaN(tempValue) && parseInt(tempValue) >= 0 && !tempValue.includes('.') && !tempValue.includes('-')) newSelectedMerchList[i].maxQtyPerCust = tempValue;
                    }
                    setSelectedMerchList(newSelectedMerchList)
                }
            }
        }
        setCancelDisabled(false)
    }

    // to change input values
    const handleChangeField = (e: React.ChangeEvent<any>) => {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;
        setValue(targetName, targetValue);
        setCancelDisabled(false)
    }

    // to change dropdown values here.
    const handleChange = (e: React.ChangeEvent<{ value: string | unknown }>) => {
        const targetType = (e.target as HTMLInputElement).value;
        const targetName = (e.target as HTMLInputElement).name;

        // to pass selected STHExperience to useState
        if (targetName === 'sthExperience') {
            setSelSTHExperience(targetType);
            setSelectedEventsList([])
            setPastDate(true)
            setDisplayMonth(moment().format('MMM YYYY'))
            setGetEventsByYearMonth(moment().format('YYYY-MM'))
            setIsCurrentMonth(true)
            eventsRefetch()
        }

        if (targetName === 'category') {
            setCategory(targetType)

            // remove already selected events if any.
            // if (targetType === 'Merch' || targetType === 'Autographs') {
            if (targetType !== 'Experiences') {
                setValue("merFulfillment", 'YES');
                setEventsList([]);
                setSelectedEventsList([]);
                setSelSTHExperience('');
                setValue("merchandiseName", '');
                setValue("pointsCost", '');
                setSelectedMerchList([{ idx: Math.floor(100000 + Math.random() * 900000), type: '', subType: '', qty: '', maxQtyPerCust: '', isEventType: 'NEW' }]);
            }
            // remove added merch data if any.
            // if (targetType !== 'Merch' && targetType !== 'Autographs') {
            if (targetType === 'Experiences') {
                setValue("merchandiseName", '');
                setValue("pointsCost", '');
                setValue("merFulfillment", 'NO');
                setSelectedMerchList([{ idx: Math.floor(100000 + Math.random() * 900000), type: '', subType: '', qty: '', maxQtyPerCust: '', isEventType: 'NEW' }]);
            }
        }

        setValue(targetName, targetType);
        setCancelDisabled(false)
    }

    // to set selected image as merchandise primary thumbnail.
    const handleMerchandiseThumbnail = (index: number) => {
        // copying the old merchandiseImgList array
        let newMerchandiseImgList = [...merchandiseImgList];

        if (newMerchandiseImgList[index].imageUrl) {
            // to clear all active image flag before set new active image flag as true.
            for (let i = 0; i <= merchandiseImgList.length - 1; i++) {
                newMerchandiseImgList[i].isActive = false;
            }

            newMerchandiseImgList[index].isActive = true;

            setMerchandiseImgUrl(newMerchandiseImgList[index].imageUrl)
            setMerchandiseImgList(newMerchandiseImgList);
        } else {
            let isImgAvailable = false
            for (let i = 0; i <= merchandiseImgList.length - 1; i++) {
                if (newMerchandiseImgList[i].imageUrl) {
                    isImgAvailable = true
                    newMerchandiseImgList[i].isActive = true;
                    setMerchandiseImgUrl(newMerchandiseImgList[i].imageUrl)
                    setMerchandiseImgList(newMerchandiseImgList);
                    break;
                }
            }
            if (!isImgAvailable) setMerchandiseImgUrl('')
        }
    }

    // to get date format as "Fri, Mar 12"
    const getWeekDayNameAndMonthNameAndDateNo = (objDate: any) => {
        // if (objDate) {
        //     let d = new Date(objDate);

        //     // get week name as "Fri"
        //     let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        //     let dayName = days[d.getDay()].substring(0,3);

        //     // get month name as "Mar"
        //     let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        //     let monthName = monthNames[d.getMonth()].substring(0,3)

        //     // get date number as "04" || "21".
        //     let dt = d.getDate()
        //     let getDate = dt.toString()
        //     if (dt < 10) getDate = '0' + getDate

        //     return dayName + ', ' + monthName + ' ' + getDate
        // }

        if (objDate) {
            let d = objDate + 'T00:00:00' // '2021-03-01T'
            let dDate: any = moment(d).format('llll')
            dDate = dDate.split(',')

            return dDate[0] + ', ' + dDate[1]  // Tue, Jun 22
        }
    }

    // to display time from "17:35:00" to "05:35 PM" 
    const getTimeAMPM = (time: any) => {
        time = time.substring(0, 5)
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = time[0] % 12 || 12; // Adjust hours
            time[0] = time[0] < 10 ? '0' + time[0] : time[0];
        }
        return time.join(''); // return adjusted time or original string
    }

    // to display time and zone as " 10:00 AM - 12:00 PM (EST)"
    const getFullDateWithTimeStampAndZone = (objData: any) => {
        // console.log('objData rk', objData)

        let d = objData.eld_date + 'T' // '2021-03-01T'
        // let dStartTime = moment(d + objData.eld_starttime).format('hha')
        // let dEndTime = moment(d + objData.eld_endtime).format('hha')

        let dStartTime = getTimeAMPM(objData.eld_starttime)
        let dEndTime = getTimeAMPM(objData.eld_starttime)

        // to get end time as starttime + 60 min.

        let tempdEndTime = parseInt(dEndTime.toString().split(":")) + 1;
        let fulldEndTime = tempdEndTime < 10 ? ('0' + tempdEndTime) : tempdEndTime
        let custdEndTime = fulldEndTime + ':' + dEndTime.toString().split(":")[1]

        if (fulldEndTime === 12) {
            let tempTimeFormat = (dStartTime.substring(6, 2) === 'AM' ? ' PM' : ' AM')
            custdEndTime = fulldEndTime + ':' + (dEndTime.toString().split(":")[1]).substring(0, 2) + tempTimeFormat
        }

        let dateAndTimeZone = d + objData.eld_starttime

        // let timezone = moment(dateAndTimeZone).tz('America/Los_Angeles').format('z')

        let timezoneName = 'PDT'
        for (let j = 0; j < zoneList.length; j++) {
            if (zoneList[j].zoneCode === objData.eld_timezone_abbreviation.toUpperCase()) {
                timezoneName = zoneList[j].zoneName;
            }
        }
        let timezone = moment(dateAndTimeZone).tz(timezoneName).format('z')

        return dStartTime + ' - ' + custdEndTime + ' (' + timezone + ')'
    }

    // based on event selection, pushing the event records to useState
    const handleEventsSelection = (eventData: any) => {
        let isEventAlreadyExist = false
        // console.log('selected event', eventData)

        let newSelectedEventsList = [...selectedEventsList];

        if (selectedEventsList && selectedEventsList.length > 0) {
            for (let i = 0; i <= selectedEventsList.length - 1; i++) {
                if (selectedEventsList[i].eld_id === eventData.eld_id) {
                    // to get selected events only.
                    let filteredArray = selectedEventsList.filter(function (item: any) {
                        return eventData.eld_id !== item.eld_id;
                    });
                    isEventAlreadyExist = true
                    setSelectedEventsList(filteredArray.length > 0 ? filteredArray : [])
                }
            }
        }

        // to push new events here
        if (!isEventAlreadyExist) {
            newSelectedEventsList.push({
                eld_id: eventData.eld_id, // "444",
                eld_name: eventData.eld_name, // "STH Tailgate",
                eld_date: eventData.eld_date, // "2021-02-12",
                eld_starttime: eventData.eld_starttime, // "10:00:00+00",
                eld_endtime: eventData.eld_endtime, // "12:00:00+00",
                eld_location: eventData.eld_location, // "Heinz Field",
                eld_city: eventData.eld_city, // "Pittsburg",
                eld_country: eventData.eld_country, // "US",
                eld_selectioncapacity: eventData.eld_availablecapacity,
                eld_availablecapacity: eventData.eld_availablecapacity,
                isEventSelected: true,
                isEventType: 'NEW',
                eld_event_id: eventData.eld_event_id,
                eld_timezone_abbreviation: eventData.eld_timezone_abbreviation,
                eld_eventcost: eventData.eld_eventcost
            })
            setSelectedEventsList(newSelectedEventsList)
        }

        // to update selected event list flag as true/false to handle border css.
        let newEventsList = [...eventsList];
        if (eventsList && eventsList.length > 0) {
            for (let i = 0; i <= eventsList.length - 1; i++) {
                if (eventsList[i].eld_id === eventData.eld_id) {
                    newEventsList[i].isEventSelected = !newEventsList[i].isEventSelected
                }
            }
            setEventsList(newEventsList)
        }
        setCancelDisabled(false)
    }

    // to remove selected event and deselect from event listing block.
    const handleRemoveSelectedEvent = (eventData: any) => {
        let newSelectedEventsList = [...selectedEventsList];

        if (selectedEventsList && selectedEventsList.length > 0) {
            for (let i = 0; i <= selectedEventsList.length - 1; i++) {
                if (selectedEventsList[i].eld_id === eventData.eld_id) {
                    // to get selected events only.
                    let filteredArray = selectedEventsList.filter(function (item: any) {
                        return eventData.eld_id !== item.eld_id;
                    });
                    setSelectedEventsList(filteredArray.length > 0 ? filteredArray : [])
                }
            }
        }

        // to update selected event list flag as true/false to handle border css.
        let newEventsList = [...eventsList];
        if (eventsList && eventsList.length > 0) {
            for (let i = 0; i <= eventsList.length - 1; i++) {
                if (eventsList[i].eld_id === eventData.eld_id) {
                    newEventsList[i].isEventSelected = false
                }
            }
            setEventsList(newEventsList)
        }
    }

    // to remove selected merch and deselect from merch listing block.
    const handleMerchListSelection = (merchData: any) => {
        // let newSelectedEventsList = [...selectedMerchList]; 

        if (selectedMerchList && selectedMerchList.length > 0) {
            for (let i = 0; i <= selectedMerchList.length - 1; i++) {
                if (selectedMerchList[i].idx === merchData.idx) {
                    // to get selected events only.
                    let filteredArray = selectedMerchList.filter(function (item: any) {
                        return merchData.idx !== item.idx;
                    });
                    setSelectedMerchList(filteredArray.length > 0 ? filteredArray : [])
                }
            }
        }
        setCancelDisabled(false)
    }

    const handleDisplayEventsByMonth = (objMonth: any, objType: any) => {

        let tempDate = objMonth.split('-')      // 2021-03
        let getYear = parseInt(tempDate[0])     // 2021
        let getMonth = parseInt(tempDate[1])    // 03

        let yearMonth = ''

        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // let monthName = monthNames[d.getMonth()].substring(0,3)


        if (objType === 'NEXT') {
            let nextMonth = getMonth + 1

            if (nextMonth > 12) {
                getYear = getYear + 1
                nextMonth = 1
            }

            yearMonth = (getYear + '-' + (nextMonth < 10 ? '0' + nextMonth : nextMonth)).toString()

            console.log('next selection', yearMonth)

            let monthNameYear = monthNames[nextMonth - 1].substring(0, 3).toString() + ' ' + getYear
            setDisplayMonth(monthNameYear)

            setGetEventsByYearMonth(yearMonth)
            setIsCurrentMonth(false)
            setPastDate(false)
            eventsRefetch()
        } else if (objType === 'PREV') {
            let prevMonth = getMonth - 1
            if (prevMonth < 1) {
                getYear = getYear - 1
                prevMonth = 12
            }

            yearMonth = (getYear + '-' + (prevMonth < 10 ? '0' + prevMonth : prevMonth)).toString()

            // to restrict past month
            let currYearMonth = moment().format('YYYY-MM')

            let tDateYear = currYearMonth.split('-')    // 2021-03
            let tgetYear = parseInt(tDateYear[0])       // 2021
            let tgetMonth = parseInt(tDateYear[1])      // 03

            // to restrict past date selection
            if ((tgetYear === getYear && prevMonth < tgetMonth) || (getYear < tgetYear)) {
                console.log('date range not accepted')
                // setPastDate(true)

            } else {
                console.log('next selection', yearMonth)
                setIsCurrentMonth(false)
                if ((tgetYear === getYear && prevMonth === tgetMonth) || (getYear < tgetYear)) {
                    // console.log('date range not accepted')
                    setIsCurrentMonth(true)
                    setPastDate(true)
                }

                let monthNameYear = monthNames[prevMonth - 1].substring(0, 3).toString() + ' ' + getYear
                setDisplayMonth(monthNameYear)

                setGetEventsByYearMonth(yearMonth)
                eventsRefetch()
            }

        }

        // setGetEventsByYearMonth

        // const [displayMonth, setDisplayMonth] = React.useState<any>(moment().format('MMM YYYY'));                   // 'Mar 2021'
        // const [getEventsByYearMonth, setGetEventsByYearMonth] = React.useState<any>(moment().format('YYYY-MM'));      // '2021-03'
    }

    const handleRemoveImage = (objIndex: any) => {
        const newMerchandiseImgList = [...merchandiseImgList]
        newMerchandiseImgList[objIndex].imageUrl = ''
        newMerchandiseImgList[objIndex].isActive = false

        setMerchandiseImgList(newMerchandiseImgList)
    }

    const handleMerchandiseList = () => {
        const newselectedMerchList = [...selectedMerchList]
        newselectedMerchList.push({ idx: Math.floor(100000 + Math.random() * 900000), type: '', subType: '', qty: '', maxQtyPerCust: '', isEventType: 'NEW' })
        setSelectedMerchList(newselectedMerchList)
    }

    return (
        <div>
            <Dialog open={onOpen} onClose={handleCloseClick} aria-labelledby="form-dialog-title" className="common-dialog-container merchandise-container confirmation-popup" disableBackdropClick={true} disableEscapeKeyDown={true}>
                <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">{editMerchandiseId > 0 ? "Edit" : "New"} Merchandise</DialogTitle>
                    <DialogContent>
                        {/* Full page */}
                        <Grid className="form-grid" container sm={12} spacing={6}>
                            {/* Left side block */}
                            <Grid className='pl-zero' item sm={4} xs={12}>
                                {/* To show Merchandise active image */}
                                <div className='mer-thumbnail-block'>
                                    {loader && <Loader size="60px" />}
                                    {!loader && <img className={merchandiseImgUrl ? 'mer-thumbnail' : 'mer-thumbnail-default'} src={merchandiseImgUrl || defaultLogoUrl} alt='Merchandise-logo' width='41px' height='41px' />}
                                </div>

                                {/* Add Merchandise Image list here */}
                                <div className='img-flx-box'>
                                    {editMerchandiseId > 0
                                        ? (
                                            merchandiseImgList.map((item: any, index: any, elements: any) => {
                                                if (index === 0 && (elements[0].imageUrl === '' || elements[1].imageUrl === '' || elements[2].imageUrl === '')) {
                                                    return (
                                                        <div className="input-group input-file-group add-mer-log-box">
                                                            <img className="mer-profile-img" src={plusIconUrl} alt="add-logo" />
                                                            <input type="file" className="form-fields form-field-file cust-file-type" onChange={(e) => uploadImage(e)} />
                                                        </div>
                                                    )
                                                }
                                            })
                                        ) : (
                                            // {/* + Add Merchandise */}
                                            <div className="input-group input-file-group add-mer-log-box">
                                                <img className="mer-profile-img" src={plusIconUrl} alt="add-logo" />
                                                <input type="file" className="form-fields form-field-file cust-file-type" onChange={(e) => uploadImage(e)} />
                                            </div>
                                        )
                                    }

                                    {/* empty image boxes to fill other merchandise images */}
                                    {merchandiseImgList.map((item: any, index: any) => {
                                        return (
                                            // <div className={item.imageUrl ? "input-group input-file-group add-mer-log-box active" : "input-group input-file-group add-mer-log-box inactive"} onClick={editMerchandiseId ? () => {return false} : () => handleMerchandiseThumbnail(index)} key={index} >
                                            <div className={item.imageUrl ? "input-group input-file-group add-mer-log-box active" : "input-group input-file-group add-mer-log-box inactive"} onClick={item.imageUrl ? () => handleMerchandiseThumbnail(index) : () => { return false; }} key={index} >
                                                {item.imageUrl && <img className="mer-small-img" src={item.imageUrl} alt="add-logo" />}
                                                {item.imageUrl && <div className='ic-remove' onClick={(e) => handleRemoveImage(index)} />}
                                            </div>
                                        )
                                    })}
                                </div>

                                {/* Category */}
                                <div className="input-group">
                                    <label className="input-label">Category*</label>
                                    <Controller
                                        name="category"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ value }) => (
                                            <Select displayEmpty name="category" value={value} disabled={editMerchandiseId > 0 ? true : false} className={`custom-select form-select  ${errors.category && 'error'}`} onChange={(e) => handleChange(e)}>
                                                <MenuItem value={value ? value : ""}>
                                                    {value
                                                        ? value === "Tickets"
                                                            ? "Loaded Value"
                                                            : value
                                                        : "Choose Category"}
                                                </MenuItem>
                                                {value !== 'Tickets' && <MenuItem value={'Tickets'}>Loaded Value</MenuItem>}
                                                {value !== 'Merch' && <MenuItem value={'Merch'}>Merch</MenuItem>}
                                                {value !== 'Experiences' && <MenuItem value={'Experiences'}>Experiences</MenuItem>}
                                                {value !== 'Autographs' && <MenuItem value={'Autographs'}>Autographs</MenuItem>}
                                            </Select>
                                        )}
                                    />
                                    {errors.category && errors.category.type == "required" && (<label className="error-message">Field is required</label>)}
                                </div>

                                {/* STH Experience */}
                                {/* {category !== 'Merch' && category !== 'Autographs' */}
                                {category === 'Experiences'
                                    ? (
                                        <div className="input-group">
                                            <label className="input-label" >STH Experience*</label>
                                            <Controller
                                                name="sthExperience"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ value }) => (
                                                    editMerchandiseId > 0
                                                        ? (
                                                            <Select displayEmpty name="sthExperience" value={value} disabled={editMerchandiseId > 0 ? true : false} className={`custom-select form-select  ${errors.sthExperience && 'error'}`} onChange={(e) => handleChange(e)} >
                                                                <MenuItem value={value ? value : ''}>{value ? value : 'Choose event'}</MenuItem>
                                                            </Select>
                                                        ) : (
                                                            <Select displayEmpty name="sthExperience" value={value} disabled={editMerchandiseId > 0 ? true : false} className={`custom-select form-select  ${errors.sthExperience && 'error'}`} onChange={(e) => handleChange(e)} >
                                                                <MenuItem value=''>Choose event</MenuItem>
                                                                {sthExperienceList.map((item: any, index: any) => {
                                                                    return (<MenuItem value={item.eld_name} key={index}>{item.eld_name}</MenuItem>)
                                                                })}
                                                            </Select>
                                                        )
                                                )}
                                            />
                                            {errors.sthExperience && errors.sthExperience.type == "required" && (<label className="error-message">Field is required</label>)}
                                        </div>
                                    ) : ''
                                }

                                {/* {category === 'Merch' || category === 'Autographs' */}
                                {category !== 'Experiences'
                                    ? (
                                        <>
                                            {/* Merchandise name */}
                                            <div className="input-group">
                                                <label className="input-label">Item Name*</label>
                                                <Controller as={TextField} rules={{ required: true }} type="text" name="merchandiseName" inputRef={inputRef} placeholder="Enter merchandise name" className={`cust-color form-fields  ${errors.merchandiseName && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChange(e)} />
                                                {errors.merchandiseName && errors.merchandiseName.type == "required" && (<label className="error-message">Field is required</label>)}
                                                {/* {errors.merchandiseName && errors.merchandiseName.type == "pattern" && (<label className="error-message">Please enter maximum of 2 decimal values(Ex:125.64)</label>)} */}
                                            </div>

                                            {/* Points cost */}
                                            <div className="input-group">
                                                <label className="input-label">Points Cost*</label>
                                                <Controller as={TextField} rules={{ required: true, pattern: /^([0-9]){1,5}$/, max: 99999 }} type="number" name="pointsCost" inputRef={inputRef} placeholder="Allocate points" className={`cust-color form-fields  ${errors.pointsCost && 'error'}`} control={control} defaultValue="" />
                                                {errors.pointsCost && errors.pointsCost.type == "required" && (<label className="error-message">Field is required</label>)}
                                                {errors.pointsCost && errors.pointsCost.type == "max" && (<label className="error-message">Please enter maximum of 5 digit numeric values.</label>)}
                                                {errors.pointsCost && errors.pointsCost.type == "pattern" && (<label className="error-message">Please enter numeric values.</label>)}
                                            </div>
                                        </>
                                    ) : ''
                                }
                            </Grid>

                            {/* Right side block */}
                            <Grid item className="borderL padr0" sm={8} xs={12}>
                                {/* {category === 'Merch' || category === 'Autographs' */}
                                {category !== 'Experiences'
                                    ? (
                                        <Grid item className="flx-align-center" sm={12} xs={12}>
                                            {/* Choose Merchandise Listings */}
                                            <Grid item className="padr0" sm={12} xs={12}>
                                                <div className="input-group mr-15 mt-0">
                                                    <label className="input-label">Item Listings*</label>

                                                    <div className='merch-listing-box'>
                                                        {/* to list empty capacity */}
                                                        {selectedMerchList.length === 0 &&
                                                            <div className='empty-list-title'>Select a listing to assign capacity.</div>
                                                        }

                                                        {/* Selected Event list capacity */}
                                                        <div className=''>
                                                            {selectedMerchList.map((item: any, index: any) => {
                                                                return (
                                                                    // <div className="input-group pad-20 mar-0 pb-0">
                                                                    //     <label className="input-label cap-list-title" >{getWeekDayNameAndMonthNameAndDateNo(item.eld_date)} &nbsp; {getFullDateWithTimeStampAndZone(item)} <span className='ic-cap-close' onClick={editMerchandiseId > 0 ? item.isEventSelected && item.isEventType === 'OLD' ? () => {return false} : () => handleRemoveSelectedEvent(item) : () => handleRemoveSelectedEvent(item)}>{editMerchandiseId > 0 ? item.isEventSelected && item.isEventType === 'OLD' ? '' : 'X' : 'X' }</span></label>
                                                                    //     <input type='number' className='cust-capacity' autoComplete='off' name={'capacity' + item.eld_id} disabled={item.isEventType === 'OLD' ? true : false} placeholder='Enter capacity' value={item.eld_availablecapacity} onBlur={(e:any) => handleEventCapacity(e, item.eld_id)} onChange={(e:any) => handleEventCapacity(e, item.eld_id)} maxLength={4} readOnly />                 
                                                                    // </div>
                                                                    <div className='merch-cap-list-card'>
                                                                        {/* Close button */}

                                                                        {index > 0
                                                                            ? item.isEventType === 'NEW' && <label className="input-label merch-list-close-title" onClick={() => handleMerchListSelection(item)}>X</label>
                                                                            : ''
                                                                        }

                                                                        <Grid item className="padr0" sm={3} xs={3}>
                                                                            <div className="input-group pad-20-10 mar-0 pb-0">
                                                                                {/* <input type='text' className='merch-cust-capacity' autoComplete='off' name={'capacity' + item.idx} placeholder='Type' value={item.type} title={item.type} onBlur={(e:any) => handleMerchListData(e, item.idx, '1')} onChange={(e:any) => handleMerchListData(e, item.idx, '1')} maxLength={30} readOnly={item.isEventType === 'OLD'} /> */}
                                                                                <input type='text' className='merch-cust-capacity' autoComplete='off' name={'capacity' + item.idx} placeholder='Type' value={item.type} title={item.type} onBlur={(e: any) => handleMerchListData(e, item.idx, '1')} onChange={(e: any) => handleMerchListData(e, item.idx, '1')} maxLength={30} />
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item className="padr0" sm={3} xs={3}>
                                                                            <div className="input-group pad-20-10 mar-0 pb-0">
                                                                                <input type='text' className='merch-cust-capacity' autoComplete='off' name={'capacity' + item.idx} placeholder='Sub-Type' value={item.subType} title={item.subType} onBlur={(e: any) => handleMerchListData(e, item.idx, '2')} onChange={(e: any) => handleMerchListData(e, item.idx, '2')} maxLength={30} />
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item className="padr0" sm={3} xs={3}>
                                                                            <div className="input-group pad-20-10 mar-0 pb-0">
                                                                                <input type='number' min="0" className='merch-cust-capacity' autoComplete='off' name={'capacity' + item.idx} placeholder='Avail Qty' value={item.qty} onBlur={(e: any) => handleMerchListData(e, item.idx, '3')} onChange={(e: any) => handleMerchListData(e, item.idx, '3')} maxLength={5} />
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item className="padr0" sm={3} xs={3}>
                                                                            <div className="input-group pad-20-10 mar-0 pb-0">
                                                                                <input type='number' min="0" className='merch-cust-capacity' autoComplete='off' name={'capacity' + item.idx} placeholder='Max Qty per Cust' value={item.maxQtyPerCust} onBlur={(e: any) => handleMerchListData(e, item.idx, '4')} onChange={(e: any) => handleMerchListData(e, item.idx, '4')} maxLength={5} />
                                                                            </div>
                                                                        </Grid>
                                                                    </div>

                                                                )
                                                            })}
                                                        </div>
                                                        <div className='add-merch-list' onClick={handleMerchandiseList}>ADD</div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item className="flx-align-center" sm={12} xs={12}>
                                            {/* Choose Merchandise Listings */}
                                            <Grid item className="padr0" sm={6} xs={12}>
                                                <div className="input-group mr-15 mt-0">
                                                    <label className="input-label">Choose Item Listings*</label>

                                                    <div className='listing-box no-scroll'>
                                                        {selSTHExperience && selSTHExperience.length > 0
                                                            ? <>
                                                                {/* Merchandise list title */}
                                                                <div className='cap-list-card-title'>
                                                                    <div className={pastdate ? 'ic-arr-left dim' : 'ic-arr-left'} onClick={pastdate ? () => { return false } : () => handleDisplayEventsByMonth(getEventsByYearMonth, 'PREV')} />
                                                                    <div className='mer-list-month-title'>{displayMonth}</div>
                                                                    <div className='ic-arr-right' onClick={() => handleDisplayEventsByMonth(getEventsByYearMonth, 'NEXT')} />
                                                                </div>

                                                                {/* Day-wise merchandise listing */}
                                                                {eventsList && eventsList.length > 0
                                                                    ? (
                                                                        <div className='mer-list-card-block'>
                                                                            {/* Merchandise main block */}
                                                                            <div className="input-group pad-20 mar-0 pt-0 pb-0">
                                                                                {eventsList.map((item: any, index: any, elements: any) => {
                                                                                    // console.log('rk elements', elements)
                                                                                    return (
                                                                                        <>
                                                                                            {/* Day title */}
                                                                                            {index === 0
                                                                                                ? <label className="input-label mer-list-title mb-0">{getWeekDayNameAndMonthNameAndDateNo(item.eld_date)}</label>
                                                                                                : elements[index].eld_date !== elements[index - 1].eld_date
                                                                                                    ? <label className="input-label mer-list-title mb-0">{getWeekDayNameAndMonthNameAndDateNo(item.eld_date)}</label>
                                                                                                    : ''
                                                                                            }

                                                                                            {/* event card */}
                                                                                            <div className={item.isEventSelected ? 'mer-list-card selected' : 'mer-list-card'} onClick={editMerchandiseId > 0 ? item.isEventSelected && item.isEventType === 'OLD' ? () => { return false } : () => handleEventsSelection(item) : () => handleEventsSelection(item)}>
                                                                                                <div className='mer-list-inner-card'>
                                                                                                    <div className='mer-event-title'>{getFullDateWithTimeStampAndZone(item)}</div>
                                                                                                    <div className='mer-event-title'>
                                                                                                        <div className='ic-gps' />
                                                                                                        <div className='ic-gps-title'>{item.eld_city}, {item.eld_country}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    ) : <div className='empty-list-title'>No listings to show.</div>
                                                                }
                                                            </>
                                                            : <div className='empty-list-title'>No listings to show.</div>
                                                        }
                                                    </div>
                                                </div>
                                            </Grid>

                                            {/* Capacity per Listing */}
                                            <Grid item className="padr0" sm={6} xs={12}>
                                                <div className="input-group ml-15 mt-0">
                                                    <label className="input-label">Capacity per Listing*</label>

                                                    <div className='listing-box'>
                                                        {/* to list empty capacity */}
                                                        {selectedEventsList.length === 0 &&
                                                            <div className='empty-list-title'>Select a listing to assign capacity.</div>
                                                        }

                                                        {/* Selected Event list capacity */}
                                                        <div className='cap-list-card'>
                                                            {selectedEventsList.map((item: any, index: any) => {
                                                                return (
                                                                    item.isEventSelected &&
                                                                    <div className="input-group pad-20 mar-0 pb-0">
                                                                        <label className="input-label cap-list-title" >{getWeekDayNameAndMonthNameAndDateNo(item.eld_date)} &nbsp; {getFullDateWithTimeStampAndZone(item)} <span className='ic-cap-close' onClick={editMerchandiseId > 0 ? item.isEventSelected && item.isEventType === 'OLD' ? () => { return false } : () => handleRemoveSelectedEvent(item) : () => handleRemoveSelectedEvent(item)}>{editMerchandiseId > 0 ? item.isEventSelected && item.isEventType === 'OLD' ? '' : 'X' : 'X'}</span></label>
                                                                        <input type='number' className='cust-capacity' autoComplete='off' name={'capacity' + item.eld_id} disabled={item.isEventType === 'OLD' ? true : false} placeholder='Enter capacity' value={item.eld_availablecapacity} onBlur={(e: any) => handleEventCapacity(e, item.eld_id)} onChange={(e: any) => handleEventCapacity(e, item.eld_id)} maxLength={4} readOnly />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )
                                }

                                {/* Description */}
                                <div className="input-group textarea-group">
                                    <label className="input-label cust-dec">Description <span>(max. 1000 characters)</span></label>
                                    <Controller control={control} name="description"
                                        render={({ value }) => (
                                            <TextareaAutosize
                                                value={value}
                                                // disabled={fieldEnabled}
                                                aria-label="minimum height"
                                                rowsMin={3}
                                                maxLength={1000}
                                                placeholder="Describe the voucher in brief"
                                                name="description"
                                                className="form-fields textarea-field MuiInputBase-input MuiInput-input cust-text-area"
                                                onChange={(e) => handleChangeField(e)}
                                            />
                                        )}
                                    />
                                </div>

                                {/* <Grid item className="flx-align-center" sm={12} xs={12}>
                                    <Grid item className="padr0" sm={6} xs={12}>
                                        <div className="input-group mr-15">
                                            <label className="input-label" >Email Voucher</label>
                                            <Controller
                                                name="emailVoucher"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ value }) => (
                                                    <Select displayEmpty name="emailVoucher" value={value} className={`custom-select form-select  ${errors.emailVoucher && 'error'}`} onChange={(e) => handleChange(e)} >
                                                        <MenuItem value="YES">YES</MenuItem>
                                                        <MenuItem value="NO">NO</MenuItem>
                                                    </Select>
                                                )}
                                            />
                                            {errors.emailVoucher && errors.emailVoucher.type == "required" && (<label className="error-message">Field is required</label>)}
                                        </div>
                                    </Grid>

                                    <Grid item className="padr0" sm={6} xs={12}>
                                        <div className="input-group ml-15">
                                            <label className="input-label" >Item Fulfillment</label>
                                            <Controller
                                                name="merFulfillment"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ value }) => (
                                                    <Select displayEmpty name="merFulfillment" value={value} disabled={editMerchandiseId > 0 ? true : false} className={`custom-select form-select  ${errors.merFulfillment && 'error'}`} onChange={(e) => handleChange(e)} >
                                                        <MenuItem value={value ? value : 'NO'}>{value ? value : 'NO'}</MenuItem>
                                                        {value !== 'YES' && <MenuItem value="YES">YES</MenuItem>}
                                                        {value !== 'NO' && <MenuItem value="NO">NO</MenuItem>}
                                                    </Select>
                                                )}
                                            />
                                            {errors.merFulfillment && errors.merFulfillment.type == "required" && (<label className="error-message">Field is required</label>)}
                                        </div>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </Grid>

                        {/* Confirmation of Submit Merchandise Details */}
                        {openConfirm &&
                            <div className="confim-model-container cust-modal">
                                <Paper className="confirmation-wrapper padd-20">
                                    <Grid container sm={12}>
                                        <div className="confirmation-sec">
                                            <p>{confirmMessage}</p>
                                            <DialogActions>
                                                <Button className="btn btn-secondary" onClick={clickCanceled} color="primary">CANCEL</Button>
                                                <Button color="primary" onClick={handleSubmit(clickConfirmed)} className="btn btn-primary button">CONFIRM</Button>
                                            </DialogActions>
                                        </div>
                                    </Grid>
                                </Paper>
                            </div>
                        }

                        {openCancelConfirm &&
                            <div className="confim-model-container cust-modal">
                                <Paper className="confirmation-wrapper padd-20">
                                    <Grid container sm={12}>
                                        <div className="confirmation-sec">
                                            <p>{confirmMessage}</p>
                                            <DialogActions>
                                                <Button className="btn btn-secondary" onClick={() => onHandleCancel('NO')} color="primary">NO</Button>
                                                <Button color="primary" onClick={() => onHandleCancel('YES')} className="btn btn-primary button">YES</Button>
                                            </DialogActions>
                                        </div>
                                    </Grid>
                                </Paper>
                            </div>
                        }
                    </DialogContent>

                    {/* Submit Merchandise Details */}
                    <DialogActions>
                        {editMerchandiseId > 0 && viewTypeTxt !== 'Copy' &&
                            <Button onClick={handleDeleteMerchandise} className={statusType === 'INACTIVE' ? "btn btn-secondary status-active" : "btn btn-secondary btn-delete"} color="primary">{statusType === 'INACTIVE' ? 'ACTIVATE' : 'DEACTIVATE'}</Button>
                        }
                        <Button onClick={onCancel} className="btn btn-secondary" color="primary">CANCEL</Button>
                        <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>{editMerchandiseId && viewTypeTxt !== 'Copy' ? 'UPDATE' : 'SAVE'}</Button>
                    </DialogActions>
                </form>

                <Snackbar open={openToast} autoHideDuration={5000} onClose={handleClosed}>
                    <Alert onClose={handleClosed} severity="error">{errorMsg}</Alert>
                </Snackbar>
            </Dialog>
        </div>
    )
}




