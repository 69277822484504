import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Backdrop, Snackbar, CircularProgress } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ReactPlayer from "react-player/lazy";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import { Select, MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "../../constant.css";
import "../manageclubs/manageclubs.css";
import "./createpromotions.css";
import { config } from "../../config";
import PromotionMap from "./promotionmap";
import { useQuery, gql, useMutation } from "@apollo/client";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import PromoQuiz from "./promoQuiz";

interface Props {
  onOpen: boolean;
  handleCloseClick: () => void;
  promoId?: any;
  promotionHuntType?: any;
}

interface ParamTypes {
  orgId: string;
}

interface IFormAddPromo {
  promoCodePrefix?: string | undefined;
  promoCodePoints: string;
  promoType: string | undefined;
  validity: string | undefined;
  validityDay: string;
  promoTotalCount: string;
  sponsorId: string | undefined;
  pointsAvailable?: number | undefined;
  noPointsPerPromo?: number;
  promoSentToMail?: string | null;
  promoTitle?: string | undefined;
  promoMessage: string;
  promoSentTOClub?: string | undefined;
  notifyMessage?: string | undefined;
  huntLocation?: string | undefined;
  videoLink?: string | undefined;
  promoQuiz?: string;
}

let defaultValues: IFormAddPromo = {
  promoCodePrefix: "",
  promoCodePoints: "",
  promoType: "",
  validity: "",
  validityDay: "",
  promoTotalCount: "1",
  sponsorId: "",
  pointsAvailable: 100,
  noPointsPerPromo: 10,

  promoSentToMail: "",
  promoTitle: "Promo Title!",
  promoMessage: "Promo Message",
  promoSentTOClub: "",
  notifyMessage: "Promo Notification",
  huntLocation: "",
  videoLink: "",
  promoQuiz: "No",
};

const promoTypes = [
  {
    lebel: "Select",
    value: "",
  },
  {
    lebel: "Email Coupon",
    value: "Email",
  },
  {
    lebel: "App Notification",
    value: "Notification",
  },
  {
    lebel: "Video Quiz",
    value: "Video",
  },
  {
    lebel: "Hunt",
    value: "Hunt",
  },
];

export const GET_SPONSOR_QUERY = gql`
  query GetSponsorInventory($clubId: String!) {
    getSponsorWithOrgId(clubId: $clubId) {
      id
      sponsorName
      sponsorLogo
      availablePoints
    }
  }
`;

const CREATE_PROMO = gql`
  mutation createPromo($data: NewPromoInput!) {
    createPromo(data: $data) {
      id
    }
  }
`;

const CREATE_HUNT = gql`
  mutation createHunt($data: NewHuntInput!) {
    createHunt(data: $data) {
      id
    }
  }
`;

const UPDATE_PROMO = gql`
  mutation updatePromos($id: String!, $data: UpdatePromoInput!) {
    updatePromo(id: $id, data: $data) {
      promoType
    }
  }
`;
const PROMOTION_BY_ID = gql`
  query getPromoFindOne($id: String!) {
    getPromoWithId(id: $id) {
      promoCodePrefix
      promoCodePoints
      promoType
      validity
      validityDay
      promoTotalCount
      sponsorId
      id
      promoTitle
      pointsAvailable
      noPointsPerPromo
      promoSentToMail
      promoMessage
      promoSentTOClub
      videoLink
      promoUrl
      promoUrl_ios
      promoQuiz
      promoQuizList
    }
  }
`;

const HUNT_WITH_ID = gql`
  query getHuntWithId($id: String!) {
    getHunt(id: $id) {
      huntCodePrefix
      huntCodePoints
      validity
      validityDay
      sponsorId
      noPointsPerHunt
      locationName
      mainLat
      mainLng
      id
      points {
        lat
        lng
        pointsValue
      }
    }
  }
`;

export default function CreatePromotions({
  handleCloseClick,
  onOpen,
  promoId,
  promotionHuntType,
}: Props) {
  const promotionId = promoId ? promoId : "";
  const open = onOpen;
  let { orgId } = useParams<ParamTypes>();
  const fieldEnabled = promotionId ? true : false;
  const [error, setError] = useState<any>("");
  const [totalCoins, setTotalCoins] = useState(0);
  const [placedCoins, setPlacedCoins] = useState(0);
  const [promoTitle, setPromoTitle] = useState("PromoTitle");
  const [maxAllocation, setMaxAllocation] = useState(0);
  const [load, setLoad] = useState(false);
  const [locationSelected, setLocationSelected] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [value, setValueLocation] = useState("");
  const [promoMessageValidation, setPromoMessageValidation] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<string>(
    "Are you sure with the Point Requested value?"
  );
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);
  const inputRef: any = useRef();
  const [videoLink, setVideoLink] = useState<string | undefined>("");
  const [promoQuiz, setPromoQuiz] = useState("");
  const [quizList, setQuizList] = React.useState<any>([
    {
      questionId: Math.floor(100000 + Math.random() * 900000),
      questionTitle: "",
      keyList: [
        { id: Math.floor(100000 + Math.random() * 900000), keyName: "" },
      ],
      answer: null,
    },
  ]);

  const [openToast, setOpenToast] = React.useState<any>(false);
  const {
    loading: sponsorLoading,
    data: getSponsor,
    refetch: refetchSponsor,
  } = useQuery<any>(GET_SPONSOR_QUERY, { variables: { clubId: orgId } });
  const {
    loading: promoLoading,
    data: PromoDetails,
    refetch: refetchPromoWithID,
  } = useQuery<any>(PROMOTION_BY_ID, { variables: { id: promotionId } });
  const {
    loading: huntLoading,
    data: huntData,
    refetch: refetchHunt,
  } = useQuery<any>(HUNT_WITH_ID, { variables: { id: promotionId } });
  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };
  const [promoTotalCountValidation, setPromoTotalCountValidation] =
    useState<any>("");
  const [promotionType, setPromotionType] = React.useState<any>("");
  const [promotion, setPromotion] =
    React.useState<IFormAddPromo>(defaultValues);
  const [noofFieldCaption, setNoofFieldCaption] = React.useState("Promocodes");
  const [validityDate, setValidityDate] = React.useState<any>(
    moment().format("D MMM YYYY")
  );
  const {
    handleSubmit,
    register,
    reset,
    clearErrors,
    control,
    errors,
    setValue,
    getValues,
  } = useForm<IFormAddPromo>({ defaultValues, shouldUnregister: false });
  const [sponsorLogo, setSponsorLogo] = useState<any>("");
  const [latlng, setLatlng] = useState({
    lat: 26.02628761374811,
    lng: -80.20744120866775,
  });
  const [markerImage, setMarkerImage] = useState("");
  const [locationName, setLocationName] = useState("");
  const [noCoinsValue, setNoCoinsValue] = useState(0);
  const [points, setPoints] = useState<any[]>([]);
  const [distArr, setDistArr] = useState<any>([]);
  const [disableDist, setDisableDist] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = useState({
    info: "Place coins before you save...",
    severity: "warning",
  });
  const [huntLocation, setHuntLocation] = useState("");

  const promoInputValues = (values: any) => {
    setValue("promoCodePrefix", values.promoCodePrefix);
    setValue("promoCodePoints", values.promoCodePoints);
    setValue("promoType", values.promoType);
    setValue("validity", values.validity);
    setValue("validityDay", values.validityDay);
    setValue("promoTotalCount", values.promoTotalCount);
    setValue("sponsorId", values.sponsorId);
    setValue("pointsAvailable", values.pointsAvailable);
    setValue("noPointsPerPromo", values.noPointsPerPromo);
    setValue("promoSentToMail", values.promoSentToMail);
    setValue("promoTitle", values.promoTitle);
    setValue("promoMessage", values.promoMessage);
    setValue("promoSentTOClub", values.promoSentTOClub);
    setValue("notifyMessage", values.notifyMessage);
    setValue("huntLocation", values.huntLocation);
    setValue("videoLink", values.videoLink);

    if (promotionId) {
      setValue("promoQuiz", values.promoQuiz ? "Yes" : "No");
      let tempPromoQuiz: any = values.promoQuiz ? "Yes" : "No";
      setPromoQuiz(tempPromoQuiz);
    } else {
      setValue("promoQuiz", "No");
      setPromoQuiz("");
    }
    setPromotionType(defaultValues.promoType);
    setValidityDate(defaultValues.validity);
    setVideoLink(defaultValues.videoLink);
  };

  const handleConfirmationBox: SubmitHandler<IFormAddPromo> = (data: any) => {
    if (distArr.length > 0 && locationName != "") {
      setConfirmMessage(
        "Do you want to confirm with this points " +
          getValues("promoTotalCount") +
          " ?"
      );
      setOpenConfirm(true);
    } else {
      setError("Location and Coin distribution fields are mandatory");
      setOpenToast(true);
    }
  };
  const clickCanceled = () => {
    setConfirmed(false);
    setOpenConfirm(false);
  };

  const handleUpdateQuiz = (obj: any) => {
    setQuizList(obj);
  };

  useEffect(() => {
    if (promotionType != "Hunt" && promotionHuntType != "Hunt") {
      if (!promoLoading && promotionId) {
        defaultValues = { ...PromoDetails.getPromoWithId };
        setPromotion(PromoDetails.getPromoWithId);
        promoInputValues(defaultValues);
        setPromotion(defaultValues);
      } else {
        defaultValues = {
          promoCodePrefix: "",
          promoCodePoints: "",
          promoType: "",
          validity: "",
          validityDay: "",
          promoTotalCount: "1",
          sponsorId: "",
          pointsAvailable: 100,
          noPointsPerPromo: 10,
          promoSentToMail: "",
          promoTitle: "Promo Title!",
          promoMessage: "Promo Message",
          promoSentTOClub: "",
          notifyMessage: "Promo Notification",
          huntLocation: "",
        };
        promoInputValues(defaultValues);
        setPromotion(defaultValues);
      }
    } else {
      if (!huntLoading && promotionId) {
        const data: any = huntData.getHunt;

        defaultValues = { ...huntData.getHunt };
        let latlng: any = { lat: data.mainLat, lng: data.mainLng };
        setValue("promoCodePrefix", data.huntCodePrefix);
        setValue("promoCodePoints", data.huntCodePoints);
        setValue("promoType", data.__typename);
        setValue("validity", data.validity);
        setValue("validityDay", data.validityDay);
        setValue("promoTotalCount", data.noPointsPerHunt);
        setValue("sponsorId", data.sponsorId);
        setDistArr(data.points);
        setLatlng(latlng);
        setPromotionType(huntData.getHunt.__typename);
        setNoofFieldCaption("Points per user");
        setHuntLocation(data.locationName);
        setPromotion(defaultValues);
      } else {
        defaultValues = {
          promoCodePrefix: "",
          promoCodePoints: "",
          promoType: "",
          validity: "",
          validityDay: "",
          promoTotalCount: "1",
          sponsorId: "",
          pointsAvailable: 100,
          noPointsPerPromo: 10,
          promoSentToMail: "",
          promoTitle: "Promo Title!",
          promoMessage: "Promo Message",
          promoSentTOClub: "",
          notifyMessage: "Promo Notification",
          huntLocation: "",
        };
        promoInputValues(defaultValues);
        setPromotion(defaultValues);
        setVideoLink(PromoDetails?.videoLink);
      }
    }
  }, [open, PromoDetails, huntData, setValue]);

  const [createPromo] = useMutation(CREATE_PROMO, {
    // after updating the post, we go to the home page
    onCompleted: () => {
      handleCloseClick();
      setLoad(false);
    },
    onError: (data) => {
      setError(data.message);
      setOpenToast(true);
      setLoad(false);
    },
  });

  const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setLoad(false);
  };

  const [createHunt] = useMutation(CREATE_HUNT, {
    // after updating the post, we go to the home page
    onCompleted: () => {
      handleCloseClick();
      setLoad(false);
    },
    onError: (data) => {
      setError(data.message);
      setOpenToast(true);
      setLoad(false);
    },
  });

  const [updatePromo] = useMutation(UPDATE_PROMO, {
    // after updating the post, we go to the home page
    onCompleted: () => {
      handleCloseClick();
      setLoad(false);
    },
    onError: (data) => {
      setError(data.message);
      setOpenToast(true);
      setLoad(false);
    },
  });
  const onSubmit: SubmitHandler<IFormAddPromo> = (data: any) => {
    setLoad(true);
    if (data.promoType === "Hunt") {
      const formData = {
        huntCodePrefix: data.promoCodePrefix,
        huntCodePoints: parseInt(data.promoCodePoints),
        validity: validityDate,
        validityDay: parseInt(data.validityDay),
        huntTotalCount: parseInt(data.promoCodePoints),
        noPointsPerHunt: parseInt(data.promoTotalCount),
        sponsorId: data.sponsorId,
        orgId: orgId,
        mainLat: latlng.lat,
        mainLng: latlng.lng,
        locationName: locationName,
        points: points,
      };
      if (distArr.length > 0 && locationName != "") {
        if (noCoinsValue == totalCoins) {
          if (isSubmit) {
            if (points.length === distArr.length) {
              setConfirmed(true);
              setOpenConfirm(false);
              createHunt({
                variables: { data: { ...formData } },
              });
            }
          }
        } else {
          setError("You missed some to place the coins.");
          setOpenToast(true);
        }
      } else {
        setError("Location and Coin distribution fields are mandatory");
        setOpenToast(true);
      }
    } else if (promoId) {
      updatePromo({
        variables: {
          id: promoId,
          data: {
            promoCodePrefix: data.promoCodePrefix,
            promoCodePoints: parseInt(data.promoCodePoints),
            promoType: data.promoType,
            validity: validityDate,
            validityDay: parseInt(data.validityDay),
            promoTotalCount: parseInt(data.promoTotalCount),
            sponsorId: data.sponsorId,
            orgId: orgId,
            pointsAvailable: parseInt(data.promoTotalCount),
            noPointsPerPromo: parseInt(data.promoTotalCount),
            promoSentToMail: data.promoSentToMail ? data.promoSentToMail : "",
            promoTitle: data.promoTitle ? data.promoTitle : "",
            promoMessage: data.promoMessage ? data.promoMessage : "",
            promoSentTOClub: data.promoSentTOClub ? data.promoSentTOClub : "",
          },
        },
      });
    } else {
      if (parseInt(data.promoTotalCount) > 0 && !promoMessageValidation) {
        createPromo({
          variables: {
            data: {
              promoCodePrefix: data.promoCodePrefix,
              promoCodePoints: parseInt(data.promoCodePoints),
              promoType: data.promoType,
              validity: validityDate,
              validityDay: parseInt(data.validityDay),
              promoTotalCount: parseInt(data.promoTotalCount),
              sponsorId: data.sponsorId,
              sponsorLogo: sponsorLogo,
              orgId: orgId,
              pointsAvailable: parseInt(data.promoTotalCount),
              noPointsPerPromo: parseInt(data.promoTotalCount),
              promoSentToMail: data.promoSentToMail ? data.promoSentToMail : "",
              promoTitle: data.promoTitle ? data.promoTitle : "",
              promoMessage: data.promoMessage ? data.promoMessage : "",
              promoSentTOClub: data.promoSentTOClub ? data.promoSentTOClub : "",
              videoLink: data.videoLink ? data.videoLink : "",
              promoUrl: data.promoUrl,
              promoQuiz: promoQuiz === "Yes" ? true : false,
              promoQuizList: JSON.stringify(quizList),
            },
          },
        });
      } else {
        !promoMessageValidation &&
          setPromoTotalCountValidation("It should be greater then 0");
      }
    }
  };

  const handleClose = () => {
    setLocationName("");
    setNoCoinsValue(0);
    setPoints([]);
    setDistArr([]);
    setPromoQuiz("");
    setDisableDist(true);
    handleCloseClick();
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const targetType = (e.target as HTMLInputElement).value;
    const targetName: string = (e.target as HTMLInputElement).name;
    setValue(targetName, targetType);
    if (targetName !== "promoQuiz") setPromotionType(targetType);
    setNoofFieldCaption("Promocodes");
    if (targetName == "promoType") {
      clearErrors(targetName);
    }
    if (targetName == "Hunt" || targetType === "Hunt") {
      setLatlng({ lat: 26.02628761374811, lng: -80.20744120866775 });
      setNoofFieldCaption("Points per user");
    }
    if (targetName === "promoQuiz") {
      setPromoQuiz(targetType);
      setQuizList([
        {
          questionId: Math.floor(100000 + Math.random() * 900000),
          questionTitle: "",
          keyList: [
            { id: Math.floor(100000 + Math.random() * 900000), keyName: "" },
          ],
          answer: null,
        },
      ]);
    }
  };

  const handleChangeField = (e: React.ChangeEvent<any>) => {
    const targetValue = (e.target as HTMLInputElement).value;
    const targetName: string = (e.target as HTMLInputElement).name;
    setValue(targetName, targetValue);
    if (targetName == "sponsorId") {
      let selectedSponsor = getSponsor.getSponsorWithOrgId.find(
        (i: any) => i.id === targetValue
      );
      setSponsorLogo(selectedSponsor.sponsorLogo);
      setMarkerImage(selectedSponsor.sponsorLogo);
      setMaxAllocation(selectedSponsor.availablePoints);
      clearErrors(targetName);
    }
    if (targetName == "validityDay") {
      setValidityDate(moment().add(targetValue, "days").format("D MMM YYYY"));
      clearErrors(targetName);
    }
    if (targetName == "promoMessage") {
      if (targetValue.length >= 100) {
        setPromoMessageValidation(true);
      } else {
        setPromoMessageValidation(false);
      }
      setPromoTitle(targetValue);
      clearErrors(targetName);
    }
    if (targetName == "promoCodePrefix") {
      setValue("promoCodePrefix", targetValue.toUpperCase());
      clearErrors(targetName);
    }
    if (targetName === "videoLink") {
      setVideoLink(targetValue);
      clearErrors(targetName);
    }
    if (targetName == "promoCodePoints") {
      clearErrors(targetName);
    }

    if (targetName == "promoTotalCount") {
      clearErrors(targetName);
      let promoCodePoints: any = getValues("promoCodePoints");
      if (parseInt(targetValue) <= 0) {
        setIsSubmit(false);
        setPromoTotalCountValidation("It should be greater than 0");
      } else if (parseInt(targetValue) > parseInt(promoCodePoints)) {
        setIsSubmit(false);
        setPromoTotalCountValidation(
          "It should not be greater than " + promoCodePoints
        );
      } else {
        setIsSubmit(true);
        setPromoTotalCountValidation("");
      }
    }
    if (targetName == "promoTotalCount") {
      clearErrors(targetName);
      setNoCoinsValue(parseInt(targetValue));
      setPoints([]);
      setDistArr([]);
      setTotalCoins(0);
      if (parseInt(targetValue) >= 5) {
        setDisableDist(false);
      } else {
        setDisableDist(true);
      }
    }

    setPromotion({ ...promotion, [targetName]: targetValue });
  };

  const distribution = (value: any) => {
    var sum = distArr.reduce(function (a: number, b: number) {
      return a + b;
    }, 0);
    if (sum + value <= noCoinsValue) {
      setTotalCoins(sum + value);
      setDistArr([...distArr, value]);
      if (sum + value === noCoinsValue) {
        setDisableDist(true);
      }
    } else if (sum >= noCoinsValue) {
      setDisableDist(true);
    }
    console.log(distArr);
  };

  const resetDist = () => {
    setDistArr([]);
  };

  const getOccurrence = (value: number) => {
    return distArr.filter((v: number) => v === value).length;
  };

  const plusDistri = (value: number) => {
    console.log(value);
    //points array should be empty
    setPoints([]);
    var sum = distArr.reduce(function (a: number, b: number) {
      return a + b;
    }, 0);
    if (sum + value <= noCoinsValue) {
      setTotalCoins(sum + value);
      setDistArr([...distArr, value]);
      if (sum + value === noCoinsValue) {
        setDisableDist(true);
      }
    } else if (sum >= noCoinsValue) {
      setDisableDist(true);
    }
  };

  const minusDistri = (value: number) => {
    setPoints([]);
    var sum = distArr.reduce(function (a: number, b: number) {
      return a + b;
    }, 0);

    if (getOccurrence(value) > 0) {
      for (var i = 0; i < distArr.length; i++) {
        if (distArr[i] === value) {
          distArr.splice(i, 1);
          console.log(distArr);
          setDistArr([...distArr]);
          setTotalCoins(sum - value);
          setDisableDist(false);
          return false;
        }
      }
    }
  };

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Dialog
      open={onOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className="common-dialog-container org-container"
    >
      <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className="pad0">
          <Grid className="form-grid" container sm={12} spacing={2}>
            <Grid item sm={12} className="pl-0">
              <h5 id="form-dialog-title" className="section-heading">
                {promotionId ? "VIEW" : "CREATE"} Promotions
              </h5>
            </Grid>
            <Grid
              container
              className="borderR padd-20 pt-0 pl-0"
              sm={6}
              xs={12}
              spacing={3}
            >
              <Grid container sm={12} spacing={3}>
                <Grid item sm={6} className="pl-0">
                  <div className="input-group">
                    <label className="input-label">Promotion Type</label>

                    <Controller
                      name="promoType"
                      control={control}
                      rules={{ required: true }}
                      render={({ value }) => (
                        <Select
                          displayEmpty
                          name="promoType"
                          value={value}
                          disabled={fieldEnabled}
                          className={`custom-select form-select  ${
                            errors.promoType && "error"
                          }`}
                          onChange={(e) => handleChange(e)}
                        >
                          {promoTypes.map(({ lebel, value }, index) => (
                            <MenuItem key={index} value={value}>
                              {lebel}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.promoType &&
                      errors.promoType.type == "required" && (
                        <label className="error-message">
                          Field is required
                        </label>
                      )}
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <div className="input-group">
                    <label className="input-label">Sponsor</label>
                    <Controller
                      name="sponsorId"
                      rules={{ required: true }}
                      control={control}
                      render={({ value }) => (
                        <Select
                          displayEmpty
                          name="sponsorId"
                          disabled={fieldEnabled}
                          className={`custom-select form-select  ${
                            errors.sponsorId && "error"
                          }`}
                          value={value}
                          onChange={(e) => handleChangeField(e)}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {sponsorLoading ? (
                            <MenuItem value={"Loading"}>Loading ...</MenuItem>
                          ) : (
                            getSponsor.getSponsorWithOrgId.map(
                              (sponsor: any) => (
                                <MenuItem
                                  key={sponsor.id}
                                  data-img={sponsor.sponsorLogo}
                                  value={sponsor.id}
                                >
                                  {sponsor.sponsorName}
                                </MenuItem>
                              )
                            )
                          )}
                        </Select>
                      )}
                    />
                    {errors.sponsorId &&
                      errors.sponsorId.type == "required" && (
                        <label className="error-message">
                          Field is required
                        </label>
                      )}
                  </div>
                </Grid>
                <Grid item sm={6} className="pl-0">
                  <div className="input-group">
                    <label className="input-label">Code Prefix</label>
                    <Controller
                      control={control}
                      name="promoCodePrefix"
                      rules={{
                        required: true,
                        validate: (value) => value.length <= 6,
                      }}
                      render={({ value }) => (
                        <TextField
                          name="promoCodePrefix"
                          disabled={fieldEnabled}
                          value={value}
                          placeholder="Code Prefix"
                          onChange={(e) => handleChangeField(e)}
                          className={`form-fields ${
                            errors.promoCodePrefix && "error"
                          }`}
                        />
                      )}
                    />
                    {errors.promoCodePrefix &&
                      errors.promoCodePrefix.type == "validate" && (
                        <label className="error-message">
                          Code should be Max 6 Characters
                        </label>
                      )}
                    {errors.promoCodePrefix &&
                      errors.promoCodePrefix.type == "required" && (
                        <label className="error-message">
                          Field is required
                        </label>
                      )}
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <div className="input-group">
                    <label className="input-label">
                      {promotionType === "Hunt" ? "Points per Hunt" : "Points"}
                    </label>
                    <Controller
                      name="promoCodePoints"
                      control={control}
                      rules={{ required: true }}
                      render={({ value }) => (
                        <Select
                          displayEmpty
                          name="promoCodePoints"
                          value={value}
                          disabled={fieldEnabled}
                          className={`custom-select form-select  ${
                            errors.promoCodePoints && "error"
                          }`}
                          onChange={(e) => handleChangeField(e)}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {Array.from({ length: 10 }, (_, i) => (
                            <MenuItem key={i + 1} value={`${(i + 1) * 100}`}>
                              {(i + 1) * 100}
                            </MenuItem>
                          ))}
                          <MenuItem value="10000">10000</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.promoCodePoints &&
                      errors.promoCodePoints.type == "required" && (
                        <label className="error-message">
                          Field is required
                        </label>
                      )}
                  </div>
                </Grid>
                <Grid item sm={6} className="pl-0">
                  <div className="input-group">
                    <label className="input-label">
                      No.of {noofFieldCaption}
                    </label>
                    <Controller
                      control={control}
                      inputRef={inputRef}
                      rules={{
                        required: true,
                        pattern: /[0-9]/,
                        validate: (value) => value.length > 0,
                      }}
                      name="promoTotalCount"
                      type="number"
                      render={({ value }) => (
                        <TextField
                          type="number"
                          disabled={fieldEnabled}
                          value={value}
                          name="promoTotalCount"
                          placeholder={"No of " + noofFieldCaption}
                          onChange={(e) => {
                            handleChangeField(e);
                          }}
                          className={`form-fields ${
                            errors.promoTotalCount && "error"
                          }`}
                        />
                      )}
                    />
                    {errors.promoTotalCount &&
                      errors.promoTotalCount.type == "required" && (
                        <label className="error-message">
                          Field is required
                        </label>
                      )}
                    {promoTotalCountValidation && (
                      <label className="error-message">
                        {promoTotalCountValidation}
                      </label>
                    )}
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <div className="input-group">
                    <label className="input-label">Validity</label>
                    <Controller
                      name="validityDay"
                      control={control}
                      rules={{ required: true }}
                      render={({ value }) => (
                        <Select
                          displayEmpty
                          name="validityDay"
                          disabled={fieldEnabled}
                          value={value}
                          className={`custom-select form-select  ${
                            errors.validityDay && "error"
                          }`}
                          onChange={(e) => handleChangeField(e)}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {Array.from({ length: 7 }, (_, i) => (
                            <MenuItem key={i + 1} value={`${i + 1}`}>
                              {`${i + 1} Day${i + 1 > 1 ? "s" : ""}`}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.validityDay &&
                      errors.validityDay.type == "required" && (
                        <label className="error-message">
                          Field is required
                        </label>
                      )}
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                className="promo-dynfield-container"
                sm={12}
                xs={12}
              >
                <label className="section-heading sub-heading">
                  Maximum Allocation {maxAllocation}
                </label>
                <Grid item sm={12}>
                  {promotionType == "Email" && (
                    <div className="info-section email-info">
                      <label className="section-heading sub-heading">
                        Email info
                      </label>
                      <Grid item sm={12}>
                        <div className="input-group">
                          <label className="input-label">Email</label>
                          <Controller
                            control={control}
                            rules={{
                              required: true,
                              pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                            }}
                            name="promoSentToMail"
                            render={({ value }) => (
                              <TextField
                                type="text"
                                value={value}
                                disabled={fieldEnabled}
                                name="promoSentToMail"
                                placeholder="example@gmail.com"
                                className="form-fields"
                                onChange={(e) => handleChangeField(e)}
                              />
                            )}
                          />
                          {errors.promoSentToMail &&
                            errors.promoSentToMail.type == "required" && (
                              <label className="error-message">
                                Field is requried
                              </label>
                            )}
                          {errors.promoSentToMail &&
                            errors.promoSentToMail.type == "pattern" && (
                              <label className="error-message">
                                Field should be in mail format
                              </label>
                            )}
                        </div>
                      </Grid>
                      <Grid item sm={12}>
                        <div className="input-group">
                          <label className="input-label">Title</label>
                          <Controller
                            control={control}
                            name="promoTitle"
                            render={({ value }) => (
                              <TextField
                                type="text"
                                disabled={fieldEnabled}
                                value={value}
                                name="promoTitle"
                                placeholder="promoTitle"
                                className="form-fields"
                                onChange={(e) => handleChangeField(e)}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item sm={12}>
                        <div className="input-group textarea-group">
                          <label className="input-label">Message</label>
                          <Controller
                            control={control}
                            name="promoMessage"
                            render={({ value }) => (
                              <TextareaAutosize
                                value={value}
                                disabled={fieldEnabled}
                                aria-label="minimum height"
                                rowsMin={3}
                                placeholder="Address"
                                name="promoMessage"
                                className="form-fields textarea-field MuiInputBase-input MuiInput-input"
                                onChange={(e) => handleChangeField(e)}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </div>
                  )}
                  {/* Video Promo Info Block */}
                  {promotionType === "Video" && (
                    <div className="info-section notification-info">
                      <label className="section-heading sub-heading">
                        Video Info
                      </label>
                      <Grid item sm={12}>
                        <div>
                          <label className="input-label">Video Link</label>
                          <Controller
                            control={control}
                            rules={{
                              required: true,
                              pattern:
                                /^(http(s)?:\/\/)?((w){3}.)?(youtu(be|.be)|vimeo)?(\.com)?\/.+/,
                            }}
                            name="videoLink"
                            render={({ value }) => (
                              <TextField
                                type="text"
                                disabled={fieldEnabled}
                                name="videoLink"
                                value={value}
                                placeholder="https://www.youtube.com/xyz"
                                className="form-fields"
                                onChange={(e) => handleChangeField(e)}
                              />
                            )}
                          />
                          {errors.videoLink &&
                            errors.videoLink.type === "required" && (
                              <label className="error-message">
                                Field is required
                              </label>
                            )}
                          {errors.videoLink &&
                            errors.videoLink.type === "pattern" && (
                              <label className="error-message">
                                Please enter valid YouTube or Vimeo link
                              </label>
                            )}
                        </div>
                      </Grid>
                      <Grid item sm={12}>
                        <div className="input-group">
                          <label className="input-label">Message</label>
                          <Controller
                            control={control}
                            name="promoMessage"
                            rules={{ required: false }}
                            render={({ value }) => (
                              <TextField
                                type="text"
                                disabled={fieldEnabled}
                                name="promoMessage"
                                value={value}
                                placeholder="Message"
                                className="form-fields"
                                onChange={(e) => handleChangeField(e)}
                              />
                            )}
                          />
                          {promoMessageValidation && (
                            <label className="error-message">
                              Message should be in less than 100 characters
                            </label>
                          )}
                        </div>
                      </Grid>
                      <Grid item sm={12} className="pl-0">
                        <div className="input-group">
                          <label className="input-label">
                            Video Promotion Quiz
                          </label>
                          <Controller
                            name="promoQuiz"
                            control={control}
                            rules={{ required: true }}
                            render={({ value }) => (
                              <Select
                                displayEmpty
                                name="promoQuiz"
                                value={value}
                                disabled={true}
                                className={`custom-select form-select  ${
                                  errors.promoQuiz && "error"
                                }`}
                                onChange={(e) => handleChange(e)}
                              >
                                {["Yes", "No"].map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                          {errors.promoQuiz &&
                            errors.promoQuiz.type === "required" && (
                              <label className="error-message">
                                Field is required
                              </label>
                            )}
                        </div>
                      </Grid>
                      <Snackbar
                        open={openToast}
                        autoHideDuration={3000}
                        onClose={handleClosed}
                      >
                        <Alert onClose={handleClosed} severity="error">
                          {error}
                        </Alert>
                      </Snackbar>
                      <Backdrop className="loader-signup" open={load}>
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </div>
                  )}
                  {promotionType == "Notification" && (
                    <div className="info-section notification-info">
                      <label className="section-heading sub-heading">
                        Notification info
                      </label>
                      <Grid item sm={12}>
                        <div className="input-group">
                          <label className="input-label">Notify to</label>
                          <Controller
                            name="promoSentTOClub"
                            control={control}
                            rules={{ required: true }}
                            render={({ value }) => (
                              <Select
                                displayEmpty
                                disabled={fieldEnabled}
                                name="promoSentTOClub"
                                value={value}
                                className={`custom-select form-select  ${
                                  errors.promoSentTOClub && "error"
                                }`}
                                onChange={(e) => handleChangeField(e)}
                              >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem value={"all"}>All Org</MenuItem>
                              </Select>
                            )}
                          />
                          {errors.promoSentTOClub &&
                            errors.promoSentTOClub.type == "required" && (
                              <label className="error-message">
                                Field is required
                              </label>
                            )}
                        </div>
                      </Grid>
                      <Grid item sm={12}>
                        <div className="input-group">
                          <label className="input-label">Message</label>
                          <Controller
                            control={control}
                            name="promoMessage"
                            render={({ value }) => (
                              <TextField
                                type="text"
                                disabled={fieldEnabled}
                                name="promoMessage"
                                value={value}
                                placeholder="Message"
                                className="form-fields"
                                onChange={(e) => handleChangeField(e)}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </div>
                  )}
                  {promotionType == "Hunt" && (
                    <div className="info-section hunt-info">
                      <label className="section-heading sub-heading">
                        Hunt Info
                      </label>
                      {!promotionId && (
                        <Grid item sm={12}>
                          <div className="input-group">
                            <label className="input-label">
                              Points to Coin Distribution : {totalCoins}
                            </label>
                            <ul className="point-distribution">
                              <li>
                                <label
                                  className={disableDist ? "selected" : ""}
                                  onClick={() => distribution(10)}
                                >
                                  10 X {getOccurrence(10)}
                                </label>
                                <p className="actions">
                                  <span onClick={(e) => plusDistri(10)}>+</span>{" "}
                                  <span onClick={(e) => minusDistri(10)}>
                                    -
                                  </span>
                                </p>
                              </li>
                              <li>
                                <label
                                  className={disableDist ? "selected" : ""}
                                  onClick={() => distribution(20)}
                                >
                                  20 X {getOccurrence(20)}
                                </label>
                                <p className="actions">
                                  <span onClick={(e) => plusDistri(20)}>+</span>{" "}
                                  <span onClick={(e) => minusDistri(20)}>
                                    -
                                  </span>
                                </p>
                              </li>
                              <li>
                                <label
                                  className={disableDist ? "selected" : ""}
                                  onClick={() => distribution(50)}
                                >
                                  50 X {getOccurrence(50)}
                                </label>
                                <p className="actions">
                                  <span onClick={(e) => plusDistri(50)}>+</span>{" "}
                                  <span onClick={(e) => minusDistri(50)}>
                                    -
                                  </span>
                                </p>
                              </li>
                              <li>
                                <label
                                  className={disableDist ? "selected" : ""}
                                  onClick={() => distribution(100)}
                                >
                                  100 X {getOccurrence(100)}
                                </label>
                                <p className="actions">
                                  <span onClick={(e) => plusDistri(100)}>
                                    +
                                  </span>{" "}
                                  <span onClick={(e) => minusDistri(100)}>
                                    -
                                  </span>
                                </p>
                              </li>
                              <li>
                                <label
                                  className={disableDist ? "selected" : ""}
                                  onClick={() => distribution(500)}
                                >
                                  500 X {getOccurrence(500)}
                                </label>
                                <p className="actions">
                                  <span onClick={(e) => plusDistri(500)}>
                                    +
                                  </span>{" "}
                                  <span onClick={(e) => minusDistri(500)}>
                                    -
                                  </span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </Grid>
                      )}
                      <Grid item sm={12}>
                        <div className="input-group location-field">
                          <label className="input-label">Location</label>
                          {promotionId ? (
                            <label className="hunt-location-name">
                              {huntLocation}
                            </label>
                          ) : (
                            <GooglePlacesAutocomplete
                              selectProps={{
                                isClearable: true,
                                clearValue: () => {
                                  setValueLocation("");
                                },
                                defaultValues: value,
                                onChange: async (place: any) => {
                                  place &&
                                    fetch(
                                      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place.value.place_id}&key=${config.googleAPIKEY}`
                                    )
                                      .then((response) => response.json())
                                      .then((data: any) => {
                                        setValueLocation(value);
                                        setLocationName(place.label);
                                        setLocationSelected(true);
                                        setLatlng({
                                          lat: data.results[0].geometry.location
                                            .lat,
                                          lng: data.results[0].geometry.location
                                            .lng,
                                        });
                                      });
                                },
                              }}
                            />
                          )}
                        </div>
                      </Grid>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12}>
              <label className="section-heading sub-heading mrgb-20">
                Promotion Preview
              </label>
              {/* Video Preview */}
              {promotionType === "Video" && (
                <div className="video-preview-block">
                  <div className="video-preview">
                    <Grid container sm={12}>
                      <div className="preview-wrapper">
                        <div>
                          <Grid
                            container
                            className="flx-align-center videoContainer"
                            sm={12}
                          >
                            <ReactPlayer
                              width="435px"
                              height="200px"
                              url={videoLink}
                              config={{
                                youtube: {
                                  playerVars: {
                                    showinfo: 0,
                                    controls: 1,
                                    fs: 0,
                                    modestbranding: 1,
                                  },
                                },
                              }}
                            />
                            <div className="align-center sponsorlogoforvideo">
                              <img
                                src={
                                  sponsorLogo ||
                                  "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"
                                }
                                alt="sponsorlogo"
                                className="sponsor-logo-video"
                              />
                            </div>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </div>
                  <div className="preview-body">
                    <Grid container sm={12} className="video-disp-flex">
                      <Grid item sm={6}>
                        <div
                          className="video-message-preview sub-caption"
                          title={
                            promotion.promoMessage
                              ? promotion.promoMessage
                              : getValues("promoMessage")
                          }
                        >
                          {promotion.promoMessage
                            ? promotion.promoMessage.length > 30
                              ? promotion.promoMessage.substr(0, 30) + "..."
                              : promotion.promoMessage
                            : getValues("promoMessage").length > 30
                            ? getValues("promoMessage").substr(0, 30) + "..."
                            : getValues("promoMessage")}
                        </div>
                        <label className="update-date-txt">
                          Valid till {validityDate}
                        </label>
                      </Grid>
                      <Grid className="promo-code-sec" item sm={6}>
                        <label className="promocode-preview">
                          {promotion.promoCodePrefix +
                            promotion.promoCodePoints}
                        </label>
                      </Grid>
                    </Grid>
                  </div>

                  {/* Quiz Block */}
                  {promoQuiz === "Yes" && (
                    <PromoQuiz
                      fieldEnabled={fieldEnabled}
                      handleQuizList={quizList}
                      updateQuiz={(obj: any) => handleUpdateQuiz(obj)}
                      setError={setError}
                      setOpenToast={setOpenToast}
                    />
                  )}
                </div>
              )}
              {promotionType == "Email" && (
                <div className="email-preview">
                  <Grid container sm={12}>
                    <div className="preview-wrapper">
                      <div className="preview-head">
                        <Grid container className="flx-align-center" sm={12}>
                          <Grid className="align-center" item sm={3}>
                            <img
                              src={
                                sponsorLogo ||
                                "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"
                              }
                              alt="sponsorlogo"
                              className="sponsor-logo"
                            />
                          </Grid>
                          <Grid item sm={9}>
                            <label className="section-heading sub-heading email-title">
                              {promotion.promoTitle}
                            </label>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="preview-body">
                        <Grid container sm={12}>
                          <Grid item sm={12}>
                            <p className="message-preview sub-caption">
                              {promotion.promoMessage}
                            </p>
                          </Grid>
                          <Grid className="promo-code-sec" item sm={12}>
                            <label className="promocode-preview">
                              {promotion.promoCodePrefix +
                                promotion.promoCodePoints}
                            </label>
                            <label className="update-date-txt">
                              Valid till {validityDate}
                            </label>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </div>
              )}
              {promotionType == "Notification" && (
                <div className="appnotification-preview p-relative ">
                  <Grid container sm={12}>
                    <div className="preview-wrapper">
                      <div className="preview-body">
                        <Grid
                          container
                          className="flx-align-center"
                          sm={12}
                          spacing={2}
                        >
                          <img
                            src={
                              sponsorLogo ||
                              "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"
                            }
                            alt="sponsorlogo"
                            className="sponsor-logo"
                          />
                          <div className="title-container">
                            <label className="section-heading sub-heading notify-title">
                              {promoTitle}
                            </label>
                            <label className="update-date-txt">
                              Valid till {validityDate}
                            </label>
                          </div>
                          <label className="promocode">
                            {promotion.promoCodePrefix +
                              promotion.promoCodePoints}
                          </label>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </div>
              )}
              {promotionType == "Hunt" && (
                <div className="map-preview">
                  <Grid container sm={12}>
                    <PromotionMap
                      locationSelected={locationSelected}
                      placedCoins={placedCoins}
                      viewType={promotionId ? "Edit" : "Create"}
                      markerImage={markerImage}
                      coords={latlng}
                      coinsNvalue={noCoinsValue}
                      pointsArray={distArr}
                      updatePoints={(point: any) => {
                        points.push(point);
                      }}
                    />
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
          {openConfirm && (
            <div className="confim-model-container hunt-confirmation">
              <Paper className="confirmation-wrapper padd-20">
                <Grid container sm={12}>
                  <div className="confirmation-sec">
                    <p>{confirmMessage}</p>
                    <DialogActions>
                      <Button
                        className="btn btn-secondary"
                        onClick={clickCanceled}
                        color="primary"
                      >
                        CANCEL
                      </Button>
                      <Button
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        className="btn btn-primary button"
                      >
                        CONFIRM
                      </Button>
                    </DialogActions>
                  </div>
                </Grid>
              </Paper>
            </div>
          )}
        </DialogContent>
        <DialogActions
          className="edit-member-profile-actions"
          style={{ display: fieldEnabled ? "none" : "flex" }}
        >
          <Button
            onClick={handleClose}
            className="btn btn-secondary"
            color="primary"
          >
            CANCEL
          </Button>
          {promotionType == "Hunt" ? (
            <Button
              color="primary"
              className="btn btn-primary button"
              onClick={handleSubmit(handleConfirmationBox)}
            >
              CREATE HUNT
            </Button>
          ) : (
            <Button
              color="primary"
              className="btn btn-primary button"
              onClick={handleSubmit(onSubmit)}
            >
              SEND PROMOTION
            </Button>
          )}
        </DialogActions>

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClosed}
        >
          <Alert onClose={handleClosed} severity="warning">
            {snackMessage.info}
          </Alert>
        </Snackbar>
      </form>
      <Snackbar open={openToast} autoHideDuration={3000} onClose={handleClosed}>
        <Alert onClose={handleClosed} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
