import React ,{useState,useEffect}from 'react'
import { useHistory } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import ReactDatePicker from "react-datepicker";
import '../../constant.css';
import '../manageclubs/manageclubs.css';
import { useQuery, gql, useMutation } from '@apollo/client';
import AWS from 'aws-sdk';


//import OrgImage from '../../../assets/Bitmap@2x.png';
interface Props {
    onOpen : boolean,
    handleCloseClick: () => void,
    memberId:string,
}

interface IFormAddClub {
    
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address: string;
    dob : string;
    email : string;
}

let defaultValues:any = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address:"",
    dob:"10/10/2200",
    email:""
  };

  const GET_USER_BY_ID = gql`
  query getUsers($username: String!) {
    getUserWithIdAdmin(username: $username) {
        id
        username
        givenName
        email
        dob
        address
        phoneNumber
        isActive

    }
  }
`;

const UPDATE_MEMBER = gql`
mutation updateUserDetails($username : String!,$data: UpdatedUserInput! ){
    updateUserDetails(username: $username, data: $data ){
        username
    }
   }`;

export default function EditMemberProfile({handleCloseClick,onOpen,memberId}:Props) {
    const userId = memberId?memberId:"";
    const openState = onOpen;
    const { loading, data,refetch } = useQuery<any>(GET_USER_BY_ID, { variables: { username: userId } });
    //console.log(data);
    const history = useHistory();
    const backClick = () => history.push('/managemembers');
    const [open, setOpen] = React.useState(false);
    const [isValid, setIsValid] = React.useState(true);

    const [dobValidate,setDobValidate] = React.useState<any>(null);
    const [isActiveStatus, setIsActiveStatus] = React.useState(true);
    const { handleSubmit, control,setValue, reset,register ,errors,clearErrors} = useForm<IFormAddClub>({defaultValues,shouldUnregister:false});
    useEffect(() => {
       //setTimeout(() => { 
        if(!loading){
            clearErrors();
            console.log(data);
            setIsActiveStatus(data.getUserWithIdAdmin.isActive);
            //setMemberDeails(data.getMemberWithId);
            const { givenName,email,phoneNumber,dob,address } = data.getUserWithIdAdmin;
            defaultValues = {givenName,email,phoneNumber,dob,address};
            let dateConversion = moment(defaultValues.dob).format("YYYY-MM-DD");
            let userName:string = defaultValues.givenName; 
            console.log(defaultValues);
            setValue("firstName",userName.split("&")[0]);
            setValue("lastName",userName.split("&")[1]);
            setValue("phoneNumber",defaultValues.phoneNumber);
            setValue("email",defaultValues.email);
            setValue("dob", dateConversion);
            setValue("address",defaultValues.address);
        }
    //})
    },[data,openState,setValue])

    const [updateUserDetails] = useMutation(UPDATE_MEMBER,{ onCompleted : () => { 
        //history.push('/managemembers'); 
        history.push({pathname:'/memberprofile',state:userId});

        handleCloseClick();
    }
    });

    const onSubmit: SubmitHandler<IFormAddClub> = data => {
        if(isValid){
            if(userId){
                
                updateUserDetails({
                    variables : 
                    { username : userId,
                        data : 
                        { 
                            givenName : data.firstName+"&"+data.lastName,
                            address : data.address,
                            username:userId,
                            email : data.email,
                            dob :new Date(data.dob),
                            phoneNumber : data.phoneNumber

                        }
                    }
                })
            }
        }else{
            setDobValidate("Dob should not greater than");
        }
    }
    const handledeactiveClick = () => {    
        
            if(userId){
                updateUserDetails({
                    variables : 
                    { username : userId,
                        data : 
                        { 
                            isActive : !isActiveStatus
                        }
                    }
                });
                // AWS.config.update({
                //         region: 'us-east-1',
                //         accessKeyId: 'AKIAY4YVUTARQJ6Y2BFU',
                //         secretAccessKey: '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'
                // });
                
                // var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
                // //AWS.config.region = 'us-east-1';

                // var params = {
                //     UserPoolId: 'us-east-1_Pr0JlHTmo', /* required */
                //     Username: userId /* required */
                // };
                // if(isActiveStatus){
                //     cognitoidentityserviceprovider.adminDisableUser(params, function(err:any, data:any) {
                //         if (err) console.log(err, err.stack); // an error occurred
                //         else     console.log(data);           // successful response
                //     });

                // }else{
                //     cognitoidentityserviceprovider.adminEnableUser(params, function(err:any, data:any) {
                //         if (err) console.log(err, err.stack); // an error occurred
                //         else     console.log(data);           // successful response
                //     });
                // }

            }
        
    }
    const handleChangeField = (e: React.ChangeEvent<any>) => {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName = (e.target as HTMLInputElement).name;
        console.log(targetValue);
        setValue(targetName, targetValue);
        const currentDate = new Date().setHours(0,0,0);
        const selectedDate = new Date(targetValue).setHours(0,0,0);
        if(selectedDate >= currentDate){
            setDobValidate("Dob should not greater than");
            setIsValid(false);
        }else{
            setIsValid(true);
            setDobValidate(null);
        }
        
    }

    // const handleChangeFieldPhone = (e: React.ChangeEvent<any>) => {
    //     const targetValue:string = (e.target as HTMLInputElement).value;
    //     const targetName = (e.target as HTMLInputElement).name;
    //     const re =  /^[0-9-+]/;
    //     if (targetValue === '' || targetValue.match(re)) {
    //         console.log("inside",targetValue);
    //         setValue(targetName, targetValue);
    //     }
    // }

    return (
        <Dialog open={onOpen} onClose={handleCloseClick} aria-labelledby="form-dialog-title" className="common-dialog-container member-container">
                <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                   
                <DialogContent>
                        <h5 id="form-dialog-title" className="section-heading">Edit Member</h5>
                        <Grid className="form-grid" container sm={12} spacing={2}>
                            
                            <Grid item sm={6} xs={12}>
                                <div className="input-group">
                                    <label className="input-label" >First Name</label>
                                    <Controller as={TextField}  type="text" name="firstName" defaultValue="" placeholder="Full Name" className="form-fields" control={control}/>                            
                                </div>
                                
                                <div className="input-group">
                                    <label className="input-label" >Phone Number <span className="field-notes">(+91xxxx)</span></label>
                                    <Controller as ={TextField} type="text" name="phoneNumber"   placeholder="Phone number" className={`form-fields  ${errors.phoneNumber && 'error'}`} rules={{ required: true,pattern:/^\+[1-9]{1}[0-9]{3,14}[0-9]+$/, validate : (value:any) =>value.length<=13 && value.length>=5}}  defaultValue=""  control={control}  />                            
                                    {errors.phoneNumber && errors.phoneNumber.type == "required" && (<label className="error-message">Field is required</label>)}
                                    {errors.phoneNumber && errors.phoneNumber.type == "pattern" && (<label className="error-message">Field should be a numbers</label>)}
                                    {errors.phoneNumber && errors.phoneNumber.type == "validate" && (<label className="error-message">Number should be Min 5 and Max 13 Characters</label>)}
                                </div>
                                <div className="input-group textarea-group">
                                    <label className="input-label" >Address</label>
                                    <Controller as={TextareaAutosize} aria-label="minimum height" defaultValue="" rowsMin={3} placeholder="Address" name="address"  className="form-fields textarea-field MuiInputBase-input MuiInput-input" control={control}   />                            
                                </div>
                            </Grid>
                            <Grid item className="padr0" sm={6} xs={12}>  
                                <div className="input-group">
                                    <label className="input-label" >Last Name</label>
                                    <Controller as={TextField}  type="text" name="lastName" defaultValue="" placeholder="Full Name" className="form-fields" control={control}/>                            
                                </div>                 
                                <div className="input-group">
                                    <label className="input-label" >DOB</label>
                                    <Controller  type="date"  name="dob"  control={control} placeholder="yyyy-mm-dd" render = { ({value}) => ( <TextField type="date" name="dob" value={value}  className={`form-fields  ${errors.dob && 'error'}`}   onChange={(e) => handleChangeField(e)}   />)}  />                            
                                    {dobValidate && (<label className="error-message">{dobValidate}</label>)}
                                </div>
                                <div className="input-group">
                                    <label className="input-label" >Email ID</label>
                                    <Controller as={TextField}  type="text" disabled name="email" defaultValue="" placeholder="example@gmail.com" className="form-fields" control={control}  />                            
                                </div>
                                
                            </Grid>
                            
                        </Grid>
                    </DialogContent>
                    <DialogActions className="edit-member-profile-actions">
                        <Button  className="btn btn-secondary btn-delete display-none" color="primary">
                            DELETE
                        </Button>
                        <Button   onClick={handledeactiveClick} className="btn btn-secondary btn-delete" color="primary">
                            { isActiveStatus ? "DEACTIVATE":"ACTIVATE"}
                        </Button>
                        <Button onClick={handleCloseClick} className="btn btn-secondary" color="primary">
                            CANCEL
                        </Button>
                        <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>
                            UPDATE   
                        </Button>
                    </DialogActions>
                </form>
        </Dialog>
    )
}
