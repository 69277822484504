import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import Dashboard from "./containers/dashboard/dashboard";
import Manageclubs from "./containers/manageclubs/manageclubs";
import ManageMembers from "./containers/manageusers/manageusers";
import CsvList from "./containers/csvlist/csvlist";
import TransactionList from "./containers/transaction/transactions";
import MemberProfile from "./containers/memberprofile/memberprofile";
import ClubDetails from "./containers/clubdetails/clubdetails";
import AdminProfileDetails from "./containers/adminprofiledetails/adminprofiledetails";
import AdminEditProfile from "./containers/admineditprofile/admineditprofile";

import Login from "./containers/login/login";
import FanCashAward from "./containers/fancash";

interface Props {
  onParentRender: () => void;
}

export default function PTVRouter({ onParentRender }: Props) {
  return (
    <Switch>
      <Route path="/managemembers">
        <ManageMembers />
      </Route>
      <Route path="/csvlist">
        <CsvList />
      </Route>
      <Route path="/fancash">
        <FanCashAward />
      </Route>
      <Route path="/manageclubs">
        <Manageclubs />
      </Route>
      <Route path="/transactions">
        <TransactionList />
      </Route>
      <Route path="/memberprofile">
        <MemberProfile />
      </Route>
      <Route path="/clubdetails/:orgId">
        <ClubDetails />
      </Route>
      <Route path="/profiledetails">
        <AdminProfileDetails onParentRender={onParentRender} />
      </Route>
      <Route path="/editprofile">
        <AdminEditProfile />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/">
        <Dashboard />
      </Route>
    </Switch>
  );
}
