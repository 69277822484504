import React ,{useEffect}from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import OrgLogo from '../../assets/toyota-logo.png';
import Pirates from '../../assets/Pirates.png';
import Pnc from '../../assets/PNC.png';
import Highmark from '../../assets/Highmark.png';
import Nasdaq from '../../assets/Nasdaq.png';



interface promotionInventory {
  id: string;
  
}

interface promotionInventoryData {
  promotionInventory: promotionInventory[];
}

interface promotionInventoryVars {
  year: number;
}


export const GET_PROMO_QUERY = gql`
query getAllPromos{
  getPromotions{
    id
    orgName
    orgLogo
    sponsorName
    sponsorLogo
    availablePoints
    validityDay
  }
}
`;

export default function PromotionOverview() {

  const history = useHistory();
  //const promotionOverView = () => history.push({pathname:'/clubdetails', state:"dashboard"});
  const {refetch, loading, data } = useQuery<any>(GET_PROMO_QUERY);  
  useEffect(() => {
    console.log("useEffect");
    refetch();
},[]);
  return (
    <div className="table-container">
    <TableContainer className="table-container" style={{ height: 305, width: '100%' }} component={Paper}>
      <Table stickyHeader className="" aria-label="simple table">
      <TableHead>
          <TableRow>
            <TableCell>Sponsor</TableCell>
            <TableCell align="left">Organization</TableCell>
            <TableCell align="left">Available</TableCell>
            <TableCell align="left">Expiring Soon</TableCell>
          </TableRow>
        </TableHead> 
        { loading ? (
            <TableBody>
                <TableCell colSpan={6}>
                    <Loader size="90px"/>
                </TableCell>
              </TableBody> ): (
        <TableBody>
          {data?.getPromotions.map((promos:any) =>  {
            if(promos.availablePoints > 0){
            return(
            <TableRow >
              <TableCell padding="none" className="nowrap" >
                <img className="profile-img " src={promos.sponsorLogo || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} />
                <label className="name">{promos.sponsorName}</label>
              </TableCell>
              <TableCell padding="none" align="left"><label >{promos.orgName}</label></TableCell>
              <TableCell padding="none" align="left"><label >{promos.availablePoints}</label></TableCell>
              <TableCell padding="none" align="left"><label >{promos.validityDay}</label></TableCell>
            </TableRow>
          )}})}
        </TableBody>
        )}
      </Table>
    </TableContainer>
     <Grid container className="top-section" sm={12} >
        <Grid className="btn-section view-all-btn" item  xs={12}>
            {/* <Button onClick={promotionOverView}className="btn" variant="contained">View All</Button> */}
        </Grid>
      </Grid> 
    </div>
  );
}