import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core/styles";
import "./settingorg.css";
import ExternalApi from "../externalapi/externalApi";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTab-root.Mui-selected": {
      color: "red",
      backgroundColor: "red",
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `vertical-tab-${index}`,
  "aria-controls": `vertical-tabpanel-${index}`,
});

export default function SettingsOrg() {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Define tabs with show property
  const tabs = [
    { label: "Manage Members", component: <></>, show: false },
    { label: "Widget Installation", component: <></>, show: false },
    { label: "White Label Config", component: <></>, show: false },
    { label: "Api Configuration", component: <ExternalApi />, show: true },
  ];

  // Filter tabs based on show property
  const visibleTabs = tabs.filter((tab) => tab.show);

  return (
    <div className="list-page transaction-list-wrapper">
      <Grid container xs={12} sm={12}>
        <Grid item sm={12} xs={12}>
          <Paper className="table-wrapper">
            <Grid container className="table-head-section" sm={12}>
              <Grid item sm={8}>
                <label className="table-heading">SETTINGS</label>
              </Grid>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                }}
              >
                <Tabs
                  orientation="vertical"
                  classes={classes}
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{
                    borderRight: 1,
                    borderColor: "divider",
                    minWidth: 190,
                    "& .MuiTabs-indicator": { display: "none" },
                    "& .MuiTab-root.Mui-selected": {
                      backgroundColor: "#2b2e4c",
                      borderRadius: "10px",
                      alignSelf: "flex-start",
                    },
                  }}
                >
                  {visibleTabs.map((tab, index) => (
                    <Tab
                      key={index}
                      label={tab.label}
                      {...a11yProps(index)}
                      sx={{ alignSelf: "flex-start" }}
                    />
                  ))}
                </Tabs>
                {visibleTabs.map((tab, index) => (
                  <TabPanel key={index} value={value} index={index}>
                    {tab.component}
                  </TabPanel>
                ))}
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
