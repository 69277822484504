import React,{useState,useEffect} from 'react';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useHistory ,useParams} from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import MUIDataTable from "mui-datatables";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import DropdownSelect from '../../components/Dropdown/dropdownselect';
import Button from "@material-ui/core/Button";
import '../../constant.css';
import '../transaction/transactions.css';
import './eventslist.css';

import SponsorEmty from '../../assets/sponsor-empty@2x.svg';
import SponsorLogo from '../../assets/sponsor-logo.png';
import Emirates from '../../assets/emirates.png';
import Spark from '../../assets/spark.png';
import Omega from '../../assets/omega.png';
import AddMerchandise from './merchandise/addmerchandise';
import { useQuery, useMutation, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import { AnyAaaaRecord } from 'dns';
import S3 from 'aws-s3';
import { config, zoneList } from '../../config';

interface Props {
    onParentRender: () => void,
  }

interface ParamTypes {
    orgId: string;
}

// to get all STH Experience events list
const GET_EVENTS_LIST = gql`
    query getAllEvents($orgId: String!){
        getAllEvents(orgId: $orgId){
            eld_name
            eld_date
            eld_starttime
            eld_endtime
            eld_location
            eld_city
            eld_country
            eld_event_id
            eld_availablecapacity
            eld_event_soldout
            eld_event_status
            eld_event_inventoryType
            eld_timezone_abbreviation
            eld_eventcost
            eld_currencycode
        }
    }
`;

// to get all STH Experience events list
const RELOAD_EVENTS_LIST = gql`
    mutation trackeventsandox($orgId: String!) {
        trackeventsandox(orgId: $orgId) {
            msg
        }
    }
`;

export default function EventsList({onParentRender}:Props) {

    let { orgId } = useParams<ParamTypes>();

    const [eventsReloaded, setEventsReloaded] = React.useState(false)

    const [isReloadClicked, setIsReloadClicked] = React.useState(false)

    const { loading: eventsListLoading, data: eventsListData, refetch: eventsRefetch } = useQuery<any>(GET_EVENTS_LIST,{variables:{orgId:orgId}});

    const [eventsList, setEventsList] = React.useState([]);

    const [errorMsg,setErrorMsg] = useState<any>("");
    const [openToast,setOpenToast] = React.useState<any>(false);

    // to open error messages.
    const Alert = (props: AlertProps)  => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = () => {
        // refetch();
        // setOpen(false);
        // setMerchandiseId(0);
        // setViewType('Create');
        // refetch();  // reload
    };

    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const reLoadEventsList = () => {
        // console.log('reload start')

        if (isReloadClicked) return false
        else {
            setIsReloadClicked(true)

            // to reload events from tickets.com
            trackeventsandox({variables:{orgId:orgId}})
        }
    }

    const [trackeventsandox] = useMutation(RELOAD_EVENTS_LIST,{
        // after updating the post, we go to the home page
        onCompleted: () => {
            // console.log('reload done')
            
            // RK - here we need to cover the response data then show then else part error show.

            setIsReloadClicked(false)
            eventsRefetch()
            setErrorMsg('Events reloaded successfully.');
            setEventsReloaded(true)
            setOpenToast(true);
        },
        onError: (data) => { 
            // setErrorMsg(data.message);
            setIsReloadClicked(false)
            setErrorMsg('Problem communicating with partner, please try again.');
            setEventsReloaded(false)
            setOpenToast(true);
         }
      });

    // to display time from "17:35:00" to "05:35 PM" 
    const getTimeAMPM = (time:any) => {
        time = time.substring(0, 5)
        // Check correct time format and split into components
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      
        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
          time[5] = time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
          time[0] = time[0] % 12 || 12; // Adjust hours
          time[0] = time[0] < 10 ? '0'+time[0] : time[0];
        }
        return time.join (''); // return adjusted time or original string
    }

    const getEventsListDetails = (objData:any) => {
        let eventImageUrl = ''
        let newEventsList:any = []

        // console.log('RK events Data', objData)
        // "soldOut": false, "status": "ON_SALE" and "inventoryType": "NON_INVENTORIED"

        for (let i=0; i<objData.length; i++) {
            let d = objData[i].eld_date + 'T' // '2021-03-01T'
            // let dStartTime = moment(d + objData[i].eld_starttime).format('hha')
            let dDate = d+"00:00:00"
            let dStartTime =  getTimeAMPM(objData[i].eld_starttime)
            let dateAndTimeZone = d + objData[i].eld_starttime

            let timezoneName = 'PDT'    // default zone code.
            for (let j=0; j<zoneList.length; j++) {
                if(objData[i].eld_timezone_abbreviation) {
                    if  (zoneList[j].zoneCode === objData[i].eld_timezone_abbreviation.toUpperCase()) {
                        timezoneName = zoneList[j].zoneName ? zoneList[j].zoneName : 'PDT';
                    }
                }
            }
            let timezone = moment(dateAndTimeZone).tz(timezoneName).format('z')

            newEventsList.push ({
                eventId: objData[i].eld_event_id,
                // eventName: JSON.stringify([{
                //     givenName: objData[i].eld_name,
                //     profileImage: eventImageUrl,
                // }]),
                eventName: objData[i].eld_name,
                eventDate: dDate,
                eventStartTime: dStartTime + ' (' + timezone + ')',
                eventLocation: objData[i].eld_location,
                eventCity: objData[i].eld_city,
                eventCountry: objData[i].eld_country,
                eventAvailableCapacity: objData[i].eld_availablecapacity,
                eventSoldOut: objData[i].eld_event_soldout ? 'YES' : 'NO',
                eventStatus: objData[i].eld_event_status ? objData[i].eld_event_status : 'ON_SALE',
                eventInventoryType: objData[i].eld_event_inventoryType ? objData[i].eld_event_inventoryType : 'NON-INVENTORIED',
                // eventLastUpdated: objData[i].eld_updateddate,
                eventPointCost: objData[i].eld_eventcost ? objData[i].eld_eventcost.toString().split(".")[0] : '0',
                eventPrice: objData[i].eld_eventcost ? objData[i].eld_eventcost.toFixed(2) : '0.00',
                eventCurrencyCode: objData[i].eld_currencycode
            })
        }
        setEventsList(newEventsList)
    }

    useEffect(() => {        
        if (!eventsListLoading) getEventsListDetails(eventsListData.getAllEvents)
    },[eventsListData]);
   
    // to pass table columns
    const columns:any = [
        // {
        //     name:"eventName",
        //     options:{
        //         filter:false,
        //         sortThirdClickReset: true,
        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { 
        //             console.log(sortOrder);
        //             let orderName = (sortOrder.name == "eventName") ? sortOrder.direction : "";
        //             return (
        //                 <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
        //                     <a className="sort-by">Event Name</a>
        //                 </th>
        //             )
        //         },
        //         customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
        //             let fieldValues = JSON.parse(value);
        //             let userName:string = fieldValues[0].givenName;
        //             return (
        //                 <div className="pointer">
        //                     <img className={fieldValues[0].profileImage ? 'profile-img merchandise' : 'profile-img'} src={fieldValues[0].profileImage ||  "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} /><label className='membername pointer'>{userName.replace("&"," ")}</label>
        //                 </div>
        //             )
        //         }
        //     }
        // },
        {
            name:"eventName",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventName")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Event Name</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"eventId",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventId")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Code</a>
                        </th>
                    )
                }
            }
        }, 
        {
            name: 'eventDate',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { 
                    let orderName = (sortOrder.name == "eventDate")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                            <a className="sort-by">Event Date</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    // console.log(value);
                    return (
                        // <div><label className="">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>
                        <div><label className="">{moment(value).format('MM/DD/YYYY')}</label></div>
                    )
                }
            }
        },
        {
            name:"eventStartTime",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventStartTime")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                            <a className="sort-by">Start At</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"eventAvailableCapacity",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventAvailableCapacity")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Avail. Cap.</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"eventPointCost",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventPointCost")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Point Cost</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"eventCurrencyCode",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventCurrencyCode")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Currency</a>
                        </th>
                    )
                }
            }
        },        
        {
            name:"eventPrice",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventPrice")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Price</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"eventSoldOut",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventSoldOut")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                            <a className="sort-by">Sold Out</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"eventStatus",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventStatus")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(6)} >
                            <a className="sort-by">Status</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"eventInventoryType",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventInventoryType")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(7)} >
                            <a className="sort-by">Inventory Type</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"eventLocation",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventLocation")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={8} onClick={() => updateDirection(8)} >
                            <a className="sort-by">Location</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"eventCity",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventCity")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={9} onClick={() => updateDirection(9)} >
                            <a className="sort-by">City</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"eventCountry",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "eventCountry")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={10} onClick={() => updateDirection(10)} >
                            <a className="sort-by">Country</a>
                        </th>
                    )
                }
            }
        }       
    ];

    // // to se various options for table columns and rows.
    const options:any = {
        filter: false,
        filterType: "dropdown",
        responsive:"standard",
        print:false,
        download : false,
        pagination : true,
        viewColumns : false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader : true,
        tableBodyHeight:'450px',
        search:false,
        // expandableRows: true,
        // expandableRowsHeader: false,
        // expandableRowsOnClick: true,
        // isRowExpandable: (dataIndex:any, expandedRows:any) => {
        //     // if (dataIndex === 1 || dataIndex === 2) return false;

        //     // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
        //     if (expandedRows.data.length > 1 && expandedRows.data.filter((d:any) => d.dataIndex === dataIndex).length === 0) return false;
        //     return true;
        // },
        // rowsExpanded: [], //[0, 1],
        // renderExpandableRow: (rowData:any, rowMeta:AnyAaaaRecord) => {
        //     // console.log('rk test data', rowData)           
        //     const colSpan = rowData.length + 1;
        //     return (
        //         // <TableRow>
        //         //     {/* <TableCell colSpan={colSpan}>
        //         //         Custom expandable row option. Data: {JSON.stringify(rowData)}
        //         //     </TableCell> */}
        //         // </TableRow>
        //         <TableRow>
        //             <TableCell colSpan={colSpan} className='expand-block-highlight'>
        //                 <Grid container spacing={4}>
        //                     <Grid item xs={3}>
        //                         <div className='expand-desc-title'>Description</div>
        //                         <div>{rowData[9].length > 0 ? rowData[9] : 'N/A'}</div>
        //                     </Grid>
        //                     <Grid item xs={2}>
        //                         <div className='expand-desc-title'>Email Voucher</div>
        //                         <div>{rowData[10] ? 'YES' : 'NO'}</div>
        //                     </Grid>
        //                     <Grid item xs={2}>
        //                         <div className='expand-desc-title'>Merchandise Fulfillment</div>
        //                         <div>{rowData[11] ? 'YES' : 'NO'}</div>
        //                     </Grid>
        //                 </Grid>
        //             </TableCell>          
        //         </TableRow>
        //     );
        // },
        // onRowExpansionChange: (curExpanded:any, allExpanded:any, rowsExpanded:any) => console.log(curExpanded, allExpanded, rowsExpanded)
    }

    // to upload csv file to S3 to add merchandise as draft status.
    // const uploadImage = (e:React.ChangeEvent<any>) => {
    //     const targetFiles:any = e.target.files;

    //     console.log(targetFiles);

    //     if (targetFiles[0].type == "text/csv") {
    //         // setLoader(true);
    //         if (targetFiles.length > 0) {
    //             S3Client
    //             .uploadFile(targetFiles[0], `import_merchandise_${Math.random()}`)
    //             .then((res: any) => { 
    //                 console.log(res.location);

    //                 // setLoader(false);
    //             })
    //             .catch((err: any) => console.error(err));
    //         }
    //     } else {
    //         setErrorMsg("Please upload file type as CSV.");
    //         setOpenToast(true);
    //     }
    // }
    
    return (
        <div className="list-page manage-member-wrapper">
            <Grid container xs={12} sm={12}>
                <Grid  item sm={12} xs={12}>
                    <Paper className="table-wrapper">
                        <Grid container className="table-head-section" sm={12}>
                            <Grid item sm={8}>
                                <label className="table-heading">EVENTS LIST</label>
                            </Grid>
                            <Grid  item sm={4} className="select-with-label align-right flx-align-center flx-end">
                                <div className='import-box flx-align-center cust-bg-clr' onClick={reLoadEventsList}>
                                    <div className='import-flx-box flx-align-center'>
                                        <div className={isReloadClicked ? 'ic-import rotate' : 'ic-import'} /> <span className='reload-btn-tile'>reload</span>
                                        {/* <input type="file" className="form-fields form-field-file import-csv-file" onChange={(e) => uploadImage(e)} /> */}
                                    </div>
                                </div>
                            </Grid>
                            <Grid container className='cust-tble-hdr' xs={12} sm={12}>
                                <Grid item sm={12} xs={12}>
                                    <div className="table-container muidata-table event-cust-tbl-body">
                                        {eventsListLoading ? (
                                            <Loader size="90px"/>
                                        ):(
                                            <MUIDataTable
                                                title={""}
                                                data={eventsList}
                                                columns={columns}
                                                options={options}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>    
                </Grid>
            </Grid>
            
            {/* to open Add Merchandise Dialog */}
            {/* <AddMerchandise onOpen={open} editId={merchandiseId} viewTypeTxt={viewType} handleCloseClick={handleClose} /> */}

            {/* To display error message */}
            <Snackbar open={openToast} autoHideDuration={5000}  onClose={handleClosed}>
                <Alert onClose={handleClosed} severity={eventsReloaded ? "success" : "error"}>{errorMsg}</Alert>
            </Snackbar>
        </div>
    )
}
