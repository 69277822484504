import React,{useState,useEffect} from 'react';
import { useParams} from "react-router-dom";
import { Controller, useForm} from "react-hook-form";

import MUIDataTable from "mui-datatables";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import '../../constant.css';
import '../transaction/transactions.css';
import { Select, MenuItem } from "@material-ui/core";
import moment from 'moment';
import './merchandiselist.css';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import { CSVLink } from "react-csv";
import {Button,} from "@material-ui/core";
import ViewMembersItem from './merchandise/viewmeberslist';

interface Props {
    onParentRender: () => void,
  }

interface ParamTypes {
    orgId: string;
}



interface IFormAddMembership {
    membershipname: string,
    membershipkit: string,
}


let defaultValues: any = {
    // selectedMerImg: '',
    membershipname: 'All',
    membershipkit: 'All',
}
export const GET_MEMBERS_BY_CLUB_ID_QUERY = gql`
query getAllMembersByClubId($orgId: String!, $membershipNameBy: String!, $membershipKitStatus: String!){
    getAllMembersByClubId(orgId:$orgId, membershipNameBy:$membershipNameBy, membershipKitStatus:$membershipKitStatus){
      id
      username
      memberName
      membershipName
      patronName
      patronId
      membershipkit
      membersUpdatedDate
      memberskitFulfillment
    }
}`;
export const GET_MEMBERSHIP_ALL = gql`
query getMembershipAll($club_id: String!){
    getMembershipAll(club_id: $club_id){
        name
        img_url
      	status
      	club_id
      	refrence_id
        id
        buyer_type_id
        faqsLink
    }
}   
`
export default function MembersList({onParentRender}:Props) {

    let { orgId } = useParams<ParamTypes>();
    const [membershipNameBy, setMembershipNameBy] = React.useState("All");
    const [membershipKit, setMembershipKit] = React.useState("All");

    const { refetch, loading, data } = useQuery<any>(GET_MEMBERS_BY_CLUB_ID_QUERY, { variables: { orgId: orgId, membershipNameBy:membershipNameBy, membershipKitStatus: membershipKit}});
    const { refetch:membershipAllRefetch, loading:membershipAllLoading, data:membershipAllData } = useQuery<any>(GET_MEMBERSHIP_ALL, { variables: { club_id: orgId }});
    const [membersData, setMembersData] = React.useState([]);
    const [arrayToCsv, setArrayToCsv] = useState<any>([]);
    
    const [open, setOpen] = React.useState(false);
    const [errorMsg, setErrorMsg] = useState<any>("");
    const [openToast, setOpenToast] = React.useState<any>(false);
    const [memberId , setMemberId] = React.useState<any>(0);
    const [viewType , setViewType] = React.useState<any>("Create");
    const [singleMemberData, setSingleMemberData] = React.useState([]);

    const {  control, } = useForm<IFormAddMembership>({ defaultValues, shouldUnregister: false });
    
    // to open error messages.
    const Alert = (props: AlertProps)  => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    

    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };
    const handleClose = () => {
        setOpen(false);
        setMemberId(0);
        setViewType('Create');
        refetch();  // reload 
    };

    const handleEditClickOpen = (id:string) => {
        //console.log(id);
        setOpen(true);
        setMemberId(id);
        setViewType('Edit');
        // console.log(id);
    };

    const getMembersList = (objData:any) => {
        let newMembersData:any = []
        for (let i=0; i<objData.length; i++) {
            newMembersData.push ({
                id: objData[i].id,
                username: objData[i].username,
                memberName: objData[i].memberName,
                membershipkit: objData[i].membershipkit,
                membersUpdatedDate: objData[i].membersUpdatedDate,
                membershipName: objData[i].membershipName,
                patronName: objData[i].patronName,
                patronId: objData[i].patronId,
                memberskitFulfillment: objData[i].memberskitFulfillment,
                membersEditDet: JSON.stringify([{
                    id: objData[i].id,
                    membersData: objData[i],
                }])
            })
        }
        setMembersData(newMembersData)
    }

    useEffect(() => {
        if (!loading) getMembersList(data.getAllMembersByClubId)
        // onParentRender();
    },[data]);
    
    const arrayToCsvFunc = (data: any) => {
        const titleKeys = Object.keys(data[0])
        let newMemberData = data.map((obj: any) => {
            return {
                memberName : obj.memberName,
                membershipName : obj.membershipName,
                membershipkit: obj.membershipkit,
                patronId: obj.patronId,
                patronName: obj.patronName,
                memberskitFulfillment: obj.memberskitFulfillment === 'true' ? 'Deliverd' : 'Not Delivered',
                membershipDate: moment(parseInt(obj.membersUpdatedDate)).format('MM/DD/YYYY hh:mm:ss')
            }
        });
        // let newData = data.map((obj: any) => {
        //     let newObj = { ...obj };
        //     delete newObj.id; // Remove the "id" property
        //     return newObj; // Return modified object
        // });
        const refinedData: any = [
            ['Member Name', 'Membership Name',  'Membership Kit',  'Patron ID',  'Patron Name', 'Members Kit Fulfillment Status', 'Fulfillment Update Date', ],
        ]
        newMemberData.forEach((item: any) => {
            refinedData.push(Object.values(item))
        })
        let csvContent = ''
        refinedData.forEach((row: any) => {
            csvContent += row.join(',') + '\n'
        })
        return csvContent;
    }
    useEffect(() => {
        if(membersData.length > 1){
            setArrayToCsv(arrayToCsvFunc(membersData));
        }
        // onParentRender();
    },[membersData]);

    // to pass table columns
    const columns:any = [
        {
            name:"memberName",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { 
                    console.log(sortOrder);
                    let orderName = (sortOrder.name == "memberName") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                            <a className="sort-by">Member Name</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let userName:string = value;
                    return (
                        <div className="pointer">
                            <img className={'profile-img'} src={"https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} /><label className='membername pointer'>{userName.replace("&"," ")}</label>
                        </div>
                    )
                }
            }
        },
        {
            name:"membershipName",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "membershipName")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Memberships</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"membershipkit",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "membershipkit")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Membership Kit Size</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"patronId",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "patronId")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(2)} >
                            <a className="sort-by">Patron Id</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"patronName",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "patronName")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Patron Name</a>
                        </th>
                    )
                }
            }
        },
        {
            name: 'memberskitFulfillment',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "memberskitFulfillment")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(8)} >
                            <a className="sort-by">Fulfillment Status</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    //console.log(value);
                    return (
                        <div><label className={value === 'true' ? 'status-active' : 'status-inactive'}>{value === 'true' ? "Delivered" : "Not Delivered"}</label></div>
                    )
                }
            }
        },
        {
            name: 'membersUpdatedDate',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { 
                    let orderName = (sortOrder.name == "membersUpdatedDate")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Membership Created Date</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    // console.log(value);
                    return (
                        // <div><label className="">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>
                        <div><label className="">{moment(parseInt(value)).format('MM/DD/YYYY hh:mm:ss')}</label></div>
                    )
                }
            }
        },
        {
            name: 'membersEditDet',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "membersEditDet")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(9)} >
                            {/* <a className="sort-by">Edit</a> */}
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let fieldValues = JSON.parse(value);
                    let userListingId = fieldValues[0].id;
                    // let fulfillmentStatus = fieldValues[0].fulfillmentStatus;
                    
                    return (
                        // <div><label className="">{value ? "true":"Intrue"}</label></div>
                        <label className=""><span onClick={() => {handleEditClickOpen(userListingId); setSingleMemberData(tableMeta.tableData[tableMeta.rowIndex])}} data-id={userListingId} data-view="Edit" className="table-edit-icon"></span></label>
                    )
                }
            }
        },
    ];

    // // to se various options for table columns and rows.
    const options:any = {
        filter: false,
        filterType: "dropdown",
        responsive:"standard",
        print:false,
        download : false,
        pagination : true,
        viewColumns : false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader : true,
        tableBodyHeight:'450px',
        search:true,
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        isRowExpandable: (dataIndex:any, expandedRows:any) => {
            // if (dataIndex === 1 || dataIndex === 2) return false;

            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            if (expandedRows.data.length > 1 && expandedRows.data.filter((d:any) => d.dataIndex === dataIndex).length === 0) return false;
            return true;
        },
        rowsExpanded: [], //[0, 1],
        onRowExpansionChange: (curExpanded:any, allExpanded:any, rowsExpanded:any) => console.log(curExpanded, allExpanded, rowsExpanded)
    }

    const handleChange = (e: React.ChangeEvent<{ value: string | unknown }>) => {
        const targetType = (e.target as HTMLInputElement).value;
        const targetName = (e.target as HTMLInputElement).name;

        if (targetName === 'membershipname') {
            setMembershipNameBy(targetType)
        }

        if (targetName === 'membershipkit') {
            setMembershipKit(targetType)
        }

        refetch();
    }    
    return (
        <div className="list-page manage-member-wrapper">
            <Grid container xs={12} sm={12}>
                <Grid  item sm={12} xs={12}>
                    <Paper className="table-wrapper">
                        <Grid container className="table-head-section" sm={12}>
                            <Grid item sm={4}>
                                <label className="table-heading">MEMBERS LIST</label>
                            </Grid>
                            <Grid  item sm={8} className="select-with-label align-right flx-align-center flx-end">
                            
                            {/* Category */}
                            <div className="input-group" style={{marginRight: 10}}>
                                <label style={{marginRight: 10}}>Membership Name</label>
                                <Controller
                                    name="membershipname"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ value }) => (
                                        <Select label="Membership Name" displayEmpty name="membershipname" value={membershipNameBy} className={`custom-select form-select`} onChange={(e) => handleChange(e)}>
                                            <MenuItem value='All'>All</MenuItem>
                                            {membershipAllData?.getMembershipAll.map((res: any) => {
                                                return(<MenuItem value={res.name}>{res.name}</MenuItem>)
                                            })}
                                        </Select>
                                    )}
                                />
                            </div>
                            <div className="input-group" style={{marginRight: 10}}>
                                <label style={{marginRight: 10}}>Membership Kit Status</label>

                                <Controller
                                    name="membershipkit"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ value }) =>(
                                        <Select label="Membership Kit" displayEmpty name="membershipkit" value={membershipKit} className={`custom-select form-select`} onChange={(e) => handleChange(e)}>
                                            <MenuItem value='All'>All</MenuItem>
                                            <MenuItem value='true' >Delivered</MenuItem>
                                            <MenuItem value='false' >Not Delivered</MenuItem>
                                        </Select>
                                    )}
                                />
                            </div>
                            <Button className="btn btn-secondary" style={{ minWidth: "82px", }}>
                                <CSVLink filename={"members_list_export.csv"} data={arrayToCsv} className='import-btn-tile' style={{ color: "#8A8D9D", textDecoration: "none" }}>
                                    Export to CSV
                                </CSVLink>
                            </Button>
                            </Grid>
                            <Grid container className='cust-tble-hdr' xs={12} sm={12}>
                                <Grid item sm={12} xs={12}>
                                    <div className="table-container muidata-table cust-tbl-block">
                                        {loading ? (
                                            <Loader size="90px"/>
                                        ):(
                                            <MUIDataTable
                                                title={""}
                                                data={membersData}
                                                columns={columns}
                                                options={options}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>    
                </Grid>
            </Grid>
            
            {/* to open Add Merchandise Dialog */}
            {/* {open && <AddMerchandise onOpen={open} editId={fulfillmentId} viewTypeTxt={viewType} handleCloseClick={handleClose} />} */}
            <ViewMembersItem onOpen={open} editId={memberId} singleMerchData={singleMemberData} viewTypeTxt={viewType} handleCloseClick={handleClose} />

            {/* To display error message */}
            <Snackbar open={openToast} autoHideDuration={5000}  onClose={handleClosed}>
                <Alert onClose={handleClosed} severity="success">{errorMsg}</Alert>
            </Snackbar>
        </div>
    )
}
