import React , {useEffect,useState , useRef}from 'react';
import { useHistory ,useParams} from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import {
    Select,
    MenuItem,
    Switch,
  } from "@material-ui/core";
import '../../../constant.css';
import { gql, useMutation,useQuery } from '@apollo/client';
import S3 from 'aws-s3';
//import { s3config } from "../../../s3config";
import { config } from '../../../config';
import Loader from '../../../components/Loader/loader';
import SponsorEmty from '../../../assets/sponsor-empty@2x.svg';

import SponsorLogo from '../../../assets/sponsor-logo.png';
import { stringify } from 'querystring';
interface Props {
    onOpen : boolean,
    handleCloseClick: () => void,
    viewTypeTxt ?: any,
    editId ? : any
}
interface ParamTypes {
    orgId: string;
}
interface IFormAddSponsor {
    sponsorLogo: string;
    sponsorName: string;
    pointRequest: string;
    contactName: string;
    contactEmail : string;
    contactPhone : string;
    contactAddress : string;
}

let defaultValues:any = {
        sponsorLogo: "",
        sponsorName: "",
        pointRequest: "",
        contactName: "",
        contactEmail : "",
        contactPhone : "",
        contactAddress : ""
}
const CREATE_SPONSOR = gql`
  mutation createSponsor($data: NewSponsorInput! ) {
    createSponsor(data: $data) {
      sponsorName,
      contactName
    }
  }
`;

const UPDATE_SPONSOR = gql`
mutation updateSponsors($id : String!,$data: UpdateSponsorInput! ){
    updateSponsor(id: $id, data: $data ){
        sponsorName
    }
   }`;

const SPONSOR_BY_ID = gql`
query getSponsor($id: String!) {
    getSponsorWithId(id: $id) {
        id
        sponsorName
        contactName
        sponsorLogo
        contactEmail
        contactPhone
        contactAddress
        pointRequest
  }
}
`;

const DELETE_SPONSOR_BY_ID = gql`
mutation deleteSponsors($id: String!) {
    deleteSponsor(id: $id)
}`;


const UPLOAD_MERCH_IMAGE = gql`
mutation uploadMerchImage($imageData: String!, $fileNamePrefix: String!){
    uploadMerchImage(imageData: $imageData, fileNamePrefix: $fileNamePrefix)
}
`;
export default function CreateSponsor({handleCloseClick,onOpen,viewTypeTxt,editId}:Props) {
    const sponsorId = editId ? editId : "";
    const open = onOpen;
    const [confirmed, setConfirmed] = React.useState(false);
    const inputRef:any = useRef();

    let { orgId } = useParams<ParamTypes>();
    const [errorMsg,setErrorMsg] = useState<any>("");
    const [openToast,setOpenToast] = React.useState<any>(false);

    const [openConfirm,setOpenConfirm] = React.useState(false);
    const [logoUrl, setlogoUrl] = useState<any>();
    const [loader,setLoader] = useState<any>(false);
    const [confirmMessage,setConfirmMessage] = useState<any>("Are you sure with the Point Requested value?");

    //const methods = useForm<IFormAddClub>();
    const [sponsorInfo ,setSponsorInfo] = useState<any>({});
    const [userData,setUserData] = useState([]);
    const [name,setName] = useState("");
    const { loading, data,refetch } = useQuery<any>(SPONSOR_BY_ID, { variables: { id: sponsorId } });
    const { register, handleSubmit, errors,control, reset, formState, setValue} = useForm<IFormAddSponsor>({defaultValues,shouldUnregister:false});
    const Alert = (props: AlertProps)  => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
    const fieldSetValueFunction = (defaultValue:any) =>{
        setlogoUrl(defaultValues.sponsorLogo);     
        setValue("sponsorName",defaultValues.sponsorName);
        setValue("contactName",defaultValues.contactName);
        setValue("contactEmail",defaultValues.contactEmail);
        setValue("contactPhone",defaultValues.contactPhone);
        setValue("contactAddress",defaultValues.contactAddress);
        setValue("pointRequest",defaultValues.pointRequest);
    }

    useEffect(() => {
        refetch();
        if(!loading && editId){
            defaultValues = {...data.getSponsorWithId};
            fieldSetValueFunction(defaultValues);
            setConfirmed(true);
        }else{
            setOpenConfirm(false);
            setConfirmed(false);
            defaultValues = {
                sponsorLogo: "",
                sponsorName: "",
                pointRequest: "",
                contactName: "",
                contactEmail : "",
                contactPhone : "",
                contactAddress : ""
            };
            fieldSetValueFunction(defaultValues);
        }
    },[data,open,setValue]);
    
    const [uploadMerchImage] = useMutation(UPLOAD_MERCH_IMAGE, {
        onCompleted: (res) => {
            console.log('RESULT', res)
        }
    });

     const [deleteSponsors] = useMutation(DELETE_SPONSOR_BY_ID,{
        // after updating the post, we go to the home page
        onCompleted: () => handleCloseClick(),
        onError: (data) => { 
            setErrorMsg(data.message); 
            setOpenToast(true);
         }
      });
     const handleDeleteSponsor = () => {
        setConfirmMessage("Are you sure you want to delete this Sponsor.By choosing Delete you will loose all the information for "+data.getSponsorWithId.sponsorName);
        setOpenConfirm(true);  
     }
     const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenToast(false);
      };
    
    const [createSponsor,{error, loading:resLoading}] = useMutation(CREATE_SPONSOR ,{
        // after updating the post, we go to the home page
        onCompleted: () => handleCloseClick(),
      });
    const [updateSponsors] = useMutation(UPDATE_SPONSOR , {
        // after updating the post, we go to the home page
        onCompleted: () => handleCloseClick(),
      });
    
    const clickCanceled = () => {
        setConfirmed(false);
        setOpenConfirm(false);
        inputRef.current.focus();
    }
    const clickConfirmed: SubmitHandler<IFormAddSponsor> = (fieldData) => {
        if(editId){
            deleteSponsors({variables:{id:sponsorId}});
            console.log("EDIT")
        }else{
            console.log("ADD")
            createSponsor({variables: 
                {
                    data: 
                    {
                        sponsorName: fieldData.sponsorName,
                        sponsorLogo: logoUrl, 
                        pointRequest: parseInt(fieldData.pointRequest),
                        contactName: fieldData.contactName,
                        contactEmail : fieldData.contactEmail,
                        contactPhone : fieldData.contactPhone,
                        contactAddress : fieldData.contactAddress,
                        clubId:orgId
                    }
                }
            });
        }
        setConfirmed(true);
        setOpenConfirm(false);  
    }
    const onSubmit: SubmitHandler<IFormAddSponsor> = fieldData => {
        setConfirmMessage("Are you sure with the Point Requested value?");

        if(confirmed){
            if(sponsorId){
                updateSponsors({variables: 
                    {
                        id : sponsorId,
                        data: 
                        {
                            sponsorName: fieldData.sponsorName,
                            sponsorLogo: logoUrl, 
                            pointRequest: parseInt(fieldData.pointRequest),
                            contactName: fieldData.contactName,
                            contactEmail : fieldData.contactEmail,
                            contactPhone : fieldData.contactPhone,
                            contactAddress : fieldData.contactAddress,
                            clubId:orgId
                        }
                    }
                });
            }else{
            createSponsor({variables: 
                {
                    data: 
                    {
                        sponsorName: fieldData.sponsorName,
                        sponsorLogo: logoUrl, 
                        pointRequest: parseInt(fieldData.pointRequest),
                        contactName: fieldData.contactName,
                        contactEmail : fieldData.contactEmail,
                        contactPhone : fieldData.contactPhone,
                        contactAddress : fieldData.contactAddress,
                        clubId:orgId
                    }
                }
            });
            
            }
            
            // //handleCloseClick();
        }else{
            setOpenConfirm(true);
        }
    }
   
    const uploadImage = (e:React.ChangeEvent<any>) =>{
        const targetFiles:any = e.target.files;

        console.log(targetFiles);
        if(targetFiles[0].type == "image/png" || targetFiles[0].type == "image/jpeg"){
            setLoader(true);
            if(targetFiles.length>0){
                const file = targetFiles[0];
                const reader = new FileReader();

                    reader.onload = (e) => {
                        // Update state with the base64 encoded image string
                        console.log('READER', reader.result);
                        uploadMerchImage({
                          variables:
                          {
                              "imageData": reader.result,
                              "fileNamePrefix": 'sponsor',
                          }
                      }).then((res:any)=>{
                          console.log('adminresult', res?.data?.uploadMerchImage);
                          setlogoUrl(res?.data?.uploadMerchImage);
                        setLoader(false);
                      }).catch((err: any) => console.error(err));
                      };
                      if (file) {
                        // Read the image file as a data URL
                        reader.readAsDataURL(file);
                      }
            }
        }else{
            setErrorMsg("Image format should png,jpg");
            setOpenToast(true);
        }
    }

    // const handleChange = (e: React.ChangeEvent<{ value: string | unknown }>) => {
    //         const targetType = (e.target as HTMLInputElement).value;
    //         const targetName = (e.target as HTMLInputElement).name;
    //         setValue(targetName ,targetType);
   
    //    }    
    
    return (
        <div>
        <Dialog  open={onOpen} onClose={handleCloseClick} aria-labelledby="form-dialog-title" className="common-dialog-container org-container confirmation-popup">
                <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">{sponsorId ? "Edit" : "Create" } Sponsor</DialogTitle>
                    <DialogContent>
                        <Grid className="form-grid" container sm={12} spacing={6}>
                            <Grid item sm={4} xs={12}>
                                <div className="input-group input-file-group edit">
                                    {loader && <Loader size="30px"/>}
                                    <img className="profile-img-editview" src={logoUrl || SponsorEmty} alt="org-logo"/>
                                    <input ref={register} type="file" name="sponsorLogo"  onChange={(e) => uploadImage(e)} className="form-fields form-field-file" />                            
                                    <div className="file-mask-section edit-view">
                                        <span className="upload-img"></span>
                                        <label>upload Logo</label>
                                       
                                    </div>
                                </div>
                                <div className="input-group">
                                    <label className="input-label" >Company Name*</label>
                                    <Controller as={TextField}  type="text" rules={{ required: true}} name="sponsorName" placeholder="Company Name" className={`form-fields  ${errors.sponsorName && 'error'}`} control={control} />                            
                                    {errors.sponsorName && errors.sponsorName.type == "required" && (<label className="error-message">Field is requried</label>)}
                                </div>
                                <div className="input-group">
                                    <label className="input-label" >Points Request*</label>
                                    <Controller as={TextField} rules={{required:true }} type="number" name="pointRequest"   onFocus={() => inputRef.current.focus()}  inputRef={inputRef} disabled={ (sponsorId)?true:false} placeholder="Point request" className={`form-fields  ${errors.pointRequest && 'error'}`} control={control} defaultValue="" />                            
                                    {errors.pointRequest && errors.pointRequest.type == "required" && (<label className="error-message">Field is requried</label>)}
                                </div>
                                {/* <div className="input-group">
                                        <label className="input-label" >Points</label>
                                        <Controller
                                            name="pointRequest"
                                            control={control} 
                                            rules={{ required: true}}
                                            render = {({value}) => (
                                                <Select displayEmpty name="pointRequest"  value={value} disabled={(sponsorId)?true:false} className={`custom-select form-select  ${errors.pointRequest && 'error'}`} onChange={(e) => handleChange(e)} >
                                                    <MenuItem value="">Select</MenuItem>
                                                    <MenuItem value={50}>50</MenuItem>
                                                    <MenuItem value={100}>100</MenuItem>
                                                    <MenuItem value={200}>200</MenuItem>
                                                    <MenuItem value={300}>300</MenuItem>
                                                </Select>
                                              )
                                            }  
                                        />
                                        {errors.pointRequest && errors.pointRequest.type == "required" && (<label className="error-message">Field is requried</label>)} 
                                    </div> */}
                                
                            </Grid>
                            <Grid item className="borderL padr0" sm={8} xs={12}>
                                <h5 className="section-heading form-heading mrg0">Sponsor Info</h5>
                                <div className="input-group">
                                    <label className="input-label">Contact Name*</label>
                                    <Controller as={TextField}  type="text"  rules={{ required: true}} name="contactName" placeholder="Contact Name"  className={`form-fields  ${errors.contactName && 'error'}`} control={control} defaultValue="" />                            
                                    {errors.contactName && errors.contactName.type == "required" && (<label className="error-message">Field is requried</label>)}  
                                </div>
                                <div className="input-group">
                                    <label className="input-label">Contact Email*</label>
                                    <Controller as={TextField}  type="email"  rules={{ required: true,pattern :/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/}} name="contactEmail" placeholder="example@gmail.com"  className={`form-fields  ${errors.contactEmail && 'error'}`} control={control} defaultValue="" />                            
                                    {errors.contactEmail && errors.contactEmail.type == "required" && (<label className="error-message">Field is requried</label>)}
                                    {errors.contactEmail && errors.contactEmail.type == "pattern" && (<label className="error-message">Field should be in mail format</label>)}
                                </div>
                                <div className="input-group">
                                    <label className="input-label">Contact Phone*</label>
                                    <Controller as={TextField}  type="number" rules={{ required: true, pattern:/[0-9]/ , validate : (value) =>value.length<=13 && value.length>=5}} name="contactPhone" placeholder="Contact Phone Number"  className={`form-fields  ${errors.contactPhone && 'error'}`} control={control} defaultValue="" />                            
                                    {errors.contactPhone && errors.contactPhone.type == "required" && (<label className="error-message">Field is requried</label>)}
                                    {errors.contactPhone && errors.contactPhone.type == "validate" && (<label className="error-message">Number should be Min 5 and Max 13 Characters</label>)}
                                </div>
                                <div className="input-group">
                                    <label className="input-label">Address</label>
                                    <Controller as={TextField}  type="text" name="contactAddress" placeholder="Address" className="form-fields" control={control} defaultValue="" />                            
                                </div>
                            </Grid>
                            
                        </Grid>
                        { openConfirm &&
                        <div className="confim-model-container">
                            <Paper  className="confirmation-wrapper padd-20">
                                <Grid container sm={12}>
                                    <div className="confirmation-sec">
                                        <p>{confirmMessage}</p>
                                        <DialogActions>
                                            <Button  className="btn btn-secondary" onClick={clickCanceled} color="primary">
                                                CANCEL
                                            </Button>
                                            <Button color="primary" onClick={handleSubmit(clickConfirmed)} className="btn btn-primary button">
                                                CONFIRM
                                            </Button>
                                        </DialogActions>
                                    </div>    
                                </Grid>
                            </Paper>    
                        </div>
                        }          

                    </DialogContent>
                    <DialogActions>
                        {
                            sponsorId && 
                        <Button onClick={handleDeleteSponsor} className="btn btn-secondary btn-delete" color="primary">
                            DELETE
                        </Button>
                        }   
                        <Button onClick={handleCloseClick} className="btn btn-secondary" color="primary">
                            CANCEL
                        </Button>
                        <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>
                            {sponsorId ? "UPDATE SPONSOR" :"ADD SPONSOR"  } 
                        </Button>
                    </DialogActions>
                </form>
                <Snackbar open={openToast} autoHideDuration={5000}  onClose={handleClosed}>
                    <Alert onClose={handleClosed} severity="error">
                        {errorMsg}
                    </Alert>
                </Snackbar>
            </Dialog>
            </div>
    )
}
