import { AnyNsRecord } from 'dns';
import React, { useEffect, useRef, useState } from 'react'
import coin from '../../assets/coin.png';
interface Props {
  coords?: any;
  coinsNvalue?:any;
  markerImage ?: any; 
  pointsArray?: any;
  viewType?:any;
  placedCoins?:any;
  locationSelected ?: any;
  updatePoints: (e: any) => void; 
}

export default function Promotionmap({coords,markerImage,locationSelected,placedCoins, coinsNvalue,viewType, pointsArray, updatePoints}: Props) {

  const refMap: any = useRef();
  const [placedCoin,setPlacedCoins] = useState(placedCoins);
  let points: any = [];

  let map: any;

  const onClick = (point:any) => {
    points.push(point);
    console.log(point);
    updatePoints(point);
    setPlacedCoins(placedCoin + points)
    console.log(placedCoin)
  }
console.log(pointsArray);
  const onLoad = (mylatlag: any) => {
    points = [];
    map = new window.google.maps.Map(refMap.current, {center: mylatlag, zoom: 19})
    if(viewType == "Edit"){
      pointsArray.forEach( (element:any) => {
          console.log(element.pointsValue);
          const marker = new window.google.maps.Marker({
            map,
            position: element,
            label: `${element.pointsValue}`,
            title: "Map",
            icon: coin,
          })
      });
    }
    

    // onMount && onMount(map)
    // const marker = new window.google.maps.Marker({
    //   map,
    //   position: myLatlng,
    //   label: `${"1" + 1}`,
    //   title: "Test",
    // })

    // let infoWindow = new google.maps.InfoWindow({
    //   content: "Click the map to get Lat/Lng!",
    //   position: mylatlag,
    // });
    // infoWindow.open(map);
    if(viewType != "Edit"){
      map.addListener("click", (mapsMouseEvent: any) => {
        // Close the current InfoWindow.
        // infoWindow.close();
        if(points.length < pointsArray.length && locationSelected) {
          console.log("total",points.length);
          console.log("Position",points.length);
          console.log("poinsts",pointsArray[points.length === 0 ? 0 : points.length - 1]);
          
          onClick({...mapsMouseEvent.latLng.toJSON(), pointsValue: pointsArray[points.length]});
        const marker = new window.google.maps.Marker({
          map,
          position: mapsMouseEvent.latLng,
          label: `${pointsArray[points.length - 1]}`,
          title: "Test",
          icon: coin,
        })
      }
    
  
      // Create a new InfoWindow.
      // infoWindow = new google.maps.InfoWindow({
      //   position: mapsMouseEvent.latLng,
      // });
      // infoWindow.setContent(
      //   JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
      // );
      // infoWindow.open(map);
    });
  }

  }

  

  useEffect(() => {
    onLoad(coords);
  }, [coords, coinsNvalue, pointsArray])

  return (
    <div ref={refMap} className="map">
      map
    </div>
  )
}
