import React,{useState,useEffect} from 'react';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useHistory ,useParams} from "react-router-dom";
import MUIDataTable from "mui-datatables";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import '../../constant.css';
import '../transaction/transactions.css';
import './merchandiselist.css';
import ViewFulfillmentListItem from './merchandise/viewfulfillmentlistitem';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import { CSVLink } from "react-csv";
import {Button,} from "@material-ui/core";

interface Props {
    onParentRender: () => void,
  }
interface ParamTypes {
    orgId: string;
}

export const GET_FULFILLMENT_QUERY = gql`
query getAllTransactionsByClubId($orgId: String!){
    getAllTransactionsByClubId(orgId:$orgId){
      id
      username
      transactionId
      userListingFulfillment
      userListingFulfillmentDate
      userListingId
      transactionDate
      transactionRefrenceCode
      merchandiseName
      merchandiseImage
      merchandiseCategory
      merchandiseItemNo
      transactionQty
      transactionConsumedPoint
      transactionTotalConsumedPoint
      tdcPatronId
      tdcPatronName
      tdcEmail
      shippingAddress
    }
    }
`;

const UPLOAD_MERCH_IMAGE = gql`
mutation uploadMerchImage($imageData: String!, $fileNamePrefix: String!){
    uploadMerchImage(imageData: $imageData, fileNamePrefix: $fileNamePrefix)
}
`;

export default function FulfillmentList({onParentRender}:Props) {

    let { orgId } = useParams<ParamTypes>();
    const { refetch, loading, data } = useQuery<any>(GET_FULFILLMENT_QUERY, { variables: { orgId: orgId }});
    const [fulfillmentData, setFulfillmentData] = React.useState([]);
    const [singleFulfillmentData, setSingleFulfillmentData] = React.useState([]);
    const [fulfillmentId , setFulfillmentId] = React.useState<any>(0);

    const [open, setOpen] = React.useState(false);
    const [errorMsg,setErrorMsg] = useState<any>("");
    const [openToast,setOpenToast] = React.useState<any>(false);
    const [arrayToCsv, setArrayToCsv] = useState<any>([]);
    // const [viewType , setViewType] = React.useState<string | null>("Create");
    const [viewType , setViewType] = React.useState<any>("Create");


    // const [sponsorId , setSponsorId] = React.useState<any>("");


    // to open error messages.
    const Alert = (props: AlertProps)  => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = () => {
        refetch();
        setOpen(false);
        setFulfillmentId(0);
        setViewType('Create');
        refetch();  // reload 
    };

    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(true);
        setFulfillmentId(0);
        // const targetElement = (event.target as HTMLButtonElement);
        setViewType('Create');
    };

    const handleEditClickOpen = (id:string) => {
        //console.log(id);
        setOpen(true);
        setFulfillmentId(id);
        setViewType('Edit');
        // console.log(id);
    };
    const handleCopyClickOpen = (id:string) => {
        setOpen(true);
        setFulfillmentId(id);
        setViewType('Copy');
    };
    // useEffect(() => {
    //     console.log("useEffect");
    //     refetch();
    //     onParentRender();
    // },[open,data]);
    
    const [uploadMerchImage] = useMutation(UPLOAD_MERCH_IMAGE, {
        onCompleted: (res) => {
            console.log('RESULT', res)
        }
    });

    const getMerchandiseDetails = (objData:any) => {
        let selectedImageUrl = ''

        let newFulfillmentData:any = []
        for (let i=0; i<objData.length; i++) {
            let imageUrlList = objData[i].merchandiseImage ? JSON.parse(objData[i].merchandiseImage) : []
            if (imageUrlList && imageUrlList.length > 0) {
                for (let j=0; j<imageUrlList.length; j++) {
                    if (imageUrlList[j].isActive) {
                        selectedImageUrl = imageUrlList[j].imageUrl
                    }
                }
            } else {
                // selectedImageUrl = 'https://pointsvilleimagebucket.s3.amazonaws.com/photos_58cce953-1431-4260-b725-077b3c276d66'
            }
            // let customIndex:any = objData[i].userListingId;
            newFulfillmentData.push ({
                transactionId: objData[i].transactionId,
                userListingId: objData[i].userListingId,
                transactionDate: objData[i].transactionDate,
                transactionRefrenceCode: objData[i].transactionRefrenceCode,
                merchName:objData[i].merchandiseName,
                merchImage: selectedImageUrl,
                merchandiseName: JSON.stringify([{
                    givenName: objData[i].merchandiseName,
                    profileImage: selectedImageUrl,
                }]),
                merchandiseCategory: objData[i].merchandiseCategory,
                merchandiseItemNo: objData[i].merchandiseItemNo,
                transactionQty: objData[i].transactionQty,
                merchandisePointsCost: objData[i].transactionConsumedPoint,
                transactionTotalConsumedPoint: objData[i].transactionTotalConsumedPoint,
                patronId: objData[i].tdcPatronId,
                tdcPatronName: objData[i].tdcPatronName,
                tdcEmail: objData[i].tdcEmail,
                shippingAddress: objData[i].shippingAddress,
                userListingFulfillment: objData[i].userListingFulfillment,
                userListingFulfillmentDate: objData[i].userListingFulfillmentDate,
                fulfillmentEditDet: JSON.stringify([{
                    userListingId: objData[i].userListingId,
                    fulfillmentStatus: objData[i].userListingFulfillment,
                }])
            })
        }
        setFulfillmentData(newFulfillmentData)
    }

    useEffect(() => {
        if (!loading) getMerchandiseDetails(data.getAllTransactionsByClubId)
        // onParentRender();
    },[data]);
    
    
    const arrayToCsvFunc = (data: any) => {
        let newData = data.map((res: any) => {
           let ar = {  
               'transactionRefrenceCode' : res.transactionRefrenceCode,
                'merchName' : res.merchName,
                'merchandiseCategory' : res.merchandiseCategory,
                'merchandiseItemNo' : res.merchandiseItemNo,
                'merchandisePointsCost' : res.merchandisePointsCost,
                'transactionId' : res.transactionId,
                'transactionDate' : moment(parseInt(res.transactionDate)).format('MM/DD/YYYY'),
                'transactionQty' : res.transactionQty,
                'transactionTotalConsumedPoint' : res.transactionTotalConsumedPoint,
                'tdcPatronName' : res.tdcPatronName,
                'tdcEmail' : res.tdcEmail,
                'patronId' : res.patronId,
                'userListingFulfillment' : res.userListingFulfillment,
                'userListingFulfillmentDate' : moment(parseInt(res.userListingFulfillmentDate)).format('MM/DD/YYYY')
            };
            return ar;
        });
        const refinedData: any = [
            [ 'Order No','Merchandise Name', 'Merchandise Category', 'Item No', 'Point Cost','Transaction Id', 'Transaction Date', 'Transaction Qty', 'Total Consumed Points', 'Patron Name', 'Patron Email', 'Patron ID', 'Fulfillment Status', 'Fulfillment Date'],
        ]
        newData.forEach((item: any) => {
            refinedData.push(Object.values(item))
        })
        let csvContent = ''
        refinedData.forEach((row: any) => {
            csvContent += row.join(',') + '\n'
        })
        return csvContent;
    }
    useEffect(() => {
        if(fulfillmentData.length > 1){
            setArrayToCsv(arrayToCsvFunc(fulfillmentData));
        }
    },[fulfillmentData]);

    // const handleReadMoreLess = () => {
    //     setExpanded(expanded ? false : true)
    // }
   
    // const methods = useForm<IFormAddClub>();
    // // const { handleSubmit, control, reset,errors } = methods;
    // const onSubmit: SubmitHandler<IFormAddClub> = data =>
    // console.log(JSON.stringify(data));

    // to pass table columns
    const columns:any = [
        {
            name:"merchandiseName",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { 
                    console.log(sortOrder);
                    let orderName = (sortOrder.name == "merchandiseName") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                            <a className="sort-by">Merchandise</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let fieldValues = JSON.parse(value);
                    let userName:string = fieldValues[0].givenName;
                    return (
                        <div className="pointer">
                            <img className={fieldValues[0].profileImage ? 'profile-img merchandise' : 'profile-img'} src={fieldValues[0].profileImage ||  "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} /><label className='membername pointer'>{userName.replace("&"," ")}</label>
                        </div>
                    )
                }
            }
        },
        {
            name: 'transactionDate',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { 
                    let orderName = (sortOrder.name == "transactionDate")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Transaction Date</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    // console.log(value);
                    return (
                        // <div><label className="">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>
                        <div><label className="">{moment(parseInt(value)).format('MM/DD/YYYY')}</label></div>
                    )
                }
            }
        },
        {
            name:"transactionRefrenceCode",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "transactionRefrenceCode")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(2)} >
                            <a className="sort-by">Order No</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"tdcPatronName",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "tdcPatronName")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(3)} >
                            <a className="sort-by">Patron</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"tdcEmail",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "tdcEmail")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Patron Email</a>
                        </th>
                    )
                }
            }
        },
        {
            name:"merchandiseCategory",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseCategory")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(5)} >
                            <a className="sort-by">Category</a>
                        </th>
                    )
                }
            }
        },
        {
             name:"merchandisePointsCost",
             options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandisePointsCost")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(6)} >
                            <a className="sort-by">Points</a>
                        </th>
                    )
                }
             }
        },
        {
            name:"transactionQty",
            options:{
               filter:false,
               sortThirdClickReset: true,
               customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                   let orderName = (sortOrder.name == "transactionQty")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(7)} >
                            <a className="sort-by">Qty</a>
                        </th>
                    )
               }
            }
        },
        {
            name: 'userListingFulfillment',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "userListingFulfillment")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(8)} >
                            <a className="sort-by">Fulfillment Status</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    //console.log(value);
                    return (
                        <div><label className={value === true ? 'status-active' : 'status-inactive'}>{value === true ? "Delivered" : "Not Delivered"}</label></div>
                    )
                }
            }
        },
        {
            name: 'userListingFulfillmentDate',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { 
                    let orderName = (sortOrder.name == "userListingFulfillmentDate")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Fulfillment Date</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    // console.log(value);
                    return (
                        // <div><label className="">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>
                        <div><label className="">{moment(parseInt(value)).format('MM/DD/YYYY hh:mm:ss')}</label></div>
                    )
                }
            }
        },
        {
            name: 'fulfillmentEditDet',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "fulfillmentEditDet")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(9)} >
                            {/* <a className="sort-by">Edit</a> */}
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let fieldValues = JSON.parse(value);
                    let userListingId = fieldValues[0].userListingId;
                    // let fulfillmentStatus = fieldValues[0].fulfillmentStatus;
                    
                    return (
                        // <div><label className="">{value ? "true":"Intrue"}</label></div>
                        <label className=""><span onClick={() => {handleEditClickOpen(userListingId); setSingleFulfillmentData(tableMeta.tableData[tableMeta.rowIndex])}} data-id={userListingId} data-view="Edit" className="table-edit-icon"></span></label>
                    )
                }
            }
        },
        {
            name: 'userListingId',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "isEdit")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={8} onClick={() => updateDirection(10)} >
                            {/* <a className="sort-by">Edit</a> */}
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let userListingId = value;
                    //console.log(value);
                    return (
                        // <div><label className="">{value ? "Active":"InActive"}</label></div>
                        <label className=""><span data-id={userListingId} data-view="Edit" className=""></span></label>
                    )
                }
            }
        },
    ];

    // // to se various options for table columns and rows.
    const options:any = {
        filter: false,
        filterType: "dropdown",
        responsive:"standard",
        print:false,
        download : false,
        pagination : true,
        viewColumns : false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader : true,
        tableBodyHeight:'450px',
        search:true,
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        isRowExpandable: (dataIndex:any, expandedRows:any) => {
            // if (dataIndex === 1 || dataIndex === 2) return false;

            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            if (expandedRows.data.length > 1 && expandedRows.data.filter((d:any) => d.dataIndex === dataIndex).length === 0) return false;
            return true;
        },
        rowsExpanded: [], //[0, 1],
        onRowExpansionChange: (curExpanded:any, allExpanded:any, rowsExpanded:any) => console.log(curExpanded, allExpanded, rowsExpanded)
    }
    
    return (
        <div className="list-page manage-member-wrapper">
            <Grid container xs={12} sm={12}>
                <Grid  item sm={12} xs={12}>
                    <Paper className="table-wrapper">
                        <Grid container className="table-head-section" sm={12}>
                            <Grid item sm={8}>
                                <label className="table-heading">FULFILLMENT LIST</label>
                            </Grid>
                            <Grid  item sm={4} className="select-with-label align-right flx-align-center flx-end">
                            <Button className="btn btn-secondary" style={{ minWidth: "82px", }}>
                                <CSVLink filename={"fulfillment_list_export.csv"} data={arrayToCsv} className='import-btn-tile' style={{ color: "#8A8D9D", textDecoration: "none" }}>
                                    Export to CSV
                                </CSVLink>
                            </Button>
                            </Grid>
                            <Grid container className='cust-tble-hdr' xs={12} sm={12}>
                                <Grid item sm={12} xs={12}>
                                    <div className="table-container muidata-table cust-tbl-block">
                                        {loading ? (
                                            <Loader size="90px"/>
                                        ):(
                                            <MUIDataTable
                                                title={""}
                                                data={fulfillmentData}
                                                columns={columns}
                                                options={options}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>    
                </Grid>
            </Grid>
            
            {/* to open Add Merchandise Dialog */}
            {/* {open && <AddMerchandise onOpen={open} editId={fulfillmentId} viewTypeTxt={viewType} handleCloseClick={handleClose} />} */}
            <ViewFulfillmentListItem onOpen={open} editId={fulfillmentId} singleMerchData={singleFulfillmentData} viewTypeTxt={viewType} handleCloseClick={handleClose} />

            {/* To display error message */}
            <Snackbar open={openToast} autoHideDuration={5000}  onClose={handleClosed}>
                <Alert onClose={handleClosed} severity="success">{errorMsg}</Alert>
            </Snackbar>
        </div>
    )
}
