import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler , Controller} from 'react-hook-form';
import { useParams } from "react-router-dom";
import { Paper,
    Button,
    TextField,
    Input,
    Checkbox
    } from "@material-ui/core";
import './iPhone15Pro.css';

// Define a simple interface for the form data
interface FormData {
  url: string;
  email: string;
  field : string
  userName: string
}

interface Props {
  onParentRender: () => void;
}

interface ParamTypes {
  orgId: string;
}
export default function Loyalty({ onParentRender }: Props) {
  let { orgId } = useParams<ParamTypes>();

  const clubData  = [
  { id: "2985fbb8-a126-4051-bed1-21f7869f854f", name: "pirates", url:"https://beta-pirates.pointsville.com/?email="},
  { id: "f6cb89b4-2e13-4e03-b36c-6a50b9625e31", name: "astros", url:"https://beta-astros.pointsville.com/?email=" },
  { id: "12a02063-654b-4740-8788-f7c2e65133c1", name: "phillies", url:"https://beta-phillies.pointsville.com/?email=" },
  { id: "c0479d6a-1321-4b91-ba39-0ee43eb52b57", name: "nymets", url:"https://beta-nymets.pointsville.com/?email="}
  ]

  let url1:any = clubData.find(e => e.id === orgId)

  const [iframeSrc, setIframeSrc] = useState('');
  const { control, handleSubmit } = useForm<FormData>();
  const [showIframe, setShowIframe] = useState(false);



  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log(data.userName);
    setIframeSrc(url1.url + data.userName);
    setShowIframe(true);
  };

  if (showIframe) {
    
  }
  
//   useEffect(() => {
//     setIframeSrc(url1.url+"prem@pointsville.com")
//   },[url1]);
  
  return (
    <Paper>
    <div className="center-container">
        <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
        <label className="input-label">Enter the user email to preview</label>
        <div className="Controller">
        <Controller as={TextField}  type="text" name="userName" placeholder="example@gmail.com"  control={control} defaultValue="surendra@pointsville.com"  rules={{ required: 'Email is required' }}  className="Control" />
        
            <Button type="submit" variant="contained" color="primary" className="Button">
              Preview
            </Button>
            </div>
          </form>
      <div className="iphone-15-pro">
        <div className="iphone-screen">
            {
                showIframe ? 
                <iframe
              src={iframeSrc}
              width="409"
              height="670"
              title="WebPage Display"
            ></iframe>
            :
            <div className="iPhone-message">"Please enter the email address to see preview"</div>
            }
            
        </div>
      </div>
    </div>
    </Paper>
  );
}