import React ,{useEffect} from 'react';
import {
Button,
TextField,
Input,
Grid,
Paper,
Box,
AppBar,
Typography,
Toolbar,
Link,
Checkbox
} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import Amplify from 'aws-amplify';

import { ConfirmSignIn, ConfirmSignUp, ForgotPassword,AmplifyTheme, RequireNewPassword, SignIn, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';

import {  AmplifySignOut,AmplifyAuthenticator, AmplifySignUp, AmplifySignIn ,AmplifyForgotPassword} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import { useQuery, gql } from '@apollo/client';
import './App.css';
import './containers/login/login';
import Logo from './assets/logo-big.png';

interface SignInForm {
    userName: string;
    passWord: string;
    Checkbox: boolean;
    showPassword: boolean;
}
const themeStyle = {
    ...AmplifyTheme,
    signInButton: {
  color: "red",
  backgroundColor: "green"
    } 
  }

class SignInComp extends SignIn {
    constructor(props: any) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp","forgotPassword"];
      };

      render(){
        return (
        <div className="sign-wrapper">
            <div className="section-header">
                <img src={Logo} alt="logo" className="signin-logo-img"/>
            </div>
            <AmplifyAuthenticator  >
                {/* <AmplifySignIn headerText="SIGN IN"  slot="sign-in" hideSignUp></AmplifySignIn> */}
                {/* <AmplifyForgotPassword headerText="FORGOT PASSWORD" slot="forgot-password"></AmplifyForgotPassword> */}
                <AmplifySignUp 
                    headerText="SIGN UP"  
                    slot="sign-up"           
                    formFields={[
                                {
                                  type: "name",
                                  label: "Name",
                                  placeholder: "Name",
                                  inputProps: { required: true, autocomplete: "name" },
                                },
                                { type: "username" },
                                { type: "password" },
                                { type: "email" }
                              ]}
                  ></AmplifySignUp>
            </AmplifyAuthenticator>
        </div>
        )
      }
}


export default SignInComp;
