import React,{useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { useQuery, gql } from '@apollo/client';
import MUIDataTable from "mui-datatables";
import Loader from '../../components/Loader/loader';
import Grid from '@material-ui/core/Grid';
import DropdownSelect from '../../components/Dropdown/dropdownselect';
import Button from "@material-ui/core/Button";
import moment from 'moment';
import '../../constant.css';
import './manageuser.css';


interface MemberInventory {
    id: string;
    promoType: string;
}

interface MemberInventoryData {
    memberInventory: MemberInventory[];
}

interface MemberInventoryVars {
    year: number;
}

//   export const GET_MEMBER_QUERY = gql`
//     query GetUserData {
//         getUser{
//             id
//             user
//             username
//             email
//             phoneNumber
//             isActive
//             walletId
//             walletPoints
//             balance
//             createdAt
//             tdc_patronid
//             tdc_walletbalance

//         }
//     }
// `;

export const GET_MEMBER_QUERY = gql`
query GetUserByOrgData {
    getUserByOrg{
        id
        user
        username
        email
        phoneNumber
        isActive
        walletId
        createdAt
        tdc_patronid
        tdc_walletbalance

    }
}
`;

export default function ManageMembers() {
   
    const history = useHistory();
    const {data, loading, refetch} = useQuery<any>(GET_MEMBER_QUERY);
    const [total,setTotal] = useState(0);
    const [active,setActive] = useState(0);
    const [inActive,setInActive] =  useState(0);

    const memberprofileClick = (userId:string) => {
        history.push({pathname:'/memberprofile',state:userId});
    }

    const columns:any = [
        {
            name:"user",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { console.log(sortOrder);
                    let orderName = (sortOrder.name == "user")?sortOrder.direction:"";
                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                      <a className="sort-by">Member Name</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let fieldValues = JSON.parse(value);
                    let userName:string = fieldValues.givenName;
                    return (<div className="pointer" onClick={() => memberprofileClick(fieldValues.username)}><img className='profile-img pointer' src={fieldValues.profileImage ||  "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} /><label className='membername pointer'>{userName.replace("&"," ")}</label></div>)
                }
            }
        },
        {
            name:"email",
            options:{
                filter:false,
                sortThirdClickReset: true,
                
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "email")?sortOrder.direction:"";
                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                      <a className="sort-by">Email Id</a>
                    </th>
                    )
                }
            }
        },
        {
             name:"phoneNumber",
             options:{
                filter:false,
                sortThirdClickReset: true,
                
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "phoneNumber")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                      <a className="sort-by">Phone Number</a>
                    </th>
                    )
                }
             }
        },
        // {
        //     name:"tdc_patronid",
        //     options:{
        //         filter:false,
        //         sortThirdClickReset: true,
                
        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
        //             let orderName = (sortOrder.name == "tdc_patronid")?sortOrder.direction:"";

        //             return(
        //             <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
        //               <a className="sort-by">TDC Patron Id</a>
        //             </th>
        //             )
        //         }
        //     }
        // },
        {
            name:"tdc_walletbalance",
            options:{
                filter:false,
                sortThirdClickReset: true,
                
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "tdc_walletbalance")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                      <a className="sort-by">TDC Wallet Balance</a>
                    </th>
                    )
                }
            }
        },
        // {
        //     name:"walletId",
        //     options:{
        //         filter:false,
        //         sortThirdClickReset: true,
                
        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
        //             let orderName = (sortOrder.name == "walletId")?sortOrder.direction:"";

        //             return(
        //             <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
        //               <a className="sort-by">Wallet ID</a>
        //             </th>
        //             )
        //         }
        //     }
        //  }, 
        //  {
        //     name:"walletPoints",
        //     options:{
        //         filter:false,

        //         sortThirdClickReset: true,
                
        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
        //             let orderName = (sortOrder.name == "walletPoints")?sortOrder.direction:"";

        //             return(
        //             <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
        //               <a className="sort-by">Wallet Points</a>
        //             </th>
        //             )
        //         }
        //     }
        // },
        // {
        //     name:"balance",
        //     options:{
        //         filter:false,

        //         sortThirdClickReset: true,
                
        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { 
        //             let orderName = (sortOrder.name == "balance")?sortOrder.direction:"";

        //             return(
        //             <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
        //               <a className="sort-by">$ Balance</a>
        //             </th>
        //             )
        //         }
        //     }

        // },
        {
            name: 'createdAt',
            label:"Member Since",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => { 
                    let orderName = (sortOrder.name == "createdAt")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(6)} >
                      <a className="sort-by">Member Since</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    console.log(value);
                    return (<div><label className="membername">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>)
                }
            }
        },
        {
            name: 'isActive',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "isActive")?sortOrder.direction:"";
                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(7)} >
                      <a className="sort-by">Status</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    //console.log(value);
                return (<div><label className="membername">{value ? "Active":"InActive"}</label></div>)
                }
            }
        }
            
        ];
  
const options:any = {
    filter: false,
    filterType: "dropdown",
    responsive:"standard",
    print:false,
    download : false,
    pagination : false,
    viewColumns : false,
    selectableRowsHideCheckboxes: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: "none",
    selectableRowsHeader: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    fixedHeader : true,
    tableBodyHeight:'450px',
    search:false,
  }

    useEffect(() => {   
        // refetch();
        if(!loading){   
            console.log(data.getUserByOrg);
            const activeUser = data.getUserByOrg.filter( (user:any) => user.isActive === true);
            setActive(activeUser.length);
            const inActiveUser = data.getUserByOrg.filter( (user:any) => user.isActive === false);
            setInActive(inActiveUser.length);
            setTotal(data.getUserByOrg.length);
        }
    },[data]);

    return (
    <div className="list-page manage-member-wrapper">
        <h2 className="page-title">MANAGE MEMBERS</h2>
        <Grid container xs={12} sm={12}>
            <div>
                <Grid container className="item-status manage-member-counts" xs={12}>
                        <Grid item sm={2} xs={2}>
                            <span className="data-bold total-member">{total}</span><label className="sub-caption">Total Member</label>
                        </Grid>
                        <Grid item sm={2} xs={2}>
                            <span className="data-bold active-member">{active}</span><label className="sub-caption">Active Member</label>
                        </Grid>
                        <Grid item sm={2} xs={2}>
                            <span className="data-bold inactive-member">{inActive}</span><label className="sub-caption">Inactive Member</label>
                        </Grid>
                        {/* <Grid item sm={1} xs={2}>
                            <span className="data-bold kyc-pending" >3000</span><label className="sub-caption">KYC Pending</label>
                        </Grid> */}
                        {/* <Grid item sm={1} xs={2}>
                            <span className="data-bold kyc-rejected">3000</span><label className="sub-caption">KYC Rejected</label>
                        </Grid> */}
                    </Grid>
            </div>
                <Grid  item sm={12} xs={12}>
                    <Paper className="table-wrapper">
                    {/* <Grid container sm={12} className="mrgb-20">
                        <Grid item sm={8}>
                            <label className="table-heading">MEMBER LIST</label>
                        </Grid>
                        <Grid  item sm={4} className="select-with-label">
                            <label>Sort By</label>
                            <DropdownSelect/>
                        </Grid>
                    </Grid>
                    <TableContainer className="table-container" style={{ height: 450, width: '100%' }} component={Paper}>
                        <Table stickyHeader className="" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Member Name</TableCell>
                                    <TableCell align="left">Email Id</TableCell>
                                    <TableCell align="left">Phone Number</TableCell>
                                    <TableCell align="left">Wallet ID</TableCell>
                                    <TableCell align="left">Wallet Points</TableCell>
                                    <TableCell align="left">$ Balance</TableCell>
                                    <TableCell align="left">Member Since</TableCell>
                                    {/* <TableCell align="left">KYC</TableCell> 
                                    <TableCell align="left">Status</TableCell>
                                </TableRow>
                            </TableHead> 
                            { (loading) ? ( <p>...Loading</p>) : (
                            <TableBody>
                            {data.getUserByOrg.map((user:any) => (
                                <TableRow className={user.isActive?"enable-row":"disabled-row"} onClick={() => {user.isActive && memberprofileClick(user.username)}} >
                                <TableCell className="label-parent" padding="none" >
                                    <img className="profile-img " src={user.profileImage || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} />
                                    <label className="membername">{user.givenName}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <label className="">{user.email}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                            <label className="">{user.phoneNumber}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                            <label className="">{user.walletId}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <label className="">{user.userPrice}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                            <label className="">${user.walletBalance}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <label className="">{moment(parseInt(user.createdAt)).format('D MMM YYYY HH:MM')}</label>
                                </TableCell>
                                {/* <TableCell className="label-parent" padding="none" >
                                       {(row.kycStatus == "pending") ? <Chip className="status pending" label={row.kycStatus} variant="outlined" />:''}
                                </TableCell> 
                                <TableCell className="label-parent" padding="none" >
                                    <label className="">{user.isActive? "Active":"InActive"}</label>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                            )}
                        </Table>
                    </TableContainer> */}
                    <div className="table-container muidata-table">
                    {loading 
                        ? ( 
                            <Loader size="90px"/>
                        ):(
                            <MUIDataTable
                                title={"MEMBER LIST"}
                                data={data.getUserByOrg}
                                columns={columns}
                                options={options} /> 
                        )
                    }
                    </div>
                </Paper>    
            </Grid>
        </Grid>
    </div>
    )
}
