import React,{useState,useEffect} from 'react';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useHistory ,useParams} from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import DropdownSelect from '../../components/Dropdown/dropdownselect';
import Button from "@material-ui/core/Button";
import '../../constant.css';
import '../transaction/transactions.css';
import './sponsorList.css';
import SponsorEmty from '../../assets/sponsor-empty@2x.svg';
import SponsorLogo from '../../assets/sponsor-logo.png';
import Emirates from '../../assets/emirates.png';
import Spark from '../../assets/spark.png';
import Omega from '../../assets/omega.png';
import CreateSponsor from './createsponsor/createsponsor';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';

interface Props {
    onParentRender: () => void,
  }
interface SponsorInventory {
    id: string;
    sponsor_name: string;
    contact_name : string;
    contact_email : string;
}

interface SponsorInventoryData {
    sponsorInventory: SponsorInventory[];
}

interface SponsorInventoryVars {
    year: number;
}
interface ParamTypes {
    orgId: string;
}


interface IFormAddClub {
    sponsorLogo: string;
    companyName: string;
    pointRequest: string;
    contactName: string;
    contactMail : string;
    contactPhone : string;
    contactAddress : string;
}



export const GET_SPONSOR_QUERY = gql`
query GetSponsorInventory ($clubId: String!) {
    getSponsorWithOrgId (clubId: $clubId){
    id
    sponsorName
    sponsorLogo
    pointRequest
    contactName
    contactEmail
    contactPhone
    contactAddress
    promotions
    availablePoints
    since
  }
}
`;


export default function SponsorList({onParentRender}:Props) {
    let { orgId } = useParams<ParamTypes>();
    
    const {refetch, loading, data } = useQuery<any>(
        GET_SPONSOR_QUERY,{variables : {clubId : orgId}}
    );

    
    
       console.log(data);
    const [open, setOpen] = React.useState(false);
    const [sponsorInfo ,setSponsorInfo] = useState<any>({});
    const [viewType , setViewType] = React.useState<string | null>("Create");
    const [sponsorId , setSponsorId] = React.useState<any>("");
    //const [sponsorData, setSponsorData] = React.useState<any>({});
   
    
    const { register, handleSubmit, errors,control, reset, formState, setValue} = useForm();

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(true);
        setSponsorId(false);
        const targetElement = (event.target as HTMLButtonElement);
        setViewType(targetElement.getAttribute("data-view"));
    };
    const handleEditClickOpen = (id:string) => {
        //console.log(id);
        setOpen(true);
        setSponsorId(id);  
        console.log(id);
    };
    
    useEffect(() => {
        console.log("useEffect");
        refetch();
        onParentRender();
    },[open,data]);

    const handleClose = () => {
        setOpen(false);
    };
    
    const methods = useForm<IFormAddClub>();
    // const { handleSubmit, control, reset,errors } = methods;
    const onSubmit: SubmitHandler<IFormAddClub> = data =>
    console.log(JSON.stringify(data));
    return (
    <div className="list-page sponsor-list-wrapper">
        <Grid container xs={12} sm={12}>
            <Grid  item sm={12} xs={12}>
                <Paper className="table-wrapper">
                    <Grid container className="table-head-section" sm={12}>
                        <Grid item sm={8}>
                            <label className="table-heading">SPONSORS LIST</label>
                        </Grid>
                        <Grid  item sm={4} className="select-with-label align-right flx-align-center flx-end">
                            {/* <label className="select-label">Sort By</label>
                            <DropdownSelect/> */}
                            <button onClick={handleClickOpen} data-view="Create" className="btn btn-primary add-btn"><span data-view="Create" className="btn-icon"></span><label className="btn-caption"> Add SPONSOR</label></button>
                        </Grid>
                    </Grid>
                    <TableContainer className="table-container" style={{ height: 450, width: '100%' }} component={Paper}>
                        <Table stickyHeader className="" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Company Name</TableCell>
                                    <TableCell align="left">Address</TableCell>
                                    <TableCell align="left">Contact Email</TableCell>
                                    <TableCell align="left">Sponsors Since</TableCell>
                                    <TableCell align="left">Points Available / Total</TableCell>
                                    <TableCell align="left">Promotions</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            {loading ? (
                                <TableBody>
                                    <TableCell colSpan={6}>
                                        <Loader size="90px"/>
                                    </TableCell>
                                </TableBody>
                             ) : (  
                                
                            <TableBody>
                            {data.getSponsorWithOrgId.map((sponsor : any) =>  {
                                let availablePoints = sponsor.availablePoints || 0;
                                return(
                                <TableRow >
                                <TableCell className="label-parent company-details" padding="none" >
                                    <img className="profile-img" src={sponsor.sponsorLogo || SponsorEmty} />
                                     <label className="companyname">{sponsor.sponsorName}</label> 
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <label className="">{sponsor.contactAddress}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                     <label className="">{sponsor.contactEmail}</label> 
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <label className="">{moment(parseInt(sponsor.since)).format('MM/DD/YYYY')}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <label className="org-info-section"> 
                                        <span className="data-bold-with-icon">
                                            <span className="org-info-icon green"></span>
                                            <span className="data-bold">{availablePoints +"/"+sponsor.pointRequest}</span>
                                        </span> 
                                    </label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                            <label className="">{sponsor.promotions || 0}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <label className=""><span onClick={() => handleEditClickOpen(sponsor.id)} data-id={sponsor.id} data-view="Edit" className="table-edit-icon"></span></label>
                                </TableCell>
                                    
                                </TableRow>
                            )} )}
                            </TableBody>
                         )} 
                        </Table>
                    </TableContainer>
                </Paper>    
            </Grid>
        </Grid>
      
        <CreateSponsor  onOpen ={open} editId = {sponsorId} viewTypeTxt={viewType} handleCloseClick={handleClose}/>
    </div>
    )
}
