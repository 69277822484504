import React, { useEffect } from 'react';
import { TextField, Button, Paper, Grid } from '@material-ui/core';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import './emailPatron.css';  // Import the new CSS file

interface Props {
  onParentRender: () => void,
}

interface ParamTypes {
  orgId: string;
}

interface IFormInput {
  header: string;
  title: string;
  message: string;
}

export const GET_PROMO_QUERY = gql`
  query GetPromotionsData($orgId: String!) {
    getPromoWithOrgId(orgId: $orgId) {
      promoCodePrefix
      promoCodePoints
      promoType
      validity
      promoTotalCount
      sponsorId
      id
      pointsAvailable
      noPointsPerPromo
      sponsorLogo
      createdAt
      expired
    }
  }
`;

export const GET_HUNT_QUERY = gql`
  query getAllHuntsByOrg($orgId: String!) {
    getAllHuntsByOrg(orgId: $orgId) {
      id
      huntCodePrefix
      huntCodePoints
      validity
      validityDay
      orgId
      sponsorId
      sponsorLogo
      noPointsPerHunt
      pointsAvailable        
      isActive
      createdAt
    }
  }
`;

export const GET_EMAIL_TEMPLATE_QUERY = gql`
  query getEmailTemplateIdByOrgId($orgId: String!) {
    getEmailTemplateIdByOrgId(orgId: $orgId)
  }
`;

export const ADD_EMAIL_TEMPLATE_MUTATION = gql`
  mutation addEmailTemplate($data: EmailTemplateInput!) {
    addEmailTemplate(data: $data) {
      orgId
      header_message
      body_title
      body_message
    }
  }
`;

export const UPDATE_EMAIL_TEMPLATE_MUTATION = gql`
  mutation updateEmailTemplate($id: String!, $header_message: String!, $body_title: String!, $body_message: String!) {
    updateEmailTemplate(id: $id, header_message: $header_message, body_title: $body_title, body_message: $body_message) 
  }
`;

export default function EmailPatron({ onParentRender }: Props) {
  const [open, setOpen] = React.useState(false);
  const [promotionId, setPromotionId] = React.useState<any>();
  let { orgId } = useParams<ParamTypes>();
  const [promotionType, setPromotionType] = React.useState<any>("Email");

  const { loading, data, refetch } = useQuery(GET_PROMO_QUERY, { variables: { orgId: orgId } });
  const { loading: loadingHunt, data: dataHunt, refetch: refetchHunt } = useQuery(GET_HUNT_QUERY, { variables: { orgId: orgId } });

  const { control, handleSubmit, reset } = useForm<IFormInput>();

  const [addEmailTemplate] = useMutation(ADD_EMAIL_TEMPLATE_MUTATION);
  const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE_MUTATION);
  const { data: emailTemplateData, refetch: refetchEmailTemplate } = useQuery(GET_EMAIL_TEMPLATE_QUERY, { variables: { orgId: orgId } });

  const handleClickOpen = () => {
    setOpen(true);
    setPromotionId(false);
  };

  useEffect(() => {
    console.log("useEffect");
    refetch();
    onParentRender();
    refetchHunt();
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setPromotionType("");
    reset();
  };

  const handleEditClickOpen = (id: string, promotionType: string) => {
    setOpen(true);
    setPromotionId(id);
    setPromotionType(promotionType);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    const { header, title, message } = formData;
    const emailTemplateId = emailTemplateData?.getEmailTemplateIdByOrgId;

    if (emailTemplateId) {
      // Update the existing email template
      await updateEmailTemplate({
        variables: {
          id: emailTemplateId,
          header_message: header,
          body_title: title,
          body_message: message,
        },
      });
    } else {
      // Add a new email template
      await addEmailTemplate({
        variables: {
          data: {
            orgId,
            header_message: header,
            body_title: title,
            body_message: message,
          },
        },
      });
    }

    handleClose();
    refetchEmailTemplate();
  };

  return (
    <div className="form-container">
      <Paper className="form-paper">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="info-section email-info">
            <label className="section-heading sub-heading">Email Template Info</label>
            <Grid item sm={12}>
              <div className="input-group">
                <label className="input-label">Header Message</label>
                <Controller
                  as={TextField}
                  name="header"
                  control={control}
                  defaultValue=""
                  fullWidth
                  variant="outlined"
                  className="input-field"
                />
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-group">
                <label className="input-label">Title</label>
                <Controller
                  as={TextField}
                  name="title"
                  control={control}
                  defaultValue="Subject Line"
                  fullWidth
                  variant="outlined"
                  className="input-field"
                />
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-group textarea-group">
                <label className="input-label">Message</label>
                <Controller
                  as={TextField}
                  name="message"
                  control={control}
                  defaultValue="Enter the message here"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  className="input-field"
                />
              </div>
            </Grid>
            <Grid item sm={12} className="button-group">
              <Button type="submit" variant="contained" color="primary" className="submit-button">Save</Button>
            </Grid>
          </div>
        </form>
      </Paper>
    </div>
  );
}