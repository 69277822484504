import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import MUIDataTable from "mui-datatables";
import Loader from "../../components/Loader/loader";
import Grid from "@material-ui/core/Grid";
import DropdownSelect from "../../components/Dropdown/dropdownselect";
// import Button from "@material-ui/core/Button";
import moment from "moment";
import "../../constant.css";
import { CSVLink } from "react-csv";
import {
  Snackbar,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

export const GET_AWARDS_FANCASH = gql`
  query awardFanCash($data: AwardFanCashDetailInput!) {
    awardFanCash(data: $data) {
      totalCountCSVFile
      totalFailedErrorCount
      totalSuccessCount
      awardFanCashDetails {
        email
        amount
        start_dt
        expiry_dt
        currency
        segment_name
        status
      }
    }
  }
`;

export default function FanCashAward() {
  const inputRef: any = useRef(null);
  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [arrayToCsv, setArrayToCsv] = useState<any>([]);
  const [csvParsePayload, setCsvParsePayload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPatronBalance, setIsPatronBalance] = useState(false);
  const [csvFileName, setCsvFileName] = useState("");
  const [showButton, setShowbutton] = useState(false);
  const [totalCountCSVFile, setTotalCountCSVFile] = useState(0);
  const [totalFailedErrorCount, setTotalFailedErrorCount] = useState(0);
  const [totalSuccessCount, setTotalSuccessCount] = useState(0);
  const [Error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>("");
  const [errorType, setErrorType] = useState<any>("");

  const Alert: any = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const [getAwardFanCash] = useLazyQuery(GET_AWARDS_FANCASH, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      console.log("RESP", data?.awardFanCash);
      let exportCsvArray: any = [
        {
          0: "email",
          1: "amount",
          2: "start_dt",
          3: "expiry_dt",
          4: "currency",
          5: "segment_name",
          6: "status",
        },
      ];

      setTotalCountCSVFile(data.awardFanCash.totalCountCSVFile);
      setTotalFailedErrorCount(data.awardFanCash.totalFailedErrorCount);
      setTotalSuccessCount(data?.awardFanCash?.totalSuccessCount);
      exportCsvArray.push(data?.awardFanCash?.awardFanCashDetails);

      console.log("ARRY", arrayToCsvFunc(exportCsvArray));
      setCsvArray(data.awardFanCash.awardFanCashDetails);
      setArrayToCsv(arrayToCsvFunc(exportCsvArray));
      setIsLoading(false);
      setIsPatronBalance(true);

      setErrorType("success");
      setErrorMsg("Award points has been applied");
      setError(true);
    },
    onError: (error) => {
      console.log(" error", error);
      setIsLoading(false);
      setIsPatronBalance(false);
      setErrorType("error");
      setErrorMsg(error?.message);
      setError(true);
    },
  });

  const columns: any = [
    {
      name: "email",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customHeadRender: (
          columnMeta: any,
          updateDirection: any,
          sortOrder: any
        ) => {
          let orderName = sortOrder.name == "email" ? sortOrder.direction : "";
          return (
            <th
              className={"table-header-sorting " + orderName}
              style={{ cursor: "pointer" }}
              key={2}
              onClick={() => updateDirection(0)}
            >
              <a className="sort-by">Email</a>
            </th>
          );
        },
      },
    },
    {
      name: "amount",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customHeadRender: (
          columnMeta: any,
          updateDirection: any,
          sortOrder: any
        ) => {
          let orderName = sortOrder.name == "amount" ? sortOrder.direction : "";
          return (
            <th
              className={"table-header-sorting " + orderName}
              style={{ cursor: "pointer" }}
              key={2}
              onClick={() => updateDirection(1)}
            >
              <a className="sort-by">Award Amount</a>
            </th>
          );
        },
      },
    },
    {
      name: "start_dt",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customHeadRender: (
          columnMeta: any,
          updateDirection: any,
          sortOrder: any
        ) => {
          let orderName =
            sortOrder.name == "start_dt" ? sortOrder.direction : "";
          return (
            <th
              className={"table-header-sorting " + orderName}
              style={{ cursor: "pointer" }}
              key={2}
              onClick={() => updateDirection(2)}
            >
              <a className="sort-by">Start Date</a>
            </th>
          );
        },
      },
    },
    {
      name: "expiry_dt",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customHeadRender: (
          columnMeta: any,
          updateDirection: any,
          sortOrder: any
        ) => {
          let orderName =
            sortOrder.name == "expiry_dt" ? sortOrder.direction : "";
          return (
            <th
              className={"table-header-sorting " + orderName}
              style={{ cursor: "pointer" }}
              key={2}
              onClick={() => updateDirection(3)}
            >
              <a className="sort-by">Expiration Date</a>
            </th>
          );
        },
      },
    },
    {
      name: "currency",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customHeadRender: (
          columnMeta: any,
          updateDirection: any,
          sortOrder: any
        ) => {
          let orderName =
            sortOrder.name == "currency" ? sortOrder.direction : "";
          return (
            <th
              className={"table-header-sorting " + orderName}
              style={{ cursor: "pointer" }}
              key={2}
              onClick={() => updateDirection(4)}
            >
              <a className="sort-by">Currency</a>
            </th>
          );
        },
      },
    },
    {
      name: "segment_name",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customHeadRender: (
          columnMeta: any,
          updateDirection: any,
          sortOrder: any
        ) => {
          let orderName =
            sortOrder.name == "segment_name" ? sortOrder.direction : "";
          return (
            <th
              className={"table-header-sorting " + orderName}
              style={{ cursor: "pointer" }}
              key={2}
              onClick={() => updateDirection(5)}
            >
              <a className="sort-by">Segment Name</a>
            </th>
          );
        },
      },
    },
    {
      name: "status",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customHeadRender: (
          columnMeta: any,
          updateDirection: any,
          sortOrder: any
        ) => {
          let orderName = sortOrder.name == "status" ? sortOrder.direction : "";
          return (
            <th
              className={"table-header-sorting " + orderName}
              style={{ cursor: "pointer" }}
              key={2}
              onClick={() => updateDirection(6)}
            >
              <a className="sort-by">Status</a>
            </th>
          );
        },
      },
    },
    // {
    //   name: "errorMsg",
    //   options: {
    //     filter: false,
    //     sortThirdClickReset: true,
    //     customHeadRender: (
    //       columnMeta: any,
    //       updateDirection: any,
    //       sortOrder: any
    //     ) => {
    //       let orderName =
    //         sortOrder.name == "errorMsg" ? sortOrder.direction : "";
    //       return (
    //         <th
    //           className={"table-header-sorting " + orderName}
    //           style={{ cursor: "pointer" }}
    //           key={2}
    //           onClick={() => updateDirection(8)}
    //         >
    //           <a className="sort-by">Error Message</a>
    //         </th>
    //       );
    //     },
    //   },
    // },
  ];

  const options: any = {
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    download: false,
    pagination: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: "none",
    selectableRowsHeader: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    fixedHeader: true,
    tableBodyHeight: "450px",
    search: false,
  };

  const handleClick = (e: any) => {
    inputRef.current.click(fileChange);
  };

  const isValidFileUploaded = (file: any) => {
    const validExtensions = ["csv"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const fileChange = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    if (isValidFileUploaded(file)) {
      setCsvFile(file);
      setCsvFileName(e.target.files[0].name);
      //file is valid
    } else {
      setErrorType("error");
      setErrorMsg("Invalid file format only .csv file support");
      setError(true);
      //file is invalid
    }
  };
  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const processCSV = (str: any, delim = ",") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    const rows: any = str.slice(str.indexOf("\n") + 1).split("\n");
    const newArray = rows.map((row: any) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj: any, header: any, i: any) => {
        let h = header.replace(/^"|"$/g, "");
        obj[h] = values[i];
        console.log("OBJECT", obj);
        return obj;
      }, {});
      return eachObject;
    });

    setCsvArray(newArray);
  };
  function csvToJson(csv: any) {
    // \n or \r\n depending on the EOL sequence
    const lines = csv.split("\r\n");
    const delimeter = ",";
    const intArray: any = [
      "loyaltyProgramId",
      "patronAccountId",
      "numberOfPoints",
      "noteTypeId",
    ];
    const result = [];

    const headers = lines[0].split(delimeter);

    for (const line of lines) {
      const obj: any = {};
      const row = line.split(delimeter);

      for (let i = 0; i < headers.length; i++) {
        const header = headers[i];
        if (intArray.includes(header)) {
          obj[header] = parseInt(row[i]);
        } else {
          obj[header] = row[i];
        }
      }

      result.push(obj);
    }

    // Prettify output
    return result;
  }
  const arrayToCsvFunc = (data: any) => {
    const titleKeys = Object.keys(data[0]);

    const refinedData: any = [];

    // refinedData.push(titleKeys)

    data.forEach((item: any) => {
      refinedData.push(Object.values(item));
    });

    let csvContent = "";

    refinedData.forEach((row: any) => {
      csvContent += row.join(",") + "\n";
    });
    return csvContent;
  };
  const submit = () => {
    setIsPatronBalance(false);
    const input: any = csvFile;
    const reader = new FileReader();
    reader.onload = function (e: any) {
      const text = e.target.result;
      const csvArrayCount = csvToArray(text);
      if (csvArrayCount.length > 501) {
        setCsvArray([]);
        setErrorType("error");
        setErrorMsg(
          "This tool supports a maximum number of 500 users, please edit the CSV and re-upload the file."
        );
        setError(true);
        setShowbutton(false);
      } else {
        const array = csvToArray(text);
        // console.log("CSV TO ARY", csvToArray(text))
        setArrayToCsv(csvToArray(text));
        // console.log("csvtoArray", csvToArray(text))
        // document.write(JSON.stringify(array));
        setCsvArray(array.splice(1, array.length));
        const csvParse: any = csvToJson(text);
        // const csvParsePayload = csvParse.splice(1, csvParse.length);
        setCsvParsePayload(csvParse.splice(1, csvParse.length));
        // console.log("csvParsePayload", csvParsePayload);
        const jsonString = JSON.stringify(csvParse, null, 2);
        // get awards api call
        setShowbutton(true);
      }
    };

    reader.readAsText(input);

    function csvToArray(str: any, delimiter = ",") {
      let array: any = [];
      str.split("\r\n").map(function (line: any, index: number) {
        const splitData = line.split(delimiter);
        array.push({
          email: splitData[0],
          amount: splitData[1],
          start_dt: splitData[2],
          expiry_dt: splitData[3],
          currency: splitData[4],
          segment_name: splitData[5],
          status: "",
        });
      });
      return array;
    }
  };

  const submitAward = () => {
    setIsLoading(true);
    getAwardFanCash({
      variables: {
        data: {
          awardDetailObject: csvParsePayload,
        },
      },
    });
  };
  useEffect(() => {
    if (csvFile) {
      submit();
    }
  }, [csvFile]);
  return (
    <div className="list-page manage-member-wrapper">
      <h2 className="page-title">Bulk Award FanCash to Users</h2>
      <h4 className="sub-caption">
        This tool will award the FanCash to the Users by uploading your comma
        separated file.
      </h4>
      <h4 className="sub-caption">
        The file is expected to have
        email,amount,start_dt,expiry_dt,currency,segment_name fields.
      </h4>
      <h4 className="sub-caption">
        Please
        <a href="https://pv-images-alpha.s3.us-west-2.amazonaws.com/bulk-award-points.csv">
          download
        </a>
        example file for reference.
      </h4>
      <h4
        className="sub-caption"
        style={{ fontWeight: "bold", textDecoration: "underline" }}
      >
        *Note: Please upload csv file with not more than 200 Users at a time.
      </h4>
      {isPatronBalance ? (
        <div>
          <Grid container className="item-status manage-member-counts" xs={12}>
            <Grid item sm={2} xs={2}>
              <span className="data-bold total-member">
                {totalCountCSVFile && totalCountCSVFile > 0
                  ? totalCountCSVFile
                  : 0}
              </span>
              <label className="sub-caption">Users in CSV Loaded</label>
            </Grid>
            <Grid item sm={2} xs={2}>
              <span className="data-bold inactive-member">
                {totalSuccessCount && totalSuccessCount > 0
                  ? totalSuccessCount
                  : 0}
              </span>
              <label className="sub-caption">Success</label>
            </Grid>
            <Grid item sm={2} xs={2}>
              <span className="data-bold active-member">
                {totalFailedErrorCount && totalFailedErrorCount > 0
                  ? totalFailedErrorCount
                  : 0}
              </span>
              <label className="sub-caption">Errors</label>
            </Grid>
          </Grid>
        </div>
      ) : null}
      <Grid container xs={12} sm={12}>
        <Grid item sm={12} xs={12}>
          <Paper className="table-wrapper">
            <div className="table-container muidata-table">
              <div
                className="color-code-container"
                style={{ overflow: "hidden" }}
              >
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Grid style={{ width: "20%" }}>
                    <div>
                      <FormControl
                        style={{
                          bottom: "20px",
                          marginTop: "58px",
                        }}
                      >
                        <InputLabel
                          style={{
                            marginLeft: "10px",
                            fontSize: 15,
                            display: "flex",
                            justifyContent: "center",
                            bottom: "20px",
                          }}
                        >
                          {!csvFileName ? "Upload CSV file" : csvFileName}
                        </InputLabel>
                        <OutlinedInput
                          disabled
                          id="API-KEY"
                          style={{
                            top: "11px",
                            height: "41px",
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <Button
                                onClick={handleClick}
                                style={{
                                  backgroundColor: "#2b2e4c",
                                  color: "white",
                                  fontSize: 10,
                                  height: "24px",
                                }}
                              >
                                <input
                                  type="file"
                                  ref={inputRef}
                                  accept=".csv"
                                  style={{ display: "none" }}
                                  id="csvFile"
                                  onChange={fileChange}
                                  hidden
                                />
                                Upload
                              </Button>
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      <Snackbar
                        open={Error}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity={errorType}>
                          {errorMsg}
                        </Alert>
                      </Snackbar>
                    </div>
                  </Grid>
                  <Grid
                    style={{
                      marginTop: "55px",
                    }}
                  >
                    {showButton ? (
                      isLoading ? (
                        <Button
                          className="btn btn-primary add-btn"
                          style={{ minWidth: "82px" }}
                        >
                          Loading&nbsp;&nbsp;
                          <CircularProgress color="inherit" size={15} />
                        </Button>
                      ) : (
                        <Button
                          className="btn btn-primary add-btn"
                          style={{ minWidth: "82px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (csvFile) submitAward();
                          }}
                        >
                          Award FanCash
                        </Button>
                      )
                    ) : null}
                  </Grid>
                  {showButton ? (
                    <>
                      <Grid style={{ marginTop: "58px", marginLeft: "60%" }}>
                        <Button
                          className="btn btn-secondary"
                          style={{ minWidth: "82px" }}
                        >
                          <CSVLink
                            filename={"exported_csv.csv"}
                            data={arrayToCsv}
                            style={{ color: "#8A8D9D", textDecoration: "none" }}
                          >
                            Export to CSV
                          </CSVLink>
                        </Button>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </div>
              {loading ? (
                <Loader size="90px" />
              ) : (
                <MUIDataTable
                  title={"Bulk Award FanCash to Users"}
                  data={csvArray}
                  columns={columns}
                  options={options}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
