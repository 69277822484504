import React from "react";
import {
Button,
TextField,
Input,
Grid,
Paper,
Box,
AppBar,
Typography,
Toolbar,
Link,
Checkbox
} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


import { useForm, Controller, SubmitHandler } from "react-hook-form";
import './login.css';


interface SignInForm {
    userName: string;
    passWord: string;
    Checkbox: boolean;
    showPassword: boolean;
}

export default function Login() {
    //const [values, setValues] = React.useState<SignInForm>({passWord: '',showPassword: false});
    const methods = useForm<SignInForm>();
    const { handleSubmit, control, reset } = methods;
    const onSubmit: SubmitHandler<SignInForm> = data =>
    console.log(JSON.stringify(data));

    
    return (
        <div className="login-container">
            <Grid container spacing={0} justify="center" direction="row">
                <Box width="40%">
                    <div className="header-brand"></div>
                    <Paper  elevation={3}>
                        <label className="form-title">Sign In</label>
                        <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-group">
                                <label className="input-label" >Email</label>
                                <Controller as={TextField}  type="text" name="userName" placeholder="example@gmail.com" className="sign-form-fields" control={control} defaultValue="" />                            
                            </div>
                            <div className="input-group">
                                <label className="input-label" >Password</label>
                                <Controller as={TextField} type="password" name="passWord" placeholder="Password Here" className="sign-form-fields" control={control} defaultValue="" />                            
                            </div>
                            <div className="input-group splited-group">
                                <label className="forgot-password">Forgot Password?</label>
                                <FormControlLabel control= {
                                                <Controller
                                                        name="Checkbox"
                                                        control={control}
                                                        defaultValue={true}
                                                        rules={{ required: true }}
                                                        render={props => <Checkbox {...props} />} // props contains: onChange, onBlur and value
                                                    />}
                                    label="Remember me"
                                />
                            </div>
                            <div className="input-group submit-btn">
                                <input type="submit" name="submit" className="form-submit-btn"/>
                            </div>
                        </form>
                    </Paper>
                    <div className="signup-section">
                        <div className="signup-header">
                            <span className="headline-divider"></span>
                            <label className="form-title">Sign Up</label>
                        </div>
                        <div className="signup-socialicon-section">
                            <ul className="social-icons">
                                <li><label className="">G </label></li>
                                <li><label className="">G</label></li>
                                <li><label className="">G</label></li>
                                <li><label className="">G</label></li>
                                <li><label className="">G</label></li>
                            </ul>
                        </div>
                    </div>
                </Box>    
            </Grid>
        </div>
    );
}
