import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField/TextField";
import "./ExternalApi.css";
import { Box } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import Tooltip from "@mui/material/Tooltip";
import { gql, useMutation } from "@apollo/client";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
import { Snackbar, CircularProgress } from "@material-ui/core";
import "./ExternalApi.css";

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

interface Props {
  reloadList: () => void;
}

interface ParamTypes {
  orgId: string;
}

const CREATE_EXTERNAL_API = gql`
  mutation createExternalApiKey($apiKeyName: String!, $orgId: String!) {
    createExternalApiKey(apiKeyName: $apiKeyName, orgId: $orgId) {
      apiKey
      clientSecret
    }
  }
`;
export default function GenerateApiKey({ reloadList }: Props) {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const theme = useTheme();

  const [openCopy, setOpenCopy] = React.useState(false);
  const [copyFeedback, setCopyFeedback] = React.useState({
    apikey: "",
    clientSecret: "",
  });
  const [alertType, setAlertType] = useState<any>("");
  const [error, setError] = useState<any>("");
  const [apiKeyName, setApiKeyName] = React.useState("");
  const [apiKeyResponse, setApiKeyResponse] = React.useState({
    apiKey: " ",
    clientSecret: "",
  });
  const [apiKeyAlert, setApiKeyAlert] = useState<any>(false);
  let { orgId } = useParams<ParamTypes>();
  const [openToast, setOpenToast] = React.useState<any>(false);
  const [showButtonLoading, setShowButtonLoading] = React.useState<any>(false);
  const [load, setLoad] = useState(false);
  const [error1, setError1] = useState("");
  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleCloseCopy = () => {
    setOpenCopy(false);
  };

  const copyToClipBoard = async (text: any, id?: number) => {
    try {
      await navigator.clipboard.writeText(text);
      const msg = "Copied Successfully";
      setCopyFeedback(
        id
          ? { apikey: msg, clientSecret: "" }
          : { apikey: "", clientSecret: msg }
      );
      setOpenCopy(true);
    } catch (err) {
      console.log("INSIDE ", { openCopy }, err);
      setOpenCopy(true);
    }
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [createExternalApiKey] = useMutation(CREATE_EXTERNAL_API, {
    // after updating the post, we go to the home page
    onCompleted: (response) => {
      setApiKeyResponse(response.createExternalApiKey);
      setShowButtonLoading(false);
      setLoad(false);
      setOpen2(true);
      setOpen(false);
      reloadList();
      setAlertType("success");
      setError("Api created");
      setOpenToast(true);
      setApiKeyName("");
    },
    onError: (data) => {
      setShowButtonLoading(false);
      setLoad(false);
      setOpen2(false);
      reloadList();
      setAlertType("error");
      setError(data.message);
      setOpenToast(true);
    },
  });
  const handleClickOpen = () => {
    setApiKeyName("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setApiKeyName(value);

    if (value === "") {
      setError("Api Key can not be empty");
      setApiKeyAlert(true);
    } else {
      setError("");
      setApiKeyAlert(false);
    }
    const regex = /^[a-zA-Z0-9\-/]+$/;
    if (value.length && regex.test(value) === false) {
      setApiKeyAlert(true);
      setError("Api Key Name only allows alphabet, numbers and -");
      return false;
    }
    if (value.length && regex.test(value) === true) {
      setApiKeyAlert(false);
      setError("");
      return true;
    }
  };
  const handleSubmit = () => {
    setShowButtonLoading(true);
    if (apiKeyName === "") {
      setError1("Api Key can not be empty");

      setError("Api Key can not be empty");
      setShowButtonLoading(false);
      setApiKeyAlert(true);
      setAlertType("error");
      setOpenToast(true);
    } else {
      setError1("");
      setError("");
      setApiKeyAlert(false);
      createExternalApiKey({
        variables: {
          apiKeyName: apiKeyName,
          orgId: orgId,
        },
      });
    }
  };

  const handleCloseD2 = () => {
    setOpen2(false);
    setOpen(false);
  };

  const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setLoad(false);
  };
  return (
    <div>
      <Button
        color="primary"
        className="btn btn-primary button"
        sx={{ padding: "12px 30px" }}
        onClick={handleClickOpen}
      >
        Generate api Keys
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <div style={{ marginTop: "-30px" }}>
            <h3>Generate Api Keys </h3>
            <h5>Key Name</h5>
            <TextField
              required
              id="outlined-required"
              fullWidth
              placeholder="Eg: API Key Name"
              inputProps={{
                style: {
                  height: "10px",
                },
              }}
              onChange={(e) => {
                handleChange(e);
              }}
              value={apiKeyName}
            />
          </div>
        </DialogContent>

        <DialogContent>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "-10px",
              background: "#eee",
              borderRadius: 3,
              padding: "10px",
            }}
          >
            <DialogContentText variant="caption" sx={{ color: "black" }}>
              Note: <br></br>
              1. Do not disclose you API Key to anyone to avoid data loss.
              <br></br>
              2. API key will only be shown once when they are created.
            </DialogContentText>
          </Box>
        </DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0px 25px",
          }}
        >
          <Button
            className="btn btn-secondary generate-apikey-btn"
            onClick={handleClose}
          >
            cancel
          </Button>
          <Button
            color="primary"
            className="btn btn-primary generate-apikey-btn"
            onClick={handleSubmit}
            autoFocus
          >
            {showButtonLoading ? (
              <CircularProgress size={23} color="inherit" />
            ) : (
              "Create"
            )}
          </Button>
        </div>
      </Dialog>
      {/*================================DIALOG BOX 2 (ON CLICK SUBMIT BUTTON )================================================================*/}
      <Dialog
        fullScreen={fullScreen}
        open={open2}
        onClose={handleCloseD2}
        aria-labelledby="responsive-dialog-title"
        sx={{ w: 5 }}
        fullWidth
      >
        <DialogContent sx={{}}>
          <div style={{ marginTop: "-30px" }}>
            <h3>Store Api Keys</h3>
            <h5> API Key </h5>
            <FormControl
              fullWidth
              style={{
                bottom: "20px",
              }}
            >
              <InputLabel
                style={{
                  height: "10px",
                  marginLeft: "10px",
                }}
                htmlFor="API-KEY"
              >
                {apiKeyResponse.apiKey.substring(0, 50)}...
              </InputLabel>
              <OutlinedInput
                id="API-KEY"
                disabled
                style={{
                  top: "10px",
                  height: "40px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      open={openCopy}
                      onClose={handleCloseCopy}
                      title={copyFeedback.apikey}
                      leaveDelay={500}
                      arrow
                      placement="top"
                    >
                      <IconButton
                        onClick={() =>
                          copyToClipBoard(apiKeyResponse.apiKey, 1)
                        }
                        edge="end"
                      >
                        <FileCopy
                          color="action"
                          style={{ width: "25px" }}
                        ></FileCopy>
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
              <p
                style={{
                  marginBottom: -10,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              >
                <b>Note:</b> Make sure that you Copy and Save it at Safe place .
              </p>
            </FormControl>
            <h5> Client Secret Key </h5>
            <FormControl
              fullWidth
              style={{
                bottom: "20px",
              }}
            >
              <InputLabel
                style={{
                  height: "10px",
                  marginLeft: "10px",
                }}
                htmlFor="API-KEY"
              >
                {apiKeyResponse.clientSecret.substring(0, 50)}...
              </InputLabel>
              <OutlinedInput
                id="API-KEY"
                disabled
                style={{
                  top: "10px",
                  height: "40px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      open={openCopy}
                      onClose={handleCloseCopy}
                      title={copyFeedback.clientSecret}
                      leaveDelay={500}
                      arrow
                      placement="top"
                    >
                      <IconButton
                        onClick={() =>
                          copyToClipBoard(apiKeyResponse.clientSecret)
                        }
                        edge="end"
                      >
                        <FileCopy
                          color="action"
                          style={{ width: "25px" }}
                        ></FileCopy>
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
              <p
                style={{
                  marginBottom: -10,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              >
                <b>Note:</b> Make sure that you Copy and Save it at Safe place .
              </p>
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button className="btn btn-secondary" onClick={handleCloseD2}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openToast} autoHideDuration={3000} onClose={handleClosed}>
        <Alert onClose={handleClosed} severity={alertType}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}
