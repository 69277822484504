import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import moment from 'moment'
import "moment-timezone"
import Snackbar from '@material-ui/core/Snackbar';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem, Switch } from "@material-ui/core";
import '../../../constant.css';
import './addmerchandise.css';
import { gql, useMutation, useQuery } from '@apollo/client';
import S3 from 'aws-s3';
//import { s3config } from "../../../s3config";
import { config, zoneList } from '../../../config';
import Loader from '../../../components/Loader/loader';
import SponsorEmty from '../../../assets/sponsor-empty@2x.svg';

import SponsorLogo from '../../../assets/sponsor-logo.png';

import MerchandiseLogo from '../../../assets/ic-default-logo.svg';
import PlusIcon from '../../../assets/ic-plus.svg';

import { stringify } from 'querystring';
import { AnyCnameRecord } from 'dns';

interface Props {
    onOpen: boolean,
    handleCloseClick: () => void,
    viewTypeTxt?: any,
    singleMerchData?: any,
    editId?: any
}

interface ParamTypes {
    orgId: string;
}

interface IFormAddMerchandise {
    category: string,
    merchandiseName: string,
    sthExperience: string,
    pointsCost: string,
    description: string,
    emailVoucher: string,
    merFulfillment: string,
    merStatus: string
}

let defaultValues: any = {
    // selectedMerImg: '',
    category: 'Merch',
    merchandiseName: '',
    sthExperience: '',
    pointsCost: '',
    description: '',
    emailVoucher: 'YES',
    merFulfillment: 'YES',
    merStatus: 'ACTIVE'
}

// to get all STH Experience
const GET_STH_EXPERIENCE = gql`
    query eventsAllNames($orgId: String!){
        eventsAllNames(orgId: $orgId){
            eld_name
        }
    }
`;

// to get all STH Experience events list
const GET_STH_EXPERIENCE_EVENTS_LIST = gql`
    query eventsByNameDate($event_name: String!, $date: String!, $current_month: Boolean!, $orgId: String!) {
        eventsByNameDate(event_name: $event_name, date: $date, current_month: $current_month, orgId:$orgId){
            eld_id
            eld_name
            eld_date
            eld_starttime
            eld_endtime
            eld_location
            eld_city
            eld_country
            eld_event_id
            eld_availablecapacity
            eld_timezone_abbreviation
            eld_eventcost
        }
    }
`;

const CREATE_MERCHANDISE = gql`
    mutation addMerchandise($data: NewMerchandiseInput!) {
        addMerchandise(data: $data) {
            merchandise_id
            merchandise_name
        }
    }
`;

const UPLOAD_MERCH_IMAGE = gql`
mutation uploadMerchImage($imageData: String!, $fileNamePrefix: String!){
    uploadMerchImage(imageData: $imageData, fileNamePrefix: $fileNamePrefix)
}
`;

const MERCHANDISE_BY_ID = gql`
    query merchandiseById($merchandise_id: Int!,$merchandise_orgId: String!){
        merchandiseById(merchandise_id:$merchandise_id,merchandise_orgId:$merchandise_orgId){
        merchandise_id
        merchandise_imageurl
        merchandise_name
        merchandise_category
        merchandise_pointcost
        merchandise_numberoflisting
        merchandise_listings {
            ml_id
            ml_eldid
            ml_date
            ml_starttime
            ml_endtime
            ml_selectioncapacity
            ml_availablecapacity
            ml_location
            ml_city
            ml_country
            ml_event_id
            ml_timezone_abbreviation
            ml_eventcost
            ml_type_subtype
            ml_max_qty_per_user
        }
        merchandise_description
        merchandise_isemailvoucher
        merchandise_fulfillment
        merchandise_status
        }
    }
`;

const UPDATE_MERCHANDISE = gql`
    mutation updateMerchandise($merchandise_id: Int!,$data: UpdateMerchandiseInput! ){
        updateMerchandise(merchandise_id: $merchandise_id, data: $data ){
            merchandise_id
            merchandise_name
        }
    }
`;

const DEACTIVATE_MERCHANDISE_BY_ID = gql`
    mutation updateMerchandiseStatus($merchandise_id: Int!, $merchandise_status: String!){
        updateMerchandiseStatus(merchandise_id: $merchandise_id, merchandise_status: $merchandise_status)
    }
`;

const UPDATE_FULFILLMENT = gql`
    mutation updateFulfillmentStatus($merchandise_userlisting_mlid: Int!, $fulfillment_status: Boolean!){
        updateFulfillmentStatus(merchandise_userlisting_mlid: $merchandise_userlisting_mlid, fulfillment_status: $fulfillment_status)
    }
`;

export default function ViewFulfillmentListItem({ handleCloseClick, onOpen, viewTypeTxt, editId, singleMerchData }: Props) {
    const editMerchandiseId = editId > 0 ? editId : 0;
    const open = onOpen;

    const [category, setCategory] = useState<any>('Merch');

    const [cancelDisabled, setCancelDisabled] = useState<any>(true);

    const [statusType, setStatusType] = useState<any>('');
    const [isADClicked, setIsADClicked] = useState<any>(false);     // AD -> ActivateDeactivate

    const [loader, setLoader] = useState<any>(false);               // Loader icon

    let { orgId } = useParams<ParamTypes>();

    // Events display by month.
    const [displayMonth, setDisplayMonth] = React.useState<any>(moment().format('MMM YYYY'));                   // 'Mar 2021'
    const [getEventsByYearMonth, setGetEventsByYearMonth] = React.useState<any>(moment().format('YYYY-MM'));      // '2021-03'
    const [isCurrentMonth, setIsCurrentMonth] = React.useState<any>(true);
    const [pastdate, setPastDate] = useState<any>(true);

    // Merchandise Image useState values. 
    const [defaultLogoUrl, setDefaultLogoUrl] = useState<any>(MerchandiseLogo);         // Default logo
    const [merchandiseImgUrl, setMerchandiseImgUrl] = React.useState<any>('');          // Selected Merchandise Image
    const [plusIconUrl, setPlusIconUrl] = React.useState<any>(PlusIcon);                // Plus icon 
    const [merchandiseImgList, setMerchandiseImgList] = React.useState<any>([
        { idx: 0, imageUrl: '', isActive: false },
        { idx: 1, imageUrl: '', isActive: false },
        { idx: 2, imageUrl: '', isActive: false }
    ]);

    // STHExperience useState.
    const [sthExperienceList, setSthExperienceList] = React.useState<any>([]);

    // to call API to get all STHExperience list.
    const { loading: sthExpLoading, data: sthExpData, refetch: sthExpRefetch } = useQuery<any>(GET_STH_EXPERIENCE, { variables: { orgId: orgId } });

    // to reassign all events list to useState for internal business logic.
    const [eventsList, setEventsList] = React.useState<any>([]);
    const[singleMerchaImage, setSingleMerchaImage] = React.useState<any>('');
    const [selectedEventsList, setSelectedEventsList] = React.useState<any>([]);
    const [selectedMerchList, setSelectedMerchList] = React.useState<any>([
        { idx: Math.floor(100000 + Math.random() * 900000), type: '', subType: '', qty: '', maxQtyPerCust: '', isEventType: 'NEW' }
    ]);

    const [selSTHExperience, setSelSTHExperience] = React.useState<any>('');
    const { loading: eventsListLoading, data: eventsListData, refetch: eventsRefetch } = useQuery<any>(GET_STH_EXPERIENCE_EVENTS_LIST, { variables: { event_name: selSTHExperience, date: getEventsByYearMonth, current_month: isCurrentMonth, orgId:orgId } });

    const [confirmed, setConfirmed] = React.useState(false);
    const inputRef: any = useRef();

    const [errorMsg, setErrorMsg] = useState<any>("");
    const [openToast, setOpenToast] = React.useState<any>(false);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openCancelConfirm, setOpenCancelConfirm] = React.useState(false);

    const [confirmMessage, setConfirmMessage] = useState<any>("Are you sure with the Point Requested value?");

    const { loading, data, refetch } = useQuery<any>(MERCHANDISE_BY_ID, { variables: { merchandise_id: editMerchandiseId, merchandise_orgId: orgId } });

    const { register, handleSubmit, errors, control, reset, formState, setValue } = useForm<IFormAddMerchandise>({ defaultValues, shouldUnregister: false });

    // to open error messages 
    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // to clear all hooks values here before cancel the dialogue box
    const handleClearAndClose = () => {
        setConfirmed(false);
        setOpenConfirm(false);
        setOpenCancelConfirm(false)
        setOpenToast(false);

        setValue("sthExperience", '');
        setValue("category", 'Merch');
        setValue("merchandiseName", '');
        setValue("pointsCost", '');
        setValue("description", '');
        setValue("numberOfListing", 0);
        setValue("emailVoucher", 'YES');
        setValue("merFulfillment", 'YES');
        setValue("merStatus", 'ACTIVE');

        setMerchandiseImgList([
            { idx: 0, imageUrl: '', isActive: false },
            { idx: 1, imageUrl: '', isActive: false },
            { idx: 2, imageUrl: '', isActive: false }
        ]);

        setSelectedMerchList([{ idx: Math.floor(100000 + Math.random() * 900000), type: '', subType: '', qty: '', maxQtyPerCust: '', isEventType: 'NEW' }])

        setCategory('Merch')
        setDisplayMonth(moment().format('MMM YYYY'))
        setGetEventsByYearMonth(moment().format('YYYY-MM'));
        setIsCurrentMonth(true)
        setStatusType('')
        setIsADClicked(false)
        setLoader(false)
        setPastDate(true)
        setSthExperienceList([])
        setMerchandiseImgUrl('')
        setEventsList([])
        setSelectedEventsList([])
        setSelSTHExperience('')
        setCancelDisabled(true)

        handleCloseClick()
    }

    // to pre-fill all form data in edit.
    const fieldSetValueFunction = (defaultValue: any) => {

        if (viewTypeTxt === 'Create') {

            if (selSTHExperience === '') {
                setValue("sthExperience", '');
                // setValue("category", 'Merch');
                // setValue("pointsCost", '');
                setValue("description", '');
                setValue("numberOfListing", 0);
                setValue("emailVoucher", 'YES');
                // setValue("merFulfillment", 'YES');
                setValue("merStatus", 'ACTIVE');

                setSelectedEventsList([])
            }

            if (merchandiseImgUrl === '') {
                setMerchandiseImgList([
                    { idx: 0, imageUrl: '', isActive: false },
                    { idx: 1, imageUrl: '', isActive: false },
                    { idx: 2, imageUrl: '', isActive: false }
                ]);
            }
        } else {
            // to clear all active image flag before set new active image falg as true.
            let tempArr = defaultValues.merchandise_imageurl && defaultValues.merchandise_imageurl.length > 0 ? JSON.parse(defaultValues.merchandise_imageurl) : []
            for (let i = 0; i < tempArr.length; i++) {
                if (tempArr[i].isActive) {
                    setMerchandiseImgUrl(tempArr[i].imageUrl)
                }
            }

            setMerchandiseImgList(defaultValues.merchandise_imageurl && defaultValues.merchandise_imageurl.length > 0 ? JSON.parse(defaultValues.merchandise_imageurl) : [])

            // if (defaultValues.merchandise_category === 'Merch' || defaultValues.merchandise_category === 'Autographs') {
            if (defaultValues.merchandise_category !== 'Experiences') {
                setValue("merchandiseName", defaultValues.merchandise_name);
                setValue("sthExperience", '');
                setValue("pointsCost", defaultValues.merchandise_pointcost ? defaultValues.merchandise_pointcost.toString() : '');
                // setCategory('Merch')
            } else {
                setValue("merchandiseName", '');
                setValue("sthExperience", defaultValues.merchandise_name);
                // setCategory('')
            }

            setValue("category", defaultValues.merchandise_category);
            setValue("description", defaultValues.merchandise_description);
            setValue("numberOfListing", defaultValues.merchandise_numberoflisting);
            setValue("emailVoucher", defaultValues.merchandise_isemailvoucher ? 'YES' : 'NO');
            setValue("merFulfillment", defaultValues.merchandise_fulfillment ? 'YES' : 'NO');
            setValue("merStatus", defaultValues.merchandise_status);

            // If Merchandise category is selected as Merch or Autographs.
            // if (defaultValues.merchandise_category === 'Merch' || defaultValues.merchandise_category === 'Autographs') {
            if (defaultValues.merchandise_category !== 'Experiences') {
                if (defaultValues.merchandise_listings.length >= selectedMerchList.length) {
                    let tempSelectedMerchList = []
                    if (defaultValues.merchandise_listings && defaultValues.merchandise_listings.length > 0) {
                        for (let i = 0; i < defaultValues.merchandise_listings.length; i++) {

                            let tempTypeSubType = defaultValues.merchandise_listings[i].ml_type_subtype.split('||')

                            tempSelectedMerchList.push({
                                // to get and maintain the merch list data in update form.
                                idx: defaultValues.merchandise_listings[i].ml_eldid,
                                type: tempTypeSubType[0],
                                subType: tempTypeSubType[1],
                                qty: defaultValues.merchandise_listings[i].ml_availablecapacity,
                                maxQtyPerCust: defaultValues.merchandise_listings[i].ml_max_qty_per_user,

                                // to get db fileds here to pass in update merchandise for merch category
                                ml_eldid: defaultValues.merchandise_listings[i].ml_eldid,
                                ml_date: defaultValues.merchandise_listings[i].ml_date,
                                ml_starttime: defaultValues.merchandise_listings[i].ml_starttime,
                                ml_endtime: defaultValues.merchandise_listings[i].ml_endtime,
                                ml_selectioncapacity: defaultValues.merchandise_listings[i].ml_selectioncapacity,
                                ml_availablecapacity: defaultValues.merchandise_listings[i].ml_availablecapacity,
                                ml_location: defaultValues.merchandise_listings[i].ml_location,
                                ml_city: defaultValues.merchandise_listings[i].ml_city,
                                ml_country: defaultValues.merchandise_listings[i].ml_country,
                                ml_id: parseInt(defaultValues.merchandise_listings[i].ml_id),
                                isEventSelected: true,
                                isEventType: 'OLD',
                                ml_event_id: defaultValues.merchandise_listings[i].ml_event_id,
                                ml_timezone_abbreviation: defaultValues.merchandise_listings[i].ml_timezone_abbreviation,
                                ml_eventcost: defaultValues.merchandise_listings[i].ml_eventcost,
                                ml_type_subtype: defaultValues.merchandise_listings[i].ml_type_subtype,
                                ml_max_qty_per_user: defaultValues.merchandise_listings[i].ml_max_qty_per_user
                            })
                        }
                    }
                    setSelectedMerchList(tempSelectedMerchList)
                }
            } else {
                // to show selected events and their capacity in edit form.
                // if (selectedEventsList.length === 0 && defaultValues.merchandise_listings.length > selectedEventsList.length ) {
                if (defaultValues.merchandise_listings.length > selectedEventsList.length) {
                    let tempSelectedEventsList = []
                    if (defaultValues.merchandise_listings && defaultValues.merchandise_listings.length > 0) {
                        for (let i = 0; i < defaultValues.merchandise_listings.length; i++) {
                            tempSelectedEventsList.push({
                                eld_id: defaultValues.merchandise_listings[i].ml_eldid,
                                eld_date: defaultValues.merchandise_listings[i].ml_date,
                                eld_starttime: defaultValues.merchandise_listings[i].ml_starttime,
                                eld_endtime: defaultValues.merchandise_listings[i].ml_endtime,
                                eld_selectioncapacity: defaultValues.merchandise_listings[i].ml_selectioncapacity,
                                eld_availablecapacity: defaultValues.merchandise_listings[i].ml_availablecapacity,
                                eld_location: defaultValues.merchandise_listings[i].ml_location,
                                eld_city: defaultValues.merchandise_listings[i].ml_city,
                                eld_country: defaultValues.merchandise_listings[i].ml_country,
                                ml_id: parseInt(defaultValues.merchandise_listings[i].ml_id),
                                isEventSelected: true,
                                isEventType: 'OLD',
                                eld_event_id: defaultValues.merchandise_listings[i].ml_event_id,
                                eld_timezone_abbreviation: defaultValues.merchandise_listings[i].ml_timezone_abbreviation,
                                eld_eventcost: defaultValues.merchandise_listings[i].ml_eventcost
                            })
                        }
                    }
                    setSelectedEventsList(tempSelectedEventsList)
                }
            }
        }
    }

    // to reassign all events list to useState.
    const getAllEventsList = (objData: any) => {
        if (objData && objData.eventsByNameDate.length > 0) {
            let newEventsList = []
            // to clear all active image flag before set new active image flag as true.
            for (let i = 0; i < objData.eventsByNameDate.length; i++) {
                let isEventMatched = false
                let tempCapacity = objData.eventsByNameDate[i].eld_availablecapacity
                for (let j = 0; j < selectedEventsList.length; j++) {
                    if (objData.eventsByNameDate[i].eld_id === selectedEventsList[j].eld_id) {
                        isEventMatched = true
                        tempCapacity = selectedEventsList[j].eld_availablecapacity

                    }
                }
                newEventsList.push({
                    eld_id: objData.eventsByNameDate[i].eld_id, // "444",
                    eld_name: objData.eventsByNameDate[i].eld_name, // "STH Tailgate",
                    eld_date: objData.eventsByNameDate[i].eld_date, // "2021-02-12",
                    eld_starttime: objData.eventsByNameDate[i].eld_starttime, // "10:00:00+00",
                    eld_endtime: objData.eventsByNameDate[i].eld_endtime, // "12:00:00+00",
                    eld_location: objData.eventsByNameDate[i].eld_location, // "Heinz Field",
                    eld_city: objData.eventsByNameDate[i].eld_city, // "Pittsburg",
                    eld_country: objData.eventsByNameDate[i].eld_country, // "US",
                    eld_selectioncapacity: tempCapacity,
                    eld_availablecapacity: tempCapacity,
                    isEventSelected: isEventMatched ? true : false,
                    isEventType: isEventMatched ? 'OLD' : 'NEW',
                    eld_event_id: objData.eventsByNameDate[i].eld_event_id,
                    eld_timezone_abbreviation: objData.eventsByNameDate[i].eld_timezone_abbreviation,
                    eld_eventcost: objData.eventsByNameDate[i].eld_eventcost
                })
            }
            setEventsList(newEventsList)
        } else {
            setEventsList([])
        }
    }

    useEffect(() => {
        // console.log('RK viewType', viewTypeTxt)
        // console.log('RK editId', editId)

        // console.log('RK category', category)

        // if (category !== 'Merch' && category !== 'Autographs') {
        if (category === 'Experiences') {
            // to pass all STHExperience list to useState to populate dropdown list.
            if (!sthExpLoading) setSthExperienceList(sthExpData.eventsAllNames)

            // console.log(selSTHExperience+"selSTHExperience")
            // console.log(eventsListLoading+"eventsListLoading")

            if (selSTHExperience && !eventsListLoading) {
                // console.log('events list data 1', eventsListData)
                getAllEventsList(eventsListData)
            }
        }

        // to get merchandise details using merchandise id for update merchandise.
        if (!loading && editId && (viewTypeTxt === 'Edit' || viewTypeTxt === 'Copy')) {
            // After updating the merchandise form, old state still maintained. So, forcefully refetch the data once to overwrite.
            refetch();
            // console.log('merchandise details', data)

            // eventsRefetch()

            if (data) {
                defaultValues = { ...data.merchandiseById };
                setCategory(defaultValues.merchandise_category);
                setStatusType(defaultValues.merchandise_status);

                // if (defaultValues.merchandise_category !== 'Merch' && defaultValues.merchandise_category !== 'Autographs') {
                if (defaultValues.merchandise_category === 'Experiences') {
                    setSelSTHExperience(defaultValues.merchandise_name);

                    // We have to do "eventsRefetch()" after getting STHExperience.
                    if (!eventsListData) eventsRefetch()

                    if (eventsListData && eventsListData.eventsByNameDate.length > 0) {
                        if (selectedEventsList.length === 0) {
                            let newEventsList = []
                            // newEventsList = [...eventsList];

                            // to clear all active image flag before set new active image flag as true.
                            for (let i = 0; i < eventsListData.eventsByNameDate.length; i++) {
                                let isEventMatched = false
                                let tempCapacity = 0
                                if (defaultValues.merchandise_listings && defaultValues.merchandise_listings.length > 0) {
                                    for (let j = 0; j < defaultValues.merchandise_listings.length; j++) {
                                        if (defaultValues.merchandise_listings[j].ml_eldid === eventsListData.eventsByNameDate[i].eld_id) {
                                            isEventMatched = true
                                            tempCapacity = defaultValues.merchandise_listings[j].ml_selectioncapacity
                                        }
                                    }
                                }
                                newEventsList.push({
                                    eld_id: eventsListData.eventsByNameDate[i].eld_id, // "444",
                                    eld_name: eventsListData.eventsByNameDate[i].eld_name, // "STH Tailgate",
                                    eld_date: eventsListData.eventsByNameDate[i].eld_date, // "2021-02-12",
                                    eld_starttime: eventsListData.eventsByNameDate[i].eld_starttime, // "10:00:00+00",
                                    eld_endtime: eventsListData.eventsByNameDate[i].eld_endtime, // "12:00:00+00",
                                    eld_location: eventsListData.eventsByNameDate[i].eld_location, // "Heinz Field",
                                    eld_city: eventsListData.eventsByNameDate[i].eld_city, // "Pittsburg",
                                    eld_country: eventsListData.eventsByNameDate[i].eld_country, // "US",
                                    eld_availablecapacity: eventsListData.eventsByNameDate[i].eld_availablecapacity,
                                    eld_selectioncapacity: tempCapacity,
                                    isEventSelected: isEventMatched ? true : false,
                                    isEventType: isEventMatched ? 'OLD' : 'NEW',
                                    eld_event_id: eventsListData.eventsByNameDate[i].eld_event_id,
                                    eld_timezone_abbreviation: eventsListData.eventsByNameDate[i].eld_timezone_abbreviation,
                                    eld_eventcost: eventsListData.eventsByNameDate[i].eld_eventcost
                                })
                            }
                            setEventsList(newEventsList)
                        }
                        // console.log('RK events ok 2', newEventsList)
                    }
                }

                fieldSetValueFunction(defaultValues);
            }
            // setConfirmed(true);
        }

        // to clear all state and hook values
        if (viewTypeTxt === 'Create') {
            sthExpRefetch()
            setOpenConfirm(false);
            setOpenCancelConfirm(false);
            setConfirmed(false);
            // setCategory('Merch')
            defaultValues = {}
            fieldSetValueFunction(defaultValues);
        }
    }, [category, selSTHExperience, sthExpData, eventsListData, data, open, setValue, statusType,]);

    const [updateMerchandiseStatus] = useMutation(DEACTIVATE_MERCHANDISE_BY_ID, {
        // after updating the post, we go to the home page
        onCompleted: () => handleCloseClick(),
        onError: (data) => {
            setErrorMsg(data.message);
            setOpenToast(true);
        }
    });

    const handleDeleteMerchandise = () => {
        // setConfirmMessage("Are you sure you want to delete this Sponsor.By choosing Delete you will loose all the information for "+data.getSponsorWithId.sponsorName);
        if (data.merchandiseById.merchandise_status === 'ACTIVE') {
            setConfirmMessage("Are you sure you want to deactivate " + data.merchandiseById.merchandise_name + " merchandise?");
        } else if (data.merchandiseById.merchandise_status === 'INACTIVE') {
            setConfirmMessage("Are you sure you want to activate " + data.merchandiseById.merchandise_name + " merchandise?");
        }
        setIsADClicked(true)
        setOpenConfirm(true);
    }

    // to close snack bar error message toaster.
    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    // to add merchandise details 
    const [addMerchandise, { error, loading: resLoading }] = useMutation(CREATE_MERCHANDISE, {
        // after updating the post, we go to the home page
        // onCompleted: () => handleCloseClick(),
        onCompleted: () => handleClearAndClose()
    });

    // const [updateSponsors] = useMutation(UPDATE_SPONSOR , {
    //     // after updating the post, we go to the home page
    //     onCompleted: () => handleCloseClick(),
    // });

    // const [updateMerchandise] = useMutation(UPDATE_MERCHANDISE, {
    //     // after updating the post, we go to the home page
    //     // onCompleted: () => handleCloseClick(),
    //     onCompleted: () => handleClearAndClose()
    // });

    const [uploadMerchImage] = useMutation(UPLOAD_MERCH_IMAGE, {
        // after updating the post, we go to the home page
        // onCompleted: () => handleCloseClick(),
        onCompleted: (res) => {
            console.log('RESULT', res)
        }
    });

    const [updateFulfillmentStatus] = useMutation(UPDATE_FULFILLMENT, {
        // after updating the post, we go to the home page
        onCompleted: () => {
            handleCloseClick();
            setConfirmed(false)
            setOpenConfirm(false)
        }
    });

    // cancel click on confirm prompt.
    const clickCanceled = () => {
        setConfirmed(false);
        setOpenConfirm(false);
    }

    const clickConfirmed: SubmitHandler<IFormAddMerchandise> = (fieldData) => {
        console.log('fieldData-------', fieldData);
        // updateMerchandiseStatus({ variables: { merchandise_id: editMerchandiseId, merchandise_status: fieldData.merStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' } });
        updateFulfillmentStatus({ variables: { merchandise_userlisting_mlid: parseInt(editId), fulfillment_status: fieldData.category === 'true' ? true : false } });
       
        // setConfirmed(true);
        // setOpenConfirm(false);
    }

    const onCancel = () => {
        if (!cancelDisabled) {
            setConfirmMessage("Are you sure you want to cancel? By cancel this operation, you may lose all changes you made.");
            setOpenConfirm(true);
            setOpenCancelConfirm(true)
        } else {
            handleClearAndClose()
        }
    }

    const onHandleCancel = (objType: any) => {
        if (objType === 'YES') {
            handleClearAndClose()
        } if (objType === 'NO') {
            setOpenConfirm(false)
            setOpenCancelConfirm(false)
        }
    }

    const onSubmit: SubmitHandler<IFormAddMerchandise> = fieldData => {

        setConfirmMessage("Are you sure with the selected fulfillment status?");

        if (!confirmed) setOpenConfirm(true);
    }

    // to check all 3 images has been uploaded or not.
    const merchandiseImageUploadChecking = () => {
        let isMaxCountReached = true
        let newMerchandiseImgList = [...merchandiseImgList];

        for (let i = 0; i <= merchandiseImgList.length - 1; i++) {
            if (newMerchandiseImgList[i].imageUrl === '') isMaxCountReached = false
        }
        return isMaxCountReached
    }

    // to upload selected images to S3.
    const uploadImage = (e: React.ChangeEvent<any>) => {
        const targetFiles: any = e.target.files;

        // console.log(targetFiles);

        if (targetFiles[0].type == "image/png" || targetFiles[0].type == "image/jpeg") {
            if (!merchandiseImageUploadChecking()) {
                setLoader(true);
                if (targetFiles.length > 0) {
                    // upload image graphql api start 
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const file = targetFiles[0];
                    const reader = new FileReader();
                
                    reader.onload = (e) => {
                      // Update state with the base64 encoded image string
                      console.log('READER', reader.result);
                      uploadMerchImage({
                        variables:
                        {
                            "imageData": reader.result,
                            "fileNamePrefix": 'merchandise'
                        }
                    }).then((res:any)=>{
                        console.log('result', res?.data?.uploadMerchImage);
                        setMerchandiseImgUrl(res?.data?.uploadMerchImage)

                            // copying the old merchandiseImgList array
                            let newMerchandiseImgList = [...merchandiseImgList];

                            // to clear all active image flag before set new active image falg as true.
                            for (let i = 0; i <= merchandiseImgList.length - 1; i++) {
                                newMerchandiseImgList[i].isActive = false;
                            }

                            let isImageAddedToArray = false
                            // replace newly added image with whatever you want to change it to
                            for (let i = 0; i <= merchandiseImgList.length - 1; i++) {
                                if (!isImageAddedToArray && newMerchandiseImgList[i].imageUrl === '') {
                                    newMerchandiseImgList[i].imageUrl = res?.data?.uploadMerchImage;
                                    newMerchandiseImgList[i].isActive = true;
                                    isImageAddedToArray = true
                                }
                            }

                            setMerchandiseImgList(newMerchandiseImgList);
                            setLoader(false);
                            setCancelDisabled(false)
                    }).catch((err: any) => console.error(err));
                    };
                
                    if (file) {
                      // Read the image file as a data URL
                      reader.readAsDataURL(file);
                    }
                    // upload image graphql api end
                }
            } else {
                setErrorMsg("You can upload a maximum of 3 images.");
                setOpenToast(true);
            }
        } else {
            setErrorMsg("Please upload image format as png or jpg.");
            setOpenToast(true);
        }
    }

    // to capture event capacity.
    const handleEventCapacity = (e: React.ChangeEvent<any>, selEventId: any) => {
        // console.log('rk chec')
        const targetValue = (e.target as HTMLInputElement).value;
        let newSelectedEventsList = [...selectedEventsList];

        if (selectedEventsList && selectedEventsList.length > 0) {
            for (let i = 0; i <= selectedEventsList.length - 1; i++) {
                if (selectedEventsList[i].eld_id === selEventId) {
                    newSelectedEventsList[i].eld_selectioncapacity = targetValue.length < 4 ? targetValue : targetValue.slice(0, 5);
                    setSelectedEventsList(newSelectedEventsList)
                }
            }
        }
    }

    // to capture type, sub-type, qty and max qty per customer.
    const handleMerchListData = (e: React.ChangeEvent<any>, idx: any, col: any) => {
        // console.log('rk chec')
        const targetValue = (e.target as HTMLInputElement).value;
        let newSelectedMerchList = [...selectedMerchList]; // selectedMerchList

        if (selectedMerchList && selectedMerchList.length > 0) {
            for (let i = 0; i <= selectedMerchList.length - 1; i++) {
                if (selectedMerchList[i].idx === idx) {
                    if (col == '1') newSelectedMerchList[i].type = targetValue
                    if (col == '2') newSelectedMerchList[i].subType = targetValue
                    if (col == '3') {
                        // newSelectedMerchList[i].qty = targetValue.length < 4 ? targetValue : targetValue.slice(0, 5);
                        let tempValue: any = targetValue.length < 4 ? targetValue : targetValue.slice(0, 5);
                        if (tempValue === '') newSelectedMerchList[i].qty = tempValue;
                        else if (!isNaN(tempValue) && parseInt(tempValue) >= 0 && !tempValue.includes('.') && !tempValue.includes('-')) newSelectedMerchList[i].qty = tempValue;
                    }
                    if (col == '4') {
                        // newSelectedMerchList[i].maxQtyPerCust = targetValue.length < 4 ? targetValue : targetValue.slice(0, 5);
                        let tempValue: any = targetValue.length < 4 ? targetValue : targetValue.slice(0, 5);
                        if (tempValue === '') newSelectedMerchList[i].maxQtyPerCust = tempValue;
                        else if (!isNaN(tempValue) && parseInt(tempValue) >= 0 && !tempValue.includes('.') && !tempValue.includes('-')) newSelectedMerchList[i].maxQtyPerCust = tempValue;
                    }
                    setSelectedMerchList(newSelectedMerchList)
                }
            }
        }
        setCancelDisabled(false)
    }

    // to change input values
    const handleChangeField = (e: React.ChangeEvent<any>) => {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;
        setValue(targetName, targetValue);
        setCancelDisabled(false)
    }

    // to change dropdown values here.
    const handleChange = (e: React.ChangeEvent<{ value: string | unknown }>) => {
        const targetType = (e.target as HTMLInputElement).value;
        const targetName = (e.target as HTMLInputElement).name;

        // to pass selected STHExperience to useState
        if (targetName === 'sthExperience') {
            setSelSTHExperience(targetType);
            setSelectedEventsList([])
            setPastDate(true)
            setDisplayMonth(moment().format('MMM YYYY'))
            setGetEventsByYearMonth(moment().format('YYYY-MM'))
            setIsCurrentMonth(true)
            eventsRefetch()
        }

        if (targetName === 'category') {
            setCategory(targetType)

            // remove already selected events if any.
            // if (targetType === 'Merch' || targetType === 'Autographs') {
            if (targetType !== 'Experiences') {
                setValue("merFulfillment", 'YES');
                setEventsList([]);
                setSelectedEventsList([]);
                setSelSTHExperience('');
                setValue("merchandiseName", '');
                setValue("pointsCost", '');
                setSelectedMerchList([{ idx: Math.floor(100000 + Math.random() * 900000), type: '', subType: '', qty: '', maxQtyPerCust: '', isEventType: 'NEW' }]);
            }
            // remove added merch data if any.
            // if (targetType !== 'Merch' && targetType !== 'Autographs') {
            if (targetType === 'Experiences') {
                setValue("merchandiseName", '');
                setValue("pointsCost", '');
                setValue("merFulfillment", 'NO');
                setSelectedMerchList([{ idx: Math.floor(100000 + Math.random() * 900000), type: '', subType: '', qty: '', maxQtyPerCust: '', isEventType: 'NEW' }]);
            }
        }

        setValue(targetName, targetType);
        setCancelDisabled(false)
    }

    // to set selected image as merchandise primary thumbnail.
    const handleMerchandiseThumbnail = (index: number) => {
        // copying the old merchandiseImgList array
        let newMerchandiseImgList = [...merchandiseImgList];

        if (newMerchandiseImgList[index].imageUrl) {
            // to clear all active image flag before set new active image flag as true.
            for (let i = 0; i <= merchandiseImgList.length - 1; i++) {
                newMerchandiseImgList[i].isActive = false;
            }

            newMerchandiseImgList[index].isActive = true;

            setMerchandiseImgUrl(newMerchandiseImgList[index].imageUrl)
            setMerchandiseImgList(newMerchandiseImgList);
        } else {
            let isImgAvailable = false
            for (let i = 0; i <= merchandiseImgList.length - 1; i++) {
                if (newMerchandiseImgList[i].imageUrl) {
                    isImgAvailable = true
                    newMerchandiseImgList[i].isActive = true;
                    setMerchandiseImgUrl(newMerchandiseImgList[i].imageUrl)
                    setMerchandiseImgList(newMerchandiseImgList);
                    break;
                }
            }
            if (!isImgAvailable) setMerchandiseImgUrl('')
        }
    }

    // to get date format as "Fri, Mar 12"
    const getWeekDayNameAndMonthNameAndDateNo = (objDate: any) => {
        // if (objDate) {
        //     let d = new Date(objDate);

        //     // get week name as "Fri"
        //     let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        //     let dayName = days[d.getDay()].substring(0,3);

        //     // get month name as "Mar"
        //     let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        //     let monthName = monthNames[d.getMonth()].substring(0,3)

        //     // get date number as "04" || "21".
        //     let dt = d.getDate()
        //     let getDate = dt.toString()
        //     if (dt < 10) getDate = '0' + getDate

        //     return dayName + ', ' + monthName + ' ' + getDate
        // }

        if (objDate) {
            let d = objDate + 'T00:00:00' // '2021-03-01T'
            let dDate: any = moment(d).format('llll')
            dDate = dDate.split(',')

            return dDate[0] + ', ' + dDate[1]  // Tue, Jun 22
        }
    }

    // to display time from "17:35:00" to "05:35 PM" 
    const getTimeAMPM = (time: any) => {
        time = time.substring(0, 5)
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = time[0] % 12 || 12; // Adjust hours
            time[0] = time[0] < 10 ? '0' + time[0] : time[0];
        }
        return time.join(''); // return adjusted time or original string
    }

    // to display time and zone as " 10:00 AM - 12:00 PM (EST)"
    const getFullDateWithTimeStampAndZone = (objData: any) => {
        // console.log('objData rk', objData)

        let d = objData.eld_date + 'T' // '2021-03-01T'
        // let dStartTime = moment(d + objData.eld_starttime).format('hha')
        // let dEndTime = moment(d + objData.eld_endtime).format('hha')

        let dStartTime = getTimeAMPM(objData.eld_starttime)
        let dEndTime = getTimeAMPM(objData.eld_starttime)

        // to get end time as starttime + 60 min.

        let tempdEndTime = parseInt(dEndTime.toString().split(":")) + 1;
        let fulldEndTime = tempdEndTime < 10 ? ('0' + tempdEndTime) : tempdEndTime
        let custdEndTime = fulldEndTime + ':' + dEndTime.toString().split(":")[1]

        if (fulldEndTime === 12) {
            let tempTimeFormat = (dStartTime.substring(6, 2) === 'AM' ? ' PM' : ' AM')
            custdEndTime = fulldEndTime + ':' + (dEndTime.toString().split(":")[1]).substring(0, 2) + tempTimeFormat
        }

        let dateAndTimeZone = d + objData.eld_starttime

        // let timezone = moment(dateAndTimeZone).tz('America/Los_Angeles').format('z')

        let timezoneName = 'PDT'
        for (let j = 0; j < zoneList.length; j++) {
            if (zoneList[j].zoneCode === objData.eld_timezone_abbreviation.toUpperCase()) {
                timezoneName = zoneList[j].zoneName;
            }
        }
        let timezone = moment(dateAndTimeZone).tz(timezoneName).format('z')

        return dStartTime + ' - ' + custdEndTime + ' (' + timezone + ')'
    }

    // based on event selection, pushing the event records to useState
    const handleEventsSelection = (eventData: any) => {
        let isEventAlreadyExist = false
        // console.log('selected event', eventData)

        let newSelectedEventsList = [...selectedEventsList];

        if (selectedEventsList && selectedEventsList.length > 0) {
            for (let i = 0; i <= selectedEventsList.length - 1; i++) {
                if (selectedEventsList[i].eld_id === eventData.eld_id) {
                    // to get selected events only.
                    let filteredArray = selectedEventsList.filter(function (item: any) {
                        return eventData.eld_id !== item.eld_id;
                    });
                    isEventAlreadyExist = true
                    setSelectedEventsList(filteredArray.length > 0 ? filteredArray : [])
                }
            }
        }

        // to push new events here
        if (!isEventAlreadyExist) {
            newSelectedEventsList.push({
                eld_id: eventData.eld_id, // "444",
                eld_name: eventData.eld_name, // "STH Tailgate",
                eld_date: eventData.eld_date, // "2021-02-12",
                eld_starttime: eventData.eld_starttime, // "10:00:00+00",
                eld_endtime: eventData.eld_endtime, // "12:00:00+00",
                eld_location: eventData.eld_location, // "Heinz Field",
                eld_city: eventData.eld_city, // "Pittsburg",
                eld_country: eventData.eld_country, // "US",
                eld_selectioncapacity: eventData.eld_availablecapacity,
                eld_availablecapacity: eventData.eld_availablecapacity,
                isEventSelected: true,
                isEventType: 'NEW',
                eld_event_id: eventData.eld_event_id,
                eld_timezone_abbreviation: eventData.eld_timezone_abbreviation,
                eld_eventcost: eventData.eld_eventcost
            })
            setSelectedEventsList(newSelectedEventsList)
        }

        // to update selected event list flag as true/false to handle border css.
        let newEventsList = [...eventsList];
        if (eventsList && eventsList.length > 0) {
            for (let i = 0; i <= eventsList.length - 1; i++) {
                if (eventsList[i].eld_id === eventData.eld_id) {
                    newEventsList[i].isEventSelected = !newEventsList[i].isEventSelected
                }
            }
            setEventsList(newEventsList)
        }
        setCancelDisabled(false)
    }

    // to remove selected event and deselect from event listing block.
    const handleRemoveSelectedEvent = (eventData: any) => {
        let newSelectedEventsList = [...selectedEventsList];

        if (selectedEventsList && selectedEventsList.length > 0) {
            for (let i = 0; i <= selectedEventsList.length - 1; i++) {
                if (selectedEventsList[i].eld_id === eventData.eld_id) {
                    // to get selected events only.
                    let filteredArray = selectedEventsList.filter(function (item: any) {
                        return eventData.eld_id !== item.eld_id;
                    });
                    setSelectedEventsList(filteredArray.length > 0 ? filteredArray : [])
                }
            }
        }

        // to update selected event list flag as true/false to handle border css.
        let newEventsList = [...eventsList];
        if (eventsList && eventsList.length > 0) {
            for (let i = 0; i <= eventsList.length - 1; i++) {
                if (eventsList[i].eld_id === eventData.eld_id) {
                    newEventsList[i].isEventSelected = false
                }
            }
            setEventsList(newEventsList)
        }
    }

    // to remove selected merch and deselect from merch listing block.
    const handleMerchListSelection = (merchData: any) => {
        // let newSelectedEventsList = [...selectedMerchList]; 

        if (selectedMerchList && selectedMerchList.length > 0) {
            for (let i = 0; i <= selectedMerchList.length - 1; i++) {
                if (selectedMerchList[i].idx === merchData.idx) {
                    // to get selected events only.
                    let filteredArray = selectedMerchList.filter(function (item: any) {
                        return merchData.idx !== item.idx;
                    });
                    setSelectedMerchList(filteredArray.length > 0 ? filteredArray : [])
                }
            }
        }
        setCancelDisabled(false)
    }

    const handleDisplayEventsByMonth = (objMonth: any, objType: any) => {

        let tempDate = objMonth.split('-')      // 2021-03
        let getYear = parseInt(tempDate[0])     // 2021
        let getMonth = parseInt(tempDate[1])    // 03

        let yearMonth = ''

        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // let monthName = monthNames[d.getMonth()].substring(0,3)


        if (objType === 'NEXT') {
            let nextMonth = getMonth + 1

            if (nextMonth > 12) {
                getYear = getYear + 1
                nextMonth = 1
            }

            yearMonth = (getYear + '-' + (nextMonth < 10 ? '0' + nextMonth : nextMonth)).toString()

            console.log('next selection', yearMonth)

            let monthNameYear = monthNames[nextMonth - 1].substring(0, 3).toString() + ' ' + getYear
            setDisplayMonth(monthNameYear)

            setGetEventsByYearMonth(yearMonth)
            setIsCurrentMonth(false)
            setPastDate(false)
            eventsRefetch()
        } else if (objType === 'PREV') {
            let prevMonth = getMonth - 1
            if (prevMonth < 1) {
                getYear = getYear - 1
                prevMonth = 12
            }

            yearMonth = (getYear + '-' + (prevMonth < 10 ? '0' + prevMonth : prevMonth)).toString()

            // to restrict past month
            let currYearMonth = moment().format('YYYY-MM')

            let tDateYear = currYearMonth.split('-')    // 2021-03
            let tgetYear = parseInt(tDateYear[0])       // 2021
            let tgetMonth = parseInt(tDateYear[1])      // 03

            // to restrict past date selection
            if ((tgetYear === getYear && prevMonth < tgetMonth) || (getYear < tgetYear)) {
                console.log('date range not accepted')
                // setPastDate(true)

            } else {
                console.log('next selection', yearMonth)
                setIsCurrentMonth(false)
                if ((tgetYear === getYear && prevMonth === tgetMonth) || (getYear < tgetYear)) {
                    // console.log('date range not accepted')
                    setIsCurrentMonth(true)
                    setPastDate(true)
                }

                let monthNameYear = monthNames[prevMonth - 1].substring(0, 3).toString() + ' ' + getYear
                setDisplayMonth(monthNameYear)

                setGetEventsByYearMonth(yearMonth)
                eventsRefetch()
            }

        }

        // setGetEventsByYearMonth

        // const [displayMonth, setDisplayMonth] = React.useState<any>(moment().format('MMM YYYY'));                   // 'Mar 2021'
        // const [getEventsByYearMonth, setGetEventsByYearMonth] = React.useState<any>(moment().format('YYYY-MM'));      // '2021-03'
    }

    const handleRemoveImage = (objIndex: any) => {
        const newMerchandiseImgList = [...merchandiseImgList]
        newMerchandiseImgList[objIndex].imageUrl = ''
        newMerchandiseImgList[objIndex].isActive = false

        setMerchandiseImgList(newMerchandiseImgList)
    }

    const handleMerchandiseList = () => {
        const newselectedMerchList = [...selectedMerchList]
        newselectedMerchList.push({ idx: Math.floor(100000 + Math.random() * 900000), type: '', subType: '', qty: '', maxQtyPerCust: '', isEventType: 'NEW' })
        setSelectedMerchList(newselectedMerchList)
    }

    return (
        <div>
            <Dialog open={onOpen} onClose={handleCloseClick} aria-labelledby="form-dialog-title" className="common-dialog-container merchandise-container confirmation-popup" disableBackdropClick={true} disableEscapeKeyDown={true}>
                <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Edit Fulfillment</DialogTitle>
                    <DialogContent>
                        {/* Full page */}
                        <Grid className="form-grid" container sm={12} spacing={6}>
                            {/* Left side block */}
                            <Grid className='pl-zero' item sm={4} xs={12}>
                                {/* To show Merchandise active image */}
                                <div className='mer-thumbnail-block'>
                                    {loader && <Loader size="60px" />}
                                    {!loader && <img className={singleMerchData.merchImage ? 'mer-thumbnail' : 'mer-thumbnail-default'} src={singleMerchData.merchImage || defaultLogoUrl} alt='Merchandise-logo' width='41px' height='41px' />}
                                </div>

                                {/* Category */}
                                <div className="input-group">
                                    <label className="input-label">Fulfillment</label>
                                    <Controller
                                        name="category"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ value }) => (
                                            <Select displayEmpty name="category" value={value} className={`custom-select form-select  ${errors.category && 'error'}`} onChange={(e) => handleChange(e)}>
                                                <MenuItem value={'true'} >Delivered</MenuItem>
                                                <MenuItem value={'false'} >Not Delivered</MenuItem>
                                            </Select>
                                        )}
                                    />
                                    {errors.category && errors.category.type == "required" && (<label className="error-message">Field is required</label>)}
                                </div>
                                <div className="input-group">
                                    <label className="input-label">Fulfillment Date</label><br/>
                                    <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.userListingFulfillmentDate ? moment(parseInt(singleMerchData.userListingFulfillmentDate)).format('MM/DD/YYYY hh:mm:ss') : null}</label>
                                </div>
                            </Grid>

                            {/* Right side block */}
                            <Grid item className="borderL padr0" sm={8} xs={12} >
                                <Grid container spacing={6}>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Transaction Date</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{moment(parseInt(singleMerchData.transactionDate)).format('MM/DD/YYYY')}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Order NO</label><br/>
                                        <label className="input-label"  style={{fontWeight: "bold"}}>{singleMerchData.transactionRefrenceCode}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Item Name</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.merchName}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Category</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.merchandiseCategory}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Item #</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.merchandiseItemNo}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Quantity</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.transactionQty}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Points</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.merchandisePointsCost}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Total Points</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.transactionTotalConsumedPoint}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Patron Id</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.patronId}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Patron Name</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.tdcPatronName}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Email Address</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.tdcEmail}</label>
                                    </Grid>
                                    <Grid className="padr0" sm={6} xs={6}>
                                        <label className="input-label">Patron Shipping Address</label><br/>
                                        <label className="input-label" style={{fontWeight: "bold"}}>{singleMerchData.shippingAddress}</label>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Confirmation of Submit Merchandise Details */}
                        {openConfirm &&
                            <div className="confim-model-container cust-modal">
                                <Paper className="confirmation-wrapper padd-20">
                                    <Grid container sm={12}>
                                        <div className="confirmation-sec">
                                            <p>{confirmMessage}</p>
                                            <DialogActions>
                                                <Button className="btn btn-secondary" onClick={clickCanceled} color="primary">CANCEL</Button>
                                                <Button color="primary" onClick={handleSubmit(clickConfirmed)} className="btn btn-primary button">CONFIRM</Button>
                                            </DialogActions>
                                        </div>
                                    </Grid>
                                </Paper>
                            </div>
                        }

                        {openCancelConfirm &&
                            <div className="confim-model-container cust-modal">
                                <Paper className="confirmation-wrapper padd-20">
                                    <Grid container sm={12}>
                                        <div className="confirmation-sec">
                                            <p>{confirmMessage}</p>
                                            <DialogActions>
                                                <Button className="btn btn-secondary" onClick={() => onHandleCancel('NO')} color="primary">NO</Button>
                                                <Button color="primary" onClick={() => onHandleCancel('YES')} className="btn btn-primary button">YES</Button>
                                            </DialogActions>
                                        </div>
                                    </Grid>
                                </Paper>
                            </div>
                        }
                    </DialogContent>

                    {/* Submit Merchandise Details */}
                    <DialogActions>
                        {editMerchandiseId > 0 && statusType === 'ACTIVE' && viewTypeTxt !== 'Copy' &&
                            <Button onClick={handleDeleteMerchandise} className={statusType === 'INACTIVE' ? "btn btn-secondary status-active" : "btn btn-secondary btn-delete"} color="primary">{statusType === 'INACTIVE' ? 'ACTIVATE' : 'DEACTIVATE'}</Button>
                        }
                        <Button onClick={onCancel} className="btn btn-secondary" color="primary">CANCEL</Button>
                        <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>UPDATE</Button>
                    </DialogActions>
                </form>

                <Snackbar open={openToast} autoHideDuration={5000} onClose={handleClosed}>
                    <Alert onClose={handleClosed} severity="error">{errorMsg}</Alert>
                </Snackbar>
            </Dialog>
        </div>
    )
}




