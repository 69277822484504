import React, { useState ,useEffect} from 'react'
import { useHistory } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import '../../constant.css';
import './manageclubs.css';
import OrgImage from '../../assets/Bitmap@2x.png';
import CreateClub from './createclub/createclub';
import EmptyOrg from '../../assets/org-empty@2x.svg';
import Pirates from '../../assets/Pirates.png';
import Pnc from '../../assets/PNC.png';
import Highmark from '../../assets/Highmark.png';
import Nasdaq from '../../assets/Nasdaq.png';
import Van from '../../assets/van.png';
import Veterans from '../../assets/Veterans.png';
import { useQuery, gql } from '@apollo/client';
interface ClubInventory {
    id: number;
    name: string;
}

interface ClubInventoryData {
    clubInventory: ClubInventory[];
}

interface ClubInventoryVars {
    year: number;
}

interface IFormAddClub {
    orgLogo: string;
    orgName: string;
    orgCode: string;
    orgRate: string;
    playerRoaster: string;
    gameSchedule: string;
    videoStream: string;
    events: string;
    news: string;
    facebook: string;
    insta: string;
    twitter: string;
}

export const GET_ORGS_QUERY = gql`
query GetClubInventory {
    orgsAll{
    id
    name
    rate
    orgCode
    logoUrl
    availablePoints
    totalPoints
    fanCount   
    isActive 
  }
}
`;

export default function Manageclubs() {
    const { refetch,loading, data } = useQuery<any>(
        GET_ORGS_QUERY
    );
    //const [data,setDatas] = useState(data);
    const [open, setOpen] = React.useState(false);
    const [orgId,setOrgId] = React.useState("");
    const methods = useForm<IFormAddClub>();
    // const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
        setOrgId("");
    };
    const handleEditClickOpen = (id:string) => {
        console.log(id);
        setOpen(true);
        setOrgId(id);
    };
    //const clubdetailsClick = (id: string) => history.push(`/clubdetails/${id}`);
    useEffect(() => {
        console.log("useEffect");
        refetch();
    },[open]);

    const handleClose = () => {
        setOpen(false);
        //setTimeout (() => {refetch()},1000);
    };
   
    // Click event for club details
    const history = useHistory();
    const clubdetailsClick = (id: string) => history.push(`/clubdetails/${id}`);


    const { handleSubmit, control, reset } = methods;
    const onSubmit: SubmitHandler<IFormAddClub> = data =>
        console.log(JSON.stringify(data));

    return (
        <div className="detail-page manage-organization-list">
            <div className="page-head">
                <Grid container sm={12}>
                    <Grid item sm={6}>
                        <h2 className="page-title">MANAGE ORGANIZATIONS</h2>
                    </Grid>
                    <Grid className="align-right" item sm={6}>
                        <button onClick={handleClickOpen} className="btn btn-primary add-btn"><span className="btn-icon"></span><label className="btn-caption"> Add Org</label></button>
                    </Grid>
                </Grid>
            </div>
            <div className="page-body" >
            {loading ? (
        <p>Loading ...</p>
      ) : (
                <Grid container sm={12} spacing={1}>
                    {data?.orgsAll.map((org: any) =>
                        <Grid item sm={4} className={org.isActive?"active-org":"inactive-org"}>
                            <Paper  className="org-paper-container">
                                <div className="org-container">
                                    <Grid container sm={12} spacing={1}>
                                        <Grid item sm={3}>
                                            <img className="org-profile-img" src={org.logoUrl || EmptyOrg } alt="org profile" />
                                        </Grid>
                                        <Grid className="org-body-content" container sm={9} spacing={2}>
                                            <Grid container className="org-title-container" sm={12}>
                                                <Grid className="details-page" onClick={() => clubdetailsClick(org.id)} item sm={8}>
                                                    <label className="org-title pointer">{org.name}</label>
                                                </Grid>
                                                <Grid item sm={4} className="align-right">
                                                    <span onClick={() => handleEditClickOpen(org.id)} data-id={org.id} className="general-icon edit-icon"></span>
                                                </Grid>
                                            </Grid>
                                            <Grid container sm={12} spacing={1} >
                                                <Grid item sm={8}>
                                                    <div className="org-info-section">
                                                        <span className="data-bold-with-icon"><span className="org-info-icon green"></span><span className="data-bold">{org.availablePoints} / {org.totalPoints}</span></span>
                                                        <label className="sub-caption">Available/total</label>
                                                    </div>
                                                </Grid>
                                                {/* <Grid item sm={3}>
                                                    <div className="org-info-section">
                                                        <span className="data-bold-with-icon  doller-txt"><span className="org-info-icon d-txt">$</span><span className="data-bold">{org.rate}</span></span>
                                                        <label className="sub-caption">Rate</label>
                                                    </div>
                                                </Grid> */}
                                                <Grid item sm={4}>
                                                    <div className="org-info-section">
                                                         <span className="data-bold-with-icon avatar-img"><span className="org-info-icon avatar"></span><span className="data-bold">{org.fanCount}</span></span>
                                                        <label className="sub-caption">Point Holders</label>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </Grid>
                    )}
                </Grid>)}
            </div>
            <CreateClub onOpen={open} editId = {orgId} handleCloseClick={handleClose} />
        </div>
    )
}
