import React ,{useEffect}from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import Button from "@material-ui/core/Button";
import Pirates from '../../assets/Pirates.png';
import Pnc from '../../assets/PNC.png';
import Highmark from '../../assets/Highmark.png';
import Nasdaq from '../../assets/Nasdaq.png';

interface ClubInventory {
  id: number;
  name: string;
}

interface ClubInventoryData {
  clubInventory: ClubInventory[];
}

interface ClubInventoryVars {
  year: number;
}


export const GET_ORGS_QUERY = gql`
query GetClubInventory {
orgs{
  id
  name
  rate
  orgCode
  logoUrl
  availablePoints
  totalPoints
  distributionPoints
  createdAt
}
}
`;

export default function PointOverview() {
  const mangeOrgOverView = () => history.push('/manageclubs');
  const { refetch,loading, data } = useQuery<any>(
    GET_ORGS_QUERY
  );
  useEffect(() => {
    console.log("useEffect");
    refetch();
  },[data]);
    const history = useHistory();
    const clubdetailsClick = (id: string) => history.push(`/clubdetails/${id}`);

  return (
    <div className="table-container">
      <TableContainer className="table-container" style={{ height: 270, width: '100%' }} component={Paper}>
        <Table stickyHeader className="" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Organizations</TableCell>
            <TableCell align="left">Distribution</TableCell>
            <TableCell align="left">Available</TableCell>
            <TableCell align="left">Total</TableCell>
            <TableCell align="left">Date Added</TableCell>
          </TableRow>
        </TableHead> 
          {loading ? ( 
              <TableBody>
                <TableCell colSpan={6}>
                    <Loader size="90px"/>
                </TableCell>
              </TableBody>):(
          <TableBody>
            {data?.orgs.map((org:any) => (
              <TableRow onClick={() => clubdetailsClick(org.id)}>
                  <TableCell padding="none" className="nowrap" >
                    <img className="profile-img  pointer" src={org.logoUrl || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} />
                    <label className="name  pointer">{org.name}</label>
                  </TableCell>
                  
                  <TableCell padding="none" align="left">
                      <label>{org.distributionPoints}</label>
                    </TableCell>
                  <TableCell padding="none" align="left"><label >{org.availablePoints}</label></TableCell>
                  <TableCell padding="none" align="left"><label >{org.totalPoints}</label></TableCell>
                  <TableCell padding="none" align="left"><label>{moment(parseInt(org.createdAt)).format('MM/DD/YYYY')}</label></TableCell>
              </TableRow>
            ))}
          </TableBody>
          )}
        </Table>
      </TableContainer>
      <Grid container className="top-section" sm={12} >
        <Grid className="btn-section view-all-btn" item  xs={12}>
            <Button  onClick={mangeOrgOverView} className="btn" variant="contained">View All</Button>
        </Grid>
      </Grid>
    </div>
  );
}