// pv-pirates-alpha db
// export const config = {
//     baseURL :'https://api-beta-sponsors-pirates.pointsville.com/graphql', // http://pirates-app-alb-beta-1523115489.us-east-1.elb.amazonaws.com/graphql
//     googleAPIKEY : 'AIzaSyB7YwWsZAm6Ft9xf_8pI5VWAen1x-gGH1g',
//     s3Config : {
//         bucketName: 'pv-pirates-images-beta',
//         dirName: 'photos', /* optional */
//         region: 'us-west-2',
//         accessKeyId: 'AKIAXR6VAZISBHRBTYOE',
//         secretAccessKey: '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'
//     },
//     amplifyConfig : {
//         "aws_project_region": "us-east-1",
//         "aws_cognito_identity_pool_id": "us-east-1:41fa1565-e8ba-4a93-aecd-bddaf5444649",
//         "aws_cognito_region": "us-east-1",
//         "aws_user_pools_id": "us-east-1_Hznc2MS2i",
//         "aws_user_pools_web_client_id": "3eq3g6b7ecde9dovbuj866u8v4",
//         // "oauth": {
//         //     "domain": "pointsvilleapp55d2d438-55d2d438-dev.auth.us-east-1.amazoncognito.com",
//         //     "scope": [
//         //         "phone",
//         //         "email",
//         //         "openid",
//         //         "profile",
//         //         "aws.cognito.signin.user.admin"
//         //     ],
//         //     "redirectSignIn": "http://localhost:3000/",
//         //     "redirectSignOut": "http://localhost:3000/signout/",
//         //     "responseType": "code"
//         // },
//         "federationTarget": "COGNITO_USER_POOLS"
//     }
// }

// pv-pirates-beta db
export const config = {
    baseURL :'https://api-beta-sponsors-pirates.pointsville.com/graphql',
    googleAPIKEY : 'AIzaSyB7YwWsZAm6Ft9xf_8pI5VWAen1x-gGH1g',
    amplifyConfig : {
        "aws_project_region": "us-east-1",
        "aws_cognito_identity_pool_id": "us-east-1:41fa1565-e8ba-4a93-aecd-bddaf5444649",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_Hznc2MS2i",
        "aws_user_pools_web_client_id": "3eq3g6b7ecde9dovbuj866u8v4",
        // "oauth": {
        //     "domain": "pointsvilleapp55d2d438-55d2d438-dev.auth.us-east-1.amazoncognito.com",
        //     "scope": [
        //         "phone",
        //         "email",
        //         "openid",
        //         "profile",
        //         "aws.cognito.signin.user.admin"
        //     ],
        //     "redirectSignIn": "http://localhost:3000/",
        //     "redirectSignOut": "http://localhost:3000/signout/",
        //     "responseType": "code"
        // },
        "federationTarget": "COGNITO_USER_POOLS"
    }
}
// pv-pirates-ga db
// export const config = {
//     baseURL :'https://api-sponsors-pirates.pointsville.com/graphql', 
//     googleAPIKEY : 'AIzaSyB7YwWsZAm6Ft9xf_8pI5VWAen1x-gGH1g',
//     amplifyConfig : {
//         "aws_project_region": "us-east-1",
//         "aws_cognito_identity_pool_id": "us-east-1:61157270-28d9-4707-9e7a-32a5ce7ac8b1",
//         "aws_cognito_region": "us-east-1",
//         "aws_user_pools_id": "us-east-1_uoPftgrYJ",
//         "aws_user_pools_web_client_id": "jdqtf3gkvk2trv1kcm338dmab",
//         // "oauth": {
//         //     "domain": "pointsvilleapp55d2d438-55d2d438-dev.auth.us-east-1.amazoncognito.com",
//         //     "scope": [
//         //         "phone",
//         //         "email",
//         //         "openid",
//         //         "profile",
//         //         "aws.cognito.signin.user.admin"
//         //     ],
//         //     "redirectSignIn": "http://localhost:3000/",
//         //     "redirectSignOut": "http://localhost:3000/signout/",
//         //     "responseType": "code"
//         // },
//         "federationTarget": "COGNITO_USER_POOLS"
//     }
// }

// pv-pirates-development db
// export const config = {
//     // baseURL : 'http://localhost:4000/graphql',
//     baseURL : 'http://pointsville-api-alb-dev-1545923753.us-east-1.elb.amazonaws.com/graphql',
//     googleAPIKEY : 'AIzaSyB7YwWsZAm6Ft9xf_8pI5VWAen1x-gGH1g',
//     s3Config : {
//         bucketName: 'pv-pirates-images-alpha',
//         dirName: 'photos', /* optional */
//         region: 'us-west-2',
//         accessKeyId: 'AKIAYDC2LAKEBT3GHP3O',
//         secretAccessKey: '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'
//     },
//     amplifyConfig : {
//         "aws_project_region": "us-east-1",
//         "aws_cognito_identity_pool_id": "us-east-1:263f0379-b31c-40f7-88b3-be180686fed4",
//         "aws_cognito_region": "us-east-1",
//         "aws_user_pools_id": "us-east-1_Pr0JlHTmo",
//         "aws_user_pools_web_client_id": "21nqcub9brm55l47eqjns71fak",
//         // "oauth": {
//         //     "domain": "pointsvilleapp55d2d438-55d2d438-dev.auth.us-east-1.amazoncognito.com",
//         //     "scope": [
//         //         "phone",
//         //         "email",
//         //         "openid",
//         //         "profile",
//         //         "aws.cognito.signin.user.admin"
//         //     ],
//         //     "redirectSignIn": "http://localhost:3000/",
//         //     "redirectSignOut": "http://localhost:3000/signout/",
//         //     "responseType": "code"
//         // },
//         "federationTarget": "COGNITO_USER_POOLS"
//     }
// }

export const zoneList = [
    {zoneCode: 'PST', zoneName: 'America/Los_Angeles'},
    {zoneCode: 'PDT', zoneName: 'America/Los_Angeles'},
    {zoneCode: 'EST', zoneName: 'America/New_York'},
    {zoneCode: 'EDT', zoneName: 'America/New_York'},
]
