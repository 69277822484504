import React ,{useEffect}from 'react';
import { RouteProps,useLocation ,RouteComponentProps,useHistory} from "react-router";
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { Auth } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import '../../constant.css';
import '../memberprofile/memberprofile.css';
import './adminprofiledetails.css';
import ProfileImg from '../../assets/who@2x.png';
import DataTable from './datatable';
import { useQuery, gql } from '@apollo/client';

interface LocationState extends RouteComponentProps {
    state ?: string;
  }
  interface Props {
    onParentRender: () => void,
  }
const GET_USER_BY_ID = gql`
  query getUsers($username: String!) {
    getUserWithIdAdmin(username: $username) {
        id
        username
        givenName
        email
        profileImage
        dob
        address
        phoneNumber
        isActive

    }
  }
`; 
export default function AdminProfileDetails({onParentRender} : Props) {
    const history = useHistory();
    const {state} = useLocation<LocationState>();

    const editClick = (userId:any) => history.push({pathname:'/editprofile',state:userId});
    const [userData,setUserData] = React.useState<any>({});
    const [userId,setUserId] = React.useState<any>({});
    

    const { loading, data,refetch } = useQuery<any>(GET_USER_BY_ID, { variables: {username: localStorage.userId } });

    useEffect(() => {
        refetch();
       if(!loading){
        setUserData(data.getUserWithIdAdmin);
        onParentRender();
       }
    },[data]);
    
    const signOutClick = () => {
        try {
            Auth.signOut();
            history.push('/');
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    return (
    <div className="detail-page admin-profile-details">
        <div className="page-head p-relative padl-40">
            
        </div>
        <div className="page-body padl-30" >
            <Grid container spacing={3} sm={12}>
                <Grid item className="mrgCenter" sm={6} spacing={1}>
                    <div className="profile-img-section profile-details align-center mrgb-20">
                        <img className="profile-img" src={userData.profileImage || ProfileImg}/>
                    </div>
                    <Paper className="">
                        <Grid container className="profile-details flx-align-center" sm={12} spacing={0}>
                            <Grid item sm={6} xs={6}>
                                <h5 className="profile-name">Personal Info</h5>
                            </Grid>
                            <Grid item className="align-right" sm={6} xs={6}>
                                <span onClick={() => editClick(userData.username)} className="general-icon edit-icon"></span>
                            </Grid>
                        </Grid>    
                        <div className="admin-personal-info-section">
                            <Grid item sm={12}>
                                <label className="label-txt">First Name</label>
                                <label className="field-txt">{userData.givenName}</label>
                            </Grid>
                            <Grid item sm={12} className="display-none">
                                <label className="label-txt">Last Name</label>
                                <label className="field-txt"></label>
                            </Grid>
                            <Grid item className="borderb-none" sm={12}>
                                <label className="label-txt">Email ID</label>
                                <label className="field-txt">{userData.email}</label>
                            </Grid>
                            <Grid item sm={12}>
                                <label className="label-txt">Phone Number</label>
                                <label className="field-txt">{userData.phoneNumber}</label>
                            </Grid>
                            
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container sm={12} spacing={3}>
                <Grid item className="mrgCenter" sm={6} spacing={1}>
                    <Paper>
                        <span className="logout-icon"></span>
                        <label onClick ={signOutClick} className="logout-txt">Logout</label>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container sm={12} spacing={3}>
                <Grid item className="mrgCenter align-right" sm={6} spacing={1}>
                   <div className="company-conditions-section">
                       <a href="#">Privacy Policy</a>
                       <a href="#">Terms and Condition</a>
                   </div>
                </Grid>
            </Grid>
        </div>
    </div>
    )
}
