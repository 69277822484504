import React,{useState,useEffect} from 'react';
import { useHistory,useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import Pirates from '../../assets/Pirates.png';
import Pnc from '../../assets/PNC.png';
import Highmark from '../../assets/Highmark.png';
import Nasdaq from '../../assets/Nasdaq.png';
import Van from '../../assets/van.png';
import Veterans from '../../assets/Veterans.png';


interface transactionInventory {
  id: string;
  sponsor_name: string;
  contact_name : string;
  contact_email : string;
}

interface transactionInventoryData {
  transactionInventory: transactionInventory[];
}

interface TransactionInventoryVars {
  year: number;
}
interface ParamTypes {
  orgId: string;
}

export const GET_TRANSACTION_QUERY = gql`
query getTransactions{
getAllTransactions{
    id
    username  
    userProfilePic
    walletId
    orgName
    organization
    orgLogo
    price
    sponsorName
    sponsorLogo
    transactionDate
    transactionType
    points
}
}
`;
export default function TransactionOverview() {
  let { orgId } = useParams<ParamTypes>();
  const [transDate,setTransDate] = useState<any>("");
  const [transfer,setTransfer] = useState(0);
        const [redeem,setRedeem] = useState(0);
        const [promotions,setPromotions] = useState(0);
        const [hunt,setHunt] = useState(0);


    
  const {refetch, loading, data } = useQuery<any>(GET_TRANSACTION_QUERY);  

  const [ageFilterChecked, setAgeFilterChecked] = React.useState(false);
  const history = useHistory();
  const transactionListAll = () => history.push('/transactions');
  useEffect(() => {
    refetch();
    if(!loading){
        var promoTransactions =  data.getAllTransactions.filter(function(transaction :any) { return transaction.transactionType == "PROMO";});
        let promoTotal = promoTransactions.reduce((total:any, current:any) => {return total + current.points },0);
        setPromotions(promoTotal);
        var transactions =  data.getAllTransactions.filter(function(transaction :any) { return transaction.transactionType == "RECEIVED";});
        let transferTotal = transactions.reduce((total:any, current:any) => {return total + current.points },0);
        setTransfer(transferTotal);
        var redeemTransactions =  data.getAllTransactions.filter(function(transaction :any) { return transaction.transactionType == "REDEEM";});
        let redeemTotal = redeemTransactions.reduce((total:any, current:any) => {return total + current.points },0);
        setRedeem(redeemTotal);
        var huntTransactions =  data.getAllTransactions.filter(function(transaction :any) { return transaction.transactionType == "HUNT";});
        let huntTotal = huntTransactions.reduce((total:any, current:any) => {return total + current.points },0);
        setHunt(huntTotal);

    }
},[data]);

  return (
    <div className="table-wrapper pad0">
      <div>
        <Grid container className="transaction-status-wrapper" xs={12}>
          {/* <Grid item sm={1} xs={2}>
              <span className="data-bold pending">4000</span><label className="sub-caption">Pending</label>
          </Grid>
          <Grid item sm={1} xs={2}>
              <span className="data-bold failed">500</span><label className="sub-caption">Failed</label>
          </Grid> */}
          <Grid item sm={1} xs={2}>
        <span className="data-bold transfers">{transfer}</span><label className="sub-caption">Transfers</label>
          </Grid>
          <Grid item sm={1} xs={2}>
        <span className="data-bold redeemed" >{redeem}</span><label className="sub-caption">Redeemed</label>
          </Grid>
          <Grid item sm={1} xs={2}>
        <span className="data-bold hunt">{hunt}</span><label className="sub-caption">Hunt</label>
          </Grid>
          <Grid item sm={1} xs={2}>
        <span className="data-bold promotions">{promotions}</span><label className="sub-caption">Promotions</label>
          </Grid>
        </Grid>
      </div>
    <TableContainer className="table-container" style={{ height: 270, width: '100%' }} component={Paper}>
      <Table stickyHeader className="" aria-label="simple table">
      <TableHead>
          <TableRow>
            <TableCell>Organizations</TableCell>
            <TableCell align="left">Wallet ID</TableCell>
            <TableCell align="left">Points</TableCell>
            <TableCell align="left">Transaction ID</TableCell>
            <TableCell align="left">Date & Time</TableCell>
            {/* <TableCell align="left">Status</TableCell> */}
          </TableRow>
        </TableHead> 
        { loading ? ( 
              <TableBody>
                <TableCell colSpan={6}>
                    <Loader size="90px"/>
                </TableCell>
              </TableBody>   
          ): ( 
        <TableBody>
          {data?.getAllTransactions.map((transaction:any) => { 
            let orgDetails = JSON.parse(transaction.organization);
            return(
            <TableRow >
              <TableCell className="label-parent" padding="none" >
                <img className="profile-img " src={orgDetails.orgLogo || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} />
                <label className="name">{orgDetails.orgName}</label>
              </TableCell>
              <TableCell className="label-parent" padding="none" >
                <label className="name text-wrapper">{transaction.walletId || "-"}</label>
              </TableCell>
              <TableCell className="label-parent" padding="none" >
                <label className="name">{transaction.points}</label>
              </TableCell>
              <TableCell className="label-parent" padding="none" >
                <label className="name text-wrapper">{transaction.id}</label>
              </TableCell>
              <TableCell className="label-parent" padding="none" >
                <label className="name">{moment(parseInt(transaction.transactionDate)).format('DD MMM YYYY HH:mm')}</label>
              </TableCell>
              {/* <TableCell className="label-parent" padding="none" >
                <label className="name">{transaction.status}</label>
              </TableCell> */}
            </TableRow>
          )})}
        </TableBody>
        )}
      </Table>
    </TableContainer>
    <Grid container sm={12} >
          <Grid className="btn-section view-all-btn" item  xs={12}>
              <Button onClick={transactionListAll} className="btn" variant="contained">View All</Button>
          </Grid>
    </Grid>
    </div>
  );
}