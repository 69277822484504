import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles({
  
});

function createData(organization: string, walletId: string,status:string) {
  return { organization, walletId,status };
}

const rows = [
  createData('Pirates', '1838 3838 3838 3838 32','Completed'),
  createData('Pirates', '1838 3838 3838 3838 32','Pending'),
  createData('Pirates', '1838 3838 3838 3838 32','Pending'),
  createData('Pirates', '1838 3838 3838 3838 32','Pending'),
  createData('Pirates', '1838 3838 3838 3838 32','Completed'),
  createData('Pirates', '1838 3838 3838 3838 32','Pending'),
  createData('Pirates', '1838 3838 3838 3838 32','Pending'),
  createData('Pirates', '1838 3838 3838 3838 32','Pending'),
  createData('Pirates', '1838 3838 3838 3838 32','Completed'),
];

export default function KYCOverview() {
  const classes = useStyles({
      container : {
          padding:0,
      }
  });

  return (
    <div className="table-wrapper pad0">
       
        {/* <div>
            <Grid container className="transaction-status-wrapper" xs={12}>
            <Grid item sm={1} xs={2}>
                <span className="data-bold pending">4000</span><label className="sub-caption">Pending</label>
            </Grid>
            <Grid item sm={1} xs={2}>
                <span className="data-bold failed">500</span><label className="sub-caption">Failed</label>
            </Grid>
            </Grid>
        </div>*/}
        <TableContainer className="table-container coming-soon-section" style={{ height: 390, width: '100%' }} component={Paper}>
            <h5 className="page-title"> Coming soon</h5>
        {/* <Table stickyHeader aria-label="sticky table" className="" >
        <TableHead>
            <TableRow>
                <TableCell>Member</TableCell>
                <TableCell align="left">Wallet ID</TableCell>
                <TableCell align="left">Status</TableCell>
            </TableRow>
            </TableHead> 
            <TableBody>
            {rows.map((row) => (
                <TableRow >
                <TableCell className="label-parent" padding="none" >
                    <img className="profile-img " src="https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg" />
                    <label className="name">{row.organization}</label>
                </TableCell>
                <TableCell className="label-parent" padding="none" >
                    <label className="name">{row.walletId}</label>
                </TableCell>
                <TableCell className="label-parent" padding="none" >
                    <label className="name">{row.status}</label>
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table> */}
        </TableContainer>
        {/*<Grid container sm={12} >
            <Grid className="btn-section view-all-btn" item  xs={12}>
                <Button className="btn" variant="contained">View All</Button>
            </Grid>
        </Grid> */}
    </div>
  );
}