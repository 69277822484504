import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import DropdownSelect from "../../components/Dropdown/dropdownselect";
import CreatePromotions from "../createpromotions/createpromotions";
import { useQuery, gql } from "@apollo/client";
import "../../constant.css";
import moment from "moment";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./sponsorList.css";
import Loader from "../../components/Loader/loader";
import Pirates from "../../assets/Pirates.png";
import Pnc from "../../assets/PNC.png";
import Highmark from "../../assets/Highmark.png";
import Nasdaq from "../../assets/Nasdaq.png";
import Van from "../../assets/van.png";
import Veterans from "../../assets/Veterans.png";

interface PromotionInventory {
  id: string;
  promoType: string;
}

interface Props {
  onParentRender: () => void;
}
interface ParamTypes {
  orgId: string;
}
interface PromotionInventoryData {
  promoInventory: PromotionInventory[];
}

interface PromoInventoryVars {
  year: number;
}

export const GET_PROMO_QUERY = gql`
  query GetPromotionsData($orgId: String!) {
    getPromoWithOrgId(orgId: $orgId) {
      promoCodePrefix
      promoCodePoints
      promoType
      validity
      promoTotalCount
      sponsorId
      id
      pointsAvailable
      noPointsPerPromo
      sponsorLogo
      createdAt
      expired
    }
  }
`;

export const GET_HUNT_QUERY = gql`
  query getAllHuntsByOrg($orgId: String!) {
    getAllHuntsByOrg(orgId: $orgId) {
      id
      huntCodePrefix
      huntCodePoints
      validity
      validityDay
      orgId
      sponsorId
      sponsorLogo
      noPointsPerHunt
      pointsAvailable
      isActive
      createdAt
    }
  }
`;

export const GET_VIDEO_PROMO_QUERY = gql`
  query GetVideoPromotionsData($orgId: String!) {
    getVideoPromoWithOrgId(orgId: $orgId) {
      promoCodePrefix
      promoCodePoints
      promoType
      validity
      promoTotalCount
      sponsorId
      id
      pointsAvailable
      noPointsPerPromo
      sponsorLogo
      createdAt
      expired
    }
  }
`;
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tabpanel-parent">{children}</div>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `game-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function socialProps(index: any) {
  return {
    id: `social-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function PromotionLists({ onParentRender }: Props) {
  const [open, setOpen] = React.useState(false);
  const [promotionId, setPromotionId] = React.useState<any>();
  let { orgId } = useParams<ParamTypes>();
  const [promotionType, setPromotionType] = React.useState<any>("Email");
  const { loading, data, refetch } = useQuery<any>(GET_PROMO_QUERY, {
    variables: { orgId: orgId },
  });
  const {
    loading: loadingHunt,
    data: dataHunt,
    refetch: refetchHunt,
  } = useQuery<any>(GET_HUNT_QUERY, { variables: { orgId: orgId } });

  const {
    loading: loadingVideoPromo,
    data: dataVideoPromo,
    refetch: refetchVideoPromo,
  } = useQuery<any>(GET_VIDEO_PROMO_QUERY, { variables: { orgId: orgId } });

  const [tabPromoData, setTabPromoData] = React.useState<any>([]);

  const handleClickOpen = () => {
    setOpen(true);
    setPromotionId(false);
  };

  useEffect(() => {
    refetch();
    onParentRender();
    refetchHunt();
    refetchVideoPromo();
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setPromotionType("");
  };

  const handleEditClickOpen = (id: string, promotionType: string) => {
    setOpen(true);
    setPromotionId(id);
    setPromotionType(promotionType);
  };
  const [socialTab, setSocialTab] = React.useState(0);

  const handleChangeSocial = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setSocialTab(newValue);
  };

  useEffect(() => {
    const updateTabPromoData = () => {
      switch (socialTab) {
        case 0:
          setTabPromoData(data?.getPromoWithOrgId);
          break;
        case 1:
          setTabPromoData(
            dataHunt?.getAllHuntsByOrg?.filter(
              (hunt: any) => hunt.promoType === "Hunt"
            )
          );
          break;
        case 2:
          setTabPromoData(
            dataVideoPromo?.getVideoPromoWithOrgId?.filter(
              (video: any) => video.promoType === "Video"
            )
          );
          break;
        default:
          setTabPromoData([]);
      }
    };

    updateTabPromoData();
  }, [socialTab, data, dataHunt, dataVideoPromo]);

  return (
    <div className="list-page transaction-list-wrapper">
      <Grid container xs={12} sm={12} spacing={2}>
        <Grid item sm={12} xs={12}>
          <Paper className="table-wrapper">
            <Grid container className="table-head-section p-relative" sm={12}>
              <AppBar className="pad0 header-bar" position="static">
                <Tabs
                  value={socialTab}
                  onChange={handleChangeSocial}
                  aria-label="simple tabs example"
                >
                  {["PROMOTION LIST", "HUNT LIST", "VIDEO QUIZ"].map(
                    (label, index) => (
                      <Tab key={index} label={label} {...socialProps(index)} />
                    )
                  )}
                </Tabs>
              </AppBar>
              <Grid
                item
                sm={4}
                className="select-with-label align-right flx-align-center flx-end list-sidebar"
              >
                {/* <label className="select-label">Sort By</label>
                            <DropdownSelect/> */}
                <button
                  onClick={handleClickOpen}
                  data-view="Create"
                  className="btn btn-primary add-btn"
                >
                  <span data-view="Create" className="btn-icon"></span>
                  <label className="btn-caption">Add Promotion / Hunt</label>
                </button>
              </Grid>
            </Grid>
            <TabPanel value={socialTab} index={0}>
              <TableContainer
                className="table-container"
                style={{ height: 450, width: "100%" }}
                component={Paper}
              >
                <Table stickyHeader className="" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Promocodes</TableCell>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">Sponsors</TableCell>
                      <TableCell align="left">Points per promo</TableCell>
                      <TableCell align="left">Availability/Total</TableCell>
                      <TableCell align="left">Creation Date</TableCell>
                      <TableCell align="left">Validity Date</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableBody>
                      <TableCell colSpan={6}>
                        <Loader size="90px" />
                      </TableCell>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {tabPromoData?.map((promo: any) => (
                        <TableRow
                          className="pointer"
                          onClick={() => handleEditClickOpen(promo.id, "Email")}
                          data-id={promo.id}
                          data-view="Edit"
                        >
                          <TableCell className="label-parent " padding="none">
                            <label className="promocode pointer">
                              {promo.promoCodePrefix} {promo.promoCodePoints}
                            </label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <label className="">{promo.promoType}</label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <img
                              className="profile-img promo-list"
                              src={
                                promo.sponsorLogo ||
                                "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"
                              }
                            />
                            <label className="">{}</label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <label className="">{promo.promoCodePoints}</label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <label className="">
                              {promo.pointsAvailable +
                                "/" +
                                promo.promoTotalCount}
                            </label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <label className="">
                              {moment(parseInt(promo.createdAt)).format(
                                "MM/DD/YYYY"
                              )}
                            </label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <label className="">
                              {moment(promo.validity).format("D MMM YYYY")}
                            </label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            {promo.expired || promo.pointsAvailable == 0 ? (
                              <label className="red">Expired</label>
                            ) : (
                              <label className="green">Active</label>
                            )}
                          </TableCell>
                          <TableCell
                            className="label-parent"
                            padding="none"
                          ></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={socialTab} index={1}>
              <TableContainer
                className="table-container"
                style={{ height: 450, width: "100%" }}
                component={Paper}
              >
                <Table stickyHeader className="" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Hunt Codes</TableCell>
                      <TableCell align="left">Sponsors</TableCell>
                      <TableCell align="left">Points per User</TableCell>
                      <TableCell align="left">Availability/Total</TableCell>
                      <TableCell align="left">Creation Date</TableCell>
                      <TableCell align="left">Validity Date</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  {loadingHunt ? (
                    <TableBody>
                      <TableCell colSpan={6}>
                        <Loader size="90px" />
                      </TableCell>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {tabPromoData?.map((hunt: any) => {
                        let expired =
                          new Date().setHours(0, 0, 0, 0) <=
                          new Date(hunt.validity).setHours(0, 0, 0, 0)
                            ? true
                            : false;
                        return (
                          <TableRow
                            className="pointer"
                            onClick={() => handleEditClickOpen(hunt.id, "Hunt")}
                            data-id={hunt.id}
                            data-view="Edit"
                          >
                            <TableCell className="label-parent " padding="none">
                              <label className="promocode pointer">
                                {hunt.huntCodePrefix} {hunt.huntCodePoints}
                              </label>
                            </TableCell>
                            <TableCell className="label-parent" padding="none">
                              <img
                                className="profile-img promo-list"
                                src={
                                  hunt.sponsorLogo ||
                                  "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"
                                }
                              />
                              <label className="">{}</label>
                            </TableCell>
                            <TableCell className="label-parent" padding="none">
                              <label className="">{hunt.noPointsPerHunt}</label>
                            </TableCell>
                            <TableCell className="label-parent" padding="none">
                              <label className="">
                                {hunt.pointsAvailable +
                                  "/" +
                                  hunt.huntCodePoints}
                              </label>
                            </TableCell>
                            <TableCell className="label-parent" padding="none">
                              <label className="">
                                {moment(parseInt(hunt.createdAt)).format(
                                  "MM/DD/YYYY"
                                )}
                              </label>
                            </TableCell>
                            <TableCell className="label-parent" padding="none">
                              <label className="">{hunt.validity}</label>
                            </TableCell>
                            <TableCell className="label-parent" padding="none">
                              {expired && hunt.pointsAvailable != 0 ? (
                                <label className="green">Active</label>
                              ) : (
                                <label className="red">Expired</label>
                              )}
                            </TableCell>
                            <TableCell
                              className="label-parent"
                              padding="none"
                            ></TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={socialTab} index={2}>
              <TableContainer
                className="table-container"
                style={{ height: 450, width: "100%" }}
                component={Paper}
              >
                <Table stickyHeader className="" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Promocodes</TableCell>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">Sponsors</TableCell>
                      <TableCell align="left">Points per promo</TableCell>
                      <TableCell align="left">Availability/Total</TableCell>
                      <TableCell align="left">Creation Date</TableCell>
                      <TableCell align="left">Validity Date</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  {loadingVideoPromo ? (
                    <TableBody>
                      <TableCell colSpan={6}>
                        <Loader size="90px" />
                      </TableCell>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {tabPromoData?.map((promo: any) => (
                        <TableRow
                          className="pointer"
                          onClick={() => handleEditClickOpen(promo.id, "Email")}
                          data-id={promo.id}
                          data-view="Edit"
                        >
                          <TableCell className="label-parent " padding="none">
                            <label className="promocode pointer">
                              {promo.promoCodePrefix} {promo.promoCodePoints}
                            </label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <label className="">{promo.promoType}</label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <img
                              className="profile-img promo-list"
                              src={
                                promo.sponsorLogo ||
                                "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"
                              }
                            />
                            <label className="">{}</label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <label className="">{promo.promoCodePoints}</label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <label className="">
                              {promo.pointsAvailable +
                                "/" +
                                promo.promoTotalCount}
                            </label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <label className="">
                              {moment(parseInt(promo.createdAt)).format(
                                "MM/DD/YYYY"
                              )}
                            </label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            <label className="">
                              {moment(promo.validity).format("D MMM YYYY")}
                            </label>
                          </TableCell>
                          <TableCell className="label-parent" padding="none">
                            {promo.expired || promo.pointsAvailable == 0 ? (
                              <label className="red">Expired</label>
                            ) : (
                              <label className="green">Active</label>
                            )}
                          </TableCell>
                          <TableCell
                            className="label-parent"
                            padding="none"
                          ></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>

      <CreatePromotions
        onOpen={open}
        promoId={promotionId}
        promotionHuntType={promotionType}
        handleCloseClick={handleClose}
      />
    </div>
  );
}
