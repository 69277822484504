import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import MUIDataTable from "mui-datatables";
import Loader from "../../components/Loader/loader";
import Grid from "@material-ui/core/Grid";
import DropdownSelect from "../../components/Dropdown/dropdownselect";
// import Button from "@material-ui/core/Button";
import moment from "moment";
import "../../constant.css";
import { CSVLink } from "react-csv";
import {
    Snackbar,
    Backdrop,
    CircularProgress,
    FormControl,
    InputLabel,
    OutlinedInput,
    Button,
    InputAdornment,
} from "@material-ui/core";
// import "./csvlist.css";
import Axios from "axios";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
interface MemberInventory {
    id: string;
    promoType: string;
}

interface MemberInventoryData {
    memberInventory: MemberInventory[];
}

interface MemberInventoryVars {
    year: number;
}

export const GET_AWARDS_POINTS = gql`
query awardPointsToPartonId($data:AwardDetailInput!){
    awardPointsToPartonId(data:$data){
        totalCountCSVFile
        totalFailedErrorCount
        totalSuccessCount
        totalSuccessCountWithError50096
        awardDetails {
          patronAccountId
          newPatronBalance
          expirationDate
          reasonNote
          numberOfPoints
          currentPatronBalance
          errorCode
          errorMessage
          status
        }
      }
}
`;

export default function CsvList() {
    const history = useHistory();
    const [total, setTotal] = useState(0);
    const [active, setActive] = useState(0);
    const [inActive, setInActive] = useState(0);
    const inputRef: any = useRef(null);
    const [csvFile, setCsvFile] = useState();
    const [csvArray, setCsvArray] = useState([]);
    const [arrayToCsv, setArrayToCsv] = useState<any>([]);
    const [csvParsePayload, setCsvParsePayload] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPatronBalance, setIsPatronBalance] = useState(false);
    const [csvFileName, setCsvFileName] = useState("");
    const [showButton, setShowbutton] = useState(false);
    const [totalCountCSVFile, setTotalCountCSVFile] = useState(0);
    const [totalFailedErrorCount, setTotalFailedErrorCount] = useState(0);
    const [totalSuccessCount, setTotalSuccessCount] = useState(0);
    const [totalSuccessCountWithError50096, setTotalSuccessCountWithError50096] = useState(0);

    const memberprofileClick = (userId: string) => {
        history.push({ pathname: "/memberprofile", state: userId });
    };
    const [Error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState<any>("");
    const [errorType, setErrorType] = useState<any>("");


    const Alert: any = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    const [getAwardPoints] = useLazyQuery(GET_AWARDS_POINTS, {
        fetchPolicy: 'cache-and-network',
        // variables: { orgType },
        onCompleted: (data) => {
            console.log("RESP", data?.awardPointsToPartonId)
            let newData: any = [...csvArray]
            let exportCsvArray: any = [{
                0: "patronAccountId",
                1: "numberOfPoints",
                2: "expirationDate",
                3: "reasonNote",
                4: "currentPatronBalance",
                5: "newPatronBalance",
                6: "status",
                7: "errorCode",
                8: "errorMessage"
            }];

            setTotalCountCSVFile(data.awardPointsToPartonId.totalCountCSVFile)
            setTotalFailedErrorCount(data.awardPointsToPartonId.totalFailedErrorCount)
            setTotalSuccessCount(data.awardPointsToPartonId.totalSuccessCount + data.awardPointsToPartonId.totalSuccessCountWithError50096)
            setTotalSuccessCountWithError50096(data.awardPointsToPartonId.totalSuccessCountWithError50096)

            data.awardPointsToPartonId.awardDetails.map((res: any, index: number) => {
                let ary = {
                    "patronAccountId": res.patronAccountId,
                    "numberOfPoints": res.numberOfPoints,
                    "expirationDate": res.expirationDate,
                    "reasonNote": res.reasonNote,
                    "currentPatronBalance": res.currentPatronBalance,
                    "newPatronBalance": res.newPatronBalance,
                    "status": res.status,
                    "errorCode": res.errorCode,
                    "errorMessage": res.errorMessage
                }
                exportCsvArray.push(ary);
            });

            console.log("ARRY", arrayToCsvFunc(exportCsvArray));
            setCsvArray(data.awardPointsToPartonId.awardDetails);
            setArrayToCsv(arrayToCsvFunc(exportCsvArray))
            setIsLoading(false);
            setIsPatronBalance(true);

            setErrorType("success")
            setErrorMsg("Award points has been applied")
            setError(true)
        },
        onError: (error) => {
            console.log(" error", error);
            setIsLoading(false);
            setIsPatronBalance(false);
            setErrorType("error")
            setErrorMsg(error?.message)
            setError(true)
        }
    });


    // const columns: any = [
    //     "Patron Account Id",
    //     "Award new points",
    //     "Expiration Date",
    //     "Reason",
    // ];

    const columns: any = [
        {
            name: "patronAccountId",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "patronAccountId") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(0)} >
                            <a className="sort-by">Patron Account Id</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "numberOfPoints",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "numberOfPoints") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Award new points</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "expirationDate",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "expirationDate") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                            <a className="sort-by">Expiration Date</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "reasonNote",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "reasonNote") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(3)} >
                            <a className="sort-by">Reason</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "currentPatronBalance",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "currentPatronBalance") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Old Patron Balance</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "NewPatronBalance",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "NewPatronBalance") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(5)} >
                            <a className="sort-by">New Patron Balance</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "status",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "status") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(6)} >
                            <a className="sort-by">Status</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "errorCode",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "errorCode") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(7)} >
                            <a className="sort-by">Error Code</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "errorMsg",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "errorMsg") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(8)} >
                            <a className="sort-by">Error Message</a>
                        </th>
                    )
                },
            }
        },
    ];

    const columnswithpatronbalance: any = [
        {
            name: "patronAccountId",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "patronAccountId") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(0)} >
                            <a className="sort-by">Patron Account Id</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "numberOfPoints",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "numberOfPoints") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Award new points</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "expirationDate",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "expirationDate") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                            <a className="sort-by">Expiration Date</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "reasonNote",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "reasonNote") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(3)} >
                            <a className="sort-by">Reason</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "currentPatronBalance",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "currentPatronBalance") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Old Patron Balance</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "newPatronBalance",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "newPatronBalance") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(5)} >
                            <a className="sort-by">New Patron Balance (Updated)</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "status",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "status") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(6)} >
                            <a className="sort-by">Status</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "errorCode",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "errorCode") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(7)} >
                            <a className="sort-by">Error Code</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "errorMessage",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "errorMessage") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(8)} >
                            <a className="sort-by">Error Message</a>
                        </th>
                    )
                },
            }
        },
    ];
    // const columnswithpatronbalance: any = [
    //     "Patron Account Id",
    //     "Award new points",
    //     "Expiration Date",
    //     "Reason",
    //     "Current Patron Balance (Updated)",
    // ];

    const options: any = {
        filter: false,
        filterType: "dropdown",
        responsive: "standard",
        print: false,
        download: false,
        pagination: false,
        viewColumns: false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader: true,
        tableBodyHeight: "450px",
        search: false,
    };

    const handleClick = (e: any) => {
        inputRef.current.click(fileChange);
    };


    const isValidFileUploaded = (file: any) => {
        const validExtensions = ['csv']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }

    const fileChange = (e: any) => {
        if (e.target.files.length < 1) {
            return;
        }
        const file = e.target.files[0];
        if (isValidFileUploaded(file)) {
            setCsvFile(file)
            setCsvFileName(e.target.files[0].name)
            //file is valid
        } else {
            setErrorType("error")
            setErrorMsg("Invalid file format only .csv file support")
            setError(true)
            //file is invalid
        }
    }
    const handleClose = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;

        }
        setError(false);
    };


    const processCSV = (str: any, delim = ",") => {
        const headers = str.slice(0, str.indexOf("\n")).split(delim);
        const rows: any = str.slice(str.indexOf("\n") + 1).split("\n");
        const newArray = rows.map((row: any) => {
            const values = row.split(delim);
            const eachObject = headers.reduce((obj: any, header: any, i: any) => {
                let h = header.replace(/^"|"$/g, "");
                obj[h] = values[i];
                console.log("OBJECT", obj);
                return obj;
            }, {});
            return eachObject;
        });


        setCsvArray(newArray);
    };
    function csvToJson(csv: any) {
        // \n or \r\n depending on the EOL sequence
        const lines = csv.split('\r\n');
        const delimeter = ',';
        const intArray: any = [
            'loyaltyProgramId',
            'patronAccountId',
            'numberOfPoints',
            'noteTypeId',
        ]
        const result = [];

        const headers = lines[0].split(delimeter);

        for (const line of lines) {
            const obj: any = {};
            const row = line.split(delimeter);

            for (let i = 0; i < headers.length; i++) {
                const header = headers[i];
                if (intArray.includes(header)) {
                    obj[header] = parseInt(row[i]);
                } else {
                    obj[header] = row[i];
                }
            }

            result.push(obj);
        }

        // Prettify output
        return result;
    }
    const arrayToCsvFunc = (data: any) => {
        const titleKeys = Object.keys(data[0])

        const refinedData: any = []

        // refinedData.push(titleKeys)

        data.forEach((item: any) => {
            refinedData.push(Object.values(item))
        })


        let csvContent = ''

        refinedData.forEach((row: any) => {
            csvContent += row.join(',') + '\n'
        })
        return csvContent;
    }
    const submit = () => {
        setIsPatronBalance(false);
        const input: any = csvFile;
        const reader = new FileReader();
        reader.onload = function (e: any) {
            const text = e.target.result;
            const csvArrayCount = csvToArray(text);
            if (csvArrayCount.length > 501) {
                setCsvArray([]);
                setErrorType("error")
                setErrorMsg("This tool supports a maximum number of 500 users, please edit the CSV and re-upload the file.")
                setError(true)
                setShowbutton(false);
            } else {
                const array = csvToArray(text);
                // console.log("CSV TO ARY", csvToArray(text))
                setArrayToCsv(csvToArray(text));
                // console.log("csvtoArray", csvToArray(text))
                // document.write(JSON.stringify(array));
                setCsvArray(array.splice(1, array.length));
                const csvParse: any = csvToJson(text);
                // const csvParsePayload = csvParse.splice(1, csvParse.length);
                setCsvParsePayload(csvParse.splice(1, csvParse.length));
                // console.log("csvParsePayload", csvParsePayload);
                const jsonString = JSON.stringify(csvParse, null, 2);
                // get awards api call
                setShowbutton(true);
            }
        };

        reader.readAsText(input);

        function csvToArray(str: any, delimiter = ",") {
            let array: any = [];
            str.split("\r\n").map(function (line: any, index: number) {
                const splitData = line.split(delimiter);
                array.push({
                    patronAccountId: splitData[0],
                    numberOfPoints: splitData[1],
                    expirationDate: splitData[2],
                    reasonNote: splitData[3],
                    currentPatronBalance: "",
                    newPatronBalance: "",
                    status: "",
                    errorCode: "",
                    errorMsg: "",
                })
            });
            return array;
        }
    };

    const submitAward = () => {
        setIsLoading(true);
        getAwardPoints({
            variables: {
                data: {
                    "awardDetailObject": csvParsePayload
                }
            }
        })
    }
    useEffect(() => {
        if (csvFile) {
            submit();
        }
    }, [csvFile])
    return (
        <div className="list-page manage-member-wrapper">
            <h2 className="page-title">Bulk Award Points to Patrons</h2>
            <h4 className="sub-caption">This tool will award the loyalty points to the Patrons by uploading your comma separated file.</h4>
            <h4 className="sub-caption">The file is expected to have patronAccountId,numberOfPoints,expirationDate,reasonNote fields.</h4>
            <h4 className="sub-caption">Please <a href="https://pv-images-alpha.s3.us-west-2.amazonaws.com/bulk-award-points.csv">download</a> example file for reference.</h4>
            <h4 className="sub-caption" style={{fontWeight:"bold", textDecoration:"underline"}}>*Note: Please upload csv file with not more than 500 patrons at a time.</h4>
            {
                isPatronBalance ?
                    <div>
                        <Grid container className="item-status manage-member-counts" xs={12}>
                            <Grid item sm={2} xs={2}>
                                <span className="data-bold total-member">{totalCountCSVFile && totalCountCSVFile > 0 ? totalCountCSVFile : 0}</span><label className="sub-caption">Patrons in CSV Loaded</label>
                            </Grid>
                            <Grid item sm={2} xs={2}>
                                <span className="data-bold inactive-member">{totalSuccessCount && totalSuccessCount > 0 ? totalSuccessCount : 0}</span><label className="sub-caption">Success</label>
                            </Grid>
                            <Grid item sm={2} xs={2}>
                                <span className="data-bold active-member">{totalFailedErrorCount && totalFailedErrorCount > 0 ? totalFailedErrorCount : 0}</span><label className="sub-caption">Errors</label>
                            </Grid>
                            <Grid item sm={2} xs={2}>
                                <span className="data-bold inactive-member">{totalSuccessCountWithError50096 && totalSuccessCountWithError50096 > 0 ? totalSuccessCountWithError50096 : 0}</span><label className="sub-caption">Warnings (Patrons with no Loyalty Points before)</label>
                            </Grid>
                        </Grid>
                    </div>
                    : null
            }
            <Grid container xs={12} sm={12}>
                <Grid item sm={12} xs={12}>
                    <Paper className="table-wrapper">
                        <div className="table-container muidata-table">
                            <div
                                className="color-code-container"
                                style={{ overflow: "hidden" }}
                            >
                                {/* <Grid container style={{ justifyContent: "space-between" }}>
                                    <Grid >
                                        <div>
                                            <FormControl
                                                style={{
                                                    bottom: "20px",
                                                    marginTop: "60px",
                                                }}
                                            >
                                                <InputLabel
                                                    style={{
                                                        marginLeft: "10px",
                                                        fontSize: 15,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        bottom: "20px",
                                                    }}
                                                >
                                                    Select CSV
                                                </InputLabel>
                                                <OutlinedInput
                                                    disabled
                                                    id="API-KEY"
                                                    style={{
                                                        top: "11px",
                                                        height: "41px",
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <Button
                                                                onClick={handleClick}
                                                                style={{
                                                                    backgroundColor: "#2b2e4c",
                                                                    color: "white",
                                                                    fontSize: 10,
                                                                    height: "24px",
                                                                }}
                                                            >
                                                                <input
                                                                    type="file"
                                                                    ref={inputRef}
                                                                    accept=".csv"
                                                                    style={{ display: "none" }}
                                                                    id="csvFile"
                                                                    onChange={(e: any) => {
                                                                        setCsvFile(e.target.files[0]);
                                                                    }}
                                                                    hidden
                                                                />
                                                                Select CSV
                                                            </Button>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid style={{ marginTop: "60px" }}>
                                        <Button
                                            className="btn btn-primary add-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (csvFile) submit();
                                            }}
                                        >
                                            Load
                                        </Button>
                                        <Button
                                            className="btn btn-primary add-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (csvFile) submitAward();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid> */}

                                <Grid container style={{ justifyContent: "space-between" }}>
                                    <Grid style={{ width: "20%" }}>
                                        <div>
                                            <FormControl
                                                style={{
                                                    bottom: "20px",
                                                    marginTop: "58px",
                                                }}
                                            >
                                                <InputLabel
                                                    style={{
                                                        marginLeft: "10px",
                                                        fontSize: 15,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        bottom: "20px",
                                                    }}
                                                >{!csvFileName ? "Upload CSV file" : csvFileName}
                                                </InputLabel>
                                                <OutlinedInput
                                                    disabled
                                                    id="API-KEY"
                                                    style={{
                                                        top: "11px",
                                                        height: "41px",
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <Button
                                                                onClick={handleClick}
                                                                style={{
                                                                    backgroundColor: "#2b2e4c",
                                                                    color: "white",
                                                                    fontSize: 10,
                                                                    height: "24px",
                                                                }}
                                                            >
                                                                <input
                                                                    type="file"
                                                                    ref={inputRef}
                                                                    accept=".csv"
                                                                    style={{ display: "none" }}
                                                                    id="csvFile"
                                                                    onChange={fileChange}
                                                                    hidden
                                                                />
                                                                Upload
                                                            </Button>
                                                        </InputAdornment>
                                                    }
                                                />

                                            </FormControl>



                                            <Snackbar open={Error
                                            } autoHideDuration={6000} onClose={handleClose}>
                                                <Alert onClose={handleClose} severity={errorType}>{errorMsg}</Alert>
                                            </Snackbar>
                                        </div>
                                    </Grid>
                                    <Grid style={{
                                        marginTop: "55px",
                                    }}>
                                        {
                                            showButton ?
                                                isLoading ?
                                                    <Button
                                                        className="btn btn-primary add-btn"
                                                        style={{ minWidth: "82px" }}
                                                    >
                                                        Loading&nbsp;&nbsp;<CircularProgress color="inherit" size={15} />
                                                    </Button> :
                                                    <Button
                                                        className="btn btn-primary add-btn"
                                                        style={{ minWidth: "82px" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (csvFile) submitAward();
                                                        }}
                                                    >
                                                        Award Points
                                                    </Button>
                                                : null

                                        }

                                    </Grid>
                                    {showButton ?
                                        <>
                                            <Grid style={{ marginTop: "58px", marginLeft: "60%" }}>
                                                <Button
                                                    className="btn btn-secondary"
                                                    style={{ minWidth: "82px", }}
                                                >
                                                    <CSVLink
                                                        filename={"exported_csv.csv"}
                                                        data={arrayToCsv}
                                                        style={{ color: "#8A8D9D", textDecoration: "none" }}
                                                    >
                                                        Export to CSV
                                                    </CSVLink>
                                                </Button>
                                            </Grid>
                                            {/* <Grid
                                                style={{
                                                    marginTop: "58px",
                                                }}
                                            >
                                                {
                                                    isLoading ?
                                                    <Button
                                                        className="btn btn-primary add-btn"
                                                        style={{ minWidth: "82px" }}
                                                    >
                                                        Loading&nbsp;&nbsp;<CircularProgress color="inherit" size={15} />
                                                    </Button> :
                                                    <Button
                                                        className="btn btn-primary add-btn"
                                                        style={{ minWidth: "82px" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (csvFile) submitAward();
                                                        }}
                                                    >
                                                        Award Points
                                                    </Button>
                              
                                                        
                                                }

                                            </Grid> */}
                                        </>
                                        : null}
                                </Grid>
                            </div>
                            {loading ? (
                                <Loader size="90px" />
                            ) : (
                                <MUIDataTable
                                    title={"Bulk Award Points to Patrons"}
                                    data={csvArray}
                                    columns={isPatronBalance ? columnswithpatronbalance : columns}
                                    options={options}
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
