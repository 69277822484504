import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import PromotionLists from "./promotionlist";
import SponsorList from "./sponsorList";
import MerchandiseList from "./merchandiselist";
import FulfillmentList from "./fulfillmentlist";
import MemberList from "./memberlist";
import MembershipConfig from "./membershipconfig";
import EventsList from "./eventslist";
import Loyalty from "./loyalty";
import EmailPatron from "./emailpatron";
import SettingsOrg from "./settingsOrg";
import "./clubdetailtabs.css";

interface Props {
  onParentRender: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface ParamTypes {
  orgId: string;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tabpanel-parent">{children}</div>}
    </div>
  );
};

const a11yProps = (index: any) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

const ClubDetailsTabs: React.FC<Props> = ({ onParentRender }) => {
  const { state } = useLocation<{ state?: string }>();
  const { orgId } = useParams<ParamTypes>();
  const [isMemberListShow, setIsMemberListShow] = useState<boolean>(false);
  const initialTabValue = state ? 1 : 0;
  const [value, setValue] = useState(initialTabValue);

  useEffect(() => {
    let ids = [
      "12a02063-654b-4740-8788-f7c2e65133c1",
      "c758d3fa-fc35-46c3-a169-26a9e05eb9ca",
      "c0479d6a-1321-4b91-ba39-0ee43eb52b57",
    ];
    if (ids.find((e) => e === orgId)) {
      setIsMemberListShow(true);
    }
  }, [orgId]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const tabs = [
    {
      label: "Merchandise",
      component: <MerchandiseList onParentRender={onParentRender} />,
    },
    {
      label: "Fulfillment",
      component: <FulfillmentList onParentRender={onParentRender} />,
    },
    isMemberListShow && {
      label: "Members",
      component: <MemberList onParentRender={onParentRender} />,
    },
    {
      label: "Events",
      component: <EventsList onParentRender={onParentRender} />,
    },
    {
      label: "Promotions",
      component: <PromotionLists onParentRender={onParentRender} />,
    },
    {
      label: "Sponsor",
      component: <SponsorList onParentRender={onParentRender} />,
    },
    {
      label: "Loyalty Preview",
      component: <Loyalty onParentRender={onParentRender} />,
    },
    {
      label: "Email Patron",
      component: <EmailPatron onParentRender={onParentRender} />,
    },
    isMemberListShow && {
      label: "Membership Config",
      component: <MembershipConfig onParentRender={onParentRender} />,
    },
    { label: "Settings", component: <SettingsOrg /> },
  ].filter((tab): tab is { label: string; component: JSX.Element } =>
    Boolean(tab)
  );

  return (
    <div className="custom-tab org-info-main">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="club details tabs"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

export default ClubDetailsTabs;
