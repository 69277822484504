import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "./App.css";
import "./components/Dropdown/dropdownselect.css";
import PTVRouter from "./router";
import SignInComp from "./signin";
import ForgotPasswordComp from "./forgotpassword";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ProfileImage from "./assets/who@2x.png";
import Amplify, { Auth } from "aws-amplify";
import {
  AmplifyTheme,
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  VerifyContact,
  withAuthenticator,
} from "aws-amplify-react";

// import { AmplifySignOut,AmplifyAuthenticator, AmplifySignUp, AmplifySignIn  } from '@aws-amplify/ui-react';
import { useQuery, gql } from "@apollo/client";
interface ClubInventory {
  id: number;
  name: string;
}

interface ClubInventoryData {
  clubInventory: ClubInventory[];
}

interface ClubInventoryVars {
  year: number;
}

const GET_CLUB_INVENTORY = gql`
  query GetClubInventory {
    orgs {
      id
      name
    }
  }
`;

const GET_USER_BY_ID = gql`
  query getUsers($username: String!) {
    getUserWithIdAdmin(username: $username) {
      id
      username
      givenName
      email
      profileImage
      dob
      address
      phoneNumber
    }
  }
`;
const themeStyle = {
  ...AmplifyTheme,
  signInButton: {
    color: "red",
    backgroundColor: "green",
  },
  container: {
    paddingLeft: "100px",
  },
};

function App() {
  const { loading, data } = useQuery<any>(GET_CLUB_INVENTORY);
  const [userData, setUserData] = React.useState<any>({});
  const [adminData, setadminData] = React.useState<any>({});

  const [render, setRender] = React.useState(false);
  //const userName =  userData.sub ? userData.sub : "";
  //const { loading:userLoading, data:adminUserData,refetch:userRefetch } = useQuery<any>(GET_USER_BY_ID, { variables: {username: userName } });

  const history = useHistory();
  const editProfileClick = (userId: any) =>
    history.push({ pathname: "/profiledetails", state: userId });

  const valuesUpdate = () => {
    console.log("PARENT");
    //pointholdersRefetch();
    setRender(true);
  };

  const signOutClick = () => {
    try {
      Auth.signOut();
      history.push("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  useEffect(() => {
    //userRefetch();

    //setadminData(adminUserData.getUserWithIdAdmin);
    //if(!userLoading){
    //   setadminData(adminUserData.getUserWithIdAdmin);
    //}
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        setUserData(user.attributes);
        localStorage.setItem("userId", user.attributes.sub);
      })
      .catch((err) => console.log(err));
  }, [render]);
  // if (loading) return null;
  // console.log(data.orgs);
  return (
    <div className="ptv-app">
      <nav className="ptv-nav-left">
        <div className="logo"></div>
        <ul className="left-nav">
          <li className="dashboard-menu-item" title="Dashboard">
            <NavLink exact to="/" activeClassName="active">
              <div></div>
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li className="manage-club-menu-item" title="Manage Orgs">
            <NavLink exact to="/manageclubs" activeClassName="active">
              <div></div>
              <span>Organizations</span>
            </NavLink>
          </li>
          <li className="manage-member-menu-item" title="Manage Members">
            <NavLink exact to="/managemembers" activeClassName="active">
              <div></div>
              <span>Members</span>
            </NavLink>
          </li>
          <li className="manage-csv-menu-item" title="Award Points">
            <NavLink exact to="/csvlist" activeClassName="active">
              <div></div>
              <span>Award Points</span>
            </NavLink>
          </li>
          <li className="manage-csv-menu-item" title="Award FanCash">
            <NavLink exact to="/fancash" activeClassName="active">
              <div></div>
              <span>Award FanCash</span>
            </NavLink>
          </li>
          <li className="refresh-menu-item" title="Transactions">
            <NavLink exact to="/transactions">
              <div></div>
              <span>Transactions</span>
            </NavLink>
          </li>
        </ul>
      </nav>
      <header className="ptv-header">
        <div className="header-section">
          <Grid container className="header-container" sm={12} spacing={3}>
            <Grid item sm={7}>
              <div className="pointsville-title-container">
                <h4 className="pointsville-title">
                  PointsVille - Admin Console
                </h4>
                <label className="sub-caption">
                  Manage Organizations, Sponsors, Promotions, and Members.
                </label>
              </div>
            </Grid>
            <Grid item className="align-right" sm={5}>
              <div className="profile-section">
                <img
                  onClick={editProfileClick}
                  src={localStorage.getItem("ProfileImg") || ProfileImage}
                  className="profile-img"
                  alt="profile image"
                />
                <label
                  onClick={() => editProfileClick(userData.sub)}
                  className="profile-name"
                >
                  {userData.name}
                </label>
                <span
                  onClick={signOutClick}
                  className="logout-icon mrgl-30"
                ></span>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* <div><AmplifySignOut /></div> */}
      </header>
      <main className="ptv-main">
        <PTVRouter onParentRender={valuesUpdate} />
      </main>
    </div>
  );
}

export default withAuthenticator(
  App,
  false,
  [<SignInComp />, <ForgotPassword />],
  { theme: themeStyle }
);
